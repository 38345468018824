import {
    getEvents,
    addEvent,
    editEvent,
    deleteEvent,
} from "../../config/config";
import { toast } from "react-toastify";
import { parseError } from "../../utils/parseError";
import { LOGOUT_USER, SET_PERMISSIONS } from "./AuthActions";
import { handleError } from "../../utils/utils";

export const GET_EVENTS_REQUEST = "GET_EVENTS_REQUEST";
export const GET_EVENTS_SUCCESS = "GET_EVENTS_SUCCESS";
export const GET_EVENTS_ERROR = "GET_EVENTS_ERROR";

export const ADD_EVENT_STARTED = "ADD_EVENT_STARTED";
export const ADD_EVENT_END = "ADD_EVENT_END";

export const EDIT_EVENT_STARTED = "EDIT_EVENT_STARTED";
export const EDIT_EVENT_END = "EDIT_EVENT_END";

export const DELETE_EVENT_STARTED = "DELETE_EVENT_STARTED";
export const DELETE_EVENT_END = "DELETE_EVENT_END";

export const CLEAR_ALL_EVENTS = "CLEAR_ALL_EVENTS";

export const CLEAR_EVENTS_LOADING = "CLEAR_EVENTS_LOADING"

const actions = {
    // Get All restaurants with the selected one
    getEvents: (ruuid, bruuid) => async (dispatch, getState) => {
        const currentRestaurant = getState().event.selectedRestaurant
        const currentBranch = getState().event.selectedBranch
        if (ruuid !== currentRestaurant || bruuid !== currentBranch)
            dispatch({
                type: GET_EVENTS_REQUEST,
                payload: {
                    ruuid: ruuid,
                    bruuid: bruuid
                }
            });
        await getEvents(ruuid, bruuid).then((res) => {
            if (res?.status === 200) {
                dispatch({
                    type: GET_EVENTS_SUCCESS,
                    payload: {
                        events: res?.data?.events,
                    },
                });
            } else {
                handleError(dispatch)(res, null, 'GET ALL EVENTS ERROR')
                // if (res.status !== 401 && res.status !== 403)
                //     toast.error(res?.data?.message || "Get All Events error");
                // if (res.status === 401) {
                //     dispatch({ type: LOGOUT_USER });
                // }
                // if (res.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: res?.data?.permissions
                //     })
                // }
            }
        });
    },

    addEvent: (ruuid, bruuid, data) => async (dispatch) => {
        dispatch({
            type: ADD_EVENT_STARTED,
        });
        await addEvent(ruuid, bruuid, data).then((res) => {
            if (res?.status === 200) {
                toast.success(res?.data?.message || 'Successfully Saved')
                dispatch({
                    type: GET_EVENTS_SUCCESS,
                    payload: {
                        events: res?.data?.events,
                    },
                });
            } else {
                handleError(dispatch)(res, null, 'ADD EVENT ERROR')
                // if (res.status !== 401 && res.status !== 403)
                //     toast.error(res?.data?.message || "Add Event Error");
                // if (res.status === 401) {
                //     dispatch({ type: LOGOUT_USER });
                // }
                // if (res.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: res?.data?.permissions
                //     })
                // }
            }
        });
        dispatch({
            type: ADD_EVENT_END,
        });
    },

    editEvent: (ruuid, bruuid, uuid, data) => async (dispatch) => {
        dispatch({
            type: EDIT_EVENT_STARTED,
        });
        await editEvent(ruuid, bruuid, uuid, data).then((res) => {
            if (res?.status === 200) {
                toast.success(res?.data?.message || 'Successfully Saved')
                dispatch({
                    type: GET_EVENTS_SUCCESS,
                    payload: {
                        events: res?.data?.events,
                    },
                });
            } else {
                handleError(dispatch)(res, null, 'EDIT EVENT ERROR')
                // if (res.status !== 401 && res.status !== 403)
                //     toast.error(res?.data?.message || "Edit Events Error");
                // if (res.status === 401) {
                //     dispatch({ type: LOGOUT_USER });
                // }
                // if (res.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: res?.data?.permissions
                //     })
                // }
            }
        });
        dispatch({
            type: EDIT_EVENT_END,
        });
    },

    deleteEvent: (ruuid, bruuid, uuid) => async (dispatch) => {
        dispatch({
            type: DELETE_EVENT_STARTED,
        });
        await deleteEvent(ruuid, bruuid, uuid).then((res) => {
            if (res?.status === 200) {
                toast.success(res?.data?.message || 'Successfully Deleted')
                dispatch({
                    type: GET_EVENTS_SUCCESS,
                    payload: {
                        events: res?.data?.events,
                    },
                });
            } else {
                handleError(dispatch)(res, null, 'DELETE EVENT ERROR')
                // if (res.status !== 401 && res.status !== 403)
                //     toast.error(res?.data?.message || "Delete Event Error");
                // if (res.status === 401) {
                //     dispatch({ type: LOGOUT_USER });
                // }
                // if (res.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: res?.data?.permissions
                //     })
                // }
            }
        });
        dispatch({
            type: DELETE_EVENT_END,
        });
    },


    // CLEAR ALL Reducer
    clearAllEvents: () => (dispatch) => {
        dispatch({
            type: CLEAR_ALL_EVENTS,
        });
    },
};

export default actions;
