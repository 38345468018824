import {
  getDeliveryZones,
  addDeliveryZone,
  editDeliveryZone,
  deleteDeliveryZone,
  getOnlineDeliverySettings,
  addEditDeliverySettings
} from "../../config/config";
import { toast } from "react-toastify";
import { parseError } from "../../utils/parseError";
import { LOGOUT_USER, SET_PERMISSIONS } from "./AuthActions";
import { handleError } from "../../utils/utils";
import { SET_BUSY_SETTINGS, SET_CLOSED_SETTINGS } from "./OrderActions";

export const GET_DELIVERYZONES_REQUEST = "GET_DELIVERYZONES_REQUEST";
export const GET_DELIVERYZONES_SUCCESS = "GET_DELIVERYZONES_SUCCESS";
export const GET_DELIVERYZONES_ERROR = "GET_DELIVERYZONES_ERROR";

export const GET_DELIVERYZONES_SETTINGS_REQUEST = "GET_DELIVERYZONES_SETTINGS_REQUEST";
export const GET_DELIVERYZONES_SETTINGS_SUCCESS = "GET_DELIVERYZONES_SETTINGS_SUCCESS";
export const GET_DELIVERYZONES_SETTINGS_ERROR = "GET_DELIVERYZONES_SETTINGS_ERROR";

export const ADD_EDIT_DELIVERYZONE_SETTINGS_STARTED = "ADD_EDIT_DELIVERYZONE_SETTINGS_STARTED";
export const ADD_EDIT_DELIVERYZONE_SETTINGS_END = "ADD_EDIT_DELIVERYZONE_SETTINGS_END";

export const ADD_DELIVERYZONE_STARTED = "ADD_DELIVERYZONE_STARTED";
export const ADD_DELIVERYZONE_END = "ADD_DELIVERYZONE_END";

export const EDIT_DELIVERYZONE_STARTED = "EDIT_DELIVERYZONE_STARTED";
export const EDIT_DELIVERYZONE_END = "EDIT_DELIVERYZONE_END";

export const DELETE_DELIVERYZONE_STARTED = "DELETE_DELIVERYZONE_STARTED";
export const DELETE_DELIVERYZONE_END = "DELETE_DELIVERYZONE_END";

export const CLEAR_ALL_DELIVERYZONE = "CLEAR_ALL_DELIVERYZONE";
export const SET_SELECTED_DELIVERYZONE = "SET_SELECTED_DELIVERYZONE";

export const SET_DELIVERY_SETTINGS_MODAL_STATE = 'SET_DELIVERY_SETTINGS_MODAL_STATE'

export const ACTION_STARTED = 'ACTION_STARTED'
export const ACTION_ENDED = 'ACTION_ENDED'

export const CLEAR_ZONES_LOADING = "CLEAR_ZONES_LOADING"


const actions = {
  getOnlineDeliverySettings: (ruuid, bruuid) => async (dispatch, getState) => {
    const currentBranch = getState().zone.selectedBranchSettings
    if (bruuid !== currentBranch)
      dispatch({
        type: GET_DELIVERYZONES_SETTINGS_REQUEST,
        payload: bruuid
      })
    await getOnlineDeliverySettings(ruuid, bruuid).then((response) => {
      if (response.status === 200) {
        dispatch({
          type: GET_DELIVERYZONES_SETTINGS_SUCCESS,
          payload: response.data.onlineDeliverySetting
        })
        let busy = {
          delayPeriod: response.data.onlineDeliverySetting.delayPeriod,
          busyDateStart: response.data.onlineDeliverySetting.busyDateStart,
          busyDateEnd: response.data.onlineDeliverySetting.busyDateEnd
        }
        let closed = {
          isClosed: response.data.onlineDeliverySetting.isClosed,
          closedDateStart: response.data.onlineDeliverySetting.closedDateStart,
          closedDateEnd: response.data.onlineDeliverySetting.closedDateEnd
        }
        dispatch({
          type: SET_BUSY_SETTINGS,
          payload: busy
        })
        dispatch({
          type: SET_CLOSED_SETTINGS,
          payload: closed
        })
      }
      else {
        handleError(dispatch)(response, GET_DELIVERYZONES_ERROR, 'GET DELIVERY ZONES ERROR')
        // if (response.status !== 401 && response.status !== 403)
        //   toast.error(response?.data?.message || "Get All Delivery Zones error");
        // if (response.status === 401) {
        //   dispatch({ type: LOGOUT_USER });
        // }
        // if (response.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: response?.data?.permissions
        //   })
        // }
        // dispatch({
        //   type: GET_DELIVERYZONES_SETTINGS_ERROR,
        // });
      }
    })
  },
  addEditDeliverySettings: (ruuid, bruuid, data, close) => async (dispatch) => {
    dispatch({
      type: ADD_EDIT_DELIVERYZONE_SETTINGS_STARTED
    })
    await addEditDeliverySettings(ruuid, bruuid, data).then((response) => {
      if (response.status === 200) {
        toast.success(response.data.message || 'ADD EDIT ONLINE DELIVERY SETTINGS SUCCESS')
        dispatch({
          type: GET_DELIVERYZONES_SETTINGS_SUCCESS,
          payload: response.data.onlineDeliverySetting
        })
        if (close)
          close()
      } else {
        handleError(dispatch)(response, null, 'ADD EDIT ONLINE DELIVERY SETTINGS ERROR')
        // if (response.status !== 401 && response.status !== 403)
        //   toast.error(response?.data?.message || "ADD EDIT ONLINE DELIVERY SETTINGS ERROR");
        // if (response.status === 401) {
        //   dispatch({ type: LOGOUT_USER });
        // }
        // if (response.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: response?.data?.permissions
        //   })
        // }
      }
    })
    dispatch({
      type: ADD_EDIT_DELIVERYZONE_SETTINGS_END
    })
  },
  // Get All restaurants with the selected one
  getDeliveryZones: (ruuid, bruuid) => async (dispatch, getState) => {
    const currentRestaurant = getState().zone.selectedRestaurant
    const currentBranch = getState().zone.selectedBranch
    if (ruuid !== currentRestaurant || bruuid !== currentBranch)
      dispatch({
        type: GET_DELIVERYZONES_REQUEST,
        payload: {
          ruuid: ruuid,
          bruuid: bruuid
        }
      });
    await getDeliveryZones(ruuid, bruuid?.uuid || bruuid).then((res) => {
      if (res?.status === 200) {
        dispatch({
          type: GET_DELIVERYZONES_SUCCESS,
          payload: {
            deliveryZones: res?.data?.deliveryZones,
            globalSettings: res?.data?.globalSettings,
          },
        });
      } else {
        handleError(dispatch)(res, GET_DELIVERYZONES_ERROR, 'GET DELIVERYZONES ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || "Get All Delivery Zones error");
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER });
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
        // dispatch({
        //   type: GET_DELIVERYZONES_ERROR,
        //   payload: {
        //     message:
        //       parseError(res?.data?.error) ||
        //       res?.data?.message ||
        //       "GET delivery zones error",
        //   },
        // });
      }
    });
  },

  // addDeliveryZone: (ruuid, bruuid, data) => async (dispatch) => {
  //   dispatch({
  //     type: ADD_DELIVERYZONE_STARTED,
  //   });
  //   await addDeliveryZone(ruuid, bruuid, data).then((res) => {
  //     if (res?.status === 200) {
  //       toast.success(res?.data?.message || 'Delivery Zone Added')
  //       dispatch({
  //         type: GET_DELIVERYZONES_SUCCESS,
  //         payload: {
  //           deliveryZones: res?.data?.deliveryZones,
  //           globalSettings: res?.data?.globalSettings,
  //         },
  //       });
  //     } else {
  //if(res.status !== 401 && res.status !== 403)//       
  //toast.error(res?.data?.message || "Add delivery zone Error");
  //       if (res.status === 401) {
  //         dispatch({ type: LOGOUT_USER });
  //       }
  //     }
  //   });
  //   dispatch({
  //     type: ADD_DELIVERYZONE_END,
  //   });
  // },

  // editDeliveryZone: (ruuid, bruuid, uuid, data) => async (dispatch) => {
  //   dispatch({
  //     type: EDIT_DELIVERYZONE_STARTED,
  //   });
  //   await editDeliveryZone(ruuid, bruuid, uuid, data).then((res) => {
  //     if (res?.status === 200) {
  //       toast.success(res?.data?.message || 'Delivery Zone Edited')
  //       dispatch({
  //         type: GET_DELIVERYZONES_SUCCESS,
  //         payload: {
  //           deliveryZones: res?.data?.deliveryZones,
  //           globalSettings: res?.data?.globalSettings,
  //         },
  //       });
  //     } else {
  //if (res.status !== 401 && res.status !== 403)//       
  //toast.error(res?.data?.message || "Edit delivery zones Error");
  //       if (res.status === 401) {
  //         dispatch({ type: LOGOUT_USER });
  //       }
  //     }
  //   });
  //   dispatch({
  //     type: EDIT_DELIVERYZONE_END,
  //   });
  // },

  // deleteDeliveryZone: (ruuid, bruuid, uuid) => async (dispatch) => {
  //   dispatch({
  //     type: DELETE_DELIVERYZONE_STARTED,
  //   });
  //   await deleteDeliveryZone(ruuid, bruuid, uuid).then((res) => {
  //     if (res?.status === 200) {
  //       toast.success(res?.data?.message || 'Delivery Zone Deleted')
  //       dispatch({
  //         type: GET_DELIVERYZONES_SUCCESS,
  //         payload: {
  //           deliveryZones: res?.data?.deliveryZones,
  //           globalSettings: res?.data?.globalSettings,
  //         },
  //       });
  //     } else {
  //if(res.status !== 401 && res.status !== 403)//       
  //toast.error(res?.data?.message || "Delete Delivery Zone Error");
  //       if (res.status === 401) {
  //         dispatch({ type: LOGOUT_USER });
  //       }
  //     }
  //   });
  //   dispatch({
  //     type: DELETE_DELIVERYZONE_END,
  //   });
  // },
  action: (ruuid, bruuid, added, edited, deleted) => async (dispatch) => {
    dispatch({
      type: ACTION_STARTED
    })
    let temp = [
      { added: added },
      { edited: edited },
      { deleted: deleted }
    ]
    try {
      const addRequest = temp.reduce((promises, postData) => {
        if (postData.added) {
          const addedPromises = postData.added.map((data) =>
            addDeliveryZone(ruuid, bruuid, data)
          );
          return [...promises, ...addedPromises];
        }
        return promises;
      }, []);

      const editRequest = temp.reduce((promises, postData) => {
        if (postData.edited) {
          const editedPromises = postData.edited.map((data) =>
            editDeliveryZone(ruuid, bruuid, data.uuid, data.zone)
          );
          return [...promises, ...editedPromises];
        }
        return promises;
      }, []);

      const deleteRequest = temp.reduce((promises, postData) => {
        if (postData.deleted) {
          const deletedPromises = postData.deleted.map((data) =>
            deleteDeliveryZone(ruuid, bruuid, data)
          );
          return [...promises, ...deletedPromises];
        }
        return promises;
      }, []);

      const [addResponse, editResponse, deleteResponse] = await Promise.all([
        ...addRequest,
        ...editRequest,
        ...deleteRequest
      ]);


      if (addResponse?.status == 200 || editResponse?.status || deleteResponse?.status === 200) {
        toast.success('Successfully Saved')
        await getDeliveryZones(ruuid, bruuid).then((res) => {
          if (res?.status === 200) {
            dispatch({
              type: GET_DELIVERYZONES_SUCCESS,
              payload: {
                deliveryZones: res?.data?.deliveryZones,
                globalSettings: res?.data?.globalSettings,
              },
            });
          } else {
            handleError(dispatch)(res, GET_DELIVERYZONES_ERROR, 'GET DELIVERYZONES ERROR')
            // if (res.status !== 401 && res.status !== 403)
            //   toast.error(res?.data?.message || "Get All Delivery Zones error");
            // if (res.status === 401) {
            //   dispatch({ type: LOGOUT_USER });
            // }
            // if (res.status === 403)
            //   dispatch({
            //     type: SET_PERMISSIONS,
            //     payload: res?.data?.permissions
            //   })
            // dispatch({
            //   type: GET_DELIVERYZONES_ERROR,
            //   payload: {
            //     message:
            //       parseError(res?.data?.error) ||
            //       res?.data?.message ||
            //       "GET delivery zones error",
            //   },
            // });
          }
        })

      }
      else {
        if (addResponse?.status !== 200 && added.length > 0) {
          if (addResponse.status !== 401 && addResponse.status !== 403)
            toast.error(addResponse?.data?.message || 'Something went wrong')
          if (addResponse?.status === 401)
            dispatch({ type: LOGOUT_USER })
          if (addResponse.status === 403)
            dispatch({
              type: SET_PERMISSIONS,
              payload: addResponse?.data?.permissions
            })
        }
        if (editResponse?.status !== 200 && edited.length > 0) {
          if (editResponse.status !== 401 && editResponse.status !== 403)
            toast.error(editResponse?.data?.message || 'Something went wrong')
          if (editResponse?.status === 401)
            dispatch({ type: LOGOUT_USER })
          if (editResponse.status === 403)
            dispatch({
              type: SET_PERMISSIONS,
              payload: editResponse?.data?.permissions
            })
        }
        if (deleteResponse?.status !== 200 && edited.length > 0) {
          if (deleteResponse.status !== 401 && deleteResponse.status !== 403)
            toast.error(deleteResponse?.data?.message || 'Something went wrong')
          if (deleteResponse?.status === 401)
            dispatch({ type: LOGOUT_USER })
          if (deleteResponse.status === 403)
            dispatch({
              type: SET_PERMISSIONS,
              payload: deleteResponse?.data?.permissions
            })
        }
      }
    } catch (error) {
      console.error(error)
    }

    dispatch({
      type: ACTION_ENDED
    })
  },
  setSelectedZone: (data) => (dispatch) => {
    dispatch({
      type: SET_SELECTED_DELIVERYZONE,
      data: data,
    });
  },
  setDeliverySettingsModalState: (data) => (dispatch) => {
    dispatch({
      type: SET_DELIVERY_SETTINGS_MODAL_STATE,
      payload: data
    })
  },

  // CLEAR ALL Reducer
  clearAllZones: () => (dispatch) => {
    dispatch({
      type: CLEAR_ALL_DELIVERYZONE,
    });
  },

};

export default actions;
