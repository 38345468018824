import {
    GET_ALL_DISCOUNT_REQUEST,
    GET_ALL_DISCOUNT_SUCCESS,
    GET_ALL_DISCOUNT_ERROR,

    GET_DISCOUNT_REQUEST,
    GET_DISCOUNT_SUCCESS,
    GET_DISCOUNT_ERROR,

    GET_DISCOUNT_SETTINGS_REQUEST,
    GET_DISCOUNT_SETTINGS_SUCCESS,
    GET_DISCOUNT_SETTINGS_ERROR,

    ADD_DISCOUNT_STARTED,
    ADD_DISCOUNT_ENDED,

    EDIT_DISCOUNT_STARTED,
    EDIT_DISCOUNT_ENDED,

    EDIT_DISCOUNT_SETTINGS_STARTED,
    EDIT_DISCOUNT_SETTINGS_ENDED,

    DELETE_DISCOUNT_STARTED,
    DELETE_DISCOUNT_ENDED,

    SORT_DISCOUNT_STARTED,
    SORT_DISCOUNT_ENDED,

    SET_EDIT_DISCOUNT,

    SET_DISCOUNTS_FILTER,

    CLEAR_ALL_DISCOUNT,
    CLEAR_DISCOUNT_LOADING
} from '../actions/DiscountActions'

const initialState = {
    loading: false,
    discounts: [],
    discount: {},
    editDiscount: null,
    addLoading: false,
    editLoading: false,
    deleteLoading: false,
    sortLoading: false,
    settings: {},
    getSettingsLoading: false,
    editSettingsLoading: false,
    selectedRestaurant: '',
    selectedRestaurantSettings: '',
    filter: {
        showExpired: false,
        sorting: 'sort-by',
        timing: { from: '', to: '' },
        platform: [],
        isDate: false
    }
}

function discounts(state = initialState, action) {
    switch (action.type) {
        case CLEAR_DISCOUNT_LOADING:
            return Object.assign({}, state, {
                loading: false,
                addLoading: false,
                editLoading: false,
                deleteLoading: false,
                sortLoading: false,
                getSettingsLoading: false,
                editSettingsLoading: false,
            })
        case SET_DISCOUNTS_FILTER:
            return Object.assign({}, state, {
                filter: action.payload
            })
        case EDIT_DISCOUNT_SETTINGS_STARTED:
            return Object.assign({}, state, {
                editSettingsLoading: true
            })
        case EDIT_DISCOUNT_SETTINGS_ENDED:
            return Object.assign({}, state, {
                editSettingsLoading: false
            })
        case GET_ALL_DISCOUNT_REQUEST:
            return Object.assign({}, state, {
                loading: true,
                selectedRestaurant: action.payload
            })
        case GET_ALL_DISCOUNT_SUCCESS:
            return Object.assign({}, state, {
                loading: false,
                discounts: action.payload
            })
        case GET_ALL_DISCOUNT_ERROR:
            return Object.assign({}, state, {
                loading: false,
                discounts: []
            })
        case GET_DISCOUNT_REQUEST:
            return Object.assign({}, state, {
                loading: true
            })
        case GET_DISCOUNT_SUCCESS:
            return Object.assign({}, state, {
                loading: false,
                discount: action.payload
            })
        case GET_DISCOUNT_ERROR:
            return Object.assign({}, state, {
                loading: false,
                discount: {}
            })
        case GET_DISCOUNT_SETTINGS_REQUEST:
            return Object.assign({}, state, {
                getSettingsLoading: true,
                selectedRestaurantSettings: action.payload
            })
        case GET_DISCOUNT_SETTINGS_SUCCESS:
            return Object.assign({}, state, {
                getSettingsLoading: false,
                settings: action.payload
            })
        case GET_DISCOUNT_SETTINGS_ERROR:
            return Object.assign({}, state, {
                getSettingsLoading: false,
                settings: {}
            })
        case ADD_DISCOUNT_STARTED:
            return Object.assign({}, state, {
                addLoading: true
            })
        case ADD_DISCOUNT_ENDED:
            return Object.assign({}, state, {
                addLoading: false
            })
        case EDIT_DISCOUNT_STARTED:
            return Object.assign({}, state, {
                editLoading: true
            })
        case EDIT_DISCOUNT_ENDED:
            return Object.assign({}, state, {
                editLoading: false
            })
        case DELETE_DISCOUNT_STARTED:
            return Object.assign({}, state, {
                deleteLoading: true
            })
        case DELETE_DISCOUNT_ENDED:
            return Object.assign({}, state, {
                deleteLoading: false
            })
        case SET_EDIT_DISCOUNT:
            return Object.assign({}, state, {
                editDiscount: action.payload
            })
        case SORT_DISCOUNT_STARTED:
            return Object.assign({}, state, {
                sortLoading: true
            })
        case SORT_DISCOUNT_ENDED:
            return Object.assign({}, state, {
                sortLoading: false
            })
        case CLEAR_ALL_DISCOUNT:
            return initialState
        default:
            return state
    }
}
export default discounts