
const floorTablesData = [
    { id: '1',name: 'Ground Floor',color:'#f4f5f7',floorImage:'default',
    tables:[
        {id: 'tab1_3456', name: 'T1', type: 'circle', x: 300, y: 100, width: 70, height: 70, minSeating: 4, maxSeating: 10,actualSeats:{top:5,bottom:5,left:5,right:5},rotation:0,area: 'Smoking Area',},
        {id: 'tab1_1278', name: 'T2', type: 'rectangle', x: 500, y: 250, width: 70, height: 70, minSeating: 2,maxSeating: 4, actualSeats:{top:8,bottom:3,left:4,right:3},rotation:0, area: 'View Section',},
        {id: 'tab3_13579', name: 'T3', type: 'circle', x: 700, y: 400, width: 70, height: 70,minSeating: 2, maxSeating: 6,actualSeats:{top:8,bottom:5,left:8,right:3},rotation:0, area: 'Smoking Area',},
        {id: 'bar1_2468', name: 'C_Bar', type: 'bar', x: 5, y: 100,rotation:0, area: 'Smoking Area', depth:50,
         topSide:{width:300,visible:true,seats:{top:15,bottom:0,left:0,right:2}},
         bottomSide:{width:500,visible:true,seats:{top:0,bottom:10,left:0,right:2}},
         verticalSide:{height:300,visible:true,seats:{top:2,bottom:2,left:20,right:0}},
         rightSide:{height:300,visible:true,seats:{top:2,bottom:2,left:0,right:20}},
        minSeating: 2, maxSeating: 6,actualSeats:{top:8,bottom:5,left:8,right:3},},
    ]
},
    { id: '2',name: 'Underground Floor',color:'#f4f5f7',floorImage:'default',
    tables:[
        {id: 'tab5_3456', name: 'T1', type: 'circle', x: 300, y: 100, width: 70, height: 70, minSeating: 4, maxSeating: 10,actualSeats:{top:5,bottom:1,left:2,right:4},rotation:0,area: 'Smoking Area',},
        {id: 'tab7_1278', name: 'T2', type: 'rectangle', x: 700, y: 400, width: 70, height: 70, minSeating: 2, maxSeating: 4,actualSeats:{top:5,bottom:5,left:5,right:5},rotation:0, area: 'View Section',},
    ]
},
    { id: '3',name: 'Floor 1',color:'#f4f5f7',floorImage:'default',
    tables:[
        {id: 'tab5_3456', name: 'T1', type: 'circle', x: 10, y: 20, width: 70, height: 70, minSeating: 4, maxSeating: 10,actualSeats:{top:5,bottom:5,left:5,right:5},rotation:0,area: 'Smoking Area',},
        {id: 'tab7_1278', name: 'T2', type: 'rectangle', x: 20, y: 150, width: 70, height: 70, minSeating: 2, maxSeating: 4,actualSeats:{top:5,bottom:4,left:3,right:2},rotation:0, area: 'View Section',},
    ]
},
    { id: '4',name: 'Floor 2',color:'#f4f5f7',floorImage:'default',
    tables:[
        {id: 'tab1_3456', name: 'T1', type: 'circle', x: 150, y: 170, width: 70, height: 70, minSeating: 4, maxSeating: 4,actualSeats:{top:8,bottom:7,left:5,right:4},rotation:0,area: 'Smoking Area',},
        {id: 'tab1_1278', name: 'T2', type: 'rectangle', x: 170, y: 80, width: 70, height: 70, minSeating: 2, maxSeating: 5,actualSeats:{top:5,bottom:5,left:5,right:5},rotation:0, area: 'View Section',},
        {id: 'tab3_13579', name: 'T3', type: 'circle', x: 230, y: 230, width: 70, height: 70,minSeating: 4, maxSeating: 6,actualSeats:{top:2,bottom:2,left:2,right:2},rotation:0, area: 'Smoking Area',},
    ]
},
    { id: '5',name: 'Rooftop',color:'#f4f5f7',floorImage:'default',
    tables:[
        {id: 'tab1_3456', name: 'T1', type: 'circle', x: 60, y: 70, width: 70, height: 70, minSeating: 4, maxSeating: 5,actualSeats:{top:7,bottom:7,left:3,right:3},rotation:0,area: 'Smoking Area',},
        {id: 'tab1_1278', name: 'T2', type: 'rectangle', x: 50, y: 25, width: 70, height: 70, minSeating: 2, maxSeating: 4,actualSeats:{top:2,bottom:2,left:2,right:2},rotation:0, area: 'View Section',},
        {id: 'tab3_13579', name: 'T3', type:'circle', x: 222, y: 300, width: 70, height: 70,minSeating: 4, maxSeating: 7,actualSeats:{top:4,bottom:2,left:3,right:1},rotation:0, area: 'Smoking Area',},
    ]
},

];

export default floorTablesData;