import {
  GET_FEEDBACK_TEMPLATE_REQUEST,
  GET_FEEDBACK_TEMPLATE_SUCCESS,
  GET_FEEDBACK_TEMPLATE_ERROR,
  EDIT_FEEDBACK_TEMPLATE_STARTED,
  EDIT_FEEDBACK_TEMPLATE_ENDED,
  EDIT_FEEDBACK_TEMPLATE_MESSAGE_STARTED,
  EDIT_FEEDBACK_TEMPLATE_MESSAGE_ENDED,
  GET_FEEDBACK_REQUEST,
  GET_FEEDBACK_SUCCESS,
  GET_FEEDBACK_ERROR,
  ADD_FEEDBACK_STARTED,
  ADD_FEEDBACK_ENDED,
  EDIT_FEEDBACK_COMPLAINT_REQUEST,
  EDIT_FEEDBACK_COMPLAINT_SUCCESS,
  EDIT_FEEDBACK_COMPLAINT_ERROR,
  CLEAR_ALL_FEEDBACKS,
  SET_FEEDBACK_FILTER,
  CLEAR_FEEDBACK_LOADING
} from "../actions/FeedbackActions";

const initialState = {
  feedbacks: [],
  feedbacktemplate: [],
  addFeedbackLoading: false,
  getFeedbackLoading: false,
  getFeedbackTemplateLoading: false,
  editFeedbackTemplateLoading: false,
  editFeedbackTemplateMessageLoading: false,
  editFeedbackComplaintLoading: [],
  selectedRestaurantFeedbacks: '',
  selectedRestaurantTemplate: '',
  feedbackFilter: {
    complaintsOnly: false,
    branch: [],
    customer: [],
    feedback: [],
    date: { from: null, to: null },
    feedbackDetailsRates: [],
    source: []
  }
};

function feedback(state = initialState, action) {
  switch (action.type) {
    case CLEAR_FEEDBACK_LOADING:
      return Object.assign({}, state, {
        addFeedbackLoading: false,
        getFeedbackLoading: false,
        getFeedbackTemplateLoading: false,
        editFeedbackTemplateLoading: false,
        editFeedbackTemplateMessageLoading: false,
        editFeedbackComplaintLoading: [],
      })
    case GET_FEEDBACK_TEMPLATE_REQUEST:
      return Object.assign({}, state, {
        getFeedbackTemplateLoading: true,
        selectedRestaurantTemplate: action.payload
      });
    case GET_FEEDBACK_TEMPLATE_SUCCESS:
      return Object.assign({}, state, {
        getFeedbackTemplateLoading: false,
        feedbacktemplate: action.payload.feedbacktemplate,
      });
    case GET_FEEDBACK_TEMPLATE_ERROR:
      return Object.assign({}, state, {
        getFeedbackTemplateLoading: false,
        feedbacktemplate: [],
      });
    case EDIT_FEEDBACK_TEMPLATE_STARTED:
      return Object.assign({}, state, {
        editFeedbackTemplateLoading: true,
      });
    case EDIT_FEEDBACK_TEMPLATE_ENDED:
      return Object.assign({}, state, {
        editFeedbackTemplateLoading: false,
      });
    case EDIT_FEEDBACK_TEMPLATE_MESSAGE_STARTED:
      return Object.assign({}, state, {
        editFeedbackTemplateMessageLoading: true,
      });
    case EDIT_FEEDBACK_TEMPLATE_MESSAGE_ENDED:
      return Object.assign({}, state, {
        editFeedbackTemplateMessageLoading: false,
      });
    case GET_FEEDBACK_REQUEST:
      return Object.assign({}, state, {
        getFeedbackLoading: true,
        selectedRestaurantFeedbacks: action.payload
      });
    case GET_FEEDBACK_SUCCESS:
      return Object.assign({}, state, {
        getFeedbackLoading: false,
        feedbacks: action?.payload,
      });
    case GET_FEEDBACK_ERROR:
      return Object.assign({}, state, {
        getFeedbackLoading: false,
        feedbacks: [],
      });
    case ADD_FEEDBACK_STARTED:
      return Object.assign({}, state, {
        addFeedbackLoading: true,
      });
    case ADD_FEEDBACK_ENDED:
      return Object.assign({}, state, {
        addFeedbackLoading: false,
      });
    case EDIT_FEEDBACK_COMPLAINT_REQUEST:
      return Object.assign({}, state, {
        editFeedbackComplaintLoading: [
          ...(state.editFeedbackComplaintLoading || []),
          action?.payload?.fuuid,
        ],
      });
    case EDIT_FEEDBACK_COMPLAINT_SUCCESS:
      const updatedFeedbacks = state.feedbacks.map((feedback) => {
        if (feedback.uuid === action?.payload?.fuuid) {
          return {
            ...feedback,
            isComplaint: action?.payload?.data?.isComplaint,
          };
        } else {
          return feedback;
        }
      });
      return Object.assign({}, state, {
        editFeedbackComplaintLoading: [
          state?.editFeedbackComplaintLoading.filter(
            (uuid) => uuid !== action?.payload?.fuuid
          ),
        ],
        feedbacks: updatedFeedbacks,
      });
    case EDIT_FEEDBACK_COMPLAINT_ERROR:
      return Object.assign({}, state, {
        editFeedbackComplaintLoading:
          state?.editFeedbackComplaintLoading.filter(
            (uuid) => uuid !== action?.payload?.fuuid
          ),
      });
    case SET_FEEDBACK_FILTER:
      return Object.assign({}, state, {
        feedbackFilter: action.payload
      })
    case CLEAR_ALL_FEEDBACKS:
      return initialState
    default:
      return state;
  }
}
export default feedback;
