import { getTaxes, addTax, editTax, deleteTax, getBranchTaxes, updateBranchTaxes, getProductsWithTaxes, applyTaxesToProducts, applyTaxesToAllProducts, getModifiersWithTaxes, applyTaxesToModifiers } from '../../config/config'
import { toast } from 'react-toastify'
import { parseError } from '../../utils/parseError'
import { LOGOUT_USER, SET_PERMISSIONS } from './AuthActions'
import { handleError } from '../../utils/utils'
import { GET_BRANCHES_FINANCE_ERROR } from './BranchesFinanceActions'

export const GET_TAX_REQUEST = 'GET_TAX_REQUEST'
export const GET_TAX_SUCCESS = 'GET_TAX_SUCCESS'
export const GET_TAX_ERROR = 'GET_TAX_ERROR'

export const GET_BRANCH_TAXES_REQUEST = 'GET_BRANCH_TAXES_REQUEST'
export const GET_BRANCH_TAXES_SUCCESS = 'GET_BRANCH_TAXES_SUCCESS'
export const GET_BRANCH_TAXES_ERROR = 'GET_BRANCH_TAXES_ERROR'

export const GET_PRODUCTS_TAXES_REQUEST = 'GET_PRODUCTS_TAXES_REQUEST'
export const GET_PRODUCTS_TAXES_SUCCESS = 'GET_PRODUCTS_TAXES_SUCCESS'
export const GET_PRODUCTS_TAXES_ERROR = 'GET_PRODUCTS_TAXES_ERROR'

export const UPDATE_BRANCH_TAXES_STARTED = 'UPDATE_BRANCH_TAXES_STARTED'
export const UPDATE_BRANCH_TAXES_END = 'UPDATE_BRANCH_TAXES_END'

export const UPDATE_PRODUCTS_TAXES_STARTED = 'UPDATE_PRODUCTS_TAXES_STARTED'
export const UPDATE_PRODUCTS_TAXES_END = 'UPDATE_PRODUCTS_TAXES_END'

export const UPDATE_ALL_PRODUCTS_TAXES_STARTED = 'UPDATE_ALL_PRODUCTS_TAXES_STARTED'
export const UPDATE_ALL_PRODUCTS_TAXES_END = 'UPDATE_ALL_PRODUCTS_TAXES_END'

export const GET_MODIFIERS_TAXES_REQUEST = 'GET_MODIFIERS_TAXES_REQUEST'
export const GET_MODIFIERS_TAXES_SUCCESS = 'GET_MODIFIERS_TAXES_SUCCESS'
export const GET_MODIFIERS_TAXES_ERROR = 'GET_MODIFIERS_TAXES_ERROR'

export const UPDATE_ALL_MODIFIERS_TAXES_STARTED = 'UPDATE_ALL_MODIFIERS_TAXES_STARTED'
export const UPDATE_ALL_MODIFIERS_TAXES_END = 'UPDATE_ALL_MODIFIERS_TAXES_END'

export const ADD_TAX_STARTED = 'ADD_TAX_STARTED'
export const ADD_TAX_END = 'ADD_TAX_END'

export const EDIT_TAX_STARTED = 'EDIT_STARTED'
export const EDIT_TAX_END = 'EDIT_END'

export const DELETE_TAX_STARTED = 'DELETE_STARTED'
export const DELETE_TAX_END = 'DELETE_END'

export const SET_TAX_POPUP = 'SET_TAX_POPUP'
export const CLEAR_ALL_TAX = 'CLEAR_ALL'

export const CLEAR_TAX_LOADING = 'CLEAR_TAX_LOADING'

const actions = {
  getTaxes: () => async (dispatch, getState) => {
    if (getState().tax.taxes.length === 0)
      dispatch({
        type: GET_TAX_REQUEST,
      })
    await getTaxes().then((res) => {
      if (res?.status === 200) {
        dispatch({
          type: GET_TAX_SUCCESS,
          payload: {
            taxes: res?.data?.taxes,
          },
        })
      } else {
        handleError(dispatch)(res, GET_TAX_ERROR, 'GET TAX ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Get All Taxes error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
        // dispatch({
        //   type: GET_TAX_ERROR,
        //   payload: {
        //     message: parseError(res?.data?.error) || res?.data?.message || 'GET TAX error',
        //   },
        // })
      }
    })
  },

  addTax: (data) => async (dispatch) => {
    dispatch({
      type: ADD_TAX_STARTED,
    })
    await addTax(data).then((res) => {
      if (res?.status === 200) {
        toast.success(res?.data?.message || 'Successfully Saved')
        dispatch({
          type: GET_TAX_SUCCESS,
          payload: {
            taxes: res?.data?.taxes,
          },
        })
        dispatch({
          type: SET_TAX_POPUP,
          payload: false,
        })
      } else {
        handleError(dispatch)(res, null, 'ADD TAX ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Add Tax error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
      }
    })
    dispatch({
      type: ADD_TAX_END,
    })
  },

  editTax: (uuid, data) => async (dispatch) => {
    dispatch({
      type: EDIT_TAX_STARTED,
    })
    await editTax(uuid, data).then((res) => {
      if (res?.status === 200) {
        toast.success(res?.data?.message || 'Successfully Saved')
        dispatch({
          type: GET_TAX_SUCCESS,
          payload: {
            taxes: res?.data?.taxes,
          },
        })
        dispatch({
          type: SET_TAX_POPUP,
          payload: false,
        })
      } else {
        handleError(dispatch)(res, null, 'EDIT TAX ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Edit Tax error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
      }
    })
    dispatch({
      type: EDIT_TAX_END,
    })
  },

  deleteTax: (uuid) => async (dispatch) => {
    dispatch({
      type: DELETE_TAX_STARTED,
    })
    await deleteTax(uuid).then((res) => {
      if (res?.status === 200) {
        toast.success(res?.data?.message || 'Successfully Deleted')
        dispatch({
          type: GET_TAX_SUCCESS,
          payload: {
            taxes: res?.data?.taxes,
          },
        })
        dispatch({
          type: SET_TAX_POPUP,
          payload: false,
        })
      } else {
        handleError(dispatch)(res, null, 'DELETE TAX ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Delete Tax Error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
      }
    })
    dispatch({
      type: DELETE_TAX_END,
    })
  },

  setActionModal: (state) => (dispatch) => {
    dispatch({
      type: SET_TAX_POPUP,
      payload: state,
    })
  },
  getBranchTaxes: (ruuid) => async (dispatch, getState) => {
    const currentRestaurant = getState().tax.selectedRestaurantBranch
    if (ruuid !== currentRestaurant)
      dispatch({
        type: GET_BRANCH_TAXES_REQUEST,
        payload: ruuid
      })
    await getBranchTaxes(ruuid).then((response) => {
      if (response.status === 200)
        dispatch({
          type: GET_BRANCH_TAXES_SUCCESS,
          payload: response?.data?.branchTaxes
        })
      else {
        handleError(dispatch)(response, GET_BRANCH_TAXES_ERROR, 'GET BRANCH TAXES ERROR')
        // if (response.status !== 401 && response.status !== 403)
        //   toast.error(response?.data?.message || 'GET  BRANCH TAXES ERROR')
        // dispatch({
        //   type: GET_BRANCH_TAXES_ERROR
        // })
        // if (response.status === 401)
        //   dispatch({
        //     type: LOGOUT_USER
        //   })
        // if (response.status === 403)
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: response?.data?.permissions
        //   })
      }
    })
  },
  updateBranchTaxes: (ruuid, data) => async (dispatch) => {
    dispatch({
      type: UPDATE_BRANCH_TAXES_STARTED
    })
    await updateBranchTaxes(ruuid, data).then((response) => {
      if (response.status === 200) {
        toast.success(response?.data?.message || 'UPDATE BRANCH TAXES SUCCESS')
        dispatch({
          type: GET_BRANCH_TAXES_SUCCESS,
          payload: response?.data?.branchTaxes
        })
      }
      else {
        handleError(dispatch)(response, null, 'UPDATE BRANCH TAXES ERROR')
        // if (response.status !== 401 && response.status !== 403)
        //   toast.error(response?.data?.message || 'UPDATE BRANCH TAXES ERROR')
        // if (response.status === 401)
        //   dispatch({
        //     type: LOGOUT_USER
        //   })
        // if (response.status === 403)
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: response?.data?.permissions
        //   })
      }
    })
    dispatch({
      type: UPDATE_BRANCH_TAXES_END
    })
  },
  getProductsWithTaxes: (ruuid) => async (dispatch, getState) => {
    const currentRestaurant = getState().tax.selectedRestaurantProduct
    if (ruuid !== currentRestaurant)
      dispatch({
        type: GET_PRODUCTS_TAXES_REQUEST,
        payload: ruuid
      })
    await getProductsWithTaxes(ruuid).then((response) => {
      if (response.status === 200) {
        dispatch({
          type: GET_PRODUCTS_TAXES_SUCCESS,
          payload: response?.data?.products
        })
      }
      else {
        handleError(dispatch)(response, GET_PRODUCTS_TAXES_ERROR, 'HET PRODUCTS TAXES ERROR')
        // if (response.status !== 401 && response.status !== 403)
        //   toast.error(response?.data?.message || 'GET PRODUCTS WITH TAXES ERROR')
        // dispatch({
        //   type: GET_PRODUCTS_TAXES_ERROR
        // })
        // if (response.status === 401)
        //   dispatch({
        //     type: LOGOUT_USER
        //   })
        // if (response.status === 403)
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: response?.data?.permissions
        //   })
      }
    })
  },
  applyTaxesToProducts: (ruuid, data) => async (dispatch) => {
    dispatch({
      type: UPDATE_PRODUCTS_TAXES_STARTED
    })
    await applyTaxesToProducts(ruuid, data).then((response) => {
      if (response.status === 200) {
        toast.success(response?.data?.message || 'UPDATE PRODUCTS TAXES SUCCESS')
        dispatch({
          type: GET_PRODUCTS_TAXES_SUCCESS,
          payload: response?.data?.products
        })
      }
      else {
        handleError(dispatch)(response, null, 'APPLY TAXES TO PRODUCTS ERROR')
        // if (response.status !== 401 && response.status !== 403)
        //   toast.error(response?.data?.message || 'UPDATE PRODUCTS TAXES ERROR')
        // if (response.status === 401)
        //   dispatch({
        //     type: LOGOUT_USER
        //   })
        // if (response.status === 403)
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: response?.data?.permissions
        //   })
      }
    })
    dispatch({
      type: UPDATE_PRODUCTS_TAXES_END
    })
  },
  applyTaxesToAllProducts: (ruuid, data) => async (dispatch) => {
    dispatch({
      type: UPDATE_ALL_PRODUCTS_TAXES_STARTED
    })
    await applyTaxesToAllProducts(ruuid, data).then((response) => {
      if (response.status === 200) {
        toast.success(response?.data?.message || 'UPDATE ALL PRODUCTS TAXES SUCCESS')
        dispatch({
          type: GET_PRODUCTS_TAXES_SUCCESS,
          payload: response?.data?.products
        })
      }
      else {
        handleError(dispatch)(response, null, 'AAPPLY TAXES TO ALL PRODUCTS ERROR')
        // if (response.status !== 401 && response.status !== 403)
        //   toast.error(response?.data?.message || 'UPDATE ALL PRODUCTS TAXES ERROR')
        // if (response.status === 401)
        //   dispatch({
        //     type: LOGOUT_USER
        //   })
        // if (response.status === 403)
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: response?.data?.permissions
        //   })
      }
    })
    dispatch({
      type: UPDATE_ALL_PRODUCTS_TAXES_END
    })
  },
  getModifiersWithTaxes: (ruuid) => async (dispatch, getState) => {
    const currentRestaurant = getState().tax.selectedRestaurantModifiers
    if (ruuid !== currentRestaurant)
      dispatch({
        type: GET_MODIFIERS_TAXES_REQUEST,
        payload: ruuid
      })
    await getModifiersWithTaxes(ruuid).then((response) => {
      if (response.status === 200) {
        dispatch({
          type: GET_MODIFIERS_TAXES_SUCCESS,
          payload: response?.data?.modifiers
        })
      } else {
        handleError(dispatch)(response, GET_MODIFIERS_TAXES_ERROR, 'GET MODIFIERS TAXES ERROR')
        // dispatch({
        //   type: GET_MODIFIERS_TAXES_ERROR
        // })
        // if (response.status !== 401 && response.status !== 403)
        //   toast.error(response?.data?.message || 'GET MODIFIERS WITH TAXES ERROR')
        // if (response.status === 401)
        //   dispatch({
        //     type: LOGOUT_USER
        //   })
        // if (response.status === 403)
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: response?.data?.permissions
        //   })
      }
    })
  },
  applyTaxesToModifiers: (ruuid, data) => async (dispatch) => {
    dispatch({
      type: UPDATE_ALL_MODIFIERS_TAXES_STARTED
    })
    await applyTaxesToModifiers(ruuid, data).then((response) => {
      if (response.status === 200) {
        toast.success(response?.data?.message || 'APPLY TAXES TO MODIFIERS SUCCESS')
        dispatch({
          type: GET_MODIFIERS_TAXES_SUCCESS,
          payload: response?.data?.modifiers
        })
      } else {
        handleError(dispatch)(response, null, 'APPLY TAXES TO MODIFIERS ERROR')
        // if (response.status !== 401 && response.status !== 403)
        //   toast.error(response?.data?.message || 'UPDATE TAXES TO MODIFIERS ERROR')
        // if (response.status === 401)
        //   dispatch({
        //     type: LOGOUT_USER
        //   })
        // if (response.status === 403)
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: response?.data?.permissions
        //   })
      }
    })
    dispatch({
      type: UPDATE_ALL_MODIFIERS_TAXES_END
    })
  },
  // CLEAR ALL Reducer
  clearAll: () => (dispatch) => {
    dispatch({
      type: CLEAR_ALL_TAX,
    })
  },
}

export default actions
