import {
  GET_APP_USER_ADDRESSES_REQUEST,
  GET_APP_USER_ADDRESSES_SUCCESS,
  GET_APP_USER_ADDRESSES_ERROR,
  ADD_APP_USER_ADDRESS_REQUEST,
  ADD_APP_USER_ADDRESS_SUCCESS,
  ADD_APP_USER_ADDRESS_ERROR,
  EDIT_APP_USER_ADDRESS_REQUEST,
  EDIT_APP_USER_ADDRESS_SUCCESS,
  EDIT_APP_USER_ADDRESS_ERROR,
  DELETE_APP_USER_ADDRESS_REQUEST,
  DELETE_APP_USER_ADDRESS_SUCCESS,
  DELETE_APP_USER_ADDRESS_ERROR,
  RESET_APP_USER_ADDRESSES,
  CLEAR_APP_USER_ADDRESSES_LOADING
} from "../actions/AppUserAddressesActions";

const initialState = {
  loading: false,
  addLoading: false,
  editLoading: false,
  deleteLoading: false,
  userAddresses: [],
};

function appUserAddresses(state = initialState, action) {
  switch (action.type) {
    case CLEAR_APP_USER_ADDRESSES_LOADING:
      return Object.assign({}, state, {
        loading: false,
        addLoading: false,
        editLoading: false,
        deleteLoading: false,
      })
    case GET_APP_USER_ADDRESSES_REQUEST:
      return Object.assign({}, state, {
        loading: true,
      });
    case GET_APP_USER_ADDRESSES_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        userAddresses: action.payload.addresses,
      });
    case GET_APP_USER_ADDRESSES_ERROR:
      return Object.assign({}, state, {
        loading: false,
        userAddresses: [],
      });

    case ADD_APP_USER_ADDRESS_REQUEST:
      return Object.assign({}, state, {
        addLoading: true,
      });

    case ADD_APP_USER_ADDRESS_SUCCESS:
      return Object.assign({}, state, {
        addLoading: false,
        userAddresses: action.payload.addresses,
      });

    case ADD_APP_USER_ADDRESS_ERROR:
      return Object.assign({}, state, {
        addLoading: false,
        userAddresses: [],
      });

    case EDIT_APP_USER_ADDRESS_REQUEST:
      return Object.assign({}, state, {
        editLoading: true,
      });

    case EDIT_APP_USER_ADDRESS_SUCCESS:
      return Object.assign({}, state, {
        editLoading: false,
        userAddresses: action.payload.addresses,
      });

    case EDIT_APP_USER_ADDRESS_ERROR:
      return Object.assign({}, state, {
        editLoading: false,
      });

    case DELETE_APP_USER_ADDRESS_REQUEST:
      return Object.assign({}, state, {
        deleteLoading: true,
      });

    case DELETE_APP_USER_ADDRESS_SUCCESS:
      return Object.assign({}, state, {
        deleteLoading: false,
        userAddresses: state.userAddresses.filter((addr) => {
          return addr?.uuid !== action.payload.deletedAddress;
        }),
      });

    case DELETE_APP_USER_ADDRESS_ERROR:
      return Object.assign({}, state, {
        deleteLoading: false,
      });
    case RESET_APP_USER_ADDRESSES:
      return initialState
    default:
      return state;
  }
}

export default appUserAddresses;
