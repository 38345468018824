import {ADD_PRODUCT,EDIT_RECIPE,ADD_TO_MAIN_DINE_IN,ADD_TO_MAIN_PICK_UP,ADD_TO_MAIN_DELIVERY,
  ADD_TO_ADD_DINE_IN,ADD_TO_ADD_PICK_UP,ADD_TO_ADD_DELIVERY, 
  ADD_TO_COMBO_DINE_IN, ADD_TO_COMBO_PICK_UP, ADD_TO_COMBO_DELIVERY
} from "../actions/StockRecipesActions";
import {stockRecipesData} from "../../pages/stock/stock-recipes";

const INITIAL_STATE = {stockRecipesProducts: stockRecipesData,};

const findAndEditItem = (array, id,
  nameValue,typeValue,prodTypeValue,
  yieldValue,imageValue,
  smallUnitValue,smallQtyValue,smallCostValue,
  largeUnitValue,largeQtyValue,largeCostValue,
  familyUnitValue,familyQtyValue,familyCostValue) => {
  return array.map(item => {
    if (item.uuid === id) {
      return { ...item, name: nameValue,type:typeValue,productType:prodTypeValue,
      yield:yieldValue,image:imageValue,
      smallUnit:smallUnitValue,smallQty:smallQtyValue,smallCost:smallCostValue,
      largeUnit:largeUnitValue,largeQty:largeQtyValue,largeCost:largeCostValue,
      FamilyUnit:familyUnitValue,familyQty:familyQtyValue,familyCost:familyCostValue,
      };
    }
    return item;
  });
};

const stockRecipesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_PRODUCT:
      return {
        ...state,
        stockRecipesProducts: [...state.stockRecipesProducts, action.payload],
      };

  case EDIT_RECIPE:
  const { id, productId, 
    nameValue, typeValue, prodTypeValue,
    yieldValue, imageValue,
    smallUnitValue, smallQtyValue, smallCostValue,
    largeUnitValue, largeQtyValue, largeCostValue,
    familyUnitValue, familyQtyValue, familyCostValue 
  } = action.payload;
  
  return {
    ...state,
    stockRecipesProducts: state.stockRecipesProducts.map(product => {
      if (product.uuid === productId) {
        const updatedProduct = { ...product };
        if (updatedProduct.mainItem) {
          if ('dineIn' in updatedProduct.mainItem) {
            updatedProduct.mainItem.dineIn = findAndEditItem(updatedProduct.mainItem.dineIn, id,
              nameValue, typeValue, prodTypeValue,
              yieldValue, imageValue,
              smallUnitValue, smallQtyValue, smallCostValue,
              largeUnitValue, largeQtyValue, largeCostValue,
              familyUnitValue, familyQtyValue, familyCostValue);
          }
          if ('pickUp' in updatedProduct.mainItem) {
            updatedProduct.mainItem.pickUp = findAndEditItem(updatedProduct.mainItem.pickUp, id,
              nameValue, typeValue, prodTypeValue,
              yieldValue, imageValue,
              smallUnitValue, smallQtyValue, smallCostValue,
              largeUnitValue, largeQtyValue, largeCostValue,
              familyUnitValue, familyQtyValue, familyCostValue);
          }
          if ('delivery' in updatedProduct.mainItem) {
            updatedProduct.mainItem.delivery = findAndEditItem(updatedProduct.mainItem.delivery, id,
              nameValue, typeValue, prodTypeValue,
              yieldValue, imageValue,
              smallUnitValue, smallQtyValue, smallCostValue,
              largeUnitValue, largeQtyValue, largeCostValue,
              familyUnitValue, familyQtyValue, familyCostValue);
          }
        }
        if (updatedProduct.addOns) {
          if ('dineIn' in updatedProduct.addOns) {
            updatedProduct.addOns.dineIn = findAndEditItem(updatedProduct.addOns.dineIn, id,
              nameValue, typeValue, prodTypeValue,
              yieldValue, imageValue,
              smallUnitValue, smallQtyValue, smallCostValue,
              largeUnitValue, largeQtyValue, largeCostValue,
              familyUnitValue, familyQtyValue, familyCostValue);
          }
          if ('pickUp' in updatedProduct.addOns) {
            updatedProduct.addOns.pickUp = findAndEditItem(updatedProduct.addOns.pickUp, id,
              nameValue, typeValue, prodTypeValue,
              yieldValue, imageValue,
              smallUnitValue, smallQtyValue, smallCostValue,
              largeUnitValue, largeQtyValue, largeCostValue,
              familyUnitValue, familyQtyValue, familyCostValue);
          }
          if ('delivery' in updatedProduct.addOns) {
            updatedProduct.addOns.delivery = findAndEditItem(updatedProduct.addOns.delivery, id,
              nameValue, typeValue, prodTypeValue,
              yieldValue, imageValue,
              smallUnitValue, smallQtyValue, smallCostValue,
              largeUnitValue, largeQtyValue, largeCostValue,
              familyUnitValue, familyQtyValue, familyCostValue);
          }
        }
        if (updatedProduct.combos) {
          if ('dineIn' in updatedProduct.combos) {
            updatedProduct.combos.dineIn = findAndEditItem(updatedProduct.combos.dineIn, id,
              nameValue, typeValue, prodTypeValue,
              yieldValue, imageValue,
              smallUnitValue, smallQtyValue, smallCostValue,
              largeUnitValue, largeQtyValue, largeCostValue,
              familyUnitValue, familyQtyValue, familyCostValue);
          }
          if ('pickUp' in updatedProduct.combos) {
            updatedProduct.combos.pickUp = findAndEditItem(updatedProduct.combos.pickUp, id,
              nameValue, typeValue, prodTypeValue,
              yieldValue, imageValue,
              smallUnitValue, smallQtyValue, smallCostValue,
              largeUnitValue, largeQtyValue, largeCostValue,
              familyUnitValue, familyQtyValue, familyCostValue);
          }
          if ('delivery' in updatedProduct.combos) {
            updatedProduct.combos.delivery = findAndEditItem(updatedProduct.combos.delivery, id,
              nameValue, typeValue, prodTypeValue,
              yieldValue, imageValue,
              smallUnitValue, smallQtyValue, smallCostValue,
              largeUnitValue, largeQtyValue, largeCostValue,
              familyUnitValue, familyQtyValue, familyCostValue);
          }
        }
        return updatedProduct;
      }
      return product;
    }),
  };

  case ADD_TO_MAIN_DINE_IN:

    return {
      ...state,
      stockRecipesProducts: state.stockRecipesProducts.map(product => {
        if (product.uuid === action.payload.productId && product.mainItem && product.mainItem.dineIn) {
          return {
            ...product,
            mainItem: {
              ...product.mainItem,
              dineIn: [...product.mainItem.dineIn, action.payload.newItem],
            },
          };
        }
        return product;
      }),
    };

    case ADD_TO_MAIN_PICK_UP:

      return {
        ...state,
        stockRecipesProducts: state.stockRecipesProducts.map(product => {
          if (product.uuid === action.payload.productId && product.mainItem && product.mainItem.pickUp) {
            return {
              ...product,
              mainItem: {
                ...product.mainItem,
                pickUp: [...product.mainItem.pickUp, action.payload.newItem],
              },
            };
          }
          return product;
        }),
      };

      case ADD_TO_MAIN_DELIVERY:

      return {
        ...state,
        stockRecipesProducts: state.stockRecipesProducts.map(product => {
          if (product.uuid === action.payload.productId && product.mainItem && product.mainItem.delivery) {
            return {
              ...product,
              mainItem: {
                ...product.mainItem,
                delivery: [...product.mainItem.delivery, action.payload.newItem],
              },
            };
          }
          return product;
        }),
      };

      case ADD_TO_ADD_DINE_IN:

      return {
        ...state,
        stockRecipesProducts: state.stockRecipesProducts.map(product => {
          if (product.uuid === action.payload.productId && product.addOns && product.addOns.dineIn) {
            return {
              ...product,
              addOns: {
                ...product.addOns,
                dineIn: [...product.addOns.dineIn, action.payload.newItem],
              },
            };
          }
          return product;
        }),
      };
  
      case ADD_TO_ADD_PICK_UP:
  
        return {
          ...state,
          stockRecipesProducts: state.stockRecipesProducts.map(product => {
            if (product.uuid === action.payload.productId && product.addOns && product.addOns.pickUp) {
              return {
                ...product,
                addOns: {
                  ...product.addOns,
                  pickUp: [...product.addOns.pickUp, action.payload.newItem],
                },
              };
            }
            return product;
          }),
        };
  
        case ADD_TO_ADD_DELIVERY:
  
        return {
          ...state,
          stockRecipesProducts: state.stockRecipesProducts.map(product => {
            if (product.uuid === action.payload.productId && product.addOns && product.addOns.delivery) {
              return {
                ...product,
                addOns: {
                  ...product.addOns,
                  delivery: [...product.addOns.delivery, action.payload.newItem],
                },
              };
            }
            return product;
          }),
        };
  
        case ADD_TO_COMBO_DINE_IN:

        return {
          ...state,
          stockRecipesProducts: state.stockRecipesProducts.map(product => {
            if (product.uuid === action.payload.productId && product.combos && product.combos.dineIn) {
              return {
                ...product,
                combos: {
                  ...product.combos,
                  dineIn: [...product.combos.dineIn, action.payload.newItem],
                },
              };
            }
            return product;
          }),
        };
    
        case ADD_TO_COMBO_PICK_UP:
    
          return {
            ...state,
            stockRecipesProducts: state.stockRecipesProducts.map(product => {
              if (product.uuid === action.payload.productId && product.combos && product.combos.pickUp) {
                return {
                  ...product,
                  combos: {
                    ...product.combos,
                    pickUp: [...product.combos.pickUp, action.payload.newItem],
                  },
                };
              }
              return product;
            }),
          };
    
          case ADD_TO_COMBO_DELIVERY:
    
          return {
            ...state,
            stockRecipesProducts: state.stockRecipesProducts.map(product => {
              if (product.uuid === action.payload.productId && product.combos && product.combos.delivery) {
                return {
                  ...product,
                  combos: {
                    ...product.combos,
                    delivery: [...product.combos.delivery, action.payload.newItem],
                  },
                };
              }
              return product;
            }),
          };

  
      
      default:
        return state;
    }
  };

export default stockRecipesReducer;
