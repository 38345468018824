import axios from 'axios'
import { CLOUD9_API, MESSAGING } from '../constants'
export const PrivateApiCall = axios.create({
  baseURL: CLOUD9_API,
})
export const PrivateApiCallMessaging = axios.create({
  baseURL: MESSAGING
})

const getIncomingOrigin = () => {
  const isProduction = process.env.NODE_ENV === 'production';
  if (isProduction) {
    const hostname = window.location.hostname;
    const parts = hostname.split('.');
    // Check if the hostname includes ".app.gain"
    const index = parts.indexOf('admin');

    if (index === 1) {
      return parts[index - 1];
    } else {
      return ''; // No subdomain found
    }
  } else {
    return 'delivery'; // In development, always use 'delivery'
  }

};
export const incomingOrigin = getIncomingOrigin()
PrivateApiCall.interceptors.request.use(
  (req) => {
    const token = localStorage.getItem('access_token')
    req.headers['incoming-origin'] = incomingOrigin
    req.headers.Authorization = `Bearer ${token}`

    // Add cancel token to request
    const source = axios.CancelToken.source();
    req.cancelToken = source.token;
    req.source = source;  // Store the cancel token source in the request object

    return req
  },
  (err) => {
    throw err
  },
)

PrivateApiCallMessaging.interceptors.request.use(
  (req) => {
    const token = localStorage.getItem('access_token')
    req.headers['incoming-origin'] = incomingOrigin
    req.headers.Authorization = `Bearer ${token}`
    return req
  },
  (err) => {
    throw err
  },
)

PrivateApiCallMessaging.interceptors.response.use(
  (res) => {
    return res
  },
  (err) => {
    throw err
  },
)

PrivateApiCall.interceptors.response.use(
  (res) => {
    return res
  },
  (err) => {
    throw err
  },
)

export const PublicApiCall = axios.create({
  baseURL: CLOUD9_API,
})

PublicApiCall.interceptors.request.use(
  (req) => {
    req.headers['incoming-origin'] = incomingOrigin
    return req
  },
  (err) => {
    throw err
  },
)

PublicApiCall.interceptors.response.use(
  (res) => {
    return res
  },
  (err) => {
    throw err
  },
)
