import {
    GET_BRANCHES_FINANCE_REQUEST,
    GET_BRANCHES_FINANCE_SUCCESS,
    GET_BRANCHES_FINANCE_ERROR,

    GET_BRANCHES_FINANCE_PRODUCT_REQUEST,
    GET_BRANCHES_FINANCE_PRODUCT_SUCCESS,
    GET_BRANCHES_FINANCE_PRODUCT_ERROR,

    UPDATE_FINANCE_AVAILABILITY_STARTED,
    UPDATE_FINANCE_AVAILABILITY_ENDED,

    UPDATE_FINANCE_PRICE_STARTED,
    UPDATE_FINANCE_PRICE_ENDED,

    CLEAR_ALL_BRANCHES_FINANCE,
    CLEAR_BRANCHES_FINANCE_LOADING
} from '../actions/BranchesFinanceActions'

const initialState = {
    productLoading: false,
    loading: false,
    actionLoading: false,
    products: [],
    product: null
}

function branchesFinance(state = initialState, action) {
    switch (action.type) {
        case GET_BRANCHES_FINANCE_PRODUCT_ERROR:
            return Object.assign({}, state, {
                productLoading: false,
                product: action.payload
            })
        case GET_BRANCHES_FINANCE_PRODUCT_SUCCESS:
            return Object.assign({}, state, {
                productLoading: false,
                product: action.payload
            })
        case GET_BRANCHES_FINANCE_PRODUCT_REQUEST:
            return Object.assign({}, state, {
                productLoading: true
            })
        case CLEAR_BRANCHES_FINANCE_LOADING:
            return Object.assign({}, state, {
                loading: false,
                actionLoading: false,
            })
        case GET_BRANCHES_FINANCE_REQUEST:
            return Object.assign({}, state, {
                loading: true
            })
        case GET_BRANCHES_FINANCE_SUCCESS:
            return Object.assign({}, state, {
                loading: false,
                products: action.payload
            })
        case GET_BRANCHES_FINANCE_ERROR:
            return Object.assign({}, state, {
                loading: false,
                products: []
            })
        case UPDATE_FINANCE_AVAILABILITY_STARTED:
            return Object.assign({}, state, {
                actionLoading: true
            })
        case UPDATE_FINANCE_AVAILABILITY_ENDED:
            return Object.assign({}, state, {
                actionLoading: false
            })
        case UPDATE_FINANCE_PRICE_STARTED:
            return Object.assign({}, state, {
                actionLoading: true
            })
        case UPDATE_FINANCE_PRICE_ENDED:
            return Object.assign({}, state, {
                actionLoading: false
            })
        case CLEAR_ALL_BRANCHES_FINANCE:
            return initialState
        default:
            return state
    }
}
export default branchesFinance
