import {
  getUsers,
  addUser,
  editUser,
  deleteUser,
  editUserSettings,
  changeAccountActivity,
  changeAccountVerification,
  getAppUsers,
  getAppUser,
  addAppUser,
  editAppUser,
  deleteAppUser,
  getRestaurantsWithBranches
} from "../../config/config";
import { toast } from "react-toastify";
import { parseError } from "../../utils/parseError";
import { LOGOUT_USER, SET_PERMISSIONS } from "./AuthActions";
import { SET_LANGUAGE } from "./TranslationsActions";
import { handleError } from "../../utils/utils";

export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_ERROR = "GET_USER_ERROR";

export const GET_APP_USERS_REQUEST = "GET_APP_USERS_REQUEST";
export const GET_APP_USERS_SUCCESS = "GET_APP_USERS_SUCCESS";
export const GET_APP_USERS_ERROR = "GET_APP_USERS_ERROR";

export const GET_RESTAURANTS_BRANCHES_REQUEST = "GET_RESTAURANTS_BRANCHES_REQUEST";
export const GET_RESTAURANTS_BRANCHES_SUCCESS = "GET_RESTAURANTS_BRANCHES_SUCCESS";
export const GET_RESTAURANTS_BRANCHES_ERROR = "GET_RESTAURANTS_BRANCHES_ERROR";

export const GET_APP_USER_REQUEST = "GET_APP_USER_REQUEST";
export const GET_APP_USER_SUCCESS = "GET_APP_USER_SUCCESS";
export const GET_APP_USER_ERROR = "GET_APP_USER_ERROR";

export const ADD_USER_STARTED = "ADD_USER_STARTED";
export const ADD_USER_END = "ADD_USER_END";

export const EDIT_USER_STARTED = "EDIT_USER_STARTED";
export const EDIT_USER_END = "EDIT_USER_END";

export const DELETE_USER_STARTED = "DELETE_USER_STARTED";
export const DELETE_USER_END = "DELETE_USER_END";

export const EDIT_USER_SETTINGS_STARTED = "EDIT_USER_SETTINGS_STARTED";
export const EDIT_USER_SETTINGS_ENDED = "EDIT_USER_SETTINGS_ENDED";

export const ADD_APP_USER_STARTED = "APP_APP_USER_STARTED";
export const ADD_APP_USER_ENDED = "APP_APP_USER_ENDED";

export const EDIT_APP_USER_STARTED = "EDIT_APP_USER_STARTED";
export const EDIT_APP_USER_ENDED = "EDIT_APP_USER_ENDED";

export const DELETE_APP_USER_STARTED = "DELETE_APP_USER_STARTED";
export const DELETE_APP_USER_ENDED = "DELETE_APP_USER_ENDED";

export const SET_USER_POPUP = "SET_USER_POPUP";
export const CLEAR_ALL_USER = "CLEAR_ALL_USER";

export const CHANGE_ACCOUNT_ACTIVITY_STARTED = 'CHANGE_ACCOUNT_ACTIVITY_STARTED'
export const CHANGE_ACCOUNT_ACTIVITY_ENDED = 'CHANGE_ACCOUNT_ACTIVITY_ENDED'

export const CHANGE_ACCOUNT_VERIFICATION_STARTED = 'CHANGE_ACCOUNT_VERIFICATION_STARTED'
export const CHANGE_ACCOUNT_VERIFICATION_ENDED = 'CHANGE_ACCOUNT_VERIFICATION_ENDED'

export const CLEAR_USERS_LOADING = 'CLEAR_USERS_LOADING'

const actions = {
  getRestaurantsWithBranches: () => async (dispatch) => {
    dispatch({
      type: GET_RESTAURANTS_BRANCHES_REQUEST
    })
    await getRestaurantsWithBranches().then((response) => {
      if (response.status === 200) {
        dispatch({
          type: GET_RESTAURANTS_BRANCHES_SUCCESS,
          payload: response.data.restaurants
        })
      }
      else {
        handleError(dispatch)(response, GET_RESTAURANTS_BRANCHES_ERROR, 'GET RESTAURANTS BRANCHES ERROR')
        // if (response.status !== 401 && response.status !== 403)
        //   toast.error(response?.data?.message || "GET RESTAURANTS WITH BRANCHES ERROR");
        // if (response.status === 401) {
        //   dispatch({ type: LOGOUT_USER });
        // }
        // if (response.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: response?.data?.permissions
        //   })
        // }
        // dispatch({
        //   type: GET_RESTAURANTS_BRANCHES_ERROR
        // })
      }
    })
  },
  getUsers: () => async (dispatch, getState) => {
    if (getState().user.users.length === 0)
      dispatch({
        type: GET_USER_REQUEST,
      });
    await getUsers().then((res) => {
      if (res?.status === 200) {
        dispatch({
          type: GET_USER_SUCCESS,
          payload: res?.data?.users
        });
      } else {
        handleError(dispatch)(res, GET_USER_ERROR, 'GET USER ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || "Get All Users error");
        // dispatch({
        //   type: GET_USER_ERROR,
        // });
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER });
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
      }
    });
  },

  addUser: (data, resetPass, users) => async (dispatch) => {
    const password = data.password
    const currentUuids = users.map((user) => user.uuid)
    delete data.password
    dispatch({
      type: ADD_USER_STARTED,
    });
    await addUser(data).then((res) => {
      if (res?.status === 200) {
        dispatch({
          type: GET_USER_SUCCESS,
          payload: res?.data?.users
        });
        let addedUuid = res?.data?.users.find((user) => !currentUuids.includes(user.uuid)).uuid
        resetPass(addedUuid, {
          password: password
        })
        dispatch({
          type: SET_USER_POPUP,
          payload: false,
        });
        toast.success(res?.data.message || 'ADD USER SUCCESS')
      } else {
        handleError(dispatch)(res, null, 'ADD USER ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || "Add User error");
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER });
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
      }
    });
    dispatch({
      type: ADD_USER_END,
    });
  },

  editUser: (uuid, data) => async (dispatch) => {
    dispatch({
      type: EDIT_USER_STARTED,
    });
    await editUser(uuid, data).then((res) => {
      if (res?.status === 200) {
        toast.success(res?.data.message || 'EDIT USER SUCCESS')
        dispatch({
          type: GET_USER_SUCCESS,
          payload: res?.data?.users
        });
        dispatch({
          type: SET_USER_POPUP,
          payload: false,
        });
      } else {
        handleError(dispatch)(res, null, 'EDIT USERS ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || "Edit Users error");
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER });
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
      }
    });
    dispatch({
      type: EDIT_USER_END,
    });
  },

  deleteUser: (uuid, close) => async (dispatch) => {
    dispatch({
      type: DELETE_USER_STARTED,
    });
    await deleteUser(uuid).then((res) => {
      if (res?.status === 200) {
        dispatch({
          type: GET_USER_SUCCESS,
          payload: res?.data?.users,
        });
        dispatch({
          type: SET_USER_POPUP,
          payload: false,
        });
        if (close)
          close()
      } else {
        handleError(dispatch)(res, null, 'DELETE USER ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || "Delete User Error");
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER });
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
      }
    });
    dispatch({
      type: DELETE_USER_END,
    });
  },

  setActionModal: (state) => async (dispatch) => {
    dispatch({
      type: SET_USER_POPUP,
      payload: state,
    });
  },
  editUserSettings: (data, onClose) => async (dispatch) => {
    dispatch({
      type: EDIT_USER_SETTINGS_STARTED,
    });
    await editUserSettings(data).then((response) => {
      if (response.status === 200) {
        // toast.success(response.data?.message || "EDIT USER SETTINGS SUCCESS");
        dispatch({
          type: SET_LANGUAGE,
          payload: response.data.UserSettings.settings.language,
        });
        if (onClose)
          onClose();
      } else {
        handleError(dispatch)(response, null, 'EDIT USER SETTINGS ERROR')
        // if (response.status !== 401 && response.status !== 403)
        //   toast.error(response.data?.message || "EDIT USER SETTINGS ERROR");
        // if (response.status === 401)
        //   dispatch({
        //     type: LOGOUT_USER,
        //   });
        // if (response.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: response?.data?.permissions
        //   })
        // }
      }
    });
    dispatch({
      type: EDIT_USER_SETTINGS_ENDED,
    });
  },
  changeAccountActivity: (uuid, data) => async (dispatch) => {
    dispatch({
      type: CHANGE_ACCOUNT_ACTIVITY_STARTED
    })
    await changeAccountActivity(uuid, data).then((response) => {
      if (response.status === 200) {
        toast.success(response.data.message || 'CHANGE ACCOUNT ACTIVITY SUCCESS')
        dispatch({
          type: GET_USER_SUCCESS,
          payload: response.data.users
        })
      } else {
        handleError(dispatch)(response, null, 'CHANGE ACCOUNT ACTIVITY ERROR')
        // if (response.status !== 401 && response.status !== 403)
        //   toast.error(response.data.message || 'CHANGE ACCOUNT ACTIVITY ERROR')
        // if (response.status === 401)
        //   dispatch({
        //     type: LOGOUT_USER
        //   })
        // if (response.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: response?.data?.permissions
        //   })
        // }
      }
    })
    dispatch({
      type: CHANGE_ACCOUNT_ACTIVITY_ENDED
    })
  },
  changeAccountVerification: (uuid, data) => async (dispatch) => {
    dispatch({
      type: CHANGE_ACCOUNT_VERIFICATION_STARTED
    })
    await changeAccountVerification(uuid, data).then((response) => {
      if (response.status === 200) {
        toast.success(response.data.message || 'CHANGE ACCOUNT VERIFICATION SUCCESS')
        dispatch({
          type: GET_USER_SUCCESS,
          payload: response.data.users
        })
      } else {
        handleError(dispatch)(response, null, 'CHANGE ACCOUNT VERIFICATION ERROR')
        // if (response.status !== 401 && response.status !== 403)
        //   toast.error(response.data.message || 'CHANGE ACCOUNT VERIFICATION ERROR')
        // if (response.status === 401)
        //   dispatch({
        //     type: LOGOUT_USER
        //   })
        // if (response.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: response?.data?.permissions
        //   })
        // }
      }
    })
    dispatch({
      type: CHANGE_ACCOUNT_ACTIVITY_ENDED
    })
  },
  setLanguage: (data) => (dispatch) => {
    dispatch({
      type: SET_LANGUAGE,
      payload: data
    })
  },
  // CLEAR ALL Reducer
  clearAll: () => (dispatch) => {
    dispatch({
      type: CLEAR_ALL_USER,
    });
  },
  getAppUsers: () => async (dispatch, getState) => {
    if (getState().user.appUsers.length === 0)
      dispatch({
        type: GET_APP_USERS_REQUEST,
      });
    await getAppUsers().then((response) => {
      if (response.status === 200) {
        dispatch({
          type: GET_APP_USERS_SUCCESS,
          payload: response?.data?.users,
        });
      } else {
        handleError(dispatch)(response, GET_APP_USERS_ERROR, 'GET APP USERS ERROR')
        // if (response.status !== 401 && response.status !== 403)
        //   toast.error(response?.data?.message || "GET APP USERS ERROR");
        // dispatch({
        //   type: GET_APP_USERS_ERROR,
        // });
        // if (response.status === 401)
        //   dispatch({
        //     type: LOGOUT_USER,
        //   });
        // if (response.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: response?.data?.permissions
        //   })
        // }
      }
    });
  },
  getAppUser: (uuid) => async (dispatch) => {
    dispatch({
      type: GET_APP_USER_REQUEST,
    });
    await getAppUser(uuid).then((response) => {
      if (response.status === 200) {
        dispatch({
          type: GET_APP_USER_SUCCESS,
          payload: response?.data?.user[0],
        });
      } else {
        handleError(dispatch)(response, GET_APP_USER_ERROR, 'GET APP USER ERROR')
        // if (response.status !== 401 && response.status !== 403)
        //   toast.error(response?.data?.message || "GET APP USERS ERROR");
        // dispatch({
        //   type: GET_APP_USER_ERROR,
        // });
        // if (response.status === 401)
        //   dispatch({
        //     type: LOGOUT_USER,
        //   });
        // if (response.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: response?.data?.permissions
        //   })
        // }
      }
    });
  },
  addAppUser: (data, setSelectedValues, setModalState, setSelectedGroup) => async (dispatch) => {
    dispatch({
      type: ADD_APP_USER_STARTED,
    });
    await addAppUser(data).then((response) => {
      if (response.status === 200) {
        toast.success(response?.data?.message || "ADD APP USER SUCCESS");
        dispatch({
          type: GET_APP_USERS_SUCCESS,
          payload: response?.data?.users,
        });
        setModalState(false);
        setSelectedValues();
        if (setSelectedGroup)
          setSelectedGroup()
      } else {
        handleError(dispatch)(response, null, 'ADD APP ERROR')
        // if (response.status !== 401 && response.status !== 403)
        //   toast.error(
        //     response?.data?.message || response?.data || "ADD APP USER ERROR"
        //   );
        // if (response.status === 401)
        //   dispatch({
        //     type: LOGOUT_USER,
        //   });
        // if (response.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: response?.data?.permissions
        //   })
        // }
      }
    });
    dispatch({
      type: ADD_APP_USER_ENDED,
    });
  },
  editAppUser:
    (uuid, data, setSelectedValues, setModalState, setSelectedGroup) => async (dispatch) => {
      dispatch({
        type: EDIT_APP_USER_STARTED,
      });
      await editAppUser(uuid, data).then((response) => {
        if (response.status === 200) {
          toast.success(response?.data?.message || "EDIT APP USER SUCCESS");
          dispatch({
            type: GET_APP_USERS_SUCCESS,
            payload: response?.data?.users,
          });
          setModalState(false);
          setSelectedValues();
          if (setSelectedGroup)
            setSelectedGroup()
        } else {
          handleError(dispatch)(response, null, 'EDIT APP USER ERROR')
          // if (response.status !== 401 && response.status !== 403)
          //   toast.error(response?.data?.message || "EDIT APP USER ERROR");
          // if (response.status === 401)
          //   dispatch({
          //     type: LOGOUT_USER,
          //   });
          // if (response.status === 403) {
          //   window.location = '/forbidden'
          //   dispatch({
          //     type: SET_PERMISSIONS,
          //     payload: response?.data?.permissions
          //   })
          // }
        }
      });
      dispatch({
        type: EDIT_APP_USER_ENDED,
      });
    },
  deleteAppUser:
    (uuid, setSelectedValues, setDeleteModalState, setSideViewData, sideViewUuid) => async (dispatch) => {
      dispatch({
        type: DELETE_APP_USER_STARTED,
      });
      await deleteAppUser(uuid).then((response) => {
        if (response.status === 200) {
          toast.success(response?.data?.message || "DELETE APP USER SUCCESS");
          dispatch({
            type: GET_APP_USERS_SUCCESS,
            payload: response?.data?.users,
          });
          setSelectedValues();
          setDeleteModalState(false);
          if (uuid === sideViewUuid)
            setSideViewData()
        } else {
          handleError(dispatch)(response, null, 'DELETE APP USER ERROR ERROR')
          // if (response.status !== 401 && response.status !== 403)
          //   toast.error(response?.data?.message || "DELETE APP USER ERROR");
          // if (response.status === 401)
          //   dispatch({
          //     type: LOGOUT_USER,
          //   });
          // if (response.status === 403) {
          //   window.location = '/forbidden'
          //   dispatch({
          //     type: SET_PERMISSIONS,
          //     payload: response?.data?.permissions
          //   })
          // }
        }
      });
      dispatch({
        type: DELETE_APP_USER_ENDED,
      });
    },
};

export default actions;
