import { getRoles, addRole, editRole, deleteRole } from '../../config/config'
import { toast } from 'react-toastify'
import { parseError } from '../../utils/parseError'
import { LOGOUT_USER } from './AuthActions'
import { SET_PERMISSIONS } from './AuthActions'
import { handleError } from '../../utils/utils'

export const GET_ROLE_REQUEST = 'GET_ROLE_REQUEST'
export const GET_ROLE_SUCCESS = 'GET_ROLE_SUCCESS'
export const GET_ROLE_ERROR = 'GET_ROLE_ERROR'
export const GET_ALL_PAGES_SUCCESS = 'GET_ALL_PAGES_SUCCESS'

export const ADD_ROLE_STARTED = 'ADD_ROLE_STARTED'
export const ADD_ROLE_END = 'ADD_ROLE_END'

export const EDIT_ROLE_STARTED = 'EDIT_ROLE_STARTED'
export const EDIT_ROLE_END = 'EDIT_ROLE_END'

export const DELETE_ROLE_STARTED = 'DELETE_ROLE_STARTED'
export const DELETE_ROLE_END = 'DELETE_ROLE_END'

export const SET_ROLE_POPUP = 'SET_ROLE_POPUP'
export const CLEAR_ALL_ROLE = 'CLEAR_ALL_ROLE'

export const CLEAR_ROLE_LOADING = 'CLEAR_ROLE_LOADING'

const actions = {
  getRoles: () => async (dispatch) => {
    dispatch({
      type: GET_ROLE_REQUEST,
    })
    await getRoles().then((res) => {
      if (res?.status === 200) {
        // dispatch({
        //   type: GET_ALL_PAGES_SUCCESS,
        //   payload: res?.data?.allPages
        // })
        dispatch({
          type: GET_ROLE_SUCCESS,
          payload: {
            roles: res?.data?.roles,
            allPages: res?.data?.allPages
          },
        })
      } else {
        handleError(dispatch)(res, GET_ROLE_ERROR, 'GET ROLE ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Get All Roles error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
        // dispatch({
        //   type: GET_ROLE_ERROR,
        //   payload: {
        //     message: parseError(res?.data?.error) || res?.data?.message || 'GET Role error',
        //   },
        // })
      }
    })
  },

  addRole: (data) => async (dispatch) => {
    dispatch({
      type: ADD_ROLE_STARTED,
    })
    await addRole(data).then(async (res) => {
      if (res?.status === 200) {
        await getRoles().then((res) => {
          if (res?.status === 200) {
            // dispatch({
            //   type: GET_ALL_PAGES_SUCCESS,
            //   payload: res?.data?.allPages
            // })
            dispatch({
              type: GET_ROLE_SUCCESS,
              payload: {
                roles: res?.data?.roles,
                allPages: res?.data?.allPages
              },
            })
          } else {
            handleError(dispatch)(res, GET_ROLE_ERROR, 'GET ROLE ERROR')
            // if (res.status !== 401 && res.status !== 403)
            //   toast.error(res?.data?.message || 'Get All Roles error')
            // if (res.status === 401) {
            //   dispatch({ type: LOGOUT_USER })
            // }
            // if (res.status === 403) {
            //   window.location = '/forbidden'
            //   dispatch({
            //     type: SET_PERMISSIONS,
            //     payload: res?.data?.permissions
            //   })
            // }
            // dispatch({
            //   type: GET_ROLE_ERROR,
            //   payload: {
            //     message: parseError(res?.data?.error) || res?.data?.message || 'GET Role error',
            //   },
            // })
          }
        })
        toast.success(res?.data?.message || 'ADD ROLE SUCCESS')
      } else {
        handleError(dispatch)(res, null, 'ADD ROLE ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Add Role error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
      }
    })
    dispatch({
      type: ADD_ROLE_END,
    })
  },
  editRole: (data) => async (dispatch) => {
    dispatch({
      type: EDIT_ROLE_STARTED
    })

    try {
      const editRequests = data.reduce((promises, putData) => {
        let obj = { ...putData }
        delete obj.uuid
        const promise = editRole(putData.uuid, obj)
        return [...promises, promise];
      }, []);


      const [editResponse] = await Promise.all([
        ...editRequests,
      ]);


      if (editResponse?.status === 200) {
        toast.success(editResponse.data.message || 'Successfully Saved')
        await getRoles().then((res) => {
          if (res?.status === 200) {
            // dispatch({
            //   type: GET_ALL_PAGES_SUCCESS,
            //   payload: res?.data?.allPages
            // })
            dispatch({
              type: GET_ROLE_SUCCESS,
              payload: {
                roles: res?.data?.roles,
                allPages: res?.data?.allPages
              },
            })
          } else {
            handleError(dispatch)(res, GET_ROLE_ERROR, 'GET ROLE ERROR ERROR')
            // if (res.status !== 401 && res.status !== 403)
            //   toast.error(res?.data?.message || 'Get All Roles error')
            // if (res.status === 401) {
            //   dispatch({ type: LOGOUT_USER })
            // }
            // if (res.status === 403) {
            //   window.location = '/forbidden'
            //   dispatch({
            //     type: SET_PERMISSIONS,
            //     payload: res?.data?.permissions
            //   })
            // }
            // dispatch({
            //   type: GET_ROLE_ERROR,
            //   payload: {
            //     message: parseError(res?.data?.error) || res?.data?.message || 'GET Role error',
            //   },
            // })
          }
        })
      }
      else {
        if (editResponse?.status !== 200 && data.length > 0) {
          if (editResponse.status !== 401 && editResponse.status !== 403)
            toast.error(editResponse?.data?.message || 'Something went wrong')
          if (editResponse?.status === 401)
            dispatch({ type: LOGOUT_USER })
          if (editResponse.status === 403)
            dispatch({
              type: SET_PERMISSIONS,
              payload: editResponse?.data?.permissions
            })
        }
      }
    } catch (error) {
      console.error(error)
    }

    dispatch({
      type: EDIT_ROLE_END
    })
  },
  editRoleSingle: (uuid, data) => async (dispatch) => {
    dispatch({
      type: EDIT_ROLE_STARTED,
    })
    await editRole(uuid, data).then(async (res) => {
      if (res?.status === 200) {
        toast.success(res?.data?.message || 'EDIT ROLE SUCCESS')
        await getRoles().then((res) => {
          if (res?.status === 200) {
            // dispatch({
            //   type: GET_ALL_PAGES_SUCCESS,
            //   payload: res?.data?.allPages
            // })
            dispatch({
              type: GET_ROLE_SUCCESS,
              payload: {
                roles: res?.data?.roles,
                allPages: res?.data?.allPages
              },
            })
          } else {
            handleError(dispatch)(res, GET_ROLE_ERROR, 'GET ROLE ERROR')
            // if (res.status !== 401 && res.status !== 403)
            //   toast.error(res?.data?.message || 'Get All Roles error')
            // if (res.status === 401) {
            //   dispatch({ type: LOGOUT_USER })
            // }
            // if (res.status === 403) {
            //   window.location = '/forbidden'
            //   dispatch({
            //     type: SET_PERMISSIONS,
            //     payload: res?.data?.permissions
            //   })
            // }
            // dispatch({
            //   type: GET_ROLE_ERROR,
            //   payload: {
            //     message: parseError(res?.data?.error) || res?.data?.message || 'GET Role error',
            //   },
            // })
          }
        })
      } else {
        handleError(dispatch)(res, null, 'EDIT ROLE ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Edit Roles error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
      }
    })
    dispatch({
      type: EDIT_ROLE_END,
    })
  },

  deleteRole: (uuid) => async (dispatch) => {
    dispatch({
      type: DELETE_ROLE_STARTED,
    })
    await deleteRole(uuid).then(async (res) => {
      if (res?.status === 200) {
        await getRoles().then((res) => {
          if (res?.status === 200) {
            // dispatch({
            //   type: GET_ALL_PAGES_SUCCESS,
            //   payload: res?.data?.allPages
            // })
            dispatch({
              type: GET_ROLE_SUCCESS,
              payload: {
                roles: res?.data?.roles,
                allPages: res?.data?.allPages
              },
            })
          } else {
            handleError(dispatch)(res, GET_ROLE_ERROR, 'GET ROLE ERROR')
            // if (res.status !== 401 && res.status !== 403)
            //   toast.error(res?.data?.message || 'Get All Roles error')
            // if (res.status === 401) {
            //   dispatch({ type: LOGOUT_USER })
            // }
            // if (res.status === 403) {
            //   window.location = '/forbidden'
            //   dispatch({
            //     type: SET_PERMISSIONS,
            //     payload: res?.data?.permissions
            //   })
            // }
            // dispatch({
            //   type: GET_ROLE_ERROR,
            //   payload: {
            //     message: parseError(res?.data?.error) || res?.data?.message || 'GET Role error',
            //   },
            // })
          }
        })
        toast.success(res?.data?.message || 'DELETE ROLE SUCCESS')
      } else {
        handleError(dispatch)(res, null, 'DELETE ROLE ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Delete Role Error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
      }
    })
    dispatch({
      type: DELETE_ROLE_END,
    })
  },

  setActionModal: (state) => async (dispatch) => {
    dispatch({
      type: SET_ROLE_POPUP,
      payload: state,
    })
  },

  // CLEAR ALL Reducer
  clearAll: () => (dispatch) => {
    dispatch({
      type: CLEAR_ALL_ROLE,
    })
  },
}

export default actions
