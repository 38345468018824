import React, { lazy, useEffect } from 'react'
import { Switch, withRouter, Route, Redirect } from 'react-router-dom'
import UserRoute from '../../routers/UserRoute'
import { Box } from '@mui/material'
import { connect } from 'react-redux'
import LayoutActions from '../../redux/actions/LayoutActions'

const components = {
    productCategories: lazy(() => import('../../pages/product/Category')),
    productGroups: lazy(() => import('../../pages/product/Group')),
    productsList: lazy(() => import('../../pages/product/Products')),
    ingredients: lazy(() => import('../../pages/ingredient/Ingredient')),
    modifiersGroups: lazy(() => import('../../pages/modifier/ModifiersGroup')),
    foodTypes: lazy(() => import('../../pages/TypesAndAllergies/TypesAndAllergies')),
    modifiersGroupEdit: lazy(() => import('../../pages/modifier/add-edit-modifiers-groups/Edit')),
    productAdd: lazy(() => import("../../pages/product/AddProduct")),
    productEdit: lazy(() => import("../../pages/product/EditProduct")),
    modifiers: lazy(() => import("../../pages/modifier/Modifiers"))
};


function ProductAttributesLayout({
    setPathCheck,
    permission,
    reformat
}) {
    const access = reformat(permission.subPage)
        .sort((a, b) => {
            // Check if either 'order' is null
            if (a.order === null && b.order === null) {
                return 0; // Keep the order unchanged if both are null
            } else if (a.order === null) {
                return 1; // Place 'a' (null) after 'b' (non-null)
            } else if (b.order === null) {
                return -1; // Place 'b' (null) after 'a' (non-null)
            }
            // Sort non-null 'order' values normally
            return a.order - b.order;
        });
    useEffect(() => {
        setPathCheck(permission.route)
    }, [window.location.pathname])
    return (
        <div>
            <Box>
                <Switch>
                    <Route exact path={permission.route} render={() => <Redirect to={access[0]?.route} />} />
                    {access.map((permission2, i) =>
                        <UserRoute key={i} exact path={permission2.route} component={components[permission2.key]} permission={permission2} />
                    )}
                    <Redirect from="*" to="/not-found" />
                </Switch>
            </Box>
        </div>
    )
}
const mapStateToProps = (state) => ({
    automatedMessagesActiveTab: state.layout.automatedMessagesActiveTab
})
const mapDispatchToProps = (dispatch) => ({
    setPathCheck: (data) => dispatch(LayoutActions.setPathCheck(data))
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProductAttributesLayout))