import { ADD_LINKED_PRODUCT,ADD_UOM,ADD_PRODUCT,TOGGLE_FAVORITE} from "../actions/StockItemsActions";
import stockDataArray from "../../pages/stock/stock-data-file";
import unitMeasurement from "../../pages/stock/unit-measurement";
import LinkedProductsArray from "../../pages/stock/addProducts/linked-products-data-file";
const INITIAL_STATE = {
  stockItemsProducts: stockDataArray,
  uom: unitMeasurement,
  linkedProducts: LinkedProductsArray,
};

const stockItemsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_PRODUCT:
      return {
        ...state,
        stockItemsProducts: [...state.stockItemsProducts, action.payload],
      };

      case ADD_UOM:
      return {
        ...state,
        uom: [...state.uom, action.payload],
      };
      case TOGGLE_FAVORITE:
        const updatedLinkedProducts = state.linkedProducts.map(brand => {
          if (brand.id === action.payload) {
            return {
              ...brand,
              favorite: !brand.favorite,
            };
          }
          return brand;
        });
        return {
          ...state,
          linkedProducts: updatedLinkedProducts,
        };
        case ADD_LINKED_PRODUCT:
          return {
            ...state,
            linkedProducts: [...state.linkedProducts, action.payload],
          };

    default:
      return state;
  }
};

export default stockItemsReducer;
