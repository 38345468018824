import {
    SET_FCM_TOKEN,

    REGISTRATION_REQUEST,
    REGISTRATION_SUCCESS,
    REGISTRATION_ERROR,

    GET_NOTIFICATIONS_REQUEST,
    GET_NOTIFICATIONS_SUCCESS,
    GET_NOTIFICATIONS_ERROR,

    VIEW_NOTIFICATION_STARTED,
    VIEW_NOTIFICATION_ENDED,

    CLEAR_NOTIFICATIONS,
    CLEAR_NOTIFICATIONS_LOADING
} from '../actions/NotificationsActions'

const initialState = {
    token: '',
    loading: false,
    notifications: []
}

function notifications(state = initialState, action) {
    switch (action.type) {
        case CLEAR_NOTIFICATIONS_LOADING:
            return Object.assign({}, state, {
                loading: false
            })
        case GET_NOTIFICATIONS_ERROR:
            return Object.assign({}, state, {
                loading: false,
                notifications: []
            })
        case GET_NOTIFICATIONS_SUCCESS:
            return Object.assign({}, state, {
                loading: false,
                notifications: action.payload
            })
        case GET_NOTIFICATIONS_REQUEST:
            return Object.assign({}, state, {
                loading: true
            })
        case CLEAR_NOTIFICATIONS:
            return Object.assign({}, state, {
                token: ''
            })
        case SET_FCM_TOKEN:
            return Object.assign({}, state, {
                token: action.payload
            })
        default:
            return state
    }
}
export default notifications