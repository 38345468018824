import {
    GET_AUTOMATED_MESSAGE_REQUEST,
    GET_AUTOMATED_MESSAGE_SUCCESS,
    GET_AUTOMATED_MESSAGE_ERROR,

    GET_AUTOMATED_MESSAGES_REQUEST,
    GET_AUTOMATED_MESSAGES_SUCCESS,
    GET_AUTOMATED_MESSAGES_ERROR,

    ADD_AUTOMATED_MESSAGE_STARTED,
    ADD_AUTOMATED_MESSAGE_ENDED,

    EDIT_AUTOMATED_MESSAGE_STARTED,
    EDIT_AUTOMATED_MESSAGE_ENDED,

    DELETE_AUTOMATED_MESSAGE_STARTED,
    DELETE_AUTOMATED_MESSAGE_ENDED,

    SET_EDIT_DATA,
    CLEAR_AUTO_MSG_LOADING
} from '../actions/AutomatedMessagesActions'

const initialState = {
    automatedMessages: [],
    loading: false,
    automatedMessage: {},
    loadingSingle: false,
    addLoading: false,
    editLoading: false,
    deleteLoading: false,
    editAutoMessage: null
}

function automatedMessages(state = initialState, action) {
    switch (action.type) {
        case CLEAR_AUTO_MSG_LOADING:
            return Object.assign({}, state, {
                loading: false,
                loadingSingle: false,
                addLoading: false,
                editLoading: false,
                deleteLoading: false,
            })
        case SET_EDIT_DATA:
            return Object.assign({}, state, {
                editAutoMessage: action.payload
            })
        case ADD_AUTOMATED_MESSAGE_STARTED:
            return Object.assign({}, state, {
                addLoading: true
            })
        case ADD_AUTOMATED_MESSAGE_ENDED:
            return Object.assign({}, state, {
                addLoading: false
            })
        case EDIT_AUTOMATED_MESSAGE_STARTED:
            return Object.assign({}, state, {
                editLoading: true
            })
        case EDIT_AUTOMATED_MESSAGE_ENDED:
            return Object.assign({}, state, {
                editLoading: false
            })
        case DELETE_AUTOMATED_MESSAGE_STARTED:
            return Object.assign({}, state, {
                deleteLoading: true
            })
        case DELETE_AUTOMATED_MESSAGE_ENDED:
            return Object.assign({}, state, {
                deleteLoading: false
            })
        case GET_AUTOMATED_MESSAGES_REQUEST:
            return Object.assign({}, state, {
                loading: true
            })
        case GET_AUTOMATED_MESSAGES_SUCCESS:
            return Object.assign({}, state, {
                loading: false,
                automatedMessages: action.payload
            })
        case GET_AUTOMATED_MESSAGES_ERROR:
            return Object.assign({}, state, {
                loading: false,
                automatedMessages: []
            })
        case GET_AUTOMATED_MESSAGE_REQUEST:
            return Object.assign({}, state, {
                loadingSingle: true
            })
        case GET_AUTOMATED_MESSAGE_SUCCESS:
            return Object.assign({}, state, {
                loadingSingle: false,
                automatedMessage: action.payload
            })
        case GET_AUTOMATED_MESSAGE_ERROR:
            return Object.assign({}, state, {
                loadingSingle: false,
                automatedMessage: {}
            })
        default:
            return state
    }
}
export default automatedMessages