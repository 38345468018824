export const SET_SIDENAV_SETTINGS = 'SET_SIDENAV_SETTINGS'
export const SET_IS_OPEN = 'SET_IS_OPEN'
export const UPDATE_HEADER_STATE = 'UPDATE_HEADER_STATE'
export const SET_PATH_CHECK = 'SET_PATH_CHECK'
export const CLEAR_LAYOUT = 'CLEAR_LAYOUT'
export const SET_PRODUCTS_ATTRIBUTES_VIEW = 'SET_PRODUCTS_ATTRIBUTES_VIEW'

const actions = {
  setProductsAttributesView: (state) => (dispatch) => {
    dispatch({
      type: SET_PRODUCTS_ATTRIBUTES_VIEW,
      payload: state
    })
  },
  sideNavChange: (state) => (dispatch) => {
    dispatch({
      type: SET_SIDENAV_SETTINGS,
      payload: state
    })
  },
  setIsOpen: (state) => (dispatch) => {
    dispatch({
      type: SET_IS_OPEN,
      payload: state
    })
  },
  updateHeaderState: (state) => (dispatch) => {
    dispatch({
      type: UPDATE_HEADER_STATE,
      payload: state
    })
  },
  setPathCheck: (state) => (dispatch) => {
    dispatch({
      type: SET_PATH_CHECK,
      payload: state
    })
  },
  clearLayout: () => (dispatch) => {
    dispatch({
      type: CLEAR_LAYOUT
    })
  },
}

export default actions