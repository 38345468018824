import {
    getSplashScreenDesignSetup,
    editSplashScreenDesignSetup
} from "../../config/config";
import { toast } from "react-toastify";
import { handleError } from "../../utils/utils";

export const GET_SPLASH_SCREEN_DESIGN_SETUP_REQUEST = "GET_SPLASH_SCREEN_DESIGN_SETUP_REQUEST";
export const GET_SPLASH_SCREEN_DESIGN_SETUP_SUCCESS = "GET_SPLASH_SCREEN_DESIGN_SETUP_SUCCESS";
export const GET_SPLASH_SCREEN_DESIGN_SETUP_ERROR = "GET_SPLASH_SCREEN_DESIGN_SETUP_ERROR";
export const EDIT_SPLASH_SCREEN_DESIGN_SETUP_REQUEST = "EDIT_SPLASH_SCREEN_DESIGN_SETUP_REQUEST";
export const EDIT_SPLASH_SCREEN_DESIGN_SETUP_SUCCESS = "EDIT_SPLASH_SCREEN_DESIGN_SETUP_SUCCESS";
export const EDIT_SPLASH_SCREEN_DESIGN_SETUP_ERROR = "EDIT_SPLASH_SCREEN_DESIGN_SETUP_ERROR";

export const CLEAR_SPLASH_SCREEN_LOADING = 'CLEAR_SPLASH_SCREEN_LOADING'

const actions = {
    getSplashScreenDesignSetup: (ruuid) => async (dispatch, getState) => {
        if (getState().splashScreenDesignSetup.selectedRestaurant !== ruuid)
            dispatch({
                type: GET_SPLASH_SCREEN_DESIGN_SETUP_REQUEST,
                payload: ruuid
            });
        await getSplashScreenDesignSetup().then((res) => {
            if (res?.status === 200) {
                dispatch({
                    type: GET_SPLASH_SCREEN_DESIGN_SETUP_SUCCESS,
                    payload: {
                        splashScreenDesignSetup: res?.data?.splashScreen,
                    },
                });
            } else {
                handleError(dispatch)(res, GET_SPLASH_SCREEN_DESIGN_SETUP_ERROR, 'Get splash screen design setup error')
            }
        });
    },

    editSplashScreenDesignSetup: (data) => async (dispatch) => {
        dispatch({
            type: EDIT_SPLASH_SCREEN_DESIGN_SETUP_REQUEST,
        });

        await editSplashScreenDesignSetup(data).then(async (res) => {
            if (res?.status === 200) {
                toast.success("Splash screen design setup updated successfully");
                dispatch({
                    type: EDIT_SPLASH_SCREEN_DESIGN_SETUP_SUCCESS,
                    payload: {
                        splashScreenDesignSetup: res?.data?.splashScreen,
                    },
                });

                await dispatch(actions?.getSplashScreenDesignSetup());
            } else {
                handleError(dispatch)(res, EDIT_SPLASH_SCREEN_DESIGN_SETUP_ERROR, 'Edit splash screen design setup error')
            }
        });
    },
};

export default actions;
