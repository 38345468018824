import { getAllMediaInteractions, getAllMedia, addMedia, editMedia, deleteMedia, hideMedia, showMedia, sortMedia, updateMedia } from "../../config/config";
import { toast } from 'react-toastify'
import { parseError } from '../../utils/parseError'
import { LOGOUT_USER, SET_PERMISSIONS } from './AuthActions'
import { handleError } from "../../utils/utils";

export const GET_MEDIA_REQUEST = 'GET_MEDIA_REQUEST'
export const GET_MEDIA_SUCCESS = 'GET_MEDIA_SUCCESS'
export const GET_MEDIA_ERROR = 'GET_MEDIA_ERROR'

export const GET_MEDIA_INTERACTIONS_REQUEST = 'GET_MEDIA_INTERACTIONS_REQUEST'
export const GET_MEDIA_INTERACTIONS_SUCCESS = 'GET_MEDIA_INTERACTIONS_SUCCESS'
export const GET_MEDIA_INTERACTIONS_ERROR = 'GET_MEDIA_INTERACTIONS_ERROR'

export const ADD_MEDIA_STARTED = 'ADD_MEDIA_STARTED'
export const ADD_MEDIA_ENDED = 'ADD_MEDIA_ENDED'

export const EDIT_MEDIA_STARTED = 'EDIT_MEDIA_STARTED'
export const EDIT_MEDIA_ENDED = 'EDIT_MEDIA_ENDED'

export const DELETE_MEDIA_STARTED = 'DELETE_MEDIA_STARTED'
export const DELETE_MEDIA_ENDED = 'DELETE_MEDIA_ENDED'

export const HIDE_MEDIA_STARTED = 'HIDE_MEDIA_STARTED'
export const HIDE_MEDIA_ENDED = 'HIDE_MEDIA_ENDED'

export const SHOW_MEDIA_STARTED = 'SHOW_MEDIA_STARTED'
export const SHOW_MEDIA_ENDED = 'SHOW_MEDIA_ENDED'

export const SORT_MEDIA_STARTED = 'SORT_MEDIA_STARTED'
export const SORT_MEDIA_ENDED = 'SORT_MEDIA_ENDED'

export const GET_SORTING_REQUEST = 'GET_SORTING_REQUEST'
export const GET_SORTING_SUCCESS = 'GET_SORTING_SUCCESS'
export const GET_SORTING_ERROR = 'GET_SORTING_ERROR'

export const CLEAR_ALL_MEDIA = 'CLEAR_ALL_MEDIA'

export const CLEAR_MEDIA_LOADING = "CLEAR_MEDIA_LOADING"

const actions = {
    getAllMediaInteractions: (ruuid, uuid) => async (dispatch, getState) => {
        const currentRestaurant = getState().media.selectedRestaurant
        if (ruuid !== currentRestaurant)
            dispatch({
                type: GET_MEDIA_INTERACTIONS_REQUEST,
                payload: ruuid
            })
        await getAllMediaInteractions(ruuid, uuid).then((response) => {
            if (response?.status === 200) {
                dispatch({
                    type: GET_MEDIA_INTERACTIONS_SUCCESS,
                    payload: {
                        mediaInteractions: response?.data?.mediaInteractions
                    }
                })
            } else {
                handleError(dispatch)(response, GET_MEDIA_INTERACTIONS_ERROR, 'GET MEDIA INTERACTIONS ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error(response?.data?.message || 'Get media interactions error')
                // if (response?.status === 401) {
                //     dispatch({ type: LOGOUT_USER })
                // }
                // if (response.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: response?.data?.permissions
                //     })
                // }
                // dispatch({
                //     type: GET_MEDIA_INTERACTIONS_ERROR,
                //     payload: {
                //         message: parseError(response?.data?.error) || response?.data?.message || 'Get media interactions error',
                //     },
                // })
            }
        })
    },
    getAllMedia: (ruuid, page, limit) => async (dispatch, getState) => {
        const currentRestaurant = getState().media.selectedRestaurant
        if (ruuid !== currentRestaurant)
            dispatch({
                type: GET_MEDIA_REQUEST,
                payload: ruuid
            })
        await getAllMedia(ruuid, page, limit).then((response) => {
            if (response?.status === 200) {
                dispatch({
                    type: GET_MEDIA_SUCCESS,
                    payload: {
                        media: response?.data?.media,
                        totalRecords: response?.data?.totalRecords,
                        totalPages: response?.data?.totalPages,
                        currentPage: response?.data?.currentPage
                    }
                })
            }
            else {
                handleError(dispatch)(response, GET_MEDIA_ERROR, 'GET MEDIA ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error(response?.data?.message || 'Get media error')
                // if (response?.status === 401)
                //     dispatch({
                //         type: LOGOUT_USER
                //     })
                // if (response.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: response?.data?.permissions
                //     })
                // }
                // dispatch({
                //     type: GET_MEDIA_ERROR,
                //     payload: {
                //         message: parseError(response?.data?.error) || response?.data?.message || 'Get media error'
                //     }
                // })
            }
        })
    },
    addMedia: (ruuid, data, page, limit) => async (dispatch) => {
        dispatch({
            type: ADD_MEDIA_STARTED
        })
        await addMedia(ruuid, data).then(async (response) => {
            if (response?.status === 200) {
                toast.success(response?.data?.message)
                await getAllMedia(ruuid, page, limit).then((response) => {
                    if (response?.status === 200) {
                        dispatch({
                            type: GET_MEDIA_SUCCESS,
                            payload: {
                                media: response?.data?.media,
                                totalRecords: response?.data?.totalRecords,
                                totalPages: response?.data?.totalPages,
                                currentPage: response?.data?.currentPage
                            }
                        })
                    }
                    else {
                        handleError(dispatch)(response, GET_MEDIA_ERROR, 'GET MEDIA ERROR')
                        // if (response.status !== 401 && response.status !== 403)
                        //     toast.error(response?.data?.message || 'Get media error')
                        // if (response?.status === 401)
                        //     dispatch({
                        //         type: LOGOUT_USER
                        //     })
                        // if (response.status === 403)
                        //     dispatch({
                        //         type: SET_PERMISSIONS,
                        //         payload: response?.data?.permissions
                        //     })
                        // dispatch({
                        //     type: GET_MEDIA_ERROR,
                        //     payload: {
                        //         message: parseError(response?.data?.error) || response?.data?.message || 'Get media error'
                        //     }
                        // })
                    }
                })
            } else {
                handleError(dispatch)(response, null, 'ADD MEDIA ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error((response?.data?.message || 'Add media error'))
                // if (response?.status === 401)
                //     dispatch({
                //         type: LOGOUT_USER
                //     })
                // if (response.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: response?.data?.permissions
                //     })
                // }
            }
        })
        dispatch({
            type: ADD_MEDIA_ENDED
        })
    },
    editMedia: (ruuid, uuid, data, page, limit) => async (dispatch) => {
        dispatch({
            type: EDIT_MEDIA_STARTED
        })
        if (data.uuid) delete data.uuid
        if (data.media.path) {
            const formData = new FormData()
            formData.append('media', data.media)
            await updateMedia(ruuid, uuid, formData).then((response) => {
                if (response.status !== 200)
                    if (response.status !== 401 && response.status !== 403)
                        toast.error(response?.data?.message || 'Update Media Error')
                if (response.status === 401)
                    dispatch({
                        type: LOGOUT_USER
                    })
                if (response.status === 403) {
                    window.location = '/forbidden'
                    dispatch({
                        type: SET_PERMISSIONS,
                        payload: response?.data?.permissions
                    })
                }
            })
        }
        delete data.media
        await editMedia(ruuid, uuid, data).then(async (response) => {
            if (response?.status === 200) {
                toast.success(response?.data?.message)
                await getAllMedia(ruuid, page, limit).then((response) => {
                    if (response?.status === 200) {
                        dispatch({
                            type: GET_MEDIA_SUCCESS,
                            payload: {
                                media: response?.data?.media,
                                totalRecords: response?.data?.totalRecords,
                                totalPages: response?.data?.totalPages,
                                currentPage: response?.data?.currentPage
                            }
                        })
                    }
                    else {
                        handleError(dispatch)(response, GET_MEDIA_ERROR, 'GET MEDIA ERROR')
                        // if (response.status !== 401 && response.status !== 403)
                        //     toast.error(response?.data?.message || 'Get media error')
                        // if (response?.status === 401)
                        //     dispatch({
                        //         type: LOGOUT_USER
                        //     })
                        // if (response.status === 403)
                        //     dispatch({
                        //         type: SET_PERMISSIONS,
                        //         payload: response?.data?.permissions
                        //     })
                        // dispatch({
                        //     type: GET_MEDIA_ERROR,
                        //     payload: {
                        //         message: parseError(response?.data?.error) || response?.data?.message || 'Get media error'
                        //     }
                        // })
                    }
                })
            } else {
                handleError(dispatch)(response, null, 'EDIT MEDIA ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error((response?.data?.message || 'Edit media error'))
                // if (response?.status === 401)
                //     dispatch({
                //         type: LOGOUT_USER
                //     })
                // if (response.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: response?.data?.permissions
                //     })
                // }
            }
        })
        dispatch({
            type: EDIT_MEDIA_ENDED
        })
    },
    deleteMedia: (ruuid, uuid, page, limit) => async (dispatch) => {
        dispatch({
            type: DELETE_MEDIA_STARTED
        })
        await deleteMedia(ruuid, uuid).then(async (response) => {
            if (response?.status === 200) {
                toast.success(response?.data?.message)
                await getAllMedia(ruuid, page, limit).then((response) => {
                    if (response?.status === 200) {
                        dispatch({
                            type: GET_MEDIA_SUCCESS,
                            payload: {
                                media: response?.data?.media,
                                totalRecords: response?.data?.totalRecords,
                                totalPages: response?.data?.totalPages,
                                currentPage: response?.data?.currentPage
                            }
                        })
                    }
                    else {
                        handleError(dispatch)(response, GET_MEDIA_ERROR, 'GET MEDIA ERROR')
                        // if (response.status !== 401 && response.status !== 403)
                        //     toast.error(response?.data?.message || 'Get media error')
                        // if (response?.status === 401)
                        //     dispatch({
                        //         type: LOGOUT_USER
                        //     })
                        // if (response.status === 403)
                        //     dispatch({
                        //         type: SET_PERMISSIONS,
                        //         payload: response?.data?.permissions
                        //     })
                        // dispatch({
                        //     type: GET_MEDIA_ERROR,
                        //     payload: {
                        //         message: parseError(response?.data?.error) || response?.data?.message || 'Get media error'
                        //     }
                        // })
                    }
                })
            } else {
                handleError(dispatch)(response, null, 'DELETE MEDIA ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error((response?.data?.message || 'Delete media error'))
                // if (response?.status === 401)
                //     dispatch({
                //         type: LOGOUT_USER
                //     })
                // if (response.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: response?.data?.permissions
                //     })
                // }
            }
        })
        dispatch({
            type: DELETE_MEDIA_ENDED
        })
    },
    hideMedia: (ruuid, uuid, page, limit) => async (dispatch) => {
        dispatch({
            type: HIDE_MEDIA_STARTED
        })
        await hideMedia(ruuid, uuid).then(async (response) => {
            if (response?.status === 200) {
                toast.success(response?.data?.message)
                await getAllMedia(ruuid, page, limit).then((response) => {
                    if (response?.status === 200) {
                        dispatch({
                            type: GET_MEDIA_SUCCESS,
                            payload: {
                                media: response?.data?.media,
                                totalRecords: response?.data?.totalRecords,
                                totalPages: response?.data?.totalPages,
                                currentPage: response?.data?.currentPage
                            }
                        })
                    }
                    else {
                        handleError(dispatch)(response, GET_MEDIA_ERROR, 'GET MEDIA ERROR')
                        // if (response.status !== 401 && response.status !== 403)
                        //     toast.error(response?.data?.message || 'Get media error')
                        // if (response?.status === 401)
                        //     dispatch({
                        //         type: LOGOUT_USER
                        //     })
                        // if (response.status === 403)
                        //     dispatch({
                        //         type: SET_PERMISSIONS,
                        //         payload: response?.data?.permissions
                        //     })
                        // dispatch({
                        //     type: GET_MEDIA_ERROR,
                        //     payload: {
                        //         message: parseError(response?.data?.error) || response?.data?.message || 'Get media error'
                        //     }
                        // })
                    }
                })
            } else {
                handleError(dispatch)(response, null, 'HIDE MEDIA ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error(response?.data?.message || 'Hide media error')
                // if (response?.status === 401)
                //     dispatch({
                //         type: LOGOUT_USER
                //     })
                // if (response.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: response?.data?.permissions
                //     })
                // }
            }
            dispatch({
                type: HIDE_MEDIA_ENDED
            })
        })
    },
    showMedia: (ruuid, uuid, page, limit) => async (dispatch) => {
        dispatch({
            type: SHOW_MEDIA_STARTED
        })
        await showMedia(ruuid, uuid).then(async (response) => {
            if (response?.status === 200) {
                toast.success(response?.data?.message)
                await getAllMedia(ruuid, page, limit).then((response) => {
                    if (response?.status === 200) {
                        dispatch({
                            type: GET_MEDIA_SUCCESS,
                            payload: {
                                media: response?.data?.media,
                                totalRecords: response?.data?.totalRecords,
                                totalPages: response?.data?.totalPages,
                                currentPage: response?.data?.currentPage
                            }
                        })
                    }
                    else {
                        handleError(dispatch)(response, GET_MEDIA_ERROR, 'GET MEDIA ERROR')
                        // if (response.status !== 401 && response.status !== 403)
                        //     toast.error(response?.data?.message || 'Get media error')
                        // if (response?.status === 401)
                        //     dispatch({
                        //         type: LOGOUT_USER
                        //     })
                        // if (response.status === 403)
                        //     dispatch({
                        //         type: SET_PERMISSIONS,
                        //         payload: response?.data?.permissions
                        //     })
                        // dispatch({
                        //     type: GET_MEDIA_ERROR,
                        //     payload: {
                        //         message: parseError(response?.data?.error) || response?.data?.message || 'Get media error'
                        //     }
                        // })
                    }
                })
            } else {
                handleError(dispatch)(response, null, 'SHOW MEDIA ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error(response?.data?.message || 'Show media error')
                // if (response?.status === 401)
                //     dispatch({
                //         type: LOGOUT_USER
                //     })
                // if (response.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: response?.data?.permissions
                //     })
                // }
            }
            dispatch({
                type: SHOW_MEDIA_ENDED
            })
        })
    },
    sortMedia: (ruuid, data, page, limit) => async (dispatch) => {
        dispatch({
            type: SORT_MEDIA_STARTED,
        })
        await sortMedia(ruuid, data).then(async (response) => {
            if (response?.status === 200) {
                toast.success(response?.data?.message)
                await getAllMedia(ruuid, page, limit).then((response) => {
                    if (response?.status === 200) {
                        dispatch({
                            type: GET_MEDIA_SUCCESS,
                            payload: {
                                media: response?.data?.media,
                                totalRecords: response?.data?.totalRecords,
                                totalPages: response?.data?.totalPages,
                                currentPage: response?.data?.currentPage
                            }
                        })
                    }
                    else {
                        if (response.status !== 401 && response.status !== 403)
                            toast.error(response?.data?.message || 'Get media error')
                        if (response?.status === 401)
                            dispatch({
                                type: LOGOUT_USER
                            })
                        if (response.status === 403)
                            dispatch({
                                type: SET_PERMISSIONS,
                                payload: response?.data?.permissions
                            })
                        dispatch({
                            type: GET_MEDIA_ERROR,
                            payload: {
                                message: parseError(response?.data?.error) || response?.data?.message || 'Get media error'
                            }
                        })
                    }
                })
            }
            else {
                handleError(dispatch)(response, null, 'SORT MEDIA ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error(response?.data?.message || "Sort media error")
                // if (response.status === 401)
                //     dispatch({
                //         type: LOGOUT_USER
                //     })
                // if (response.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: response?.data?.permissions
                //     })
                // }
            }
            dispatch({
                type: SORT_MEDIA_ENDED
            })
        })
    },
    getSorting: (ruuid, limit) => async (dispatch, getState) => {
        const currentRestaurant = getState().media.selectedRestaurant
        if (ruuid !== currentRestaurant)
            dispatch({
                type: GET_SORTING_REQUEST,
                payload: ruuid
            })
        await getAllMedia(ruuid, 1, limit).then((response) => {
            if (response.status === 200) {
                dispatch({
                    type: GET_SORTING_SUCCESS,
                    payload: {
                        media: response.data.media
                    }
                })
            }
            else {
                handleError(dispatch)(response, GET_SORTING_ERROR, 'GET SORTING ERROR')
                // dispatch({
                //     type: GET_SORTING_ERROR
                // })
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error(response?.data?.message || 'Get sorting error')
                // if (response.status === 401)
                //     dispatch({
                //         type: LOGOUT_USER
                //     })
                // if (response.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: response?.data?.permissions
                //     })
                // }
            }
        })
    },
    clearMedia: () => (dispatch) => {
        dispatch({
            type: CLEAR_ALL_MEDIA
        })
    }
}
export default actions