import {
    SET_SELECTED_CUSTOMER,
    DELETE_CHAT_STARTED,
    DELETE_CHAT_ENDED,
    GET_USER_CHATS_REQUEST,
    GET_USER_CHATS_SUCCESS,
    GET_USER_CHATS_ERROR,
    GET_CHAT_MESSAGES_REQUEST,
    GET_CHAT_MESSAGES_SUCCESS,
    GET_CHAT_MESSAGES_ERROR,
    ADD_UPDATE_CHAT_STARTED,
    ADD_UPDATE_CHAT_ENDED,
    UPDATE_MESSAGE_STARTED,
    UPDATE_MESSAGE_ENDED,
    DELETE_MESSAGE_STARTED,
    DELETE_MESSAGE_ENDED,
    RESET_CHAT_MESSAGES,
    CLEAR_MESSAGES_LOADING,
    GET_MESSAGES_RESTUARNTS_BRANCHES_REQUEST,
    GET_MESSAGES_RESTUARNTS_BRANCHES_SUCCESS,
    GET_MESSAGES_RESTUARNTS_BRANCHES_ERROR
} from '../actions/MessagesActions'

const initialState = {
    getRestaurantsLoading: false,
    getUserChatsLoading: false,
    deleteChatLoading: false,
    addUpdateLoading: false,
    getChatMessagesLoading: false,
    updateMessageLoading: false,
    deleteMessageLoading: false,
    selectedCustomer: null,
    chats: [],
    chatMessages: null,
    restaurants: []
}

function messages(state = initialState, action) {
    switch (action.type) {
        case GET_MESSAGES_RESTUARNTS_BRANCHES_ERROR:
            return Object.assign({}, state, {
                getRestaurantsLoading: false
            })
        case GET_MESSAGES_RESTUARNTS_BRANCHES_SUCCESS:
            return Object.assign({}, state, {
                getRestaurantsLoading: false,
                restaurants: action.payload
            })
        case GET_MESSAGES_RESTUARNTS_BRANCHES_REQUEST:
            return Object.assign({}, state, {
                getRestaurantsLoading: true
            })
        case CLEAR_MESSAGES_LOADING:
            return Object.assign({}, state, {
                getUserChatsLoading: false,
                deleteChatLoading: false,
                addUpdateLoading: false,
                getChatMessagesLoading: false,
                updateMessageLoading: false,
                deleteMessageLoading: false,
            })
        case RESET_CHAT_MESSAGES:
            return Object.assign({}, state, {
                chatMessages: null
            })
        case SET_SELECTED_CUSTOMER:
            return Object.assign({}, state, {
                selectedCustomer: action.payload
            })
        case DELETE_CHAT_STARTED:
            return Object.assign({}, state, {
                deleteChatLoading: true,
            })
        case DELETE_CHAT_ENDED:
            return Object.assign({}, state, {
                deleteChatLoading: false,
            })
        case GET_USER_CHATS_REQUEST:
            return Object.assign({}, state, {
                getUserChatsLoading: true,
            })
        case GET_USER_CHATS_SUCCESS:
            return Object.assign({}, state, {
                getUserChatsLoading: false,
                chats: action.payload
            })
        case GET_USER_CHATS_ERROR:
            return Object.assign({}, state, {
                getUserChatsLoading: false,
                chats: []
            })
        case GET_CHAT_MESSAGES_REQUEST:
            return Object.assign({}, state, {
                getChatMessagesLoading: true,
            })
        case GET_CHAT_MESSAGES_SUCCESS:
            return Object.assign({}, state, {
                getChatMessagesLoading: false,
                chatMessages: action.payload
            })
        case GET_CHAT_MESSAGES_ERROR:
            return Object.assign({}, state, {
                getChatMessagesLoading: false,
                chatMessages: []
            })
        case ADD_UPDATE_CHAT_STARTED:
            return Object.assign({}, state, {
                addUpdateLoading: true,
            })
        case ADD_UPDATE_CHAT_ENDED:
            return Object.assign({}, state, {
                addUpdateLoading: false,
            })
        case UPDATE_MESSAGE_STARTED:
            return Object.assign({}, state, {
                updateMessageLoading: true,
            })
        case UPDATE_MESSAGE_ENDED:
            return Object.assign({}, state, {
                updateMessageLoading: false,
            })
        case DELETE_MESSAGE_STARTED:
            return Object.assign({}, state, {
                deleteMessageLoading: true,
            })
        case DELETE_MESSAGE_ENDED:
            return Object.assign({}, state, {
                deleteMessageLoading: false,
            })
        default:
            return state
    }
}
export default messages