import {
  getAppUserAddresses,
  addAppUserAddress,
  editAppUserAddress,
  deleteAppUserAddress,
} from "../../config/config";
import { toast } from "react-toastify";
import { parseError } from "../../utils/parseError";
import { handleError } from "../../utils/utils";

export const GET_APP_USER_ADDRESSES_REQUEST = "GET_APP_USER_ADDRESSES_REQUEST";
export const GET_APP_USER_ADDRESSES_SUCCESS = "GET_APP_USER_ADDRESSES_SUCCESS";
export const GET_APP_USER_ADDRESSES_ERROR = "GET_APP_USER_ADDRESSES_ERROR";

export const ADD_APP_USER_ADDRESS_REQUEST = "ADD_APP_USER_ADDRESS_REQUEST";
export const ADD_APP_USER_ADDRESS_SUCCESS = "ADD_APP_USER_ADDRESS_SUCCESS";
export const ADD_APP_USER_ADDRESS_ERROR = "ADD_APP_USER_ADDRESS_ERROR";

export const EDIT_APP_USER_ADDRESS_REQUEST = "EDIT_APP_USER_ADDRESS_REQUEST";
export const EDIT_APP_USER_ADDRESS_SUCCESS = "EDIT_APP_USER_ADDRESS_SUCCESS";
export const EDIT_APP_USER_ADDRESS_ERROR = "EDIT_APP_USER_ADDRESS_ERROR";


export const DELETE_APP_USER_ADDRESS_REQUEST =
  "DELETE_APP_USER_ADDRESS_REQUEST";
export const DELETE_APP_USER_ADDRESS_SUCCESS =
  "DELETE_APP_USER_ADDRESS_SUCCESS";
export const DELETE_APP_USER_ADDRESS_ERROR = "DELETE_APP_USER_ADDRESS_ERROR";

export const RESET_APP_USER_ADDRESSES = "RESET_APP_USER_ADDRESSES";

export const CLEAR_APP_USER_ADDRESSES_LOADING = "CLEAR_APP_USER_ADDRESSES_LOADING"

const actions = {
  getAppUserAddresses: (uuid) => async (dispatch) => {
    dispatch({
      type: GET_APP_USER_ADDRESSES_REQUEST,
    });
    await getAppUserAddresses(uuid).then((res) => {
      if (res?.status === 200) {
        dispatch({
          type: GET_APP_USER_ADDRESSES_SUCCESS,
          payload: {
            addresses: res?.data?.adresses,
          },
        });
      } else {
        handleError(dispatch)(res, GET_APP_USER_ADDRESSES_ERROR, 'GET ADDRESSES ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || "Get Addresses error");
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER });
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
        // dispatch({
        //   type: GET_APP_USER_ADDRESSES_ERROR,
        //   //   payload: {
        //   //     message:
        //   //       parseError(res?.data?.error) ||
        //   //       res?.data?.message ||
        //   //       "Get User Addresses error",
        //   //   },
        // });
      }
    });
  },

  addAppUserAddress: (uuid, data, onClose) => async (dispatch) => {
    dispatch({
      type: ADD_APP_USER_ADDRESS_REQUEST,
    });
    await addAppUserAddress(uuid, data).then((res) => {
      if (res?.status === 200) {
        toast.success("Address added successfully");
        if (onClose) {
          onClose();
        }
        dispatch({
          type: ADD_APP_USER_ADDRESS_SUCCESS,
          payload: {
            addresses: res?.data?.adresses,
          },
        });
      } else {
        handleError(dispatch)(res, ADD_APP_USER_ADDRESS_ERROR, 'ADD ADDRESS ERROR')
        // dispatch({ type: ADD_APP_USER_ADDRESS_ERROR });
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || "Add Address error");
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER });
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
      }
    });
  },

  editAppUserAddress: (uuid, data, onClose) => async (dispatch) => {
    dispatch({
      type: EDIT_APP_USER_ADDRESS_REQUEST,
    });
    await editAppUserAddress(uuid, data).then((res) => {
      if (res?.status === 200) {
        toast.success("Address updated successfully");
        if (onClose) {
          onClose();
        }
        dispatch({
          type: EDIT_APP_USER_ADDRESS_SUCCESS,
          payload: {
            addresses: res?.data?.adresses,
          },
        });
      } else {
        handleError(dispatch)(res, EDIT_APP_USER_ADDRESS_ERROR, 'EDIT ADDRESS ERROR')
        // dispatch({
        //   type: EDIT_APP_USER_ADDRESS_ERROR,
        // });
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || "Edit Address error");
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER });
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
      }
    });
  },

  deleteAppUserAddress: (uuid, successCb) => async (dispatch) => {
    dispatch({
      type: DELETE_APP_USER_ADDRESS_REQUEST,
    });
    await deleteAppUserAddress(uuid).then((res) => {
      if (res?.status === 200) {
        successCb();
        toast.success("Address deleted successfully");
        dispatch({
          type: DELETE_APP_USER_ADDRESS_SUCCESS,
          payload: {
            addresses: res?.data?.adresses,
            deletedAddress: uuid,
          },
        });
      } else {
        handleError(dispatch)(res, DELETE_APP_USER_ADDRESS_ERROR, 'DELETE ADDRESS ERROR')
        // dispatch({
        //   type: DELETE_APP_USER_ADDRESS_ERROR,
        // });
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || "Delete Address Error");
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER });
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
      }
    });
  },
  resetAppUserAddresses: () => (dispatch) => {
    dispatch({
      type: RESET_APP_USER_ADDRESSES,
    });
  }
};

export default actions;
