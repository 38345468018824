import {
  GET_MODIFIERGROUP_REQUEST,
  GET_MODIFIERGROUP_SUCCESS,
  GET_MODIFIERGROUP_ERROR,
  CLEAR_ALL_MODIFIERGROUP,
  ADD_MODIFIERGROUP_STARTED,
  ADD_MODIFIERGROUP_END,
  EDIT_MODIFIERGROUP_STARTED,
  EDIT_MODIFIERGROUP_END,
  DELETE_MODIFIERGROUP_STARTED,
  DELETE_MODIFIERGROUP_END,
  SET_MODIFIERGROUP_POPUP,
  GET_MODIFIERGROUP_DETAILS_REQUEST,
  GET_MODIFIERGROUP_DETAILS_SUCCESS,
  GET_MODIFIERGROUP_DETAILS_ERROR,
  GET_MODIFIERGROUPS_REQUEST,
  GET_MODIFIERGROUPS_SUCCESS,
  GET_MODIFIERGROUPS_ERROR,
  GET_MODIFIERGROUP_WITH_MODIFIERS_REQUEST,
  GET_MODIFIERGROUP_WITH_MODIFIERS_SUCCESS,
  GET_MODIFIERGROUP_WITH_MODIFIERS_ERROR,
  SET_EDIT_MODIFIER_GROUP_POPUP,
  SET_EDIT_MODIFIER_GROUP_DATA,
  CLEAR_MODIFIERS_GROUPS_LOADING,
  BULK_UPDATE_MODIFIERSGROUPS_STARTED,
  BULK_UPDATE_MODIFIERSGROUPS_ENDED
} from '../actions/ModifiersGroupsActions'


const initialState = {
  bulkLoading: false,
  groupsLoading: false,
  groupWithModifiers: {},
  groupWithModifiersLoading: false,
  modifierGroup: {},
  modifierGroupLoading: false,
  modifiersGroups: [],
  loadingDetails: false,
  modifiersGroupsDetails: [],
  modifierGroupPopup: false,
  addLoading: false,
  editLoading: false,
  deleteLoading: false,
  editModifierGroupPopup: false,
  editData: {},
  selectedRestaurantModGroups: '',
  selectedRestaurantModGroup: '',
  selectedRestaurantModGroupWithMod: '',
  selectedModGroup: ''
}

function modifiersGroups(state = initialState, action) {
  switch (action.type) {
    case BULK_UPDATE_MODIFIERSGROUPS_ENDED:
      return Object.assign({}, state, {
        bulkLoading: false
      })
    case BULK_UPDATE_MODIFIERSGROUPS_STARTED:
      return Object.assign({}, state, {
        bulkLoading: true
      })
    case CLEAR_MODIFIERS_GROUPS_LOADING:
      return Object.assign({}, state, {
        groupsLoading: false,
        groupWithModifiersLoading: false,
        modifierGroupLoading: false,
        loadingDetails: false,
        addLoading: false,
        editLoading: false,
        deleteLoading: false,
      })
    case SET_EDIT_MODIFIER_GROUP_DATA:
      return Object.assign({}, state, {
        editData: action.payload
      })
    case SET_EDIT_MODIFIER_GROUP_POPUP:
      return Object.assign({}, state, {
        editModifierGroupPopup: action.payload
      })
    case GET_MODIFIERGROUP_WITH_MODIFIERS_REQUEST:
      return Object.assign({}, state, {
        groupWithModifiersLoading: true,
        selectedRestaurantModGroupWithMod: action.payload.ruuid,
        selectedModGroup: action.payload.uuid
      })
    case GET_MODIFIERGROUP_WITH_MODIFIERS_SUCCESS:
      return Object.assign({}, state, {
        groupWithModifiersLoading: false,
        groupWithModifiers: action.payload.modifierGroup,
      })

    case GET_MODIFIERGROUP_WITH_MODIFIERS_ERROR:
      return Object.assign({}, state, {
        groupWithModifiersLoading: false,
        groupWithModifiers: {},
      })
    case GET_MODIFIERGROUP_REQUEST:
      return Object.assign({}, state, {
        modifierGroupLoading: true,
      })
    case GET_MODIFIERGROUP_SUCCESS:
      return Object.assign({}, state, {
        modifierGroupLoading: false,
        modifierGroup: action.payload.modifierGroup,
      })
    case GET_MODIFIERGROUP_ERROR:
      return Object.assign({}, state, {
        modifierGroupLoading: false,
        modifierGroup: {},
      })

    case GET_MODIFIERGROUPS_REQUEST:
      return Object.assign({}, state, {
        groupsLoading: true,
        selectedRestaurantModGroups: action.payload
      })
    case GET_MODIFIERGROUPS_SUCCESS:
      return Object.assign({}, state, {
        groupsLoading: false,
        modifiersGroups: action.payload.modifierGroups,
      })
    case GET_MODIFIERGROUPS_ERROR:
      return Object.assign({}, state, {
        groupsLoading: false,
        modifiersGroups: [],
      })

    case GET_MODIFIERGROUP_DETAILS_REQUEST:
      return Object.assign({}, state, {
        loadingDetails: true,
        selectedRestaurantModGroup: action.payload
      })
    case GET_MODIFIERGROUP_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        loadingDetails: false,
        modifiersGroupsDetails: action.payload.modifierGroups,
      })
    case GET_MODIFIERGROUP_DETAILS_ERROR:
      return Object.assign({}, state, {
        loadingDetails: false,
        modifiersGroupsDetails: [],
      })

    case ADD_MODIFIERGROUP_STARTED:
      return Object.assign({}, state, {
        addLoading: true,
      })

    case ADD_MODIFIERGROUP_END:
      return Object.assign({}, state, {
        addLoading: false,
      })

    case EDIT_MODIFIERGROUP_STARTED:
      return Object.assign({}, state, {
        editLoading: true,
      })

    case EDIT_MODIFIERGROUP_END:
      return Object.assign({}, state, {
        editLoading: false,
      })

    case DELETE_MODIFIERGROUP_STARTED:
      return Object.assign({}, state, {
        deleteLoading: true,
      })

    case DELETE_MODIFIERGROUP_END:
      return Object.assign({}, state, {
        deleteLoading: false,
      })

    case SET_MODIFIERGROUP_POPUP:
      return Object.assign({}, state, {
        modifierGroupPopup: action.payload,
      })

    case CLEAR_ALL_MODIFIERGROUP:
      return initialState

    default:
      return state
  }
}

export default modifiersGroups
