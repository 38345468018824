import { getCategories, addCategory, editCategory, deleteCategory, bulkUpdateCategories, importCategories } from '../../config/config'
import { parseError } from '../../utils/parseError'
import { toast } from 'react-toastify'
import { handleError } from '../../utils/utils'

export const GET_CATEGORY_REQUEST = 'GET_CATEGORY_REQUEST'
export const GET_CATEGORY_SUCCESS = 'GET_CATEGORY_SUCCESS'
export const GET_CATEGORY_ERROR = 'GET_CATEGORY_ERROR'

export const ADD_CATEGORY_STARTED = 'ADD_CATEGORY_STARTED'
export const ADD_CATEGORY_END = 'ADD_CATEGORY_END'

export const EDIT_CATEGORY_STARTED = 'EDIT_CATEGORY_STARTED'
export const EDIT_CATEGORY_END = 'EDIT_CATEGORY_END'

export const DELETE_CATEGORY_STARTED = 'DELETE_CATEGORY_STARTED'
export const DELETE_CATEGORY_END = 'DELETE_CATEGORY_END'

export const BULK_UPDATE_CATEGORY_STARTED = 'BULK_UPDATE_CATEGORY_STARTED'
export const BULK_UPDATE_CATEGORY_ENDED = 'BULK_UPDATE_CATEGORY_ENDED'

export const IMPORT_CATEGORIES_STARTED = 'IMPORT_CATEGORIES_STARTED'
export const IMPORT_CATEGORIES_ENDED = 'IMPORT_CATEGORIES_ENDED'

export const SET_IMPORT_DATA = 'SET_IMPORT_DATA'

export const SET_CATEGORY_POPUP = 'SET_CATEGORY_POPUP'
export const CLEAR_ALL_CATEGORY = 'CLEAR_CATEGORY_ALL'

export const CLEAR_CATEGORY_LOADING = "CLEAR_CATEGORY_LOADING"

const actions = {
  setImportData: (data) => async (dispatch) => {
    dispatch({
      type: SET_IMPORT_DATA,
      payload: data
    })
  },
  importCategories: (ruuid, data, setImages) => async (dispatch, getState) => {
    const categories = getState().category.categories
    dispatch({
      type: IMPORT_CATEGORIES_STARTED
    })
    await importCategories(ruuid, data).then((response) => {
      if (response.status === 200) {
        toast.success(response.data.message || 'CATEGORIES IMPORTED')
        dispatch({
          type: SET_IMPORT_DATA,
          payload: response.data
        })
        if (response.data?.added.length > 0) {
          let final = [...categories, ...response.data?.added]
          dispatch({
            type: GET_CATEGORY_SUCCESS,
            payload: {
              categories: final
            }
          })
        }
        setImages([])
      } else handleError(dispatch)(response, null, 'IMPORT CATEGORIES ERROR')
    })
    dispatch({
      type: IMPORT_CATEGORIES_ENDED
    })
  },
  bulkUpdateCategories: (ruuid, data, resetForm, setIsEditing) => async (dispatch) => {
    // dispatch({
    //   type: BULK_UPDATE_CATEGORY_STARTED
    // })
    // await bulkUpdateCategories(ruuid, data).then((response) => {
    //   if (response.status === 200) {
    //     toast.success(response.data.message || 'BULK UPDATE CATEGORIES SUCCESS')
    //     dispatch({
    //       type: GET_CATEGORY_SUCCESS,
    //       payload: { categories: response.data.allProductCategories }
    //     })
    //     setIsEditing(false)
    //   } else {
    //     resetForm()
    //     handleError(dispatch)(response, null, 'BULK UPDATE ERROR')
    //   }
    // })
    // dispatch({
    //   type: BULK_UPDATE_CATEGORY_ENDED
    // })
    dispatch({
      type: BULK_UPDATE_CATEGORY_STARTED
    });
    let success = true
    let message = 'EDIT BULK CATEGORIES SUCCESS'
    try {
      for (const item of data) {
        const response = await bulkUpdateCategories(ruuid, { productCategories: item });

        if (response.status === 200) {
          message = response?.data?.message || 'EDIT BULK CATEGORIES SUCCESS'
          //  toast.success(response?.data?.message || 'EDIT BULK PRODUCTS SUCCESS');
          dispatch({
            type: GET_CATEGORY_SUCCESS,
            payload: {
              categories: response?.data?.allProductCategories
            }
          });
        } else {
          success = false
          handleError(dispatch)(response, null, 'EDIT BULK ERROR');
        }
      }
      if (success) {
        toast.success(message)
        setIsEditing(false);
      } else resetForm()
    } catch (error) {
      console.error(error);
      toast.error('An unexpected error occurred during the bulk edit.');
    } finally {
      dispatch({
        type: BULK_UPDATE_CATEGORY_ENDED
      });
    }
  },
  getCategories: (ruuid) => async (dispatch, getState) => {
    const currentRestaurant = getState().category.selectedRestaurant
    if (ruuid !== currentRestaurant)
      dispatch({
        type: GET_CATEGORY_REQUEST,
        payload: ruuid
      })
    await getCategories(ruuid).then((res) => {
      if (res?.status === 200) {
        dispatch({
          type: GET_CATEGORY_SUCCESS,
          payload: {
            categories: res?.data?.categories,
          },
        })
      } else {
        handleError(dispatch)(res, GET_CATEGORY_ERROR, 'GET CATEGORY ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Get All Categories error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
        // dispatch({
        //   type: GET_CATEGORY_ERROR,
        //   payload: {
        //     message: parseError(res?.data?.error) || res?.data?.message || 'GET CATEGORY error',
        //   },
        // })
      }
    })
  },

  addCategory: (ruuid, data, importData, notAddedIndex) => async (dispatch, getState) => {
    dispatch({
      type: ADD_CATEGORY_STARTED,
    })
    await addCategory(ruuid, data, getState()?.rest?.selectedRest).then((res) => {
      if (res?.status === 200) {
        toast.success(res?.data?.message || 'Successfully Saved')
        dispatch({
          type: GET_CATEGORY_SUCCESS,
          payload: {
            categories: res?.data?.categories,
          },
        })
        dispatch({
          type: SET_CATEGORY_POPUP,
          payload: false,
        })
        if (importData) {
          let final = {
            ...importData,
            notAdded: importData.notAdded.filter((_, index) => index !== notAddedIndex),
            added: [...importData.added,...importData.notAdded.filter((_, index) => index === notAddedIndex).map((notAdded)=>notAdded.category)]
          }
          dispatch({
            type: SET_IMPORT_DATA,
            payload: final
          })
        }
      } else {
        handleError(dispatch)(res, null, 'ADD CATEGORY ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Add Category Error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
      }
    })
    dispatch({
      type: ADD_CATEGORY_END,
    })
  },

  editCategory: (ruuid, uuid, data, setSelectedInfo) => async (dispatch, getState) => {
    dispatch({
      type: EDIT_CATEGORY_STARTED,
    })
    await editCategory(ruuid, uuid, data, getState()?.rest?.selectedRest).then((res) => {
      if (res?.status === 200) {
        toast.success(res?.data?.message || 'Successfully Saved')
        dispatch({
          type: GET_CATEGORY_SUCCESS,
          payload: {
            categories: res?.data?.categories,
          },
        })
        dispatch({
          type: SET_CATEGORY_POPUP,
          payload: false,
        })
        setSelectedInfo({})
      } else {
        handleError(dispatch)(res, null, 'EDIT CATEGORY ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Edit Category Error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
      }
    })
    dispatch({
      type: EDIT_CATEGORY_END,
    })
  },

  deleteCategory: (ruuid, uuid) => async (dispatch, getState) => {
    dispatch({
      type: DELETE_CATEGORY_STARTED,
    })
    await deleteCategory(ruuid, uuid, getState()?.rest?.selectedRest).then((res) => {
      if (res?.status === 200) {
        toast.success(res?.data?.message || 'Successfully Deleted')
        dispatch({
          type: GET_CATEGORY_SUCCESS,
          payload: {
            categories: res?.data?.categories,
          },
        })
        dispatch({
          type: SET_CATEGORY_POPUP,
          payload: false,
        })
      } else {
        handleError(dispatch)(res, null, 'DELETE CATEGORY ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Delete Category Error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
      }
    })
    dispatch({
      type: DELETE_CATEGORY_END,
    })
  },

  setActionModal: (state) => async (dispatch) => {
    dispatch({
      type: SET_CATEGORY_POPUP,
      payload: state,
    })
  },

  // CLEAR ALL Reducer
  clearAll: () => (dispatch) => {
    dispatch({
      type: CLEAR_ALL_CATEGORY,
    })
  },
}

export default actions
