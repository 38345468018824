export const stockRecipesData = [
{
uuid:'pizzPep1',
name:'Pizza Pepperonni',
image:'https://static.vecteezy.com/system/resources/thumbnails/025/076/438/small/pizza-isolated-illustration-ai-generative-png.png',
type:'Pizzas',
price:12,
modalOpen:false,
mainItem:{
  dineIn:[
  {
      uuid: '45jk',
      image:'https://i.pinimg.com/originals/b3/77/8e/b3778ed590bc573ecd266d6b7d92d658.png',
      name: 'Cheese',
      productType:'Mozzarella Cheese',
      yield:5,
      smallUnit:'Gram',
      largeUnit:'Gram',
      FamilyUnit:'Gram',
      smallQty:100,
      smallCost:0.5,
      largeQty:150,
      largeCost:0.75,
      familyQty:150,
      familyCost:0.75,
      type:'Raw Materials',
    },
    {
      uuid: '47896',
      image:'https://www.venezias.com/wordpress/wp-content/themes/zionandzion/assets/production/images/objects/pepperoni-1.png',
      name: 'Pepperoni',
      productType:'Tomato',
      yield:5,
      smallUnit:'Gram',
      largeUnit:'Gram',
      FamilyUnit:'Gram',
      smallQty:100,
      smallCost:0.5,
      largeQty:150,
      largeCost:0.75,
      familyQty:150,
      familyCost:0.75,
      type:'Raw Materials',
    },
    {
      uuid: '56hgj',
      image:'https://png.pngtree.com/png-clipart/20230113/ourmid/pngtree-red-fresh-tomato-with-green-leaf-png-image_6561484.png',
      name: 'Pizza Sauce',
      productType:'Pizza Sauce',
      yield:5,
      smallUnit:'Gram',
      largeUnit:'Gram',
      FamilyUnit:'Gram',
      smallQty:100,
      smallCost:0.5,
      largeQty:150,
      largeCost:0.75,
      familyQty:150,
      familyCost:0.75,
      type:'Production Items',
    },
    {
      uuid: '78njhj',
      image:'https://pngimg.com/d/mushroom_PNG3217.png',
      name: 'Mushroom',
      productType:'Tomato',
      yield:5,
      smallUnit:'Gram',
      largeUnit:'Gram',
      FamilyUnit:'Gram',
      smallQty:100,
      smallCost:0.5,
      largeQty:150,
      largeCost:0.75,
      familyQty:150,
      familyCost:0.75,
      type:'Raw Materials',
    },
    {
      uuid: '456gjhuj',
      image:'https://images.squarespace-cdn.com/content/v1/5887591944024399219df6fb/1635254039284-A88QW0FRHFOSMETVJ3WN/artisan+pizza+dough.png?format=300w',
      name: 'Pizza Dough',
      productType:'Dough',
      yield:5,
      smallUnit:'Gram',
      largeUnit:'Gram',
      FamilyUnit:'Gram',
      smallQty:100,
      smallCost:0.5,
      largeQty:150,
      largeCost:0.75,
      familyQty:150,
      familyCost:0.75,
      type:'Production Items',
    },
    {
      uuid: '56fgh',
      image:'https://pngimg.com/d/olive_oil_PNG9.png',
      name: 'Olive Oil',
      productType:'Oil',
      yield:5,
      smallUnit:'Gram',
      largeUnit:'Gram',
      FamilyUnit:'Gram',
      smallQty:100,
      smallCost:0.5,
      largeQty:150,
      largeCost:0.75,
      familyQty:150,
      familyCost:0.75,
      type:'Consumables',
    },
    {
      uuid: '756hvjgh',
      image:'https://pngimg.com/d/ketchup_PNG48.png',
      name: 'Ketchup',
      productType:'Tomato',
      yield:5,
      smallUnit:'Gram',
      largeUnit:'Gram',
      FamilyUnit:'Gram',
      smallQty:100,
      smallCost:0.5,
      largeQty:150,
      largeCost:0.75,
      familyQty:150,
      familyCost:0.75,
      type:'Raw Materials',
    },

  ],
  pickUp:[
    {
        uuid: '88',
        image:'https://i.pinimg.com/originals/b3/77/8e/b3778ed590bc573ecd266d6b7d92d658.png',
        name: 'Cheese',
        productType:'Mozzarella Cheese',
        yield:5,
        smallUnit:'Gram',
        largeUnit:'Gram',
        FamilyUnit:'Gram',
        smallQty:100,
        smallCost:0.5,
        largeQty:150,
        largeCost:0.75,
        familyQty:150,
        familyCost:0.75,
        type:'Raw Materials',
      },
      {
        uuid: '99',
        image:'https://www.venezias.com/wordpress/wp-content/themes/zionandzion/assets/production/images/objects/pepperoni-1.png',
        name: 'Pepperoni',
        productType:'Tomato',
        yield:5,
        smallUnit:'Gram',
        largeUnit:'Gram',
        FamilyUnit:'Gram',
        smallQty:100,
        smallCost:0.5,
        largeQty:150,
        largeCost:0.75,
        familyQty:150,
        familyCost:0.75,
        type:'Raw Materials',
      },
      {
        uuid: '75',
        image:'https://pngimg.com/d/mushroom_PNG3217.png',
        name: 'Mushroom',
        productType:'Tomato',
        smallUnit:'Gram',
        largeUnit:'Gram',
        FamilyUnit:'Gram',
        yield:5,
        smallQty:100,
        smallCost:0.5,
        largeQty:150,
        largeCost:0.75,
        familyQty:150,
        familyCost:0.75,
        type:'Raw Materials',
      },
      {
        uuid: '85',
        image:'https://images.squarespace-cdn.com/content/v1/5887591944024399219df6fb/1635254039284-A88QW0FRHFOSMETVJ3WN/artisan+pizza+dough.png?format=300w',
        name: 'Pizza Dough',
        productType:'Dough',
      smallUnit:'Gram',
      largeUnit:'Gram',
      FamilyUnit:'Gram',
        yield:5,
        smallQty:100,
        smallCost:0.5,
        largeQty:150,
        largeCost:0.75,
        familyQty:150,
        familyCost:0.75,
        type:'Production Items',
      },
      {
        uuid: '96',
        image:'https://pngimg.com/d/olive_oil_PNG9.png',
        name: 'Olive Oil',
        productType:'Oil',
      smallUnit:'Gram',
      largeUnit:'Gram',
      FamilyUnit:'Gram',
        yield:5,
        smallQty:100,
        smallCost:0.5,
        largeQty:150,
        largeCost:0.75,
        familyQty:150,
        familyCost:0.75,
        type:'Consumables',
      },
      {
        uuid: '74',
        image:'https://pngimg.com/d/ketchup_PNG48.png',
        name: 'Ketchup',
        productType:'Tomato',
      smallUnit:'Gram',
      largeUnit:'Gram',
      FamilyUnit:'Gram',
        yield:5,
        smallQty:100,
        smallCost:0.5,
        largeQty:150,
        largeCost:0.75,
        familyQty:150,
        familyCost:0.75,
        type:'Raw Materials',
      },
    ],
  delivery:[
      {
          uuid: '456',
          image:'https://i.pinimg.com/originals/b3/77/8e/b3778ed590bc573ecd266d6b7d92d658.png',
          name: 'Cheese',
          productType:'Mozzarella Cheese',
        smallUnit:'Gram',
      largeUnit:'Gram',
      FamilyUnit:'Gram',
          yield:5,
          smallQty:100,
          smallCost:0.5,
          largeQty:150,
          largeCost:0.75,
          familyQty:150,
          familyCost:0.75,
          type:'Raw Materials',
        },
        {
          uuid: '789',
          image:'https://www.venezias.com/wordpress/wp-content/themes/zionandzion/assets/production/images/objects/pepperoni-1.png',
          name: 'Pepperoni',
          productType:'Tomato',
        smallUnit:'Gram',
      largeUnit:'Gram',
      FamilyUnit:'Gram',
          yield:5,
          smallQty:100,
          smallCost:0.5,
          largeQty:150,
          largeCost:0.75,
          familyQty:150,
          familyCost:0.75,
          type:'Raw Materials',
        },
        {
          uuid: 'llp[4',
          image:'https://pngimg.com/d/mushroom_PNG3217.png',
          name: 'Mushroom',
          productType:'Tomato',
        smallUnit:'Gram',
      largeUnit:'Gram',
      FamilyUnit:'Gram',
          yield:5,
          smallQty:100,
          smallCost:0.5,
          largeQty:150,
          largeCost:0.75,
          familyQty:150,
          familyCost:0.75,
          type:'Raw Materials',
        },
        {
          uuid: '456m',
          image:'https://images.squarespace-cdn.com/content/v1/5887591944024399219df6fb/1635254039284-A88QW0FRHFOSMETVJ3WN/artisan+pizza+dough.png?format=300w',
          name: 'Pizza Dough',
          productType:'Dough',
        smallUnit:'Gram',
      largeUnit:'Gram',
      FamilyUnit:'Gram',
          yield:5,
          smallQty:100,
          smallCost:0.5,
          largeQty:150,
          largeCost:0.75,
          familyQty:150,
          familyCost:0.75,
          type:'Production Items',
        },
        {
          uuid: '478k',
          image:'https://pngimg.com/d/ketchup_PNG48.png',
          name: 'Ketchup',
          productType:'Tomato',
        smallUnit:'Gram',
      largeUnit:'Gram',
      FamilyUnit:'Gram',
          yield:5,
          smallQty:100,
          smallCost:0.5,
          largeQty:150,
          largeCost:0.75,
          familyQty:150,
          familyCost:0.75,
          type:'Raw Materials',
        },
      ],
    },
    addOns:{
      dineIn:[
        {
            uuid: '145dt',
            image:'https://i.pinimg.com/originals/b3/77/8e/b3778ed590bc573ecd266d6b7d92d658.png',
            name: 'Cheese',
            productType:'Mozzarella Cheese',
          smallUnit:'Gram',
      largeUnit:'Gram',
      FamilyUnit:'Gram',
            yield:5,
            smallQty:100,
            smallCost:0.5,
            largeQty:150,
            largeCost:0.75,
            familyQty:150,
            familyCost:0.75,
            type:'Raw Materials',
          },
          {
            uuid: '789fgh',
            image:'https://www.venezias.com/wordpress/wp-content/themes/zionandzion/assets/production/images/objects/pepperoni-1.png',
            name: 'Pepperoni',
            productType:'Tomato',
          smallUnit:'Gram',
      largeUnit:'Gram',
      FamilyUnit:'Gram',
            yield:5,
            smallQty:100,
            smallCost:0.5,
            largeQty:150,
            largeCost:0.75,
            familyQty:150,
            familyCost:0.75,
            type:'Raw Materials',
          },
          {
            uuid: '458798frtyhty',
            image:'https://pngimg.com/d/mushroom_PNG3217.png',
            name: 'Mushroom',
            productType:'Tomato',
          smallUnit:'Gram',
      largeUnit:'Gram',
      FamilyUnit:'Gram',
            yield:5,
            smallQty:100,
            smallCost:0.5,
            largeQty:150,
            largeCost:0.75,
            familyQty:150,
            familyCost:0.75,
            type:'Raw Materials',
          },  
        ],
        pickUp:[
          {
            uuid: '694fggtu',
            image:'https://i.pinimg.com/originals/b3/77/8e/b3778ed590bc573ecd266d6b7d92d658.png',
            name: 'Cheese',
            productType:'Mozzarella Cheese',
          smallUnit:'Gram',
      largeUnit:'Gram',
      FamilyUnit:'Gram',
            yield:5,
            smallQty:100,
            smallCost:0.5,
            largeQty:150,
            largeCost:0.75,
            familyQty:150,
            familyCost:0.75,
            type:'Raw Materials',
          },
          {
            uuid: '7953ghhyuj',
            image:'https://pngimg.com/d/mushroom_PNG3217.png',
            name: 'Mushroom',
            productType:'Tomato',
          smallUnit:'Gram',
      largeUnit:'Gram',
      FamilyUnit:'Gram',
            yield:5,
            smallQty:100,
            smallCost:0.5,
            largeQty:150,
            largeCost:0.75,
            familyQty:150,
            familyCost:0.75,
            type:'Raw Materials',
          },
        
          ],
        delivery:[
          {
            uuid: '22lxcfgv',
            image:'https://i.pinimg.com/originals/b3/77/8e/b3778ed590bc573ecd266d6b7d92d658.png',
            name: 'Cheese',
            productType:'Mozzarella Cheese',
          smallUnit:'Gram',
      largeUnit:'Gram',
      FamilyUnit:'Gram',
            yield:5,
            smallQty:100,
            smallCost:0.5,
            largeQty:150,
            largeCost:0.75,
            familyQty:150,
            familyCost:0.75,
            type:'Raw Materials',
          },
          {
            uuid: '123456drtfrtyyy',
            image:'https://pngimg.com/d/mushroom_PNG3217.png',
            name: 'Mushroom',
            productType:'Tomato',
          smallUnit:'Gram',
      largeUnit:'Gram',
      FamilyUnit:'Gram',
            yield:5,
            smallQty:100,
            smallCost:0.5,
            largeQty:150,
            largeCost:0.75,
            familyQty:150,
            familyCost:0.75,
            type:'Raw Materials',
          },
            ],
    },
    combos:{
      dineIn:[
        {
          uuid: '789fggh',
          image:'https://i.pinimg.com/originals/81/48/a3/8148a3ecc26b0c02e5e27d46dfa8fdd2.png',
          name: 'Pepsi',
          productType:'Pepsi',
          unit:'Piece',
          yield:'',
          smallQty:1,
          smallCost:0.5,
          largeQty:1,
          largeCost:0.5,
          familyQty:1,
          familyCost:0.5,
          type:'Retail Items',
          selected:false,
        },
        {
          uuid: '78945gfghfgh',
          image:'https://seeklogo.com/images/1/7up-logo-6B71EF4848-seeklogo.com.png',
          name: '7up',
          productType:'7up',
          unit:'Piece',
          yield:'',
          smallQty:1,
          smallCost:0.5,
          largeQty:1,
          largeCost:0.5,
          familyQty:1,
          familyCost:0.5,
          type:'Retail Items',
          selected:false,
        },
        {
          uuid: '7845fgyhyu',
          image:'https://pngfre.com/wp-content/uploads/Fanta-15.png',
          name: 'Fanta',
          productType:'Fanta',
          unit:'Piece',
          yield:'',
          smallQty:1,
          smallCost:0.5,
          largeQty:1,
          largeCost:0.5,
          familyQty:1,
          familyCost:0.5,
          type:'Retail Items',
          selected:false,
        },
        {
            uuid: '789fgyhyui',
            image:'https://static.vecteezy.com/system/resources/previews/023/416/654/original/french-fries-3d-image-free-download-free-png.png',
            name: 'French Fries',
            productType:'Potato',
            unit:'',
            yield:'',
            smallQty:1,
            smallCost:0.5,
            largeQty:1,
            largeCost:0.5,
            familyQty:1,
            familyCost:0.5,
            type:'Sales product',
            selected:false,
          },
          {
            uuid: '78985guhuoi',
            image:'https://burgerking.com.mt/wp-content/uploads/2020/03/CURLY-FRIES.png',
            name: 'Twisted Fries',
            productType:'Potato',
            unit:'',
            yield:'',
            smallQty:1,
            smallCost:0.5,
            largeQty:1,
            largeCost:0.5,
            familyQty:1,
            familyCost:0.5,
            type:'Sales product',
            selected:false,
          },
      ],
      pickUp:[
        {
          uuid: '876578gkjgh',
          image:'https://i.pinimg.com/originals/81/48/a3/8148a3ecc26b0c02e5e27d46dfa8fdd2.png',
          name: 'Pepsi',
          productType:'Pepsi',
          unit:'Piece',
          yield:'',
          smallQty:1,
          smallCost:0.5,
          largeQty:1,
          largeCost:0.5,
          familyQty:1,
          familyCost:0.5,
          type:'Retail Items',
          selected:false,
        },
        {
          uuid: '456cfgjfgh',
          image:'https://seeklogo.com/images/1/7up-logo-6B71EF4848-seeklogo.com.png',
          name: '7up',
          productType:'7up',
          unit:'Piece',
          yield:'',
          smallQty:1,
          smallCost:0.5,
          largeQty:1,
          largeCost:0.5,
          familyQty:1,
          familyCost:0.5,
          type:'Retail Items',
          selected:false,
        },
        {
          uuid: '576386ghujdfgh',
          image:'https://pngfre.com/wp-content/uploads/Fanta-15.png',
          name: 'Fanta',
          productType:'Fanta',
          unit:'Piece',
          yield:'',
          smallQty:1,
          smallCost:0.5,
          largeQty:1,
          largeCost:0.5,
          familyQty:1,
          familyCost:0.5,
          type:'Retail Items',
          selected:false,
        },
        {
            uuid: '45fgifgjk',
            image:'https://static.vecteezy.com/system/resources/previews/023/416/654/original/french-fries-3d-image-free-download-free-png.png',
            name: 'French Fries',
            productType:'Potato',
            unit:'',
            yield:'',
            smallQty:1,
            smallCost:0.5,
            largeQty:1,
            largeCost:0.5,
            familyQty:1,
            familyCost:0.5,
            type:'Sales product',
            selected:false,
          },
          {
            uuid: '75fdgujh',
            image:'https://burgerking.com.mt/wp-content/uploads/2020/03/CURLY-FRIES.png',
            name: 'Twisted Fries',
            productType:'Potato',
            unit:'',
            yield:'',
            smallQty:1,
            smallCost:0.5,
            largeQty:1,
            largeCost:0.5,
            familyQty:1,
            familyCost:0.5,
            type:'Sales product',
            selected:false,
          },
      ],
      delivery:[
        {
          uuid: '76ghhn',
          image:'https://i.pinimg.com/originals/81/48/a3/8148a3ecc26b0c02e5e27d46dfa8fdd2.png',
          name: 'Pepsi',
          productType:'Pepsi',
          unit:'Piece',
          yield:'',
          smallQty:1,
          smallCost:0.5,
          largeQty:1,
          largeCost:0.5,
          familyQty:1,
          familyCost:0.5,
          type:'Retail Items',
          selected:false,
        },
        {
          uuid: '6756fghftu',
          image:'https://seeklogo.com/images/1/7up-logo-6B71EF4848-seeklogo.com.png',
          name: '7up',
          productType:'7up',
          unit:'Piece',
          yield:'',
          smallQty:1,
          smallCost:0.5,
          largeQty:1,
          largeCost:0.5,
          familyQty:1,
          familyCost:0.5,
          type:'Retail Items',
          selected:false,
        },
        {
          uuid: '27567gyutyu',
          image:'https://pngfre.com/wp-content/uploads/Fanta-15.png',
          name: 'Fanta',
          productType:'Fanta',
          unit:'Piece',
          yield:'',
          smallQty:1,
          smallCost:0.5,
          largeQty:1,
          largeCost:0.5,
          familyQty:1,
          familyCost:0.5,
          type:'Retail Items',
          selected:false,
        },
        {
            uuid: '756ftgyhjk',
            image:'https://static.vecteezy.com/system/resources/previews/023/416/654/original/french-fries-3d-image-free-download-free-png.png',
            name: 'French Fries',
            productType:'Potato',
            unit:'',
            yield:'',
            smallQty:1,
            smallCost:0.5,
            largeQty:1,
            largeCost:0.5,
            familyQty:1,
            familyCost:0.5,
            type:'Sales product',
            selected:false,
          },
          {
            uuid: '5686tfguyjfgj',
            image:'https://burgerking.com.mt/wp-content/uploads/2020/03/CURLY-FRIES.png',
            name: 'Twisted Fries',
            productType:'Potato',
            unit:'',
            yield:'',
            smallQty:1,
            smallCost:0.5,
            largeQty:1,
            largeCost:0.5,
            familyQty:1,
            familyCost:0.5,
            type:'Sales product',
            selected:false,
          },
      ],
      },
},
{
uuid:'burg1',
name:'Angus Burger',
image:'https://i.pinimg.com/originals/57/31/66/573166ed060a64778820b54692f91c73.png',
type:'Burgers',
price:10,
modalOpen:false,
mainItem:{
  dineIn:[
    {
        uuid: '7456fgyhhj',
        image:'https://static.vecteezy.com/system/resources/previews/010/179/013/original/fresh-red-meat-file-free-png.png',
        name: 'Ham',
        productType:'Angus Beef',
      smallUnit:'Gram',
      largeUnit:'Gram',
      FamilyUnit:'Gram',
        yield:5,
        smallQty:100,
        smallCost:0.5,
        largeQty:150,
        largeCost:0.75,
        familyQty:150,
        familyCost:0.75,
        type:'Raw Materials',
      },
      {
        uuid: '76768fuytgui',
        image:'https://png.pngtree.com/png-clipart/20230113/ourmid/pngtree-red-fresh-tomato-with-green-leaf-png-image_6561484.png',
        name: 'Tomato',
        productType:'Tomato',
      smallUnit:'Gram',
      largeUnit:'Gram',
      FamilyUnit:'Gram',
        yield:5,
        smallQty:100,
        smallCost:0.5,
        largeQty:150,
        largeCost:0.75,
        familyQty:150,
        familyCost:0.75,
        type:'Raw Materials',
      },
      {
        uuid: '7842hsrft',
        image:'https://static.vecteezy.com/system/resources/previews/024/085/828/original/bbq-sauce-in-bottle-free-png.png',
        name: 'BBQ Sauce',
        productType:'BBQ',
      smallUnit:'Gram',
      largeUnit:'Gram',
      FamilyUnit:'Gram',
        yield:5,
        smallQty:100,
        smallCost:0.5,
        largeQty:150,
        largeCost:0.75,
        familyQty:150,
        familyCost:0.75,
        type:'Production Items',
      },
      {
        uuid: '78542hjdfrtu',
        image:'https://static.vecteezy.com/system/resources/thumbnails/025/076/438/small/pizza-isolated-illustration-ai-generative-png.png',
        name: 'Cheese',
        productType:'Cheese Slice',
      smallUnit:'Gram',
      largeUnit:'Gram',
      FamilyUnit:'Gram',
        yield:5,
        smallQty:100,
        smallCost:0.5,
        largeQty:150,
        largeCost:0.75,
        familyQty:150,
        familyCost:0.75,
        type:'Raw Materials',
      },
      {
        uuid: '755jhbkfgjkf',
        image:'https://www.seastarbakery.com.au/wp-content/uploads/2016/07/burger.png',
        name: 'Burger Bun',
        productType:'Bun',
      smallUnit:'Gram',
      largeUnit:'Gram',
      FamilyUnit:'Gram',
        yield:5,
        smallQty:100,
        smallCost:0.5,
        largeQty:150,
        largeCost:0.75,
        familyQty:150,
        familyCost:0.75,
        type:'Production Items',
      },
      {
        uuid: '7123ghsert',
        image:'https://pngimg.com/d/olive_oil_PNG9.png',
        name: 'Olive Oil',
        productType:'Oil',
      smallUnit:'Gram',
      largeUnit:'Gram',
      FamilyUnit:'Gram',
        yield:5,
        smallQty:100,
        smallCost:0.5,
        largeQty:150,
        largeCost:0.75,
        familyQty:150,
        familyCost:0.75,
        type:'Consumables',
      },
      {
        uuid: '788564gyjhdtyu',
        image:'https://pngimg.com/d/ketchup_PNG48.png',
        name: 'Ketchup',
        productType:'Tomato',
      smallUnit:'Gram',
      largeUnit:'Gram',
      FamilyUnit:'Gram',
        yield:5,
        smallQty:100,
        smallCost:0.5,
        largeQty:150,
        largeCost:0.75,
        familyQty:150,
        familyCost:0.75,
        type:'Raw Materials',
      },
      {
        uuid: '7956414556fgytu',
        image:'https://www.mccormick.com/-/media/project/oneweb/mccormick-us/frenchs/products/00041500000251_a1c1_master.png?rev=9435c3cef1e047fc95a866041365f722&vd=20220421T205701Z&hash=731B4BC0C06519DDEE3FD30810F935F5',
        name: 'Mustard',
        productType:'Mustard',
      smallUnit:'Gram',
      largeUnit:'Gram',
      FamilyUnit:'Gram',
        yield:5,
        smallQty:100,
        smallCost:0.5,
        largeQty:150,
        largeCost:0.75,
        familyQty:150,
        familyCost:0.75,
        type:'Raw Materials',
      },
      {
        uuid: '789452341jhkfyuio',
        image:'https://freepngimg.com/save/153494-fresh-green-lettuce-free-transparent-image-hd/512x488',
        name: 'Lettuce',
        productType:'Lettuce',
      smallUnit:'Gram',
      largeUnit:'Gram',
      FamilyUnit:'Gram',
        yield:5,
        smallQty:100,
        smallCost:0.5,
        largeQty:150,
        largeCost:0.75,
        familyQty:150,
        familyCost:0.75,
        type:'Raw Materials',
      },
      {
        uuid: '744jkl,liol',
        image:'https://clipart-library.com/image_gallery/8099.png',
        name: 'Pickles',
        productType:'Pickles',
      smallUnit:'Gram',
      largeUnit:'Gram',
      FamilyUnit:'Gram',
        yield:5,
        smallQty:100,
        smallCost:0.5,
        largeQty:150,
        largeCost:0.75,
        familyQty:150,
        familyCost:0.75,
        type:'Raw Materials', 
      },
  
    ],
  pickUp:[
      {
          uuid: '78542pkioi',
          image:'https://static.vecteezy.com/system/resources/previews/010/179/013/original/fresh-red-meat-file-free-png.png',
          name: 'Ham',
          productType:'Angus Beef',
        smallUnit:'Gram',
      largeUnit:'Gram',
      FamilyUnit:'Gram',
          yield:5,
          smallQty:100,
          smallCost:0.5,
          largeQty:150,
          largeCost:0.75,
          familyQty:150,
          familyCost:0.75,
          type:'Raw Materials',
        },
        {
          uuid: '745632hhhuui',
          image:'https://png.pngtree.com/png-clipart/20230113/ourmid/pngtree-red-fresh-tomato-with-green-leaf-png-image_6561484.png',
          name: 'Tomato',
          productType:'Tomato',
        smallUnit:'Gram',
      largeUnit:'Gram',
      FamilyUnit:'Gram',
          yield:5,
          smallQty:100,
          smallCost:0.5,
          largeQty:150,
          largeCost:0.75,
          familyQty:150,
          familyCost:0.75,
          type:'Raw Materials',
        },
        {
          uuid: '4745jghjhlo',
          image:'https://static.vecteezy.com/system/resources/thumbnails/025/076/438/small/pizza-isolated-illustration-ai-generative-png.png',
          name: 'Cheese',
          productType:'Cheese Slice',
        smallUnit:'Gram',
      largeUnit:'Gram',
      FamilyUnit:'Gram',
          yield:5,
          smallQty:100,
          smallCost:0.5,
          largeQty:150,
          largeCost:0.75,
          familyQty:150,
          familyCost:0.75,
          type:'Raw Materials',
        },
        {
          uuid: '5555uyuyu',
          image:'https://www.seastarbakery.com.au/wp-content/uploads/2016/07/burger.png',
          name: 'Burger Bun',
          productType:'Bun',
        smallUnit:'Gram',
      largeUnit:'Gram',
      FamilyUnit:'Gram',
          yield:5,
          smallQty:100,
          smallCost:0.5,
          largeQty:150,
          largeCost:0.75,
          familyQty:150,
          familyCost:0.75,
          type:'Production Items',
        },
        {
          uuid: '6789fgfgujkj',
          image:'https://pngimg.com/d/olive_oil_PNG9.png',
          name: 'Olive Oil',
          productType:'Oil',
        smallUnit:'Gram',
      largeUnit:'Gram',
      FamilyUnit:'Gram',
          yield:5,
          smallQty:100,
          smallCost:0.5,
          largeQty:150,
          largeCost:0.75,
          familyQty:150,
          familyCost:0.75,
          type:'Consumables',
        },
        {
          uuid: '9999jdftgk',
          image:'https://www.mccormick.com/-/media/project/oneweb/mccormick-us/frenchs/products/00041500000251_a1c1_master.png?rev=9435c3cef1e047fc95a866041365f722&vd=20220421T205701Z&hash=731B4BC0C06519DDEE3FD30810F935F5',
          name: 'Mustard',
          productType:'Mustard',
        smallUnit:'Gram',
      largeUnit:'Gram',
      FamilyUnit:'Gram',
          yield:5,
          smallQty:100,
          smallCost:0.5,
          largeQty:150,
          largeCost:0.75,
          familyQty:150,
          familyCost:0.75,
          type:'Raw Materials',
        },
        {
          uuid: '10000lll',
          image:'https://freepngimg.com/save/153494-fresh-green-lettuce-free-transparent-image-hd/512x488',
          name: 'Lettuce',
          productType:'Lettuce',
        smallUnit:'Gram',
      largeUnit:'Gram',
      FamilyUnit:'Gram',
          yield:5,
          smallQty:100,
          smallCost:0.5,
          largeQty:150,
          largeCost:0.75,
          familyQty:150,
          familyCost:0.75,
          type:'Raw Materials',
        },
        {
          uuid: '88080hjjlko',
          image:'https://clipart-library.com/image_gallery/8099.png',
          name: 'Pickles',
          productType:'Pickles',
        smallUnit:'Gram',
      largeUnit:'Gram',
      FamilyUnit:'Gram',
          yield:5,
          smallQty:100,
          smallCost:0.5,
          largeQty:150,
          largeCost:0.75,
          familyQty:150,
          familyCost:0.75,
          type:'Raw Materials', 
        },
    
      ],
  delivery:[
        {
            uuid: '1112233hhhuyu',
            image:'https://static.vecteezy.com/system/resources/previews/010/179/013/original/fresh-red-meat-file-free-png.png',
            name: 'Ham',
            productType:'Angus Beef',
          smallUnit:'Gram',
      largeUnit:'Gram',
      FamilyUnit:'Gram',
            yield:5,
            smallQty:100,
            smallCost:0.5,
            largeQty:150,
            largeCost:0.75,
            familyQty:150,
            familyCost:0.75,
            type:'Raw Materials',
          },
          {
            uuid: '2456gyyhu',
            image:'https://png.pngtree.com/png-clipart/20230113/ourmid/pngtree-red-fresh-tomato-with-green-leaf-png-image_6561484.png',
            name: 'Tomato',
            productType:'Tomato',
          smallUnit:'Gram',
      largeUnit:'Gram',
      FamilyUnit:'Gram',
            yield:5,
            smallQty:100,
            smallCost:0.5,
            largeQty:150,
            largeCost:0.75,
            familyQty:150,
            familyCost:0.75,
            type:'Raw Materials',
          },
          {
            uuid: '333565yuuy8i',
            image:'https://static.vecteezy.com/system/resources/previews/024/085/828/original/bbq-sauce-in-bottle-free-png.png',
            name: 'BBQ Sauce',
            productType:'BBQ',
          smallUnit:'Gram',
      largeUnit:'Gram',
      FamilyUnit:'Gram',
            yield:5,
            smallQty:100,
            smallCost:0.5,
            largeQty:150,
            largeCost:0.75,
            familyQty:150,
            familyCost:0.75,
            type:'Production Items',
          },
          {
            uuid: '44455686jkkuio',
            image:'https://static.vecteezy.com/system/resources/thumbnails/025/076/438/small/pizza-isolated-illustration-ai-generative-png.png',
            name: 'Cheese',
            productType:'Cheese Slice',
          smallUnit:'Gram',
      largeUnit:'Gram',
      FamilyUnit:'Gram',
            yield:5,
            smallQty:100,
            smallCost:0.5,
            largeQty:150,
            largeCost:0.75,
            familyQty:150,
            familyCost:0.75,
            type:'Raw Materials',
          },
          {
            uuid: '545755uituyi',
            image:'https://www.seastarbakery.com.au/wp-content/uploads/2016/07/burger.png',
            name: 'Burger Bun',
            productType:'Bun',
          smallUnit:'Gram',
      largeUnit:'Gram',
      FamilyUnit:'Gram',
            yield:5,
            smallQty:100,
            smallCost:0.5,
            largeQty:150,
            largeCost:0.75,
            familyQty:150,
            familyCost:0.75,
            type:'Production Items',
          },
          {
            uuid: '7786786gjighjik',
            image:'https://pngimg.com/d/ketchup_PNG48.png',
            name: 'Ketchup',
            productType:'Tomato',
          smallUnit:'Gram',
      largeUnit:'Gram',
      FamilyUnit:'Gram',
            yield:5,
            smallQty:100,
            smallCost:0.5,
            largeQty:150,
            largeCost:0.75,
            familyQty:150,
            familyCost:0.75,
            type:'Raw Materials',
          },
          {
            uuid: '99989hjklhkl',
            image:'https://www.mccormick.com/-/media/project/oneweb/mccormick-us/frenchs/products/00041500000251_a1c1_master.png?rev=9435c3cef1e047fc95a866041365f722&vd=20220421T205701Z&hash=731B4BC0C06519DDEE3FD30810F935F5',
            name: 'Mustard',
            productType:'Mustard',
          smallUnit:'Gram',
      largeUnit:'Gram',
      FamilyUnit:'Gram',
            yield:5,
            smallQty:100,
            smallCost:0.5,
            largeQty:150,
            largeCost:0.75,
            familyQty:150,
            familyCost:0.75,
            type:'Raw Materials',
          },
          {
            uuid: '88565kpiop',
            image:'https://clipart-library.com/image_gallery/8099.png',
            name: 'Pickles',
            productType:'Pickles',
          smallUnit:'Gram',
      largeUnit:'Gram',
      FamilyUnit:'Gram',
            yield:5,
            smallQty:100,
            smallCost:0.5,
            largeQty:150,
            largeCost:0.75,
            familyQty:150,
            familyCost:0.75,
            type:'Raw Materials', 
          },
        ],
      },
addOns:{
  dineIn:[
    {
      uuid: 'hjhk65856',
      image:'https://png.pngtree.com/png-clipart/20230113/ourmid/pngtree-red-fresh-tomato-with-green-leaf-png-image_6561484.png',
      name: 'Tomato',
      productType:'Tomato',
      unit:'Gram',
      yield:5,
      smallQty:100,
      smallCost:0.5,
      largeQty:150,
      largeCost:0.75,
      familyQty:150,
      familyCost:0.75,
      type:'Raw Materials',
    },
    {
      uuid: '856jkljk',
      image:'https://static.vecteezy.com/system/resources/previews/024/085/828/original/bbq-sauce-in-bottle-free-png.png',
      name: 'BBQ Sauce',
      productType:'BBQ',
      unit:'Gram',
      yield:5,
      smallQty:100,
      smallCost:0.5,
      largeQty:150,
      largeCost:0.75,
      familyQty:150,
      familyCost:0.75,
      type:'Production Items',
    },
    {
      uuid: '856jkl',
      image:'https://static.vecteezy.com/system/resources/thumbnails/025/076/438/small/pizza-isolated-illustration-ai-generative-png.png',
      name: 'Cheese',
      productType:'Cheese Slice',
      unit:'Gram',
      yield:5,
      smallQty:100,
      smallCost:0.5,
      largeQty:150,
      largeCost:0.75,
      familyQty:150,
      familyCost:0.75,
      type:'Raw Materials',
    },
    {
      uuid: '100246uyhjiko',
      image:'https://freepngimg.com/save/153494-fresh-green-lettuce-free-transparent-image-hd/512x488',
      name: 'Lettuce',
      productType:'Lettuce',
      unit:'Gram',
      yield:5,
      smallQty:100,
      smallCost:0.5,
      largeQty:150,
      largeCost:0.75,
      familyQty:150,
      familyCost:0.75,
      type:'Raw Materials',
    },
    {
      uuid: '456ghjui',
      image:'https://clipart-library.com/image_gallery/8099.png',
      name: 'Pickles',
      productType:'Pickles',
      unit:'Gram',
      yield:5,
      smallQty:100,
      smallCost:0.5,
      largeQty:150,
      largeCost:0.75,
      familyQty:150,
      familyCost:0.75,
      type:'Raw Materials', 
    },
  ],
pickUp:[
  {
    uuid: '222hjklsd',
    image:'https://png.pngtree.com/png-clipart/20230113/ourmid/pngtree-red-fresh-tomato-with-green-leaf-png-image_6561484.png',
    name: 'Tomato',
    productType:'Tomato',
    unit:'Gram',
    yield:5,
    smallQty:100,
    smallCost:0.5,
    largeQty:150,
    largeCost:0.75,
    familyQty:150,
    familyCost:0.75,
    type:'Raw Materials',
  },
  {
    uuid: '42569ghyu',
    image:'https://static.vecteezy.com/system/resources/thumbnails/025/076/438/small/pizza-isolated-illustration-ai-generative-png.png',
    name: 'Cheese',
    productType:'Cheese Slice',
    unit:'Gram',
    yield:5,
    smallQty:100,
    smallCost:0.5,
    largeQty:150,
    largeCost:0.75,
    familyQty:150,
    familyCost:0.75,
    type:'Raw Materials',
  },
  {
    uuid: '10789uidfgh',
    image:'https://freepngimg.com/save/153494-fresh-green-lettuce-free-transparent-image-hd/512x488',
    name: 'Lettuce',
    productType:'Lettuce',
    unit:'Gram',
    yield:5,
    smallQty:100,
    smallCost:0.5,
    largeQty:150,
    largeCost:0.75,
    familyQty:150,
    familyCost:0.75,
    type:'Raw Materials',
  },
    ],
delivery:[
  {
    uuid: '389fgjhd',
    image:'https://static.vecteezy.com/system/resources/previews/024/085/828/original/bbq-sauce-in-bottle-free-png.png',
    name: 'BBQ Sauce',
    productType:'BBQ',
    unit:'Gram',
    yield:5,
    smallQty:100,
    smallCost:0.5,
    largeQty:150,
    largeCost:0.75,
    familyQty:150,
    familyCost:0.75,
    type:'Production Items',
  },
  {
    uuid: '44466rftguyt',
    image:'https://static.vecteezy.com/system/resources/thumbnails/025/076/438/small/pizza-isolated-illustration-ai-generative-png.png',
    name: 'Cheese',
    productType:'Cheese Slice',
    unit:'Gram',
    yield:5,
    smallQty:100,
    smallCost:0.5,
    largeQty:150,
    largeCost:0.75,
    familyQty:150,
    familyCost:0.75,
    type:'Raw Materials',
  },
      ],
},
combos:{
dineIn:[
  {
    uuid: '14568fgujh',
    image:'https://i.pinimg.com/originals/81/48/a3/8148a3ecc26b0c02e5e27d46dfa8fdd2.png',
    name: 'Pepsi',
    productType:'Pepsi',
    unit:'Piece',
    yield:'',
    smallQty:1,
    smallCost:0.5,
    largeQty:1,
    largeCost:0.5,
    familyQty:1,
    familyCost:0.5,
    type:'Retail Items',
    selected:false,
  },
  {
    uuid: '222356duyfd',
    image:'https://seeklogo.com/images/1/7up-logo-6B71EF4848-seeklogo.com.png',
    name: '7up',
    productType:'7up',
    unit:'Piece',
    yield:'',
    smallQty:1,
    smallCost:0.5,
    largeQty:1,
    largeCost:0.5,
    familyQty:1,
    familyCost:0.5,
    type:'Retail Items',
    selected:false,
  },
  {
    uuid: '3784fghj',
    image:'https://pngfre.com/wp-content/uploads/Fanta-15.png',
    name: 'Fanta',
    productType:'Fanta',
    unit:'Piece',
    yield:'',
    smallQty:1,
    smallCost:0.5,
    largeQty:1,
    largeCost:0.5,
    familyQty:1,
    familyCost:0.5,
    type:'Retail Items',
    selected:false,
  },
  {
      uuid: '47889fguyhjf',
      image:'https://static.vecteezy.com/system/resources/previews/023/416/654/original/french-fries-3d-image-free-download-free-png.png',
      name: 'French Fries',
      productType:'Potato',
      unit:'',
      yield:'',
      smallQty:1,
      smallCost:0.5,
      largeQty:1,
      largeCost:0.5,
      familyQty:1,
      familyCost:0.5,
      type:'Sales product',
      selected:false,
    },
    {
      uuid: '5555fdggsde',
      image:'https://burgerking.com.mt/wp-content/uploads/2020/03/CURLY-FRIES.png',
      name: 'Twisted Fries',
      productType:'Potato',
      unit:'',
      yield:'',
      smallQty:1,
      smallCost:0.5,
      largeQty:1,
      largeCost:0.5,
      familyQty:1,
      familyCost:0.5,
      type:'Sales product',
      selected:false,
    },
],
pickUp:[
  {
    uuid: '111pudfghx',
    image:'https://i.pinimg.com/originals/81/48/a3/8148a3ecc26b0c02e5e27d46dfa8fdd2.png',
    name: 'Pepsi',
    productType:'Pepsi',
    unit:'Piece',
    yield:'',
    smallQty:1,
    smallCost:0.5,
    largeQty:1,
    largeCost:0.5,
    familyQty:1,
    familyCost:0.5,
    type:'Retail Items',
    selected:false,
  },
  {
    uuid: '2124558fghd',
    image:'https://seeklogo.com/images/1/7up-logo-6B71EF4848-seeklogo.com.png',
    name: '7up',
    productType:'7up',
    unit:'Piece',
    yield:'',
    smallQty:1,
    smallCost:0.5,
    largeQty:1,
    largeCost:0.5,
    familyQty:1,
    familyCost:0.5,
    type:'Retail Items',
    selected:false,
  },
  {
    uuid: '3457fgtjhdfgjh',
    image:'https://pngfre.com/wp-content/uploads/Fanta-15.png',
    name: 'Fanta',
    productType:'Fanta',
    unit:'Piece',
    yield:'',
    smallQty:1,
    smallCost:0.5,
    largeQty:1,
    largeCost:0.5,
    familyQty:1,
    familyCost:0.5,
    type:'Retail Items',
    selected:false,
  },
  {
      uuid: '47855vgjhfg',
      image:'https://static.vecteezy.com/system/resources/previews/023/416/654/original/french-fries-3d-image-free-download-free-png.png',
      name: 'French Fries',
      productType:'Potato',
      unit:'',
      yield:'',
      smallQty:1,
      smallCost:0.5,
      largeQty:1,
      largeCost:0.5,
      familyQty:1,
      familyCost:0.5,
      type:'Sales product',
      selected:false,
    },
    {
      uuid: '54585ghdfgh',
      image:'https://burgerking.com.mt/wp-content/uploads/2020/03/CURLY-FRIES.png',
      name: 'Twisted Fries',
      productType:'Potato',
      unit:'',
      yield:'',
      smallQty:1,
      smallCost:0.5,
      largeQty:1,
      largeCost:0.5,
      familyQty:1,
      familyCost:0.5,
      type:'Sales product',
      selected:false,
    },
],
delivery:[
  {
    uuid: '14fghdcf',
    image:'https://i.pinimg.com/originals/81/48/a3/8148a3ecc26b0c02e5e27d46dfa8fdd2.png',
    name: 'Pepsi',
    productType:'Pepsi',
    unit:'Piece',
    yield:'',
    smallQty:1,
    smallCost:0.5,
    largeQty:1,
    largeCost:0.5,
    familyQty:1,
    familyCost:0.5,
    type:'Retail Items',
    selected:false,
  },
  {
    uuid: '245789ghjg',
    image:'https://seeklogo.com/images/1/7up-logo-6B71EF4848-seeklogo.com.png',
    name: '7up',
    productType:'7up',
    unit:'Piece',
    yield:'',
    smallQty:1,
    smallCost:0.5,
    largeQty:1,
    largeCost:0.5,
    familyQty:1,
    familyCost:0.5,
    type:'Retail Items',
    selected:false,
  },
  {
    uuid: '333ghjfg',
    image:'https://pngfre.com/wp-content/uploads/Fanta-15.png',
    name: 'Fanta',
    productType:'Fanta',
    unit:'Piece',
    yield:'',
    smallQty:1,
    smallCost:0.5,
    largeQty:1,
    largeCost:0.5,
    familyQty:1,
    familyCost:0.5,
    type:'Retail Items',
    selected:false,
  },
  {
      uuid: '44788hjfyjh',
      image:'https://static.vecteezy.com/system/resources/previews/023/416/654/original/french-fries-3d-image-free-download-free-png.png',
      name: 'French Fries',
      productType:'Potato',
      unit:'',
      yield:'',
      smallQty:1,
      smallCost:0.5,
      largeQty:1,
      largeCost:0.5,
      familyQty:1,
      familyCost:0.5,
      type:'Sales product',
      selected:false,
    },
    {
      uuid: '5',
      image:'https://burgerking.com.mt/wp-content/uploads/2020/03/CURLY-FRIES.png',
      name: 'Twisted Fries',
      productType:'Potato',
      unit:'',
      yield:'',
      smallQty:1,
      smallCost:0.5,
      largeQty:1,
      largeCost:0.5,
      familyQty:1,
      familyCost:0.5,
      type:'Sales product',
      selected:false,
    },
],
},
},
];