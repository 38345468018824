import React, { useEffect, useState, useRef } from 'react'
import { Dialog, Box, FormControlLabel, Button } from '@mui/material'
import { connect } from 'react-redux'
import { Formik, Form } from 'formik'
import { getTranslation, getField } from '../../../utils/utils'
import LoadingButton from '../../../components/loadingButton/LoadingButton'
import actions from '../../../redux/actions/QRActions'
import Loading from '../../../components/loaders/Loading'
import InputField from '../../../components/inputField/InputField'
import { getPagePermission } from '../../../utils/utils'
import CancelModal from '../../../components/ProductAddEdit/CancelModal'

const QRCondif = ({
    isOpen,
    onClose,
    translation,
    language,
    settings,
    settingsLoading,
    actionLoading,
    getSettings,
    addEditSettings,
    selectedBranch,
    selectedRestaurant,
    permissions
}) => {
    const formRef = useRef()
    const [cancelModalState, setCancelModalState] = useState(false)
    const perm = getPagePermission(permissions, 'tableOrderingConfig')
    useEffect(() => {
        if (selectedBranch !== '0' && perm)
            getSettings(selectedRestaurant, selectedBranch)
    }, [selectedBranch])
    const dictionary = getTranslation('qr-settings', translation)
    const initialState = {
        serviceCharge: settings?.settings.serviceChargeSettings.serviceCharge || ''
    }
    const handleSubmit = (finalValues) => {
        let final = {
            serviceChargeSettings: {
                isServiceChargeEnabled: true,
                serviceCharge: finalValues.serviceCharge
            }
        }
        addEditSettings(selectedRestaurant, selectedBranch, final, onClose())
    }
    return (
        <Dialog open={isOpen} onClose={() => {
            if (!formRef?.current.dirty) onClose()
            else setCancelModalState(true)
        }}>
            {!settingsLoading ? //TO ADD LOADING STATE HER
                <Box padding={2}>
                    <Box sx={{ fontWeight: 700, color: 'pageTitle' }}>
                        {getField(dictionary, 'title', language)}
                    </Box>
                    <Formik enableReinitialize initialValues={initialState} onSubmit={handleSubmit} innerRef={formRef}>
                        {(formik) =>
                            <Box>
                                <Box>
                                    {getField(dictionary, 'service-charge', language)}
                                </Box>
                                <InputField
                                    value={formik.values.serviceCharge}
                                    type='number'
                                    placeholder={getField(dictionary, 'service-charge', language)}
                                    onChange={(e) => formik.setFieldValue('serviceCharge', e.target.value)}
                                />
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 1 }}>
                                    <Button
                                        variant='contained'
                                        color='secondary'
                                        sx={{ margin: '0px 2px 0px 2px' }}
                                        onClick={() => {
                                            if (!formRef?.current.dirty) onClose()
                                            else setCancelModalState(true)
                                        }}
                                    >
                                        {getField(dictionary, 'cancel', language)}
                                    </Button>
                                    <LoadingButton
                                        variant='contained'
                                        sx={{ margin: '0px 2px 0px 2px' }}
                                        isSubmitting={actionLoading}
                                        title={getField(dictionary, 'save', language)}
                                        onClick={formik.handleSubmit}

                                    />
                                </Box>
                                <CancelModal
                                    isOpen={cancelModalState}
                                    onCancel={() => setCancelModalState(false)}
                                    action={() => {
                                        onClose()
                                        setCancelModalState(false)
                                    }}
                                />
                            </Box>
                        }
                    </Formik>
                </Box>
                :
                <Loading />
            }
        </Dialog>
    )
}
const mapStateToProps = (state) => ({
    translation: state.translation.translation,
    language: state.translation.language,
    settings: state.qr.settings,
    settingsLoading: state.qr.settingsLoading,
    actionLoading: state.qr.addSettingsLoading,
    selectedBranch: state.branch.selectedBranchtableOrdering,
    selectedRestaurant: state.rest.selectedRest,
    permissions: state.auth.permissions
})
const mapDispatchToProps = (dispatch) => ({
    getSettings: (ruuid, bruuid) => dispatch(actions.getTableOrderingSettings(ruuid, bruuid)),
    addEditSettings: (ruuid, bruuid, data, close) => dispatch(actions.addEditTableOrderingSettings(ruuid, bruuid, data, close))
})
export default connect(mapStateToProps, mapDispatchToProps)(QRCondif)