import React from 'react'
import { styled, AppBar } from '@mui/material'

const NavBar = styled(AppBar)(({ theme }) => ({
    ...(theme.palette.mode === "light" && {
        backgroundColor: theme.palette.background.default,
        boxShadow: "none",
        color: theme.palette.primary.contrastText,
    }),
    ...(theme.palette.mode === "dark" && {
        backgroundColor: theme.palette.background.paper,
        borderBottom: `1px solid ${theme.palette.divider}`,
        boxShadow: "none",
    }),
    zIndex: 9,
    height: 50,
}));

const NavBarRoot = ({ children }) => {

    return (
        <NavBar>
            {children}
        </NavBar>
    )
}
export default NavBarRoot