import {
  getModifierGroup,
  getModifiersGroups,
  getModifiersGroupsWithModifiers,
  getModifierGroupWithModifiers,
  addModifierGroup,
  editModifierGroup,
  deleteModifierGroup,
  bulkUpdateModifierGroups,
} from '../../config/config'
import { toast } from 'react-toastify'
import { parseError } from '../../utils/parseError'
import { LOGOUT_USER, SET_PERMISSIONS } from './AuthActions'
import { handleError } from '../../utils/utils'

export const GET_MODIFIERGROUPS_REQUEST = 'GET_MODIFIERGROUPS_REQUEST'
export const GET_MODIFIERGROUPS_SUCCESS = 'GET_MODIFIERGROUPS_SUCCESS'
export const GET_MODIFIERGROUPS_ERROR = 'GET_MODIFIERGROUPS_ERROR'

export const GET_MODIFIERGROUP_REQUEST = 'GET_MODIFIERGROUP_REQUEST'
export const GET_MODIFIERGROUP_SUCCESS = 'GET_MODIFIERGROUP_SUCCESS'
export const GET_MODIFIERGROUP_ERROR = 'GET_MODIFIERGROUP_ERROR'

export const GET_MODIFIERGROUP_WITH_MODIFIERS_REQUEST = 'GET_MODIFIERGROUP_WITH_MODIFIERS_REQUEST'
export const GET_MODIFIERGROUP_WITH_MODIFIERS_SUCCESS = 'GET_MODIFIERGROUP_WITH_MODIFIERS_SUCCESS'
export const GET_MODIFIERGROUP_WITH_MODIFIERS_ERROR = 'GET_MODIFIERGROUP_WITH_MODIFIERS_ERROR'

export const GET_MODIFIERGROUP_DETAILS_REQUEST = 'GET_MODIFIERGROUP_DETAILS_REQUEST'
export const GET_MODIFIERGROUP_DETAILS_SUCCESS = 'GET_MODIFIERGROUP_DETAILS_SUCCESS'
export const GET_MODIFIERGROUP_DETAILS_ERROR = 'GET_MODIFIERGROUP_DETAILS_ERROR'

export const ADD_MODIFIERGROUP_STARTED = 'ADD_MODIFIERGROUP_STARTED'
export const ADD_MODIFIERGROUP_END = 'ADD_MODIFIERGROUP_END'

export const EDIT_MODIFIERGROUP_STARTED = 'EDIT_MODIFIERGROUP_STARTED'
export const EDIT_MODIFIERGROUP_END = 'EDIT_MODIFIERGROUP_END'

export const DELETE_MODIFIERGROUP_STARTED = 'DELETE_MODIFIERGROUP_STARTED'
export const DELETE_MODIFIERGROUP_END = 'DELETE_MODIFIERGROUP_END'

export const BULK_UPDATE_MODIFIERSGROUPS_STARTED = 'BULK_UPDATE_MODIFIERSGROUPS_STARTED'
export const BULK_UPDATE_MODIFIERSGROUPS_ENDED = 'BULK_UPDATE_MODIFIERSGROUPS_ENDED'

export const SET_MODIFIERGROUP_POPUP = 'SET_MODIFIERGROUP_POPUP'
export const SET_EDIT_MODIFIER_GROUP_POPUP = 'SET_EDIT_MODIFIER_GROUP_POPUP'
export const SET_EDIT_MODIFIER_GROUP_DATA = 'SET_EDIT_MODIFIER_GROUP_DATA'
export const CLEAR_ALL_MODIFIERGROUP = 'CLEAR_ALL_MODIFIERGROUP'

export const CLEAR_MODIFIERS_GROUPS_LOADING = 'CLEAR_MODIFIERS_GROUPS_LOADING'

const actions = {
  bulkUpdateModifierGroups: (ruuid, data, resetForm, setIsEditing) => async (dispatch) => {
    // dispatch({
    //   type: BULK_UPDATE_MODIFIERSGROUPS_STARTED
    // })
    // await bulkUpdateModifierGroups(ruuid, data).then((response) => {
    //   if (response.status === 200) {
    //     toast.success(response.data.message || 'BULK UPDATE MODIFIERS GROUPS SUCCESS')
    //     dispatch({
    //       type: GET_MODIFIERGROUPS_SUCCESS,
    //       payload: {
    //         modifierGroups: response?.data?.groups,
    //       },
    //     })
    //     setIsEditing(false)
    //   } else {
    //     resetForm()
    //     handleError(dispatch)(response, null, 'BULK UPDATE MODIFIERS GROUPS ERROR')

    //   }
    // })
    // dispatch({
    //   type: BULK_UPDATE_MODIFIERSGROUPS_ENDED
    // })
    dispatch({
      type: BULK_UPDATE_MODIFIERSGROUPS_STARTED
    });
    let success = true
    let message = 'EDIT BULK MODIFIERSGROUPS SUCCESS'
    try {
      for (const item of data) {
        const response = await bulkUpdateModifierGroups(ruuid, { modifierGroups: item });

        if (response.status === 200) {
          message = response?.data?.message || 'EDIT BULK MODIFIERSGROUPS SUCCESS'
          //  toast.success(response?.data?.message || 'EDIT BULK PRODUCTS SUCCESS');
          dispatch({
            type: GET_MODIFIERGROUPS_SUCCESS,
            payload: {
              modifierGroups: response?.data?.groups
            }
          });
        } else {
          success = false
          handleError(dispatch)(response, null, 'EDIT BULK ERROR');
        }
      }
      if (success) {
        toast.success(message)
        setIsEditing(false);
      } else resetForm()
    } catch (error) {
      console.error(error);
      toast.error('An unexpected error occurred during the bulk edit.');
    } finally {
      dispatch({
        type: BULK_UPDATE_MODIFIERSGROUPS_ENDED
      });
    }
  },
  setEditData: (data) => (dispatch) => {
    dispatch({
      type: SET_EDIT_MODIFIER_GROUP_DATA,
      payload: data
    })
  },
  setEditModifierGroupModalState: (state) => (dispatch) => {
    dispatch({
      type: SET_EDIT_MODIFIER_GROUP_POPUP,
      payload: state
    })
  },
  getModifierGroupWithModifiers: (ruuid, uuid) => async (dispatch, getState) => {
    const currentRestaurant = getState().modGrp.selectedRestaurantModGroupWithMod
    const currentGroup = getState().modGrp.selectedModGroup
    if (ruuid !== currentRestaurant || uuid !== currentGroup)
      dispatch({
        type: GET_MODIFIERGROUP_WITH_MODIFIERS_REQUEST,
        payload: {
          ruuid: ruuid,
          uuid: uuid
        }
      })
    await getModifierGroupWithModifiers(ruuid, uuid).then((res) => {
      if (res.status === 200) {
        dispatch({
          type: GET_MODIFIERGROUP_WITH_MODIFIERS_SUCCESS,
          payload: {
            modifierGroup: res.data.modifierGroup,
          },
        })
      } else {
        handleError(dispatch)(res, GET_MODIFIERGROUP_WITH_MODIFIERS_ERROR, 'GET MODIFIER GROUP ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Get Modifier Group error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
        // dispatch({
        //   type: GET_MODIFIERGROUP_WITH_MODIFIERS_ERROR,
        // })
      }
    })
  },
  getModifiersGroup: (ruuid, uuid) => async (dispatch, getState) => {
    const currentRestaurant = getState().modGrp.selectedRestaurantModGroup
    if (ruuid !== currentRestaurant)
      dispatch({
        type: GET_MODIFIERGROUP_REQUEST,
        payload: ruuid
      })
    await getModifierGroup(ruuid, uuid).then((res) => {
      if (res?.status === 200) {
        dispatch({
          type: GET_MODIFIERGROUP_SUCCESS,
          payload: {
            modifierGroup: res?.data?.modifierGroup,
          },
        })
      } else {
        handleError(dispatch)(res, GET_MODIFIERGROUP_ERROR, 'GET MODIFIER GROUP ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Get Modifiers Group error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
        // dispatch({
        //   type: GET_MODIFIERGROUP_ERROR,
        //   payload: {
        //     message: parseError(res?.data?.error) || res?.data?.message || 'GET MODIFIER GROUP error',
        //   },
        // })
      }
    })
  },

  getModifiersGroups: (ruuid) => async (dispatch, getState) => {
    const currentRestaurant = getState().modGrp.selectedRestaurantModGroups
    if (ruuid !== currentRestaurant)
      dispatch({
        type: GET_MODIFIERGROUPS_REQUEST,
        payload: ruuid
      })
    await getModifiersGroups(ruuid).then((res) => {
      if (res?.status === 200) {
        dispatch({
          type: GET_MODIFIERGROUPS_SUCCESS,
          payload: {
            modifierGroups: res?.data?.modifierGroups,
          },
        })
      } else {
        handleError(dispatch)(res, GET_MODIFIERGROUPS_ERROR, 'DELETE LOYALTY GIFT ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Get All Modifiers Groups error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
        // dispatch({
        //   type: GET_MODIFIERGROUPS_ERROR,
        //   payload: {
        //     message: parseError(res?.data?.error) || res?.data?.message || 'GET MODIFIERS GROUPS error',
        //   },
        // })
      }
    })
  },

  getModifiersGroupsWithModifiers: (ruuid) => async (dispatch) => {
    dispatch({
      type: GET_MODIFIERGROUP_DETAILS_REQUEST,
    })
    await getModifiersGroupsWithModifiers(ruuid).then((res) => {
      if (res?.status === 200) {
        dispatch({
          type: GET_MODIFIERGROUP_DETAILS_SUCCESS,
          payload: {
            modifierGroups: res?.data?.modifierGroups,
          },
        })
      } else {
        handleError(dispatch)(res, GET_MODIFIERGROUP_DETAILS_ERROR, 'GET MODIFIER GROUP DETAILS ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Get All Modifiers Groups error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
        // dispatch({
        //   type: GET_MODIFIERGROUP_DETAILS_ERROR,
        //   payload: {
        //     message: parseError(res?.data?.error) || res?.data?.message || 'GET MODIFIERS GROUPS error',
        //   },
        // })
      }
    })
  },

  addModifierGroup: (ruuid, data, handleClick) => async (dispatch) => {
    dispatch({
      type: ADD_MODIFIERGROUP_STARTED,
    })
    await addModifierGroup(ruuid, data).then((res) => {
      if (res?.status === 200) {
        toast.success(res?.data?.message || 'Successfully Saved')
        dispatch({
          type: GET_MODIFIERGROUPS_SUCCESS,
          payload: {
            modifierGroups: res?.data?.modifierGroups,
          },
        })

        if (handleClick) {
          handleClick(res?.data?.newGroup, res?.data?.modifierGroups)
          // history.push(`/modifier-groups/${res.data.newGroup}/edit`)
        }
        dispatch({
          type: SET_MODIFIERGROUP_POPUP,
          payload: false,
        })
      } else {
        handleError(dispatch)(res, null, 'ADD MODIFIER GROUP ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Add Modifier Group error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
      }
    })
    dispatch({
      type: ADD_MODIFIERGROUP_END,
    })
  },

  editModifierGroup: (ruuid, uuid, data) => async (dispatch) => {
    dispatch({
      type: EDIT_MODIFIERGROUP_STARTED,
    })
    await editModifierGroup(ruuid, uuid, data).then((res) => {
      if (res?.status === 200) {
        toast.success(res?.data?.message || 'Successfully Saved')
        dispatch({
          type: GET_MODIFIERGROUPS_SUCCESS,
          payload: {
            modifierGroups: res?.data?.modifierGroups,
          },
        })
        dispatch({
          type: SET_MODIFIERGROUP_POPUP,
          payload: false,
        })
      } else {
        handleError(dispatch)(res, null, 'DEDIT MODIFIER GROUP ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Edit Modifiers Groups error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
      }
    })
    dispatch({
      type: EDIT_MODIFIERGROUP_END,
    })
  },

  deleteModifierGroup: (ruuid, uuid) => async (dispatch) => {
    dispatch({
      type: DELETE_MODIFIERGROUP_STARTED,
    })
    await deleteModifierGroup(ruuid, uuid).then((res) => {
      if (res?.status === 200) {
        toast.success(res?.data?.message || 'Successfully Deleted')
        dispatch({
          type: GET_MODIFIERGROUPS_SUCCESS,
          payload: {
            modifierGroups: res?.data?.modifierGroups,
          },
        })
        dispatch({
          type: SET_MODIFIERGROUP_POPUP,
          payload: false,
        })
      } else {
        handleError(dispatch)(res, null, 'DELETE MODIFIER GROUP ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Delete Modifier Group Error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
      }
    })
    dispatch({
      type: DELETE_MODIFIERGROUP_END,
    })
  },

  setActionModal: (state) => async (dispatch) => {
    dispatch({
      type: SET_MODIFIERGROUP_POPUP,
      payload: state,
    })
  },

  // CLEAR ALL Reducer
  clearAll: () => (dispatch) => {
    dispatch({
      type: CLEAR_ALL_MODIFIERGROUP,
    })
  },
}

export default actions
