import { Dispatch, Action } from 'redux';

export const ADD_PRODUCT = 'ADD_LINKED_PRODUCT';
export const ADD_UOM = 'ADD_UOM';
export const ADD_LINKED_PRODUCT = 'ADD_LINKED_PRODUCTS';
export const TOGGLE_FAVORITE = 'TOGGLE_FAVORITE';
export const addLinkedProduct = (newLinkedProduct) => {
    return {
      type: ADD_PRODUCT,
      payload: newLinkedProduct,
    };
  };

  export const addUOM = (newUOM) => {
    return {
      type: ADD_UOM,
      payload: newUOM,
    };
  };

  export const addLinkedBrand = (newLdProd) => {
    return {
      type: ADD_LINKED_PRODUCT,
      payload: newLdProd,
    };
  };

  export const toggleFavorite = (brandId) => {
    return {
      type: TOGGLE_FAVORITE,
      payload: brandId,
    };
  };