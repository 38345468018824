import {
    deleteChat,
    getUserChats,
    addUpdateChat,
    getChatMessages,
    updateMessage,
    deleteMessage,
    getUserGlobalChats,
    getMessagesRestaurantsAndBranches
} from '../../config/config'
import { handleError } from '../../utils/utils'

export const SET_SELECTED_CUSTOMER = 'SET_SELECTED_CUSTOMER'

export const DELETE_CHAT_STARTED = 'DELETE_CHAT_STARTED'
export const DELETE_CHAT_ENDED = 'DELETE_CHAT_ENDED'

export const GET_USER_CHATS_REQUEST = 'GET_USER_CHATS_REQUEST'
export const GET_USER_CHATS_SUCCESS = 'GET_USER_CHATS_SUCCESS'
export const GET_USER_CHATS_ERROR = 'GET_USER_CHATS_ERROR'

export const GET_MESSAGES_RESTUARNTS_BRANCHES_REQUEST = 'GET_MESSAGES_RESTUARNTS_BRANCHES_REQUEST'
export const GET_MESSAGES_RESTUARNTS_BRANCHES_SUCCESS = 'GET_MESSAGES_RESTUARNTS_BRANCHES_SUCCESS'
export const GET_MESSAGES_RESTUARNTS_BRANCHES_ERROR = 'GET_MESSAGES_RESTUARNTS_BRANCHES_ERROR'

export const GET_CHAT_MESSAGES_REQUEST = 'GET_CHAT_MESSAGES_REQUEST'
export const GET_CHAT_MESSAGES_SUCCESS = 'GET_CHAT_MESSAGES_SUCCESS'
export const GET_CHAT_MESSAGES_ERROR = 'GET_CHAT_MESSAGES_ERROR'

export const ADD_UPDATE_CHAT_STARTED = 'ADD_UPDATE_CHAT_STARTED'
export const ADD_UPDATE_CHAT_ENDED = 'ADD_UPDATE_CHAT_ENDED'

export const UPDATE_MESSAGE_STARTED = 'UPDATE_MESSAGE_STARTED'
export const UPDATE_MESSAGE_ENDED = 'UPDATE_MESSAGE_ENDED'

export const RESET_CHAT_MESSAGES = 'RESET_CHAT_MESSAGES'

export const DELETE_MESSAGE_STARTED = 'DELETE_MESSAGE_STARTED'
export const DELETE_MESSAGE_ENDED = 'DELETE_MESSAGE_ENDED'

export const CLEAR_MESSAGES_LOADING = 'CLEAR_MESSAGES_LOADING'

const actions = {
    getMessagesRestaurantsAndBranches: () => async (dispatch) => {
        dispatch({
            type: GET_MESSAGES_RESTUARNTS_BRANCHES_REQUEST
        })
        await getMessagesRestaurantsAndBranches().then((response) => {
            if (response.status === 200) {
                dispatch({
                    type: GET_MESSAGES_RESTUARNTS_BRANCHES_SUCCESS,
                    payload: response.data.restaurants
                })
            } else handleError(dispatch)(response, GET_CHAT_MESSAGES_ERROR, 'GET MESSAGES RESTAURANTS BRANCHES ERROR')
        })
    },
    getUserGlobalChats: (isSilent) => async (dispatch) => {
        if (!isSilent)
            dispatch({
                type: GET_USER_CHATS_REQUEST
            })
        await getUserGlobalChats().then(async (response) => {
            if (response.status === 200) {
                dispatch({
                    type: GET_USER_CHATS_SUCCESS,
                    payload: response.data.chatDetails || []
                })
            } else {
                handleError(dispatch)(response, GET_USER_CHATS_ERROR, 'GET USER CHATS ERROR')
            }
        })
    },
    resetChatMessages: () => (dispatch) => {
        dispatch({
            type: RESET_CHAT_MESSAGES
        })
    },
    setSelectedCustomer: (data) => (dispatch) => {
        dispatch({
            type: SET_SELECTED_CUSTOMER,
            payload: data
        })
    },
    deleteChat: (ruuid, bruuid, chatId, reset) => async (dispatch) => {
        dispatch({
            type: DELETE_CHAT_STARTED
        })
        await deleteChat(ruuid, bruuid, chatId).then(async (response) => {
            if (response.status === 200) {
                reset()
                // toast.success(response.data.message || 'DELETE CHAT SUCCESS')
                // await getUserChats(ruuid, bruuid).then((response) => {
                //     if (response.status === 200) {
                //         dispatch({
                //             type: GET_USER_CHATS_SUCCESS,
                //             payload: response.data.result
                //         })
                //         reset()
                //     } else {
                //         handleError(dispatch)(response, GET_USER_CHATS_ERROR, 'GET USER CHATS ERROR')
                //     }
                // })
                await getUserGlobalChats().then(async (response) => {
                    if (response.status === 200) {
                        dispatch({
                            type: GET_USER_CHATS_SUCCESS,
                            payload: response.data.chatDetails || []
                        })
                    } else {
                        handleError(dispatch)(response, GET_USER_CHATS_ERROR, 'GET USER CHATS ERROR')
                    }
                })
            } else
                handleError(dispatch)(response, null, 'DELETE CHAT ERROR')
        })
        dispatch({
            type: DELETE_CHAT_ENDED
        })
    },
    getUserChats: (ruuid, bruuid) => async (dispatch) => {
        dispatch({
            type: GET_USER_CHATS_REQUEST
        })
        await getUserChats(ruuid, bruuid).then(async (response) => {
            if (response.status === 200) {
                dispatch({
                    type: GET_USER_CHATS_SUCCESS,
                    payload: response.data.chatDetails
                })
            } else {
                handleError(dispatch)(response, GET_USER_CHATS_ERROR, 'GET USER CHATS ERROR')
            }
        })
    },
    addUpdateChat: (ruuid, bruuid, uuid, data, chatId, pageLimit, page, reset, isNew, history) => async (dispatch) => {
        dispatch({
            type: ADD_UPDATE_CHAT_STARTED
        })
        await addUpdateChat(ruuid, bruuid, uuid, data).then(async (response) => {
            if (response.status === 200) {
                reset('')
                // toast.success(response.data.message || 'ADD UPDATE CHAT SUCCESS')
                if (isNew) {
                    let id = null
                    await getUserGlobalChats().then(async (response) => {
                        if (response.status === 200) {
                            dispatch({
                                type: GET_USER_CHATS_SUCCESS,
                                payload: response.data.chatDetails
                            })
                            id = response.data.chatDetails[response.data.chatDetails.length - 1].chat._id
                            if (id)
                                history.push(`/messaging/${ruuid}/${bruuid}/${id}`)
                        } else {
                            handleError(dispatch)(response, GET_USER_CHATS_ERROR, 'GET USER CHATS ERROR')
                        }
                    })
                    // await getUserChats(ruuid, bruuid).then(async (response) => {
                    //     if (response.status === 200) {
                    //         dispatch({
                    //             type: GET_USER_CHATS_SUCCESS,
                    //             payload: response.data.chatDetails
                    //         })
                    //         id = response.data.chatDetails[response.data.chatDetails.length - 1].chat._id
                    //         if (id)
                    //             setId(id)
                    //     } else {
                    //         handleError(dispatch)(response, GET_USER_CHATS_ERROR, 'GET USER CHATS ERROR')
                    //     }
                    // })
                } else
                    await getChatMessages(ruuid, bruuid, chatId, pageLimit, page).then((response) => {
                        if (response.status === 200)
                            dispatch({
                                type: GET_CHAT_MESSAGES_SUCCESS,
                                payload: response.data
                            })
                        else handleError(dispatch)(response, GET_CHAT_MESSAGES_ERROR, 'GET CHAT MESSAGES ERROR')
                    })
            } else handleError(dispatch)(response, null, 'ADD UPDATE CHAT ERROR')
        })
        dispatch({
            type: ADD_UPDATE_CHAT_ENDED
        })
    },
    getChatMessages: (ruuid, bruuid, chatId, pageLimit, page, disableLoading) => async (dispatch) => {
        if (!disableLoading)
            dispatch({
                type: GET_CHAT_MESSAGES_REQUEST
            })
        await getChatMessages(ruuid, bruuid, chatId, pageLimit, page).then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_CHAT_MESSAGES_SUCCESS,
                    payload: response.data
                })
            else handleError(dispatch)(response, GET_CHAT_MESSAGES_ERROR, 'GET CHAT MESSAGES ERROR')
        })
    },
    updateMessage: (ruuid, bruuid, chatId, messageId, data, pageLimit, page) => async (dispatch) => {
        dispatch({
            type: UPDATE_MESSAGE_STARTED
        })
        await updateMessage(chatId, messageId, data).then(async (response) => {
            if (response.status === 200) {
                // toast.success(response.data.message || 'UPDATE MESSAGE SUCCESS')
                await getChatMessages(ruuid, bruuid, chatId, pageLimit, page).then((response) => {
                    if (response.status === 200)
                        dispatch({
                            type: GET_CHAT_MESSAGES_SUCCESS,
                            payload: response.data
                        })
                    else handleError(dispatch)(response, GET_CHAT_MESSAGES_ERROR, 'GET CHAT MESSAGES ERROR')
                })
            } else handleError(dispatch)(response, null, 'UPDATE MESSAGE ERROR')
        })
        dispatch({
            type: UPDATE_MESSAGE_ENDED
        })
    },
    deleteMessage: (ruuid, bruuid, chatId, messageId, pageLimit, page) => async (dispatch) => {
        dispatch({
            type: DELETE_MESSAGE_STARTED
        })
        await deleteMessage(chatId, messageId).then(async (response) => {
            if (response.status === 200) {
                // toast.success(response.data.message || 'DELETE MESSAGE SUCCESS')
                await getChatMessages(ruuid, bruuid, chatId, pageLimit, page).then((response) => {
                    if (response.status === 200)
                        dispatch({
                            type: GET_CHAT_MESSAGES_SUCCESS,
                            payload: response.data
                        })
                    else handleError(dispatch)(response, GET_CHAT_MESSAGES_ERROR, 'GET CHAT MESSAGES ERROR')
                })
            } else handleError(dispatch)(response, null, 'DELETE MESSAGE ERROR')
        })
        dispatch({
            type: DELETE_MESSAGE_ENDED
        })
    }
}
export default actions