import {
    SET_DM_SELECTED_PLATFORM
} from '../actions/MarketingActions'

const initialState = {
    dmSelectedPlatform: 'allDirectmessages'
}

function marketing(state = initialState, action) {
    switch (action.type) {
        case SET_DM_SELECTED_PLATFORM:
            return Object.assign({}, state, {
                dmSelectedPlatform: action.payload
            })
        default:
            return state
    }
}
export default marketing