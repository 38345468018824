import {
    GET_URL_LINK_SUCCESS, GET_URL_LINK_REQUEST, GET_URL_LINK_ERROR, ADD_URL_LINK_STARTED, ADD_URL_LINK_ENDED,
    EDIT_URL_LINK_STARTED, EDIT_URL_LINK_ENDED, DELETE_URL_LINK_STARTED, DELETE_URL_LINK_ENDED, CLEAR_URL_LINK_LOADING
} from '../actions/AffiliateUrlLinkActions'

const initialState = {
    getAffiliateUrlloading: false,
    addAffiliateUrlLoading: false,
    affiliateUrl: [],
    selectedRestaurantAffiliateUrl: '',
    editAffiliateLoading: false,
}

function affiliateUrl(state = initialState, action) {
    switch (action.type) {
        case CLEAR_URL_LINK_LOADING:
            return Object.assign({}, state, {
                getAffiliateUrlloading: false,
                addAffiliateUrlLoading: false,
                editAffiliateLoading: false,
            })
        case ADD_URL_LINK_STARTED:
            return Object.assign({}, state, {
                addAffiliateUrlLoading: true
            })
        case ADD_URL_LINK_ENDED:
            return Object.assign({}, state, {
                addAffiliateUrlLoading: false
            })


        case GET_URL_LINK_REQUEST:
            return Object.assign({}, state, {
                getAffiliateUrlloading: true,
                selectedRestaurantAffiliateUrl: action.payload.ruuid,

            })
        case GET_URL_LINK_SUCCESS:
            return Object.assign({}, state, {
                getAffiliateUrlloading: false,
                affiliateUrl: action.payload
            })
        case GET_URL_LINK_ERROR:
            return Object.assign({}, state, {
                getAffiliateUrlloading: false,
                affiliateUrl: null
            })

        case EDIT_URL_LINK_STARTED:
            return Object.assign({}, state, {
                editAffiliateLoading: true
            })
        case EDIT_URL_LINK_ENDED:
            return Object.assign({}, state, {
                editAffiliateLoading: false
            })

        case DELETE_URL_LINK_STARTED:
            return Object.assign({}, state, {
                editAffiliateLoading: true
            })
        case DELETE_URL_LINK_ENDED:
            return Object.assign({}, state, {
                editAffiliateLoading: false
            })
        default:
            return state;
    }
}
export default affiliateUrl