import {
    getAllPromotions,
    getPromotion,
    addPromotion,
    editPromotion,
    deletePromotion
} from '../../config/config'

import { toast } from 'react-toastify'
import { LOGOUT_USER, SET_PERMISSIONS } from './AuthActions'
import { handleError } from '../../utils/utils'

export const GET_ALL_PROMOTIONS_REQUEST = 'GET_ALL_PROMOTIONS_REQUEST'
export const GET_ALL_PROMOTIONS_SUCCESS = 'GET_ALL_PROMOTIONS_SUCCESS'
export const GET_ALL_PROMOTIONS_ERROR = 'GET_ALL_PROMOTIONS_ERROR'

export const GET_PROMOTION_REQUEST = 'GET_PROMOTION_REQUEST'
export const GET_PROMOTION_SUCCESS = 'GET_PROMOTION_SUCCESS'
export const GET_PROMOTION_ERROR = 'GET_PROMOTION_ERROR'

export const ADD_PROMOTION_STARTED = 'ADD_PROMOTION_STARTED'
export const ADD_PROMOTION_ENDED = 'ADD_PROMOTION_ENDED'

export const EDIT_PROMOTION_STARTED = 'EDIT_PROMOTION_STARTED'
export const EDIT_PROMOTION_ENDED = 'EDIT_PROMOTION_ENDED'

export const DELETE_PROMOTION_STARTED = 'DELETE_PROMOTION_STARTED'
export const DELETE_PROMOTION_ENDED = 'DELETE_PROMOTION_ENDED'

export const SET_EDIT_PROMO_CODE = 'SET_EDIT_PROMO_CODE'

export const SET_PROMOCODE_FILTER = 'SET_PROMOCODE_FILTER'

export const CLEAR_ALL_PROMOTIONS = 'CLEAR_ALL_PROMOTIONS'

export const CLEAR_PROMO_LOADING = 'CLEAR_PROMO_LOADING'

const actions = {
    setPromotionsFilter: (data) => (dispatch) => {
        dispatch({
            type: SET_PROMOCODE_FILTER,
            payload: data
        })
    },
    getAllPromotions: (ruuid) => async (dispatch, getState) => {
        const currentRestaurant = getState().promoCodes.selectedRestaurant
        if (ruuid !== currentRestaurant)
            dispatch({
                type: GET_ALL_PROMOTIONS_REQUEST,
                payload: ruuid
            })
        await getAllPromotions(ruuid).then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_ALL_PROMOTIONS_SUCCESS,
                    payload: { promotions: response.data }
                })
            else {
                handleError(dispatch)(response, GET_ALL_PROMOTIONS_ERROR, 'GET ALL PROMOTIONS ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error(response?.data?.message || 'Get All Promotions Error')
                // dispatch({
                //     type: GET_ALL_PROMOTIONS_ERROR
                // })
                // if (response.status === 401)
                //     dispatch({
                //         type: LOGOUT_USER
                //     })
                // if (response.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: response?.data?.permissions
                //     })
                // }
            }
        })
    },
    getPromotion: (ruuid, uuid) => async (dispatch) => {
        dispatch({
            type: GET_PROMOTION_REQUEST
        })
        await getPromotion(ruuid, uuid).then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_PROMOTION_SUCCESS,
                    payload: { promotion: response.data }
                })
            else {
                handleError(dispatch)(response, GET_PROMOTION_ERROR, 'GET PROMOTION ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error(response.data?.message || 'Get Promotion Error')
                // dispatch({
                //     type: GET_PROMOTION_ERROR
                // })
                // if (response.status === 401)
                //     dispatch({
                //         type: LOGOUT_USER
                //     })
                // if (response.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: response?.data?.permissions
                //     })
                // }
            }
        })
    },
    addPromotion: (ruuid, data, history, setFieldValue) => async (dispatch) => {
        dispatch({
            type: ADD_PROMOTION_STARTED
        })
        await addPromotion(ruuid, data).then(async (response) => {
            if (response.status == 200) {
                history.push('/crm/offers/promo-code')
                toast.success(response.data.message || 'Promotion code added')
                await getAllPromotions(ruuid).then((response) => {
                    if (response.status === 200) {
                        dispatch({
                            type: GET_ALL_PROMOTIONS_SUCCESS,
                            payload: { promotions: response.data }
                        })
                        if (setFieldValue)
                            setFieldValue(false)
                    }
                    else {
                        handleError(dispatch)(response, GET_ALL_PROMOTIONS_ERROR, 'GET ALL PROMOTIONS ERROR')
                        // if (response.status !== 401 && response.status !== 403)
                        //     toast.error(response?.data?.message || 'Get All Promotions Error')
                        // dispatch({
                        //     type: GET_ALL_PROMOTIONS_ERROR
                        // })
                        // if (response.status === 401)
                        //     dispatch({
                        //         type: LOGOUT_USER
                        //     })
                        // if (response.status === 403)
                        //     dispatch({
                        //         type: SET_PERMISSIONS,
                        //         payload: response?.data?.permissions
                        //     })
                    }
                })
            }
            else {
                handleError(dispatch)(response, null, 'ADD PROMOTION ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error(response.data?.message || 'Add Promotion Error')
                // if (response.status === 401)
                //     dispatch({
                //         type: LOGOUT_USER
                //     })
                // if (response.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: response?.data?.permissions
                //     })
                // }
            }
        })
        dispatch({
            type: ADD_PROMOTION_ENDED
        })
    },
    editPromotion: (ruuid, uuid, data, history, setEditPromoCode, setFieldValue) => async (dispatch) => {
        delete data.uuid
        dispatch({
            type: EDIT_PROMOTION_STARTED
        })
        await editPromotion(ruuid, uuid, data).then((response) => {
            if (response.status === 200) {
                history.push('/crm/offers/promo-code')
                toast.success(response.data.message || 'Edit Promotion Success')
                dispatch({
                    type: GET_ALL_PROMOTIONS_SUCCESS,
                    payload: { promotions: response.data.promotions }
                })
                setEditPromoCode()
                if (setFieldValue)
                    setFieldValue(false)
            }
            else {
                handleError(dispatch)(response, null, 'EDIT PROMOTION ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error(response.data?.message || 'Edit Promotion Error')
                // if (response.status === 401)
                //     dispatch({
                //         type: LOGOUT_USER
                //     })
                // if (response.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: response?.data?.permissions
                //     })
                // }
            }
        })
        dispatch({
            type: EDIT_PROMOTION_ENDED
        })
    },
    deletePromotion: (ruuid, uuid, close) => async (dispatch) => {
        dispatch({
            type: DELETE_PROMOTION_STARTED
        })
        await deletePromotion(ruuid, uuid).then((response) => {
            if (response.status === 200) {
                toast.success(response.data.message || 'Delete Promotion Success')
                dispatch({
                    type: GET_ALL_PROMOTIONS_SUCCESS,
                    payload: { promotions: response.data.promotions }
                })
                if (close)
                    close()
            }
            else {
                handleError(dispatch)(response, null, 'DELETE PROMOTION ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error(response.data?.message || 'Delete Promotion Error')
                // if (response.status === 401)
                //     dispatch({
                //         type: LOGOUT_USER
                //     })
                // if (response.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: response?.data?.permissions
                //     })
                // }
            }
        })
        dispatch({
            type: DELETE_PROMOTION_ENDED
        })
    },
    setEditPromoCode: (data) => (dispatch) => {
        dispatch({
            type: SET_EDIT_PROMO_CODE,
            payload: data
        })
    },
    clearAllPromotions: () => (dispatch) => {
        dispatch({
            type: CLEAR_ALL_PROMOTIONS
        })
    }
}
export default actions