import {
    getEmailServer,
    addEmailServer,
    testEmail
} from "../../config/config";

import { toast } from "react-toastify";
import { handleError } from '../../utils/utils'

export const GET_EMAIL_SERVER_REQUEST = "GET_EMAIL_SERVER_REQUEST"
export const GET_EMAIL_SERVER_SUCCESS = "GET_EMAIL_SERVER_SUCCESS"
export const GET_EMAIL_SERVER_ERROR = "GET_EMAIL_SERVER_ERROR"

export const ADD_EMAIL_SERVER_STARTED = 'ADD_EMAIL_SERVER_STARTED'
export const ADD_EMAIL_SERVER_ENDED = 'ADD_EMAIL_SERVER_ENDED'

export const TEST_EMAIL_STARTED = 'TEST_EMAIL_STARTED'
export const TEST_EMAIL_ENDED = 'TEST_EMAIL_ENDED'

export const CLEAR_EMAIL = 'CLEAR_EMAIL'
export const CLEAR_EMAIL_LOADING = 'CLEAR_EMAIL_LOADING'


const actions = {
    testEmail: (data, onClose) => async (dispatch) => {
        dispatch({
            type: TEST_EMAIL_STARTED
        })
        await testEmail(data).then((response) => {
            if (response.status === 200) {
                toast.success(response.data.message || 'TEST EMAIL SUCCESS')
                onClose()
            } else handleError(dispatch)(response, null, 'TEST EMAIL ERROR')
        })
        dispatch({
            type: TEST_EMAIL_ENDED
        })
    },
    getEmailServer: () => async (dispatch) => {
        dispatch({
            type: GET_EMAIL_SERVER_REQUEST
        })
        await getEmailServer().then((response) => {
            if (response.status === 200) {
                dispatch({
                    type: GET_EMAIL_SERVER_SUCCESS,
                    payload: response.data.organizationEmailServer
                })
            } else handleError(dispatch)(response, GET_EMAIL_SERVER_ERROR, 'GET EMAIL SERVER ERROR')
        })
    },
    addEmailServer: (data) => async (dispatch) => {
        dispatch({
            type: ADD_EMAIL_SERVER_STARTED
        })
        await addEmailServer(data).then(async (response) => {
            if (response.status === 200) {
                toast.success(response.data.message || 'ADD EMAIL SERVER SUCCESS')
                await getEmailServer().then((response) => {
                    if (response.status === 200) {
                        dispatch({
                            type: GET_EMAIL_SERVER_SUCCESS,
                            payload: response.data.organizationEmailServer
                        })
                    } else handleError(dispatch)(response, GET_EMAIL_SERVER_ERROR, 'GET EMAIL SERVER ERROR')
                })
            } else handleError(dispatch)(response, null, 'ADD EMAIL SERVER ERROR')
        })
        dispatch({
            type: ADD_EMAIL_SERVER_ENDED
        })
    }
}
export default actions