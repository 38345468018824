import {
    Dialog,
    Box,
    Button
} from '@mui/material'
import React from 'react'

import { connect } from 'react-redux'
import { getTranslation, getField } from '../../utils/utils'
import { getParagraphCase } from '../../utils/utils'

const CancelModal = ({
    isOpen,
    onCancel,
    yesText,
    noText,
    description,
    action,
    translation,
    language
}) => {
    const dictionary = getTranslation('cancel-modal', translation)
    return (
        <Dialog open={isOpen} onClose={onCancel}>
            <Box padding={2}>
                <Box sx={{ fontWeight: 700 }}>
                    {description ? getParagraphCase(description) : getField(dictionary, 'description', language)}
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 1 }}>
                    <Button
                        variant='contained'
                        onClick={onCancel}
                        color='secondary'
                        sx={{ margin: '0px 5px 0px 5px' }}
                    >
                        {getField(dictionary, 'no', language)}
                    </Button>
                    <Button
                        variant='contained'
                        onClick={action}
                        sx={{ margin: '0px 5px 0px 5px' }}
                    >
                        {getField(dictionary, 'yes', language)}
                    </Button>
                </Box>
            </Box>
        </Dialog>
    )
}
const mapStateToProps = (state) => ({
    translation: state.translation.translation,
    language: state.translation.language
})
export default connect(mapStateToProps)(CancelModal)