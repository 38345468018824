import {
    getAutomatedMessages,
    getAutomatedMessage,
    addAutomatedMessage,
    editAutomatedMessage,
    deleteAutomatedMessage
} from '../../config/config'

import { toast } from 'react-toastify'
import { handleError } from '../../utils/utils'

export const GET_AUTOMATED_MESSAGES_REQUEST = 'GET_AUTOMATED_MESSAGES_REQUEST'
export const GET_AUTOMATED_MESSAGES_SUCCESS = 'GET_AUTOMATED_MESSAGES_SUCCESS'
export const GET_AUTOMATED_MESSAGES_ERROR = 'GET_AUTOMATED_MESSAGES_ERROR'

export const GET_AUTOMATED_MESSAGE_REQUEST = 'GET_AUTOMATED_MESSAGE_REQUEST'
export const GET_AUTOMATED_MESSAGE_SUCCESS = 'GET_AUTOMATED_MESSAGE_SUCCESS'
export const GET_AUTOMATED_MESSAGE_ERROR = 'GET_AUTOMATED_MESSAGE_ERROR'

export const ADD_AUTOMATED_MESSAGE_STARTED = 'ADD_AUTOMATED_MESSAGE_STARTED'
export const ADD_AUTOMATED_MESSAGE_ENDED = 'ADD_AUTOMATED_MESSAGE_ENDED'

export const EDIT_AUTOMATED_MESSAGE_STARTED = 'EDIT_AUTOMATED_MESSAGE_STARTED'
export const EDIT_AUTOMATED_MESSAGE_ENDED = 'EDIT_AUTOMATED_MESSAGE_ENDED'

export const DELETE_AUTOMATED_MESSAGE_STARTED = 'DELETE_AUTOMATED_MESSAGE_STARTED'
export const DELETE_AUTOMATED_MESSAGE_ENDED = 'DELETE_AUTOMATED_MESSAGE_ENDED'

export const SET_EDIT_DATA = 'SET_EDIT_DATA'

export const CLEAR_AUTO_MSG_LOADING = "CLEAR_AUTO_MSG_LOADING"

const actions = {
    setEditData: (data) => (dispatch) => {
        dispatch({
            type: SET_EDIT_DATA,
            payload: data
        })
    },
    getAutomatedMessages: (ruuid) => async (dispatch, getState) => {
        if (getState().automatedMessages.automatedMessages.length === 0)
            dispatch({
                type: GET_AUTOMATED_MESSAGES_REQUEST
            })
        await getAutomatedMessages(ruuid).then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_AUTOMATED_MESSAGES_SUCCESS,
                    payload: response.data.automatedMessages
                })
            else {
                handleError(dispatch)(response, GET_AUTOMATED_MESSAGES_ERROR, 'GET AUTO MESSAGES ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error(response?.data?.message || 'GET AUTOMATED MESSAGES ERROR')
                // if (response.status === 401) {
                //     dispatch({ type: LOGOUT_USER })
                // }
                // if (response.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: response?.data?.permissions
                //     })
                // }
                // dispatch({
                //     type: GET_AUTOMATED_MESSAGES_ERROR
                // })
            }
        })
    },
    getAutomatedMessage: (ruuid, uuid) => async (dispatch) => {
        dispatch({
            type: GET_AUTOMATED_MESSAGE_REQUEST
        })
        await getAutomatedMessage(ruuid, uuid).then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_AUTOMATED_MESSAGE_SUCCESS,
                    payload: response.data.automatedMessage
                })
            else {
                handleError(dispatch)(response, GET_AUTOMATED_MESSAGE_ERROR, 'GET AUTO MESSAGE ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error(response?.data?.message || 'GET AUTOMATED MESSAGE ERROR')
                // if (response.status === 401) {
                //     dispatch({ type: LOGOUT_USER })
                // }
                // if (response.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: response?.data?.permissions
                //     })
                // }
                // dispatch({
                //     type: GET_AUTOMATED_MESSAGE_ERROR
                // })
            }
        })
    },
    addAutomatedMessage: (ruuid, data) => async (dispatch) => {
        dispatch({
            type: ADD_AUTOMATED_MESSAGE_STARTED
        })
        await addAutomatedMessage(ruuid, data).then((response) => {
            if (response.status === 200) {
                toast.success(response.data.message || 'ADD AUTO MESSAGE SUCCESS')
                dispatch({
                    type: GET_AUTOMATED_MESSAGES_SUCCESS,
                    payload: response.data.automatedMessages
                })
            }
            else {
                handleError(dispatch)(response, null, 'ADD AUTO MESSAGE ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error(response?.data?.message || 'ADD AUTOMATED MESSAGE ERROR')
                // if (response.status === 401) {
                //     dispatch({ type: LOGOUT_USER })
                // }
                // if (response.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: response?.data?.permissions
                //     })
                // }
            }
        })
        dispatch({
            type: ADD_AUTOMATED_MESSAGE_ENDED
        })
    },
    editAutomatedMessage: (ruuid, uuid, data, setEditData, history) => async (dispatch) => {
        dispatch({
            type: EDIT_AUTOMATED_MESSAGE_STARTED
        })
        await editAutomatedMessage(ruuid, uuid, data).then((response) => {
            if (response.status === 200) {
                toast.success(response.data.message || 'EDIT AUTO MESSAGE SUCCESS')
                dispatch({
                    type: GET_AUTOMATED_MESSAGES_SUCCESS,
                    payload: response.data.automatedMessages
                })
                setEditData()
                history.push('/crm/marketing/auto-messages/allAutomatedMessages')
            }
            else {
                handleError(dispatch)(response, null, 'EDIT AUTO MESSAGE ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error(response?.data?.message || 'EDIT AUTOMATED MESSAGE ERROR')
                // if (response.status === 401) {
                //     dispatch({ type: LOGOUT_USER })
                // }
                // if (response.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: response?.data?.permissions
                //     })
                // }
            }
        })
        dispatch({
            type: EDIT_AUTOMATED_MESSAGE_ENDED
        })
    },
    deleteAutomatedMessage: (ruuid, uuid, setEditModal) => async (dispatch) => {
        dispatch({
            type: DELETE_AUTOMATED_MESSAGE_STARTED
        })
        await deleteAutomatedMessage(ruuid, uuid).then((response) => {
            if (response.status === 200) {
                dispatch({
                    type: GET_AUTOMATED_MESSAGES_SUCCESS,
                    payload: response.data.automatedMessages
                })
                dispatch({
                    type: SET_EDIT_DATA,
                    payload: null
                })
                setEditModal(false)
            }
            else {
                handleError(dispatch)(response, null, 'DELETE MESSAGE ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error(response?.data?.message || 'DELETE AUTOMATED MESSAGE ERROR')
                // if (response.status === 401) {
                //     dispatch({ type: LOGOUT_USER })
                // }
                // if (response.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: response?.data?.permissions
                //     })
                // }
            }
        })
        dispatch({
            type: DELETE_AUTOMATED_MESSAGE_ENDED
        })
    }
}
export default actions