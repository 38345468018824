import { getProductsAvailability, updateProductsAvailability } from "../../config/config";
import { toast } from 'react-toastify'
import { LOGOUT_USER, SET_PERMISSIONS } from './AuthActions'
import { handleError } from "../../utils/utils";

export const GET_PRODUCTS_AVAILABILITY_REQUEST = 'GET_PRODUCTS_AVAILABILITY_REQUEST'
export const GET_PRODUCTS_AVAILABILITY_SUCCESS = 'GET_PRODUCTS_AVAILABILITY_SUCCESS'
export const GET_PRODUCTS_AVAILABILITY_ERROR = 'GET_PRODUCTS_AVAILABILITY_ERROR'

export const UPDATE_PRODUCTS_AVAILABILITY_STARTED = 'UPDATE_PRODUCTS_AVAILABILITY_STARTED'
export const UPDATE_PRODUCTS_AVAILABILITY_ENDED = 'UPDATE_PRODUCTS_AVAILABILITY_ENDED'

export const CLEAR_PRODUCTS_AVAILABILITY_LOADING = 'CLEAR_PRODUCTS_AVAILABILITY_LOADING'

const actions = {
    getProductsAvailability: (ruuid, bruuid) => async (dispatch, getState) => {
        const currentRestaurant = getState().productsAvailability.selectedRestaurant
        const currentBranch = getState().productsAvailability.selectedBranch
        if (ruuid !== currentRestaurant || bruuid !== currentBranch)
            dispatch({
                type: GET_PRODUCTS_AVAILABILITY_REQUEST,
                payload: {
                    ruuid: ruuid,
                    bruuid: bruuid
                }
            })
        await getProductsAvailability(ruuid, bruuid).then((response) => {
            if (response.status === 200) {
                dispatch({
                    type: GET_PRODUCTS_AVAILABILITY_SUCCESS,
                    payload: response?.data?.products
                })
            } else {
                handleError(dispatch)(response, GET_PRODUCTS_AVAILABILITY_ERROR, 'GET PRODUCTS AVAILABILITY ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error(response?.data?.message || 'GET PRODUCTS AVAILABILITY ERROR')
                // if (response.status === 401) {
                //     dispatch({ type: LOGOUT_USER })
                // }
                // if (response.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: response?.data?.permissions
                //     })
                // }
                // dispatch({
                //     type: GET_PRODUCTS_AVAILABILITY_ERROR
                // })
            }
        })
    },
    updateProductsAvailability: (ruuid, bruuid, data, onClose, isBulk, setIsBulkUpdate) => async (dispatch) => {
        dispatch({
            type: UPDATE_PRODUCTS_AVAILABILITY_STARTED
        })
        await updateProductsAvailability(ruuid, bruuid, data).then((response) => {
            if (response.status === 200) {
                toast.success(response.data.message || 'UPDATE PRODUCTS AVAILABILITY SUCCESS')
                onClose()
                if (isBulk) {
                    setIsBulkUpdate(false)
                }
                dispatch({
                    type: GET_PRODUCTS_AVAILABILITY_SUCCESS,
                    payload: response.data.products
                })
            } else {
                handleError(dispatch)(response, null, 'UPDATE PRODUCTS AVAILABILITY ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error(response?.data?.message || 'UPDATES PRODUCTS AVAILABILITY ERROR')
                // if (response.status === 401) {
                //     dispatch({ type: LOGOUT_USER })
                // }
                // if (response.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: response?.data?.permissions
                //     })
                // }
            }
        })
        dispatch({
            type: UPDATE_PRODUCTS_AVAILABILITY_ENDED
        })
    }
}
export default actions