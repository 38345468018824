import {
  GET_ALLERGENS_REQUEST,
  GET_ALLERGENS_SUCCESS,
  GET_ALLERGENS_ERROR,
  GET_ALLERGEN_REQUEST,
  GET_ALLERGEN_SUCCESS,
  GET_ALLERGEN_ERROR,
  ADD_ALLERGEN_STARTED,
  ADD_ALLERGEN_END,
  SET_ALLERGENS_POPUP,
  SET_ALLERGENS_POPUP_2,
  EDIT_ALLERGEN_STARTED,
  EDIT_ALLERGEN_END,
  DELETE_ALLERGEN_STARTED,
  DELETE_ALLERGEN_END,
  CLEAR_ALL_ALLERGENS,
  CLEAR_ALLERGENS_LOADING
} from "../actions/AllergensActions";

const initialState = {
  getAllergensLoading: false,
  getAllergenLoading: false,
  addLoading: false,
  editLoading: false,
  deleteLoading: false,
  allergens: [],
  allergensPopup: false,
  allergen: {},
  allergensPopup2: false,
};

function allergens(state = initialState, action) {
  switch (action.type) {
    case CLEAR_ALLERGENS_LOADING:
      return Object.assign({}, state, {
        getAllergensLoading: false,
        getAllergenLoading: false,
        addLoading: false,
        editLoading: false,
        deleteLoading: false,
      })
    case SET_ALLERGENS_POPUP_2:
      return Object.assign({}, state, {
        allergensPopup2: action.payload
      })
    case DELETE_ALLERGEN_STARTED:
      return Object.assign({}, state, {
        deleteLoading: true,
      })
    case DELETE_ALLERGEN_END:
      return Object.assign({}, state, {
        deleteLoading: false,
      })
    case EDIT_ALLERGEN_STARTED:
      return Object.assign({}, state, {
        editLoading: true,
      })
    case EDIT_ALLERGEN_END:
      return Object.assign({}, state, {
        editLoading: false,
      })
    case GET_ALLERGEN_REQUEST:
      return Object.assign({}, state, {
        getAllergenLoading: true,
      });
    case GET_ALLERGEN_SUCCESS:
      return Object.assign({}, state, {
        getAllergenLoading: false,
        allergens: action.payload,
      });
    case GET_ALLERGEN_ERROR:
      return Object.assign({}, state, {
        getAllergenLoading: false,
        allergen: {},
      });
    case GET_ALLERGENS_REQUEST:
      return Object.assign({}, state, {
        getAllergensLoading: true,
      });
    case GET_ALLERGENS_SUCCESS:
      return Object.assign({}, state, {
        getAllergensLoading: false,
        allergens: action.payload.allergens,
      });
    case GET_ALLERGENS_ERROR:
      return Object.assign({}, state, {
        getAllergensLoading: false,
        allergens: [],
      });
    case ADD_ALLERGEN_STARTED:
      return Object.assign({}, state, {
        addLoading: true,
      })
    case ADD_ALLERGEN_END:
      return Object.assign({}, state, {
        addLoading: false,
      })
    case SET_ALLERGENS_POPUP:
      return Object.assign({}, state, {
        allergensPopup: action.payload,
      })
    case CLEAR_ALL_ALLERGENS:
      return initialState
    default:
      return state;
  }
}

export default allergens;
