import {
    getOrdersHistory,
    getOrder
  } from "../../config/config";
  import { toast } from "react-toastify";
  import { LOGOUT_USER, SET_PERMISSIONS } from "./AuthActions";
  import { handleError } from "../../utils/utils";
import { SET_ORDERS_GRID_SORTING_ORDER } from "./OrderActions";
  
  export const SET_ORDERS_HISTORY = "SET_ORDERS_HISTORY";
  
  export const GET_ORDERS_HISTORY_REQUEST = "GET_ORDERS_HISTORY_REQUEST";
  export const GET_ORDERS_HISTORY_SUCCESS = "GET_ORDERS_HISTORY_SUCCESS";
  export const GET_ORDERS_HISTORY_ERROR = "GET_ORDERS_HISTORY_ERROR";

  export const RESET_ORDERS_HISTORY = "RESET_ORDERS_HISTORY";
  
  export const SET_ORDERS_HISTORY_VIEW = "SET_ORDERS_HISTORY_VIEW";
  export const SET_ORDERS_HISTORY_GROUP = "SET_ORDERS_HISTORY_GROUP";
  export const SET_ACTIVE_ORDERS_HISTORY_FILTER = "SET_ACTIVE_ORDERS_HISTORY_FILTER";
  export const SET_IS_ORDERS_HISTORY_FILTER = "SET_IS_ORDERS_HISTORY_FILTER";
  export const SET_ORDERS_HISTORY_FILTER = "SET_ORDERS_HISTORY_FILTER";
  export const SET_ORDERS_HISTORY_GRID_SORT_KEY = "SET_ORDERS_HISTORY_GRID_SORT_KEY"
  export const SET_ORDERS_HISTORY_GRID_SORTING_ORDER = "SET_ORDERS_HISTORY_GRID_SORTING_ORDER"
  export const CLEAR_ORDER_HISTORY_LOADING = 'CLEAR_ORDER_HISTORY_LOADING'
  
  const actions = {
    setOrdersHistory: (data) => (dispatch) => {
      dispatch({
        type: SET_ORDERS_HISTORY,
        payload: {
          data: data
        }
      });
    },
    getOrdersHistory: (ruuid, buuid, page, limit, filterUrl) => async (dispatch) => {
      dispatch({
        type: GET_ORDERS_HISTORY_REQUEST,
      });
      await getOrdersHistory(ruuid, buuid, page, limit, filterUrl).then((response) => {
        if (response?.status === 200) {
          dispatch({
            type: GET_ORDERS_HISTORY_SUCCESS,
            payload: {
              orders: response?.data?.orders,
              page: page,
              totalPages: response?.data?.totalPages,
              ruuid: ruuid,
              buuid: buuid
            },
          });
        } else {
          handleError(dispatch)(response, GET_ORDERS_HISTORY_ERROR, 'GET ORDERS ERROR')
        }
      });
    },
    resetOrdersHistory: () => async (dispatch) => {
      dispatch({
        type: RESET_ORDERS_HISTORY,
      });
    },
    setOrdersHistoryView: (value) => (dispatch) => {
      dispatch({
        type: SET_ORDERS_HISTORY_VIEW,
        payload: {
          value: value
        }
      })
    },
    setActiveOrdersHistoryFilter: (value) => (dispatch) => {
      dispatch({
        type: SET_ACTIVE_ORDERS_HISTORY_FILTER,
        payload: {
          value: value
        }
      })
    },
    setIsOrdersHistoryFilter: (value) => (dispatch) => {
      dispatch({
        type: SET_IS_ORDERS_HISTORY_FILTER,
        payload: {
          value: value
        }
      })
    },
    setOrdersHistoryFilter: (value) => (dispatch) => {
      dispatch({
        type: SET_ORDERS_HISTORY_FILTER,
        payload: {
          value: value
        }
      })
    },
    setOrdersHistoryGridSortKey: (value) => (dispatch) => {
      dispatch({
        type: SET_ORDERS_HISTORY_GRID_SORT_KEY,
        payload: {
          value: value
        }
      })
    },
    setOrdersHistoryGridSortingOrder: (value) => (dispatch) => {
      dispatch({
        type: SET_ORDERS_HISTORY_GRID_SORTING_ORDER,
        payload: {
          value: value
        }
      })
    }
  };
  
  export default actions;
  