import {
    GET_ALL_PROMOTIONS_REQUEST,
    GET_ALL_PROMOTIONS_SUCCESS,
    GET_ALL_PROMOTIONS_ERROR,

    GET_PROMOTION_REQUEST,
    GET_PROMOTION_SUCCESS,
    GET_PROMOTION_ERROR,

    ADD_PROMOTION_STARTED,
    ADD_PROMOTION_ENDED,

    EDIT_PROMOTION_STARTED,
    EDIT_PROMOTION_ENDED,

    DELETE_PROMOTION_STARTED,
    DELETE_PROMOTION_ENDED,

    CLEAR_ALL_PROMOTIONS,

    SET_EDIT_PROMO_CODE,

    SET_PROMOCODE_FILTER,
    CLEAR_PROMO_LOADING
} from "../actions/PromoCodesActions";

const initialState = {
    loading: false,
    addLoading: false,
    editLoading: false,
    deleteLoading: false,
    promotions: [],
    editPromoCode: null,
    selectedRestaurant: '',
    filter: {
        showExpired: false,
        sorting: 'sort-by',
        timing: { from: '', to: '' },
        platform: [],
        isDate: false
    }
}
function promoCodes(state = initialState, action) {
    switch (action.type) {
        case CLEAR_PROMO_LOADING:
            return Object.assign({}, state, {
                loading: false,
                addLoading: false,
                editLoading: false,
                deleteLoading: false,
            })
        case SET_PROMOCODE_FILTER:
            return Object.assign({}, state, {
                filter: action.payload
            })
        case GET_ALL_PROMOTIONS_REQUEST:
            return Object.assign({}, state, {
                loading: true,
                selectedRestaurant: action.payload
            })
        case GET_ALL_PROMOTIONS_SUCCESS:
            return Object.assign({}, state, {
                loading: false,
                promotions: action.payload.promotions
            })
        case GET_ALL_PROMOTIONS_ERROR:
            return Object.assign({}, state, {
                loading: false,
                promotions: []
            })
        case GET_PROMOTION_REQUEST:
            return Object.assign({}, state, {
                loading: true
            })
        case GET_PROMOTION_SUCCESS:
            return Object.assign({}, state, {
                loading: false,
                promotion: action.payload.promotion
            })
        case GET_PROMOTION_ERROR:
            return Object.assign({}, state, {
                loading: false,
                promotions: []
            })
        case ADD_PROMOTION_STARTED:
            return Object.assign({}, state, {
                addLoading: true
            })
        case ADD_PROMOTION_ENDED:
            return Object.assign({}, state, {
                addLoading: false
            })
        case EDIT_PROMOTION_STARTED:
            return Object.assign({}, state, {
                editLoading: true
            })
        case EDIT_PROMOTION_ENDED:
            return Object.assign({}, state, {
                editLoading: false
            })
        case DELETE_PROMOTION_STARTED:
            return Object.assign({}, state, {
                deleteLoading: true
            })
        case DELETE_PROMOTION_ENDED:
            return Object.assign({}, state, {
                deleteLoading: false
            })
        case SET_EDIT_PROMO_CODE:
            return Object.assign({}, state, {
                editPromoCode: action.payload
            })
        case CLEAR_ALL_PROMOTIONS:
            return initialState
        default:
            return state
    }
}
export default promoCodes