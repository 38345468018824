
const stockDataArray = [
    {
      id: '1',
      image:'https://png.pngtree.com/png-clipart/20230113/ourmid/pngtree-red-fresh-tomato-with-green-leaf-png-image_6561484.png',
      name: 'Tomato',
      groupName: 'Pizza',
      category:'Food',
      productType:'Raw Materials',
      deliveryPrice: 18,
      status: 'Active',
      description:'description',
      supplierName:'eks',
      gramCost:0.3,
      gramAvgCost:0.3,
    },

    {
      id: '2',
      name: 'Pepsi',
      image:'https://pngfre.com/wp-content/uploads/Pepsi-9.png',
      groupName: 'Soft Drinks',
      category:'Beverages',
      productType:'Raw Materials',
      deliveryPrice: 2,
      status: 'Not Active',
      description:'description',
      supplierName:'eks',
      gramCost:0.1,
      gramAvgCost:0.1,
    },
    {
      id: '3',
      name: 'Pizza Pepperoni',
      image:'https://pics.clipartpng.com/midle/Pizza_PNG_Clipart-572.png',
      groupName: 'Pizza',
      category:'Food',
      productType:'Raw Materials',
      deliveryPrice: 22,
      status: 'Active',
      description:'description',
      supplierName:'eks',
      gramCost:1.2,
      gramAvgCost:1.2,
    },
    {
        id: '4',
        image:'https://png.pngtree.com/png-clipart/20230113/ourmid/pngtree-red-fresh-tomato-with-green-leaf-png-image_6561484.png',
        name: 'Tomato',
        groupName: 'Pizza',
        category:'Food',
        productType:'Raw Materials',
        deliveryPrice: 12,
        status: 'Not Active',
        description:'description',
        supplierName:'eks',
        gramCost:0.3,
        gramAvgCost:0.3,
      },
  
      {
        id: '5',
        image:'https://icons.veryicon.com/png/Food%20%26%20Drinks/Coke%20%26%20Pepsi%20Can/Coca%20Cola%20Can.png',
        name: 'Coca-Cola',
        groupName: 'Soft Drinks',
        category:'Food',
        productType:'Raw Materials',
        deliveryPrice: 2,
        status: 'Not Active',
        description:'description',
        supplierName:'eks',
        gramCost:0.1,
        gramAvgCost:0.1,
      },
      {
        id: '6',
        image:'https://mezeh.com/wp-content/uploads/2019/10/Lebanese-Tabbouleh-1.png',
        name: 'Tabbouleh',
        groupName: 'Mezza Berde',
        category:'Food',
        productType:'Raw Materials',
        deliveryPrice: 20,
        status: 'Active',
        description:'description',
        supplierName:'eks',
        gramCost:3,
        gramAvgCost:2,
      },
      {
        id: '7',
        image:'https://pngimg.com/d/hummus_PNG28.png',
        name: 'Humus',
        groupName: 'Mezza Sokhne',
        category:'Food',
        productType:'Raw Materials',
        deliveryPrice: 7,
        status: 'Active',
        description:'description',
        supplierName:'eks',
        gramCost:1.6,
        gramAvgCost:0.7,
      },
  
      {
        id: '8',
        image:'https://pngfre.com/wp-content/uploads/Pepsi-9.png',
        name: 'Pepsi',
        category:'Beverages',
        groupName: 'Soft Drinks',
        productType:'Raw Materials',
        deliveryPrice: 1,
        status: 'Not Active',
        description:'description',
        supplierName:'eks',
        gramCost:0.3,
        gramAvgCost:0.3,
      },
      {
        id: '9',
        image:'https://pics.clipartpng.com/midle/Pizza_PNG_Clipart-572.png',
        name: 'Pizza Pepperoni',
        groupName: 'Pizza',
        category:'Food',
        productType:'Raw Materials',
        deliveryPrice: 5,
        status: 'Active',
        description:'description',
        supplierName:'eks',
        gramCost:0.3,
        gramAvgCost:0.3,
      },
      {
        id: '10',
        image:'https://pngimg.com/d/hummus_PNG28.png',
        name: 'Humus',
        groupName: 'Mezza Sokhne',
        category:'Food',
        productType:'Production Items',
        deliveryPrice: 8,
        status: 'Not Active',
        description:'description',
        supplierName:'eks',
        gramCost:1.6,
        gramAvgCost:0.7,
      },
  
      {
        id: '11',
        image:'https://icons.veryicon.com/png/Food%20%26%20Drinks/Coke%20%26%20Pepsi%20Can/Coca%20Cola%20Can.png',
        name: 'Coca-Cola',
        groupName: 'Soft Drinks',
        category:'Food',
        productType:'Production Items',
        deliveryPrice: 5,
        status: 'Not Active',
        description:'description',
        supplierName:'eks',
        gramCost:0.1,
        gramAvgCost:0.1,
      },
      {
        id: '12',
        image:'https://pics.clipartpng.com/midle/Pizza_PNG_Clipart-572.png',
        name: 'Pizza Pepperoni',
        groupName: 'Pizza',
        category:'Food',
        productType:'Production Items',
        deliveryPrice: 13,
        status: 'Not Active',
        description:'description',
        supplierName:'eks',
        gramCost:0.3,
        gramAvgCost:0.3,
      },
      {
          id: '13',
          image:'https://icons.veryicon.com/png/Food%20%26%20Drinks/Coke%20%26%20Pepsi%20Can/Coca%20Cola%20Can.png',
          name: 'Coca-Cola',
          groupName: 'Soft Drinks',
          category:'Beverages',
          productType:'Consumables',
          deliveryPrice: 3,
          status: 'Active',
          description:'description',
          supplierName:'eks',
          gramCost:0.1,
          gramAvgCost:0.1,
        },
    
        {
          id: '14',
          image:'https://pngfre.com/wp-content/uploads/Pepsi-9.png',
          name: 'Pepsi',
          category:'Beverages',
          groupName: 'Soft Drinks',
          productType:'Production Items',
          deliveryPrice: 5,
          status: 'Not Active',
          description:'description',
          supplierName:'eks',
          gramCost:0.3,
          gramAvgCost:0.3,
        },
        {
          id: '15',
          image:'https://pics.clipartpng.com/midle/Pizza_PNG_Clipart-572.png',
          name: 'Pizza Pepperoni',
          groupName: 'Pizza',
          category:'Food',
          productType:'Production Items',
          deliveryPrice: 10,
          status: 'Active',
          description:'description',
          supplierName:'eks',
          gramCost:0.3,
          gramAvgCost:0.3,
        },
        {
          id: '16',
          image:'https://png.pngtree.com/png-clipart/20230113/ourmid/pngtree-red-fresh-tomato-with-green-leaf-png-image_6561484.png',
          name: 'Tomato',
          groupName: 'Pizza',
          category:'Food',
          productType:'Production Items',
          deliveryPrice: 10,
          status: 'Active',
          description:'description',
          supplierName:'eks',
          gramCost:0.3,
          gramAvgCost:0.3,
        },
    
        {
          id: '17',
          image:'https://pngfre.com/wp-content/uploads/Pepsi-9.png',
          name: 'Pepsi',
          category:'Beverages',
          groupName: 'Soft Drinks',
          productType:'Production Items',
          deliveryPrice: 5,
          status: 'Not Active',
          description:'description',
          supplierName:'eks',
          gramCost:0.3,
          gramAvgCost:0.3,
        },
        {
          id: '18',
          image:'https://pics.clipartpng.com/midle/Pizza_PNG_Clipart-572.png',
          name: 'Pizza Pepperoni',
          groupName: 'Pizza',
          category:'Food',
          productType:'Production Items',
          deliveryPrice: 10,
          status: 'Active',
          description:'description',
          supplierName:'eks',
          gramCost:0.3,
          gramAvgCost:0.3,
        },
        {
            id: '19',
            image:'https://png.pngtree.com/png-clipart/20230113/ourmid/pngtree-red-fresh-tomato-with-green-leaf-png-image_6561484.png',
            name: 'Tomato',
            groupName: 'Pizza',
            category:'Food',
            productType:'Consumables',
            deliveryPrice: 10,
            status: 'Active',
            description:'description',
            supplierName:'eks',
            gramCost:0.3,
            gramAvgCost:0.3,
          },
      
          {
            id: '20',
            image:'https://pngfre.com/wp-content/uploads/Pepsi-9.png',
            name: 'Pepsi',
            category:'Beverages',
            groupName: 'Soft Drinks',
            productType:'Consumables',
            deliveryPrice: 5,
            status: 'Not Active',
            description:'description',
            supplierName:'eks',
            gramCost:0.3,
            gramAvgCost:0.3,
          },
          {
            id: '21',
            image:'https://icons.veryicon.com/png/Food%20%26%20Drinks/Coke%20%26%20Pepsi%20Can/Coca%20Cola%20Can.png',
            name: 'Coca-Cola',
            groupName: 'Soft Drinks',
            category:'Beverages',
            productType:'Consumables',
            deliveryPrice: 5,
            status: 'Not Active',
            description:'description',
            supplierName:'eks',
            gramCost:0.1,
            gramAvgCost:0.1,
          },
          {
              id: '22',
              image:'https://png.pngtree.com/png-clipart/20230113/ourmid/pngtree-red-fresh-tomato-with-green-leaf-png-image_6561484.png',
              name: 'Tomato',
              groupName: 'Pizza',
              category:'Food',
              productType:'Consumables',
              deliveryPrice: 10,
              status: 'Active',
              description:'description',
              supplierName:'eks',
              gramCost:0.3,
              gramAvgCost:0.3,
            },
        
            {
              id: '23',
              image:'https://pngimg.com/d/hummus_PNG28.png',
              name: 'Humus',
              groupName: 'Mezza Sokhne',
              category:'Food',
              productType:'Consumables',
              deliveryPrice: 7,
              status: 'Active',
              description:'description',
              supplierName:'eks',
              gramCost:1.6,
              gramAvgCost:0.7,
            },
            {
              id: '24',
              image:'https://mezeh.com/wp-content/uploads/2019/10/Lebanese-Tabbouleh-1.png',
              name: 'Tabbouleh',
              groupName: 'Mezza Berde',
              category:'Food',
              productType:'Consumables',
              deliveryPrice: 20,
              status: 'Not Active',
              description:'description',
              supplierName:'eks',
              gramCost:3,
              gramAvgCost:2,
            },
            {
              id: '25',
              image:'https://png.pngtree.com/png-clipart/20230113/ourmid/pngtree-red-fresh-tomato-with-green-leaf-png-image_6561484.png',
              name: 'Tomato',
              groupName: 'Pizza',
              category:'Food',
              productType:'Consumables',
              deliveryPrice: 10,
              status: 'Active',
              description:'description',
              supplierName:'eks',
              gramCost:0.3,
              gramAvgCost:0.3,
            },
        
            {
              id: '26',
              image:'https://pngfre.com/wp-content/uploads/Pepsi-9.png',
              name: 'Pepsi',
              category:'Beverages',
              groupName: 'Soft Drinks',
              productType:'Consumables',
              deliveryPrice: 5,
              status: 'Active',
              description:'description',
              supplierName:'eks',
              gramCost:0.3,
              gramAvgCost:0.3,
            },
            {
              id: '27',
              image:'https://pics.clipartpng.com/midle/Pizza_PNG_Clipart-572.png',
              name: 'Pizza Pepperoni',
              groupName: 'Pizza',
              category:'Food',
              productType:'Consumables',
              deliveryPrice: 10,
              status: 'Active',
              description:'description',
              supplierName:'eks',
              gramCost:0.3,
              gramAvgCost:0.3,
            },
            {
                id: '28',
                image:'https://png.pngtree.com/png-clipart/20230113/ourmid/pngtree-red-fresh-tomato-with-green-leaf-png-image_6561484.png',
                name: 'Tomato',
                groupName: 'Pizza',
                category:'Food',
                productType:'Retail products',
                deliveryPrice: 10,
                status: 'Active',
                description:'description',
                supplierName:'eks',
                gramCost:0.3,
                gramAvgCost:0.3,
              },
          
              {
                id: '29',
                image:'https://pngfre.com/wp-content/uploads/Pepsi-9.png',
                name: 'Pepsi',
                category:'Beverages',
                groupName: 'Soft Drinks',
                productType:'Retail products',
                deliveryPrice: 5,
                status: 'Not Active',
                description:'description',
                supplierName:'eks',
                gramCost:0.3,
                gramAvgCost:0.3,
              },
              {
                id: '30',
                image:'https://pngimg.com/d/hummus_PNG28.png',
                name: 'Humus',
                groupName: 'Mezza Sokhne',
                category:'Food',
                productType:'Retail products',
                deliveryPrice: 7,
                status: 'Not Active',
                description:'description',
                supplierName:'eks',
                gramCost:1.6,
                gramAvgCost:0.7,
              },
              {
                  id: '31',
                  image:'https://icons.veryicon.com/png/Food%20%26%20Drinks/Coke%20%26%20Pepsi%20Can/Coca%20Cola%20Can.png',
                  name: 'Coca-Cola',
                  groupName: 'Soft Drinks',
                  category:'Beverages',
                  productType:'Retail products',
                  deliveryPrice: 5,
                  status: 'Not Active',
                  description:'description',
                  supplierName:'eks',
                  gramCost:0.1,
                  gramAvgCost:0.1,
                },
            
                {
                  id: '32',
                  image:'https://pngfre.com/wp-content/uploads/Pepsi-9.png',
                  name: 'Pepsi',
                  category:'Beverages',
                  groupName: 'Soft Drinks',
                  productType:'Retail products',
                  deliveryPrice: 8,
                  status: 'Active',
                  description:'description',
                  supplierName:'eks',
                  gramCost:0.3,
                  gramAvgCost:0.3,
                },
                {
                  id: '33',
                  image:'https://pics.clipartpng.com/midle/Pizza_PNG_Clipart-572.png',
                  name: 'Pizza Pepperoni',
                  groupName: 'Pizza',
                  category:'Food',
                  productType:'Retail products',
                  deliveryPrice: 10,
                  status: 'Not Active',
                  description:'description',
                  supplierName:'eks',
                  gramCost:0.3,
                  gramAvgCost:0.3,
                },
                {
                  id: '34',
                  image:'https://png.pngtree.com/png-clipart/20230113/ourmid/pngtree-red-fresh-tomato-with-green-leaf-png-image_6561484.png',
                  name: 'Tomato',
                  groupName: 'Pizza',
                  category:'Food',
                  productType:'Retail products',
                  deliveryPrice: 15,
                  status: 'Active',
                  description:'description',
                  supplierName:'eks',
                  gramCost:0.3,
                  gramAvgCost:0.3,
                },
            
                {
                  id: '35',
                  image:'https://pngfre.com/wp-content/uploads/Pepsi-9.png',
                  name: 'Pepsi',
                  category:'Beverages',
                  groupName: 'Soft Drinks',
                  productType:'Retail products',
                  deliveryPrice: 25,
                  status: 'Active',
                  description:'description',
                  supplierName:'eks',
                  gramCost:0.3,
                  gramAvgCost:0.3,
                },
                {
                  id: '36',
                  image:'https://pics.clipartpng.com/midle/Pizza_PNG_Clipart-572.png',
                  name: 'Pizza Pepperoni',
                  groupName: 'Pizza',
                  category:'Food',
                  productType:'Retail products',
                  deliveryPrice: 17,
                  status: 'Not Active',
                  description:'description',
                  supplierName:'eks',
                  gramCost:0.3,
                  gramAvgCost:0.3,
                },


];

export default stockDataArray;