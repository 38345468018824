import {
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_ERROR,
  CLEAR_ALL_USER,
  ADD_USER_STARTED,
  ADD_USER_END,
  EDIT_USER_STARTED,
  EDIT_USER_END,
  DELETE_USER_STARTED,
  DELETE_USER_END,
  SET_USER_POPUP,
  EDIT_USER_SETTINGS_STARTED,
  EDIT_USER_SETTINGS_ENDED,
  GET_APP_USERS_REQUEST,
  GET_APP_USERS_SUCCESS,
  GET_APP_USERS_ERROR,
  GET_APP_USER_REQUEST,
  GET_APP_USER_SUCCESS,
  GET_APP_USER_ERROR,
  ADD_APP_USER_STARTED,
  ADD_APP_USER_ENDED,
  EDIT_APP_USER_STARTED,
  EDIT_APP_USER_ENDED,
  DELETE_APP_USER_STARTED,
  DELETE_APP_USER_ENDED,
  GET_RESTAURANTS_BRANCHES_REQUEST,
  GET_RESTAURANTS_BRANCHES_SUCCESS,
  GET_RESTAURANTS_BRANCHES_ERROR,
  CLEAR_USERS_LOADING
} from '../actions/UsersActions'

const initialState = {
  loading: false,
  users: [],
  actionLoading: false,
  userPopup: false,
  addLoading: false,
  editLoading: false,
  deleteLoading: false,
  appUser: {},
  appUsers: [],
  restaurantsBranches: [],
  restaurantsBranchesLoading: false
}

function user(state = initialState, action) {
  switch (action.type) {
    case CLEAR_USERS_LOADING:
      return Object.assign({}, state, {
        loading: false,
        actionLoading: false,
        addLoading: false,
        editLoading: false,
        deleteLoading: false,
        restaurantsBranchesLoading: false
      })
    case GET_RESTAURANTS_BRANCHES_ERROR:
      return Object.assign({}, state, {
        restaurantsBranchesLoading: false,
        restaurantsBranches: []
      })
    case GET_RESTAURANTS_BRANCHES_SUCCESS:
      return Object.assign({}, state, {
        restaurantsBranchesLoading: false,
        restaurantsBranches: action.payload
      })
    case GET_RESTAURANTS_BRANCHES_REQUEST:
      return Object.assign({}, state, {
        restaurantsBranchesLoading: true
      })
    case GET_USER_REQUEST:
      return Object.assign({}, state, {
        loading: true,
      })
    case GET_USER_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        users: action.payload,
      })
    case GET_USER_ERROR:
      return Object.assign({}, state, {
        loading: false,
        users: [],
      })

    case ADD_USER_STARTED:
      return Object.assign({}, state, {
        addLoading: true,
      })

    case ADD_USER_END:
      return Object.assign({}, state, {
        addLoading: false,
      })

    case EDIT_USER_STARTED:
      return Object.assign({}, state, {
        editLoading: true,
      })

    case EDIT_USER_END:
      return Object.assign({}, state, {
        editLoading: false,
      })

    case DELETE_USER_STARTED:
      return Object.assign({}, state, {
        deleteLoading: true,
      })

    case DELETE_USER_END:
      return Object.assign({}, state, {
        deleteLoading: false,
      })

    case SET_USER_POPUP:
      return Object.assign({}, state, {
        userPopup: action.payload,
      })

    case CLEAR_ALL_USER:
      return initialState
    case EDIT_USER_SETTINGS_STARTED:
      return Object.assign({}, state, {
        editLoading: true
      })
    case EDIT_USER_SETTINGS_ENDED:
      return Object.assign({}, state, {
        editLoading: false
      })
    case GET_APP_USERS_REQUEST:
      return Object.assign({}, state, {
        loading: true
      })
    case GET_APP_USERS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        appUsers: action.payload
      })
    case GET_APP_USERS_ERROR:
      return Object.assign({}, state, {
        loading: false,
        appUsers: []
      })
    case GET_APP_USER_REQUEST:
      return Object.assign({}, state, {
        loading: true
      })
    case GET_APP_USER_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        appUser: action.payload
      })
    case GET_APP_USER_ERROR:
      return Object.assign({}, state, {
        loading: false,
        appUser: []
      })
    case ADD_APP_USER_STARTED:
      return Object.assign({}, state, {
        actionLoading: true
      })
    case ADD_APP_USER_ENDED:
      return Object.assign({}, state, {
        actionLoading: false
      })
    case EDIT_APP_USER_STARTED:
      return Object.assign({}, state, {
        actionLoading: true
      })
    case EDIT_APP_USER_ENDED:
      return Object.assign({}, state, {
        actionLoading: false
      })
    case DELETE_APP_USER_STARTED:
      return Object.assign({}, state, {
        actionLoading: true
      })
    case DELETE_APP_USER_ENDED:
      return Object.assign({}, state, {
        actionLoading: false
      })
    default:
      return state
  }
}

export default user
