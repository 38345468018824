import {
  GET_REST_REQUEST,
  GET_REST_SUCCESS,
  GET_REST_ERROR,
  SET_REST,
  CLEAR_ALL_REST,
  ADD_REST_STARTED,
  ADD_REST_END,
  EDIT_REST_STARTED,
  EDIT_REST_END,
  DELETE_REST_STARTED,
  DELETE_REST_END,
  ALL_RESTAURANTS_ROLES,
  CLEAR_RESTAURANTS_LOADING,
  SET_REST_DATA,
  SET_MODULES
} from '../actions/RestActions'

const initialState = {
  loading: false,
  restaurants: [],
  selectedRest: '',
  addLoading: false,
  editLoading: false,
  deleteLoading: false,
  allRestaurantsRoles: [],
  restaurantData: null,
  modules: []
}

function rest(state = initialState, action) {
  switch (action.type) {
    case SET_MODULES:
      return Object.assign({}, state, {
        modules: action.payload
      })
    case SET_REST_DATA:
      return Object.assign({}, state, {
        restaurantData: action.payload
      })
    case CLEAR_RESTAURANTS_LOADING:
      return Object.assign({}, state, {
        // loading: false,
        addLoading: false,
        editLoading: false,
        deleteLoading: false,
      })
    case ALL_RESTAURANTS_ROLES:
      return Object.assign({}, state, {
        allRestaurantsRoles: action.payload
      })
    case GET_REST_REQUEST:
      return Object.assign({}, state, {
        loading: true,
      })
    case GET_REST_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        restaurants: action.payload.restaurants,
      })
    case GET_REST_ERROR:
      return Object.assign({}, state, {
        loading: false,
        restaurants: [],
      })

    case SET_REST:
      return Object.assign({}, state, {
        selectedRest: action.payload.selected,
      })

    case ADD_REST_STARTED:
      return Object.assign({}, state, {
        addLoading: true,
      })

    case ADD_REST_END:
      return Object.assign({}, state, {
        addLoading: false,
      })

    case EDIT_REST_STARTED:
      return Object.assign({}, state, {
        editLoading: true,
      })

    case EDIT_REST_END:
      return Object.assign({}, state, {
        editLoading: false,
      })

    case DELETE_REST_STARTED:
      return Object.assign({}, state, {
        deleteLoading: true,
      })

    case DELETE_REST_END:
      return Object.assign({}, state, {
        deleteLoading: false,
      })

    case CLEAR_ALL_REST:
      return initialState

    default:
      return state
  }
}

export default rest
