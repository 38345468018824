import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { Scrollbars } from 'react-custom-scrollbars-2';

const Scrollbar = forwardRef((props, ref) => {
  const { children, ...other } = props
  return (
    <Scrollbars ref={ref} {...other}>
      {children}
    </Scrollbars>
  )
})

Scrollbar.propTypes = {
  children: PropTypes.node,
}

export default Scrollbar
