import {
    getDesignSetupGlobalSettings,
    editDesignSetupGlobalSettings,
    getDesignSetup,
    editDesignSetup
} from "../../config/config";
import { toast } from "react-toastify";
import { handleError } from "../../utils/utils";

export const GET_DESIGN_SETUP_GLOBAL_SETTINGS_REQUEST = "GET_DESIGN_SETUP_GLOBAL_SETTINGS_REQUEST";
export const GET_DESIGN_SETUP_GLOBAL_SETTINGS_SUCCESS = " GET_DESIGN_SETUP_GLOBAL_SETTINGS_SUCCESS";
export const GET_DESIGN_SETUP_GLOBAL_SETTINGS_ERROR = "GET_DESIGN_SETUP_GLOBAL_SETTINGS_ERROR";
export const EDIT_DESIGN_SETUP_GLOBAL_SETTINGS_REQUEST = "EDIT_DESIGN_SETUP_GLOBAL_SETTINGS_REQUEST";
export const EDIT_DESIGN_SETUP_GLOBAL_SETTINGS_SUCCESS = "EDIT_DESIGN_SETUP_GLOBAL_SETTINGS_SUCCESS";
export const EDIT_DESIGN_SETUP_GLOBAL_SETTINGS_ERROR = "EDIT_DESIGN_SETUP_GLOBAL_SETTINGS_ERROR";
export const GET_DESIGN_SETUP_REQUEST = "GET_DESIGN_SETUP_REQUEST";
export const GET_DESIGN_SETUP_SUCCESS = "GET_DESIGN_SETUP_SUCCESS";
export const GET_DESIGN_SETUP_ERROR = "GET_DESIGN_SETUP_ERROR";
export const EDIT_DESIGN_SETUP_REQUEST = "EDIT_DESIGN_SETUP_REQUEST";
export const EDIT_DESIGN_SETUP_SUCCESS = "EDIT_DESIGN_SETUP_SUCCESS";
export const EDIT_DESIGN_SETUP_ERROR = "EDIT_DESIGN_SETUP_ERROR";
export const CLEAR_DESIGN_SETUP = 'CLEAR_DESIGN_SETUP'

export const CLEAR_DESIGN_LOADING = "CLEAR_DESIGN_LOADING"

const actions = {
    getDesignSetupGlobalSettings: () => async (dispatch, getState) => {
        if (getState().designSetup.designSetupGlobalSettings === null)
            dispatch({
                type: GET_DESIGN_SETUP_GLOBAL_SETTINGS_REQUEST,
            });
        await getDesignSetupGlobalSettings().then((res) => {
            if (res?.status === 200) {
                dispatch({
                    type: GET_DESIGN_SETUP_GLOBAL_SETTINGS_SUCCESS,
                    payload: {
                        values: res?.data?.generalConfigurations,
                    },
                });
            } else {
                handleError(dispatch)(res, GET_DESIGN_SETUP_GLOBAL_SETTINGS_ERROR, 'GET DESIGN SETUP GLOBAL SETTINGS ERROR')
                // dispatch({
                //     type: GET_DESIGN_SETUP_GLOBAL_SETTINGS_ERROR,
                // });
                // if (res.status !== 401 && res.status !== 403)
                //     toast.error(res?.data?.message || "Get Design Setup Global Settings error");
                // if (res.status === 401) {
                //     dispatch({ type: LOGOUT_USER });
                // }
                // if (res.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: res?.data?.permissions
                //     })
                // }
            }
        });
    },

    editDesignSetupGlobalSettings: (data, successCb) => async (dispatch) => {
        dispatch({
            type: EDIT_DESIGN_SETUP_GLOBAL_SETTINGS_REQUEST,
        });
        await editDesignSetupGlobalSettings(data).then((res) => {
            if (res?.status === 200) {
                toast.success("Global settings updated successfully");
                dispatch({
                    type: EDIT_DESIGN_SETUP_GLOBAL_SETTINGS_SUCCESS,
                    payload: {
                        values: res?.data?.values,
                    },
                });
                if (successCb) {
                    successCb();
                }
            } else {
                handleError(dispatch)(res, EDIT_DESIGN_SETUP_GLOBAL_SETTINGS_ERROR, 'EDIT DESIGN SETUP GLOBAL SETTINGS ERROR')
                // dispatch({
                //     type: EDIT_DESIGN_SETUP_GLOBAL_SETTINGS_ERROR,
                // });
                // if (res.status !== 401 && res.status !== 403)
                //     toast.error(res?.data?.message || "Edit Design Setup Global Settings error");
                // if (res.status === 401) {
                //     dispatch({ type: LOGOUT_USER });
                // }
                // if (res.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: res?.data?.permissions
                //     })
                // }
            }
        });
    },

    getDesignSetup: (ruuid) => async (dispatch, getState) => {
        if (getState().designSetup.selectedRestaurant !== ruuid)
            dispatch({
                type: GET_DESIGN_SETUP_REQUEST,
                payload: ruuid
            });
        await getDesignSetup(ruuid).then((res) => {
            if (res?.status === 200) {
                dispatch({
                    type: GET_DESIGN_SETUP_SUCCESS,
                    payload: {
                        designSetup: res?.data?.designSetup,
                    },
                });
            } else {
                handleError(dispatch)(res, GET_DESIGN_SETUP_ERROR, 'GET DESIGN SETUP ERROR')
                // dispatch({
                //     type: GET_DESIGN_SETUP_ERROR,
                // });
                // if (res.status !== 401 && res.status !== 403)
                //     toast.error(res?.data?.message || "Get Design Setup error");
                // if (res.status === 401) {
                //     dispatch({ type: LOGOUT_USER });
                // }
                // if (res.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: res?.data?.permissions
                //     })
                // }
            }
        });
    },

    editDesignSetup: (ruuid, data) => async (dispatch) => {
        dispatch({
            type: EDIT_DESIGN_SETUP_REQUEST,
        });

        await editDesignSetup(ruuid, data).then(async (res) => {
            if (res?.status === 200 || res?.status === 500) {
                //the or 500 is temporary for now, until the something went wrong issue is solved on the backend
                toast.success("Design setup updated successfully");
                dispatch({
                    type: EDIT_DESIGN_SETUP_SUCCESS,
                    payload: {
                        designSetup: res?.data?.designSetup,
                    },
                });

                await dispatch(actions?.getDesignSetup(ruuid));
            } else {
                handleError(dispatch)(res, EDIT_DESIGN_SETUP_ERROR, 'EDIT DESIGN SETUP ERROR')
                // dispatch({
                //     type: EDIT_DESIGN_SETUP_ERROR,
                // });
                // if (res.status !== 401 && res.status !== 403)
                //     toast.error(res?.data?.message || "Edit Design Setup error");
                // if (res.status === 401) {
                //     dispatch({ type: LOGOUT_USER });
                // }
                // if (res.status === 403) {
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: res?.data?.permissions,
                //     });
                // }
            }
        });
    },
};

export default actions;
