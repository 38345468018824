import {
    getLoginDesignSetup,
    editLoginDesignSetup
} from "../../config/config";
import { toast } from "react-toastify";
import { handleError } from "../../utils/utils";

export const GET_LOGIN_DESIGN_SETUP_REQUEST = "GET_LOGIN_DESIGN_SETUP_REQUEST";
export const GET_LOGIN_DESIGN_SETUP_SUCCESS = "GET_LOGIN_DESIGN_SETUP_SUCCESS";
export const GET_LOGIN_DESIGN_SETUP_ERROR = "GET_LOGIN_DESIGN_SETUP_ERROR";
export const EDIT_LOGIN_DESIGN_SETUP_REQUEST = "EDIT_LOGIN_DESIGN_SETUP_REQUEST";
export const EDIT_LOGIN_DESIGN_SETUP_SUCCESS = "EDIT_LOGIN_DESIGN_SETUP_SUCCESS";
export const EDIT_LOGIN_DESIGN_SETUP_ERROR = "EDIT_LOGIN_DESIGN_SETUP_ERROR";

export const CLEAR_LOGIN_DESIGN_LOADING = 'CLEAR_LOGIN_DESIGN_LOADING'

const actions = {
    getLoginDesignSetup: (ruuid) => async (dispatch, getState) => {
        if (getState().loginDesignSetup.selectedRestaurant !== ruuid)
            dispatch({
                type: GET_LOGIN_DESIGN_SETUP_REQUEST,
                payload: ruuid
            });
        await getLoginDesignSetup().then((res) => {
            if (res?.status === 200) {
                dispatch({
                    type: GET_LOGIN_DESIGN_SETUP_SUCCESS,
                    payload: {
                        loginDesignSetup: res?.data?.loginDesignSetup,
                        mainDesignSetup: res?.data?.mainDesignSetup
                    },
                });
            } else {
                handleError(dispatch)(res, GET_LOGIN_DESIGN_SETUP_ERROR, 'GET LOGIN DESIGN SETUP ERROR')
            }
        });
    },

    editLoginDesignSetup: (data) => async (dispatch) => {
        dispatch({
            type: EDIT_LOGIN_DESIGN_SETUP_REQUEST,
        });

        await editLoginDesignSetup(data).then(async (res) => {
            if (res?.status === 200) {
                toast.success("Login design setup updated successfully");
                dispatch({
                    type: EDIT_LOGIN_DESIGN_SETUP_SUCCESS,
                    payload: {
                        loginDesignSetup: res?.data?.loginDesignSetup,
                    },
                });

                await dispatch(actions?.getLoginDesignSetup());
            } else {
                handleError(dispatch)(res, EDIT_LOGIN_DESIGN_SETUP_ERROR, 'EDIT LOGIN DESIGN SETUP ERROR')
            }
        });
    },
};

export default actions;
