import { getGroups, addGroup, editGroup, deleteGroup, bulkEditGroups } from '../../config/config'
import { parseError } from '../../utils/parseError'
import { toast } from 'react-toastify'
import { LOGOUT_USER, SET_PERMISSIONS } from './AuthActions'
import { handleError } from '../../utils/utils'

export const GET_GROUP_REQUEST = 'GET_GROUP_REQUEST'
export const GET_GROUP_SUCCESS = 'GET_GROUP_SUCCESS'
export const GET_GROUP_ERROR = 'GET_GROUP_ERROR'

export const ADD_GROUP_STARTED = 'ADD_GROUP_STARTED'
export const ADD_GROUP_END = 'ADD_GROUP_END'

export const EDIT_GROUP_STARTED = 'EDIT_GROUP_STARTED'
export const EDIT_GROUP_END = 'EDIT_GROUP_END'

export const DELETE_GROUP_STARTED = 'DELETE_GROUP_STARTED'
export const DELETE_GROUP_END = 'DELETE_GROUP_END'

export const SET_GROUP_POPUP = 'SET_GROUP_POPUP'
export const CLEAR_ALL_GROUP = 'CLEAR_ALL_GROUP'

export const BULK_EDIT_GROUPS_STARTED = 'BULK_EDIT_GROUPS_STARTED'
export const BULK_EDIT_GROUPS_ENDED = 'BULK_EDIT_GROUPS_ENDED'

export const CLEAR_GROUP_LOADING = 'CLEAR_GROUP_LOADING'

const actions = {
  bulkEditGroups: (ruuid, data, reset, setIsEditing) => async (dispatch) => {
    // dispatch({
    //   type: BULK_EDIT_GROUPS_STARTED
    // })
    // await bulkEditGroups(ruuid, data).then((response) => {
    //   if (response.status === 200) {
    //     toast.success(response.data.message || 'BULK EDIT GROUPS SUCCESS')
    //     dispatch({
    //       type: GET_GROUP_SUCCESS,
    //       payload: {
    //         groups: response?.data?.groups,
    //       },
    //     })
    //     setIsEditing(false)
    //   } else {
    //     reset()
    //     handleError(dispatch)(response, null, 'BULK EDIT GROUPS ERROR')
    //   }
    // })
    // dispatch({
    //   type: BULK_EDIT_GROUPS_ENDED
    // })
    dispatch({
      type: BULK_EDIT_GROUPS_STARTED
    });
    let success = true
    let message = 'EDIT BULK GROUPS SUCCESS'
    try {
      for (const item of data) {
        const response = await bulkEditGroups(ruuid, { productGroups: item });

        if (response.status === 200) {
          message = response?.data?.message || 'EDIT BULK GROUPS SUCCESS'
          //  toast.success(response?.data?.message || 'EDIT BULK PRODUCTS SUCCESS');
          dispatch({
            type: GET_GROUP_SUCCESS,
            payload: {
              groups: response?.data?.groups
            }
          });
        } else {
          success = false
          handleError(dispatch)(response, null, 'EDIT BULK ERROR');
        }
      }
      if (success) {
        toast.success(message)
        setIsEditing(false);
      } else reset()
    } catch (error) {
      console.error(error);
      toast.error('An unexpected error occurred during the bulk edit.');
    } finally {
      dispatch({
        type: BULK_EDIT_GROUPS_ENDED
      });
    }
  },
  getGroups: (ruuid) => async (dispatch, getState) => {
    const currentRestaurant = getState().group.selectedRestaurant
    if (ruuid !== currentRestaurant)
      dispatch({
        type: GET_GROUP_REQUEST,
        payload: ruuid
      })
    await getGroups(ruuid).then((res) => {
      if (res?.status === 200) {
        dispatch({
          type: GET_GROUP_SUCCESS,
          payload: {
            groups: res?.data?.groups,
          },
        })
      } else {
        handleError(dispatch)(res, GET_GROUP_ERROR, 'GET GROUP ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Get groups error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
        // dispatch({
        //   type: GET_GROUP_ERROR,
        //   payload: {
        //     message: parseError(res?.data?.error) || res?.data?.message || 'GET GROUP error',
        //   },
        // })
      }
    })
  },

  addGroup: (ruuid, data) => async (dispatch) => {
    dispatch({
      type: ADD_GROUP_STARTED,
    })
    await addGroup(ruuid, data).then((res) => {
      if (res?.status === 200) {
        toast.success(res.data?.message || 'Successfully Saved')
        dispatch({
          type: GET_GROUP_SUCCESS,
          payload: {
            groups: res?.data?.groups,
          },
        })
        dispatch({
          type: SET_GROUP_POPUP,
          payload: false,
        })
      } else {
        handleError(dispatch)(res, null, 'ADD GROUP ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Add Group Error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
      }
    })
    dispatch({
      type: ADD_GROUP_END,
    })
  },

  editGroup: (ruuid, uuid, data) => async (dispatch) => {
    dispatch({
      type: EDIT_GROUP_STARTED,
    })
    await editGroup(ruuid, uuid, data).then((res) => {
      if (res?.status === 200) {
        toast.success(res.data?.message || 'Successfully Saved')
        dispatch({
          type: GET_GROUP_SUCCESS,
          payload: {
            groups: res?.data?.groups,
          },
        })
        dispatch({
          type: SET_GROUP_POPUP,
          payload: false,
        })
      } else {
        handleError(dispatch)(res, null, 'EDIT GROUP ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Edit Group Error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
      }
    })
    dispatch({
      type: EDIT_GROUP_END,
    })
  },

  deleteGroup: (ruuid, uuid, handleModalClose) => async (dispatch) => {
    dispatch({
      type: DELETE_GROUP_STARTED,
    })
    await deleteGroup(ruuid, uuid).then((res) => {
      if (res?.status === 200) {
        handleModalClose()
        toast.success(res?.data?.message || 'Successfully Deleted')
        dispatch({
          type: GET_GROUP_SUCCESS,
          payload: {
            groups: res?.data?.groups,
          },
        })
        dispatch({
          type: SET_GROUP_POPUP,
          payload: false,
        })
      } else {
        handleError(dispatch)(res, null, 'DELETE GROUP ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Delete Group Error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
      }
    })
    dispatch({
      type: DELETE_GROUP_END,
    })
  },

  setActionModal: (state) => async (dispatch) => {
    dispatch({
      type: SET_GROUP_POPUP,
      payload: state,
    })
  },

  // CLEAR ALL Reducer
  clearAll: () => (dispatch) => {
    dispatch({
      type: CLEAR_ALL_GROUP,
    })
  },
}

export default actions
