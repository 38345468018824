import {
    getDirectMessagesWithCustomers,
    deleteDirectMessage,
    editDirectMessage,
    addDirectMessage,
    getDirectMessage,
    getDirectMessages
} from '../../config/config'

import { toast } from 'react-toastify'
import { handleError } from '../../utils/utils'

export const GET_DIRECT_MESSAGES_REQUEST = 'GET_DIRECT_MESSAGES_REQUEST'
export const GET_DIRECT_MESSAGES_SUCCESS = 'GET_DIRECT_MESSAGES_SUCCESS'
export const GET_DIRECT_MESSAGES_ERROR = 'GET_DIRECT_MESSAGES_ERROR'

export const GET_DIRECT_MESSAGE_REQUEST = 'GET_DIRECT_MESSAGE_REQUEST'
export const GET_DIRECT_MESSAGE_SUCCESS = 'GET_DIRECT_MESSAGE_SUCCESS'
export const GET_DIRECT_MESSAGE_ERROR = 'GET_DIRECT_MESSAGE_ERROR'

export const GET_DIRECT_MESSAGES_WITH_CUSTOMERS_REQUEST = 'GET_DIRECT_MESSAGES_WITH_CUSTOMERS_REQUEST'
export const GET_DIRECT_MESSAGES_WITH_CUSTOMERS_SUCCESS = 'GET_DIRECT_MESSAGES_WITH_CUSTOMERS_SUCCESS'
export const GET_DIRECT_MESSAGES_WITH_CUSTOMERS_ERROR = 'GET_DIRECT_MESSAGES_WITH_CUSTOMERS_ERROR'

export const ADD_DIRECT_MESSAGE_STARTED = 'ADD_DIRECT_MESSAGES_STARTED'
export const ADD_DIRECT_MESSAGE_ENDED = 'ADD_DIRECT_MESSAGE_ENDED'

export const EDIT_DIRECT_MESSAGE_STARTED = 'EDIT_DIRECT_MESSAGES_STARTED'
export const EDIT_DIRECT_MESSAGE_ENDED = 'EDIT_DIRECT_MESSAGE_ENDED'

export const DELETE_DIRECT_MESSAGE_STARTED = 'DELETE_DIRECT_MESSAGES_STARTED'
export const DELETE_DIRECT_MESSAGE_ENDED = 'DELETE_DIRECT_MESSAGE_ENDED'

export const SET_EDIT_DIRECT_MESSAGE = 'SET_EDIT_DIRECT_MESSAGE'

export const CLEAR_ALL_DM = 'CLEAR_ALL_DM'
export const CLEAR_LOADING_DM = 'CLEAR_LOADING_DM'

const actions = {
    setEditDirectMessage: (data) => (dispatch) => {
        dispatch({
            type: SET_EDIT_DIRECT_MESSAGE,
            payload: data
        })
    },
    getDirectMessages: (ruuid) => async (dispatch) => {
        dispatch({
            type: GET_DIRECT_MESSAGES_REQUEST
        })
        await getDirectMessages(ruuid).then((response) => {
            if (response.status === 200) {
                dispatch({
                    type: GET_DIRECT_MESSAGES_SUCCESS,
                    payload: response.data.directMessages
                })
            } else handleError(dispatch)(response, GET_DIRECT_MESSAGES_ERROR, 'GET DIRECT MESSAGES ERROR')
        })
    },
    getDirectMessage: (ruuid, uuid) => async (dispatch) => {
        dispatch({
            type: GET_DIRECT_MESSAGE_REQUEST
        })
        await getDirectMessage(ruuid, uuid).then((response) => {
            if (response.status === 200) {
                dispatch({
                    type: GET_DIRECT_MESSAGES_SUCCESS,
                    payload: response.data.directMessage
                })
            } else handleError(dispatch)(response, GET_DIRECT_MESSAGE_ERROR, 'GET DIRECT MESSAGE ERROR')
        })
    },
    addDirectMessage: (ruuid, data, onClose) => async (dispatch) => {
        dispatch({
            type: ADD_DIRECT_MESSAGE_STARTED
        })
        await addDirectMessage(ruuid, data).then((response) => {
            if (response.status === 200) {
                toast.success(response.data.message || 'ADD DIRECT MESSAGE SUCCESS')
                dispatch({
                    type: GET_DIRECT_MESSAGES_SUCCESS,
                    payload: response.data.directMessages
                })
                onClose()
            } else handleError(dispatch)(response, null, 'ADD DIRECT MESSAGE ERROR')
        })
        dispatch({
            type: ADD_DIRECT_MESSAGE_ENDED
        })
    },
    editDirectMessage: (ruuid, uuid, data, onClose) => async (dispatch) => {
        dispatch({
            type: EDIT_DIRECT_MESSAGE_STARTED
        })
        await editDirectMessage(ruuid, uuid, data).then((response) => {
            if (response.status === 200) {
                toast.success(response.data.message || 'EDIT DIRECT MESSAGE ERROR')
                dispatch({
                    type: GET_DIRECT_MESSAGES_SUCCESS,
                    payload: response.data.directMessages
                })
                onClose()
            } else handleError(dispatch)(response, null, 'EDIT DIRECT MESSAGE ERROR')
        })
        dispatch({
            type: EDIT_DIRECT_MESSAGE_ENDED
        })
    },
    deleteDirectMessage: (ruuid, uuid, setDeleteModal) => async (dispatch) => {
        dispatch({
            type: DELETE_DIRECT_MESSAGE_STARTED
        })
        await deleteDirectMessage(ruuid, uuid).then((response) => {
            if (response.status === 200) {
                toast.success(response.data.message || 'DELETE DIRECT MESSAGE ERROR')
                dispatch({
                    type: GET_DIRECT_MESSAGES_SUCCESS,
                    payload: response.data.directMessages
                })
                setDeleteModal(false)
            } else handleError(dispatch)(response, null, 'DELETE DIRECT MESSAGE ERROR')
        })
        dispatch({
            type: DELETE_DIRECT_MESSAGE_ENDED
        })
    }
}
export default actions