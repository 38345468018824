import { getModifiers, addModifier, editModifier, deleteModifier, bulkUpdateModifiers } from '../../config/config'
import { toast } from 'react-toastify'
import { parseError } from '../../utils/parseError'
import { LOGOUT_USER, SET_PERMISSIONS } from './AuthActions'
import { handleError } from '../../utils/utils'

export const GET_MODIFIER_REQUEST = 'GET_MODIFIER_REQUEST'
export const GET_MODIFIER_SUCCESS = 'GET_MODIFIER_SUCCESS'
export const GET_MODIFIER_ERROR = 'GET_MODIFIER_ERROR'

export const ADD_MODIFIER_STARTED = 'ADD_MODIFIER_STARTED'
export const ADD_MODIFIER_END = 'ADD_MODIFIER_END'

export const EDIT_MODIFIER_STARTED = 'EDIT_MODIFIER_STARTED'
export const EDIT_MODIFIER_END = 'EDIT_MODIFIER_END'

export const DELETE_MODIFIER_STARTED = 'DELETE_MODIFIER_STARTED'
export const DELETE_MODIFIER_END = 'DELETE_MODIFIER_END'

export const SET_MODIFIER_POPUP = 'SET_MODIFIER_POPUP'
export const CLEAR_ALL_MODIFIER = 'CLEAR_ALL_MODIFIER'

export const BULK_UPDATE_MODIFIERS_STARTED = 'BULK_UPDATE_MODIFIERS_STARTED'
export const BULK_UPDATE_MODIFIERS_ENDED = 'BULK_UPDATE_MODIFIERS_ENDED'

export const CLEAR_MODIFIERS_LOADING = 'CLEAR_MODIFIERS_LOADING'

const actions = {
  bulkUpdateModifiers: (ruuid, data, resetForm, setIsEditing) => async (dispatch) => {
    // dispatch({
    //   type: BULK_UPDATE_MODIFIERS_STARTED
    // })
    // await bulkUpdateModifiers(ruuid, data).then((response) => {
    //   if (response.status === 200) {
    //     toast.success(response.data.message || 'BULK UPDATE MODIFIERS SUCCESS')
    //     dispatch({
    //       type: GET_MODIFIER_SUCCESS,
    //       payload: {
    //         modifiers: response?.data?.modifiers,
    //       },
    //     })
    //     setIsEditing(false)
    //   } else {
    //     resetForm()
    //     handleError(dispatch)(response, null, 'BULK UPDATE MODIFIERS ERROR')
    //   }
    // })
    // dispatch({
    //   type: BULK_UPDATE_MODIFIERS_ENDED
    // })
    dispatch({
      type: BULK_UPDATE_MODIFIERS_STARTED
    });
    let success = true
    let message = 'EDIT BULK MODIFIERS SUCCESS'
    try {
      for (const item of data) {
        const response = await bulkUpdateModifiers(ruuid, { modifiers: item });

        if (response.status === 200) {
          message = response?.data?.message || 'EDIT BULK MODIFIERS SUCCESS'
          //  toast.success(response?.data?.message || 'EDIT BULK PRODUCTS SUCCESS');
          dispatch({
            type: GET_MODIFIER_SUCCESS,
            payload: {
              modifiers: response?.data?.allMods
            }
          });
        } else {
          success = false
          handleError(dispatch)(response, null, 'EDIT BULK ERROR');
        }
      }
      if (success) {
        toast.success(message)
        setIsEditing(false);
      } else resetForm()
    } catch (error) {
      console.error(error);
      toast.error('An unexpected error occurred during the bulk edit.');
    } finally {
      dispatch({
        type: BULK_UPDATE_MODIFIERS_ENDED
      });
    }
  },
  getModifiers: (ruuid) => async (dispatch, getState) => {
    const currentRestaurant = getState().modifier.selectedRestaurant
    if (ruuid !== currentRestaurant)
      dispatch({
        type: GET_MODIFIER_REQUEST,
        payload: ruuid
      })
    await getModifiers(ruuid).then((res) => {
      if (res?.status === 200) {
        dispatch({
          type: GET_MODIFIER_SUCCESS,
          payload: {
            modifiers: res?.data?.modifiers,
          },
        })
      } else {
        handleError(dispatch)(res, GET_MODIFIER_ERROR, 'GET MODIFIER ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Get All Modifiers error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
        // dispatch({
        //   type: GET_MODIFIER_ERROR,
        //   payload: {
        //     message: parseError(res?.data?.error) || res?.data?.message || 'GET MODIFIER error',
        //   },
        // })
      }
    })
  },

  addModifier: (ruuid, data, setaddModifierOpen, getModifier) => async (dispatch) => {
    dispatch({
      type: ADD_MODIFIER_STARTED,
    })
    await addModifier(ruuid, data).then((res) => {
      if (res?.status === 200) {
        toast.success(res.data.message || 'Successfully saved')
        dispatch({
          type: GET_MODIFIER_SUCCESS,
          payload: {
            modifiers: res?.data?.modifiers,
          },
        })
        dispatch({
          type: SET_MODIFIER_POPUP,
          payload: false,
        })
        if (setaddModifierOpen)
          setaddModifierOpen(false)
        if (getModifier)
          getModifier()
      } else {
        handleError(dispatch)(res, null, 'ADD MODIFIER ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Add Modifier error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
      }
    })
    dispatch({
      type: ADD_MODIFIER_END,
    })
  },

  editModifier: (ruuid, uuid, data, setEditModifierData, getModifier) => async (dispatch) => {
    dispatch({
      type: EDIT_MODIFIER_STARTED,
    })
    await editModifier(ruuid, uuid, data).then((res) => {
      if (res?.status === 200) {
        toast.success(res.data.message || 'Successfully saved')
        dispatch({
          type: GET_MODIFIER_SUCCESS,
          payload: {
            modifiers: res?.data?.modifiers,
          },
        })
        dispatch({
          type: SET_MODIFIER_POPUP,
          payload: false,
        })
        if (setEditModifierData)
          setEditModifierData(null)
        if (getModifier)
          getModifier()
      } else {
        handleError(dispatch)(res, null, 'EDIT MODIFIER ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Edit Modifiers error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
      }
    })
    dispatch({
      type: EDIT_MODIFIER_END,
    })
  },

  deleteModifier: (ruuid, uuid) => async (dispatch) => {
    dispatch({
      type: DELETE_MODIFIER_STARTED,
    })
    await deleteModifier(ruuid, uuid).then((res) => {
      if (res?.status === 200) {
        toast.success(res.data.message || 'Successfully deleted')
        dispatch({
          type: GET_MODIFIER_SUCCESS,
          payload: {
            modifiers: res?.data?.modifiers,
          },
        })
        dispatch({
          type: SET_MODIFIER_POPUP,
          payload: false,
        })
      } else {
        handleError(dispatch)(res, null, 'DELETE MODIFIER ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Delete Modifier Error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
      }
    })
    dispatch({
      type: DELETE_MODIFIER_END,
    })
  },

  setActionModal: (state) => async (dispatch) => {
    dispatch({
      type: SET_MODIFIER_POPUP,
      payload: state,
    })
  },

  // CLEAR ALL Reducer
  clearAll: () => (dispatch) => {
    dispatch({
      type: CLEAR_ALL_MODIFIER,
    })
  },
}

export default actions
