import React from 'react';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Typography,
} from '@mui/material';
import { getField, getTranslation } from '../utils/utils';
import { getSVG } from '../utils/getSvg';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            eventId: undefined,
            translations: props.translations,
            language: props.language,
            goBackClicked: false // Track if Go Back button is clicked
        };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        console.error(error, errorInfo);
    }

    componentDidUpdate(prevProps, prevState) {
        // Reset the error state if translations or language props change
        if (
            prevProps.translations !== this.props.translations ||
            prevProps.language !== this.props.language
        ) {
            this.setState({
                hasError: false,
                translations: this.props.translations,
                language: this.props.language,
                goBackClicked: false // Reset goBackClicked state if props change
            });
        }
    }

    handleGoBack = () => {
        const { history } = this.props;
        if (!this.state.goBackClicked) {
            this.setState({ goBackClicked: true }, () => {
                history.goBack();
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            });
        }
    };

    render() {
        const devMode = process.env.NODE_ENV === 'development';
        const { history } = this.props;
        const dictionary = getTranslation(
            'error-boundary',
            this.state.translations
        );
        if (this.state.hasError && !devMode) {
            return (
                // <Box
                //     bgcolor="primary.main"
                //     display="flex"
                //     width="100vw"
                //     height="100vh"
                //     justifyContent="center"
                //     alignItems="center"
                // >
                //     <Card>
                //         <CardContent>
                //             <Typography>
                //                 {getField(
                //                     dictionary,
                //                     'something-wrong',
                //                     this.state.language
                //                 )}
                //             </Typography>
                //             <Typography>
                //                 {getField(
                //                     dictionary,
                //                     'notified',
                //                     this.state.language
                //                 )}
                //             </Typography>
                //         </CardContent>
                //         <CardActions>
                //             <Button color="primary">
                //                 {getField(
                //                     dictionary,
                //                     'send-feedback',
                //                     this.state.language
                //                 )}
                //             </Button>
                //             <Button
                //                 color="primary"
                //                 onClick={this.handleGoBack}
                //             >
                //                 {getField(
                //                     dictionary,
                //                     'go-back',
                //                     this.state.language
                //                 )}
                //             </Button>
                //         </CardActions>
                //     </Card>
                // </Box>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', position: 'relative' }}>
                    <Box sx={{ backgroundColor: 'background.paper', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', padding: 8, borderRadius: '18px', zIndex: 999 }}>
                        <Box>
                            {getSVG('error', null, { width: 300, height: 300 })}
                        </Box>
                        <Typography variant='h2'>
                            {getField(dictionary, 'something-wrong', this.state.language)}
                        </Typography>
                        <Typography variant='h5'>
                            {getField(dictionary, 'notified', this.state.language)}
                        </Typography>
                        <Box sx={{ display: 'flex', width: '40%', justifyContent: 'space-around' }}>
                            <Button
                                variant='contained'
                                sx={{ borderRadius: '18px', marginTop: 7 }}
                            >
                                {getField(dictionary, 'send-feedback', this.state.language)}
                            </Button>
                            <Button
                                variant='contained'
                                sx={{ borderRadius: '18px', marginTop: 7 }}
                                onClick={this.handleGoBack}
                            >
                                {getField(dictionary, 'go-back', this.state.language)}
                            </Button>
                        </Box>
                    </Box>
                    <Box sx={{ height: '250px', backgroundColor: '#b493be', width: '100%', position: 'absolute', top: '45%' }} />
                </div>
            );
        }
        if (this.state.hasError && devMode) return null;

        return this.props.children;
    }
}

const mapStateToProps = (state) => ({
    language: state.translation.language,
    translations: state.translation.translation,
});

export default connect(mapStateToProps)(withRouter(ErrorBoundary));
