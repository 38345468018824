import React, { lazy, useEffect } from 'react'
import LayoutActions from '../../redux/actions/LayoutActions'
import { Switch, withRouter, Route, Redirect } from 'react-router-dom'
import UserRoute from '../../routers/UserRoute'
import { connect } from 'react-redux'
import { Box } from '@mui/system'
import branchesActions from '../../redux/actions/BranchActions'

const components = {
    zoneSetup: lazy(() => import('../../pages/deliverySetup/DeliveryZones')),
    urlLink: lazy(() => import('../../pages/deliverySetup/UrlLink')),
    orderingQr: lazy(() => import('../../pages/deliverySetup/UrlMenu'))
};

function DeliveryLayout({
    deliveryActiveTab,
    setPathCheck,
    permission,
    reformat,
    getBranchesOnlineDelivery,
    selectedRestaurant
}) {
    const access = reformat(permission.subPage)
        .sort((a, b) => {
            // Check if either 'order' is null
            if (a.order === null && b.order === null) {
                return 0; // Keep the order unchanged if both are null
            } else if (a.order === null) {
                return 1; // Place 'a' (null) after 'b' (non-null)
            } else if (b.order === null) {
                return -1; // Place 'b' (null) after 'a' (non-null)
            }
            // Sort non-null 'order' values normally
            return a.order - b.order;
        });
    useEffect(() => {
        setPathCheck(permission.route)
    }, [window.location.pathname])
    useEffect(() => {
        getBranchesOnlineDelivery(selectedRestaurant)
    }, [selectedRestaurant])
    return (
        <div>
            <Box>
                <Switch>
                    <Route exact path="/ordering/online-delivery-setup" render={() => <Redirect to={access[0]?.route} />} />
                    {access.map((permission2, i) =>
                        <UserRoute key={i} exact path={permission2.route} component={components[permission2.key]} permission={permission2} />
                    )}
                    {/* <UserRoute exact path="/ordering/online-delivery-setup/zone-setup" component={DeliveryZones} />
                    <UserRoute exact path="/ordering/online-delivery-setup/url-link" component={UrlLink} /> */}
                    <Redirect from="*" to="/not-found" />
                </Switch>
            </Box>
        </div>
    )
}
const mapStateToProps = (state) => ({
    deliveryActiveTab: state.layout.deliveryActiveTab,
    selectedRestaurant: state.rest.selectedRest
})
const mapDispatchToProps = (dispatch) => ({
    setPathCheck: (data) => dispatch(LayoutActions.setPathCheck(data)),
    getBranchesOnlineDelivery: (ruuid) => dispatch(branchesActions.getBranchesOnlineDelivery(ruuid))
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DeliveryLayout))