import {
  GET_BRANCH_REQUEST,
  GET_BRANCH_SUCCESS,
  GET_BRANCH_ERROR,

  GET_BRANCH_TABLE_ORDERING_REQUEST,
  GET_BRANCH_TABLE_ORDERING_SUCCESS,
  GET_BRANCH_TABLE_ORDERING_ERROR,

  GET_BRANCH_LOYALTY_REQUEST,
  GET_BRANCH_LOYALTY_SUCCESS,
  GET_BRANCH_LOYALTY_ERROR,

  GET_BRANCH_QR_MENU_REQUEST,
  GET_BRANCH_QR_MENU_SUCCESS,
  GET_BRANCH_QR_MENU_ERROR,

  GET_BRANCH_ONLINE_DELIVERY_REQUEST,
  GET_BRANCH_ONLINE_DELIVERY_SUCCESS,
  GET_BRANCH_ONLINE_DELIVERY_ERROR,

  GET_BRANCHES_ORDERS_REQUEST,
  GET_BRANCHES_ORDERS_SUCCESS,
  GET_BRANCHES_ORDERS_ERROR,
  SET_SELECTED_BRANCH_ORDERS,

  CLEAR_ALL_BRANCH,
  ADD_BRANCH_STARTED,
  ADD_BRANCH_END,
  EDIT_BRANCH_STARTED,
  EDIT_BRANCH_END,
  DELETE_BRANCH_STARTED,
  DELETE_BRANCH_END,
  SET_SELECTED_BRANCH,
  SET_SELECTED_BRANCH_TABLE_ORDERING,
  SET_SELECTED_BRANCH_LOYALTY,
  SET_SELECTED_BRANCH_QR_MENU,
  SET_SELECTED_BRANCH_ONLINE_DELIVERY,
  SET_SELECTED_BRANCH_DATA,
  GET_BRANCH_FLOOR_SETUP_REQUEST,
  GET_BRANCH_FLOOR_SETUP_SUCCESS,
  GET_BRANCH_FLOOR_SETUP_ERROR,
  CLEAR_BRANCH_LOADING
} from "../actions/BranchActions";

const initialState = {
  loading: false,
  branches: [],
  selectedBranch: "0",
  addLoading: false,
  editLoading: false,
  deleteLoading: false,
  selectedRestaurant: '',
  selectedRestaurantLoyalty: '',
  selectedRestaurantTableOrdering: '',
  selectedRestaurantOnlineDelivery: '',
  selectedRestaurantOrders: '',
  selectedRestaurantQRMenu: '',
  selectedRestaurantFloorSetup: '',
  tableOrderingBranches: [],
  loyaltyBranches: [],
  qrMenuBranches: [],
  floorSetUpBranches: [],
  onlineDeliveryBranches: [],
  ordersBranches: [],
  selectedBranchtableOrdering: "0",
  selectedBranchLoyalty: "0",
  selectedBranchQRMenu: "0",
  selectedBranchFloorSetup: "0",
  selectedBranchOnlineDelivery: "0",
  selectedBranchOrders: "0",
  selectedBranchData: '',

  // floorSetupBranches: [],
  // selectedBranchFloorMenu: "0",
  // selectedRestaurantFloorMenu: '',
};

function branch(state = initialState, action) {
  switch (action.type) {
    case SET_SELECTED_BRANCH_ORDERS:
      return Object.assign({}, state, {
        selectedBranchOrders: action.data
      })
    case GET_BRANCHES_ORDERS_ERROR:
      return Object.assign({}.state, {
        loading: false,
        ordersBranches: []
      })
    case GET_BRANCHES_ORDERS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        ordersBranches: action.payload.branches
      })
    case GET_BRANCHES_ORDERS_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        selectedRestaurantOrders: action.payload
      })
    case CLEAR_BRANCH_LOADING:
      return Object.assign({}, state, {
        // loading: false,
        addLoading: false,
        editLoading: false,
        deleteLoading: false,
      })
    case SET_SELECTED_BRANCH_DATA:
      return Object.assign({}, state, {
        selectedBranchData: action.payload
      })
    case SET_SELECTED_BRANCH_ONLINE_DELIVERY:
      return Object.assign({}, state, {
        selectedBranchOnlineDelivery: action.data,
      });

    case SET_SELECTED_BRANCH_QR_MENU:
      return Object.assign({}, state, {
        selectedBranchQRMenu: action.data,
      });

    case SET_SELECTED_BRANCH_LOYALTY:
      return Object.assign({}, state, {
        selectedBranchLoyalty: action.data,
      });

    case SET_SELECTED_BRANCH_TABLE_ORDERING:
      return Object.assign({}, state, {
        selectedBranchtableOrdering: action.data,
      });

    case GET_BRANCH_ONLINE_DELIVERY_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        selectedRestaurantOnlineDelivery: action.payload
      });
    case GET_BRANCH_ONLINE_DELIVERY_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        onlineDeliveryBranches: action.payload.branches,
      });
    case GET_BRANCH_ONLINE_DELIVERY_ERROR:
      return Object.assign({}, state, {
        loading: false,
        onlineDeliveryBranches: [],
      });

    case GET_BRANCH_QR_MENU_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        selectedRestaurantQRMenu: action.payload
      });
    case GET_BRANCH_QR_MENU_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        qrMenuBranches: action.payload.branches,
      });
    case GET_BRANCH_QR_MENU_ERROR:
      return Object.assign({}, state, {
        loading: false,
        qrMenuBranches: [],
      });


    case GET_BRANCH_FLOOR_SETUP_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        selectedRestaurantFloorSetup: action.payload
      });
    case GET_BRANCH_FLOOR_SETUP_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        floorSetUpBranches: action.payload.branches,
      });
    case GET_BRANCH_FLOOR_SETUP_ERROR:
      return Object.assign({}, state, {
        loading: false,
        floorSetUpBranches: [],
      });



    case GET_BRANCH_LOYALTY_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        selectedRestaurantLoyalty: action.payload
      });
    case GET_BRANCH_LOYALTY_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        loyaltyBranches: action.payload.branches,
      });
    case GET_BRANCH_LOYALTY_ERROR:
      return Object.assign({}, state, {
        loading: false,
        loyaltyBranches: [],
      });

    case GET_BRANCH_TABLE_ORDERING_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        selectedRestaurantTableOrdering: action.payload
      });
    case GET_BRANCH_TABLE_ORDERING_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        tableOrderingBranches: action.payload.branches,
      });
    case GET_BRANCH_TABLE_ORDERING_ERROR:
      return Object.assign({}, state, {
        loading: false,
        tableOrderingBranches: [],
      });

    case GET_BRANCH_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        selectedRestaurant: action.payload
      });
    case GET_BRANCH_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        branches: action.payload.branches,
      });
    case GET_BRANCH_ERROR:
      return Object.assign({}, state, {
        loading: false,
        branches: [],
      });

    case ADD_BRANCH_STARTED:
      return Object.assign({}, state, {
        addLoading: true,
      });

    case ADD_BRANCH_END:
      return Object.assign({}, state, {
        addLoading: false,
      });

    case EDIT_BRANCH_STARTED:
      return Object.assign({}, state, {
        editLoading: true,
      });

    case EDIT_BRANCH_END:
      return Object.assign({}, state, {
        editLoading: false,
      });

    case DELETE_BRANCH_STARTED:
      return Object.assign({}, state, {
        deleteLoading: true,
      });

    case DELETE_BRANCH_END:
      return Object.assign({}, state, {
        deleteLoading: false,
      });

    case SET_SELECTED_BRANCH:
      return Object.assign({}, state, {
        selectedBranch: action.data,
      });

    case CLEAR_ALL_BRANCH:
      return initialState

    default:
      return state;
  }
}

export default branch;
