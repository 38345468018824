import {
  GET_CATEGORY_REQUEST,
  GET_CATEGORY_SUCCESS,
  GET_CATEGORY_ERROR,
  CLEAR_ALL_CATEGORY,
  ADD_CATEGORY_STARTED,
  ADD_CATEGORY_END,
  EDIT_CATEGORY_STARTED,
  EDIT_CATEGORY_END,
  DELETE_CATEGORY_STARTED,
  DELETE_CATEGORY_END,
  SET_CATEGORY_POPUP,
  CLEAR_CATEGORY_LOADING,
  BULK_UPDATE_CATEGORY_STARTED,
  BULK_UPDATE_CATEGORY_ENDED,
  IMPORT_CATEGORIES_STARTED,
  IMPORT_CATEGORIES_ENDED,
  SET_IMPORT_DATA
} from '../actions/CategoryActions'

const initialState = {
  importLoading: false,
  importData: null,
  loading: false,
  bulkLoading: false,
  categories: [],
  categoryPopup: false,
  addLoading: false,
  editLoading: false,
  deleteLoading: false,
  selectedrestaurant: ''
}

function category(state = initialState, action) {
  switch (action.type) {
    case SET_IMPORT_DATA:
      return Object.assign({}, state, {
        importData: action.payload
      })
    case IMPORT_CATEGORIES_ENDED:
      return Object.assign({}, state, {
        importLoading: false
      })
    case IMPORT_CATEGORIES_STARTED:
      return Object.assign({}, state, {
        importLoading: true
      })
    case BULK_UPDATE_CATEGORY_ENDED:
      return Object.assign({}, state, {
        bulkLoading: false
      })
    case BULK_UPDATE_CATEGORY_STARTED:
      return Object.assign({}, state, {
        bulkLoading: true
      })
    case CLEAR_CATEGORY_LOADING:
      return Object.assign({}, state, {
        loading: false,
        addLoading: false,
        editLoading: false,
        deleteLoading: false,
      })
    case GET_CATEGORY_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        selectedRestaurant: action.payload
      })
    case GET_CATEGORY_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        categories: action.payload.categories,
      })
    case GET_CATEGORY_ERROR:
      return Object.assign({}, state, {
        loading: false,
        categories: [],
      })

    case ADD_CATEGORY_STARTED:
      return Object.assign({}, state, {
        addLoading: true,
      })

    case ADD_CATEGORY_END:
      return Object.assign({}, state, {
        addLoading: false,
      })

    case EDIT_CATEGORY_STARTED:
      return Object.assign({}, state, {
        editLoading: true,
      })

    case EDIT_CATEGORY_END:
      return Object.assign({}, state, {
        editLoading: false,
      })

    case DELETE_CATEGORY_STARTED:
      return Object.assign({}, state, {
        deleteLoading: true,
      })

    case DELETE_CATEGORY_END:
      return Object.assign({}, state, {
        deleteLoading: false,
      })

    case SET_CATEGORY_POPUP:
      return Object.assign({}, state, {
        categoryPopup: action.payload,
      })

    case CLEAR_ALL_CATEGORY:
      return initialState

    default:
      return state
  }
}

export default category
