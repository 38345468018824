import { GET_GLOBALCONFIG_REQUEST, GET_GLOBALCONFIG_SUCCESS, GET_GLOBALCONFIG_ERROR, CLEAR_GLOBALCONFIG } from '../actions/GlobalActions'

const initialState = {
  loading: false,
  countries: [],
  currencies: [],
  categories: [],
}

function globalConfig(state = initialState, action) {
  switch (action.type) {
    case GET_GLOBALCONFIG_REQUEST:
      return Object.assign({}, state, {
        loading: true,
      })
    case GET_GLOBALCONFIG_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        countries: action.payload.countries,
        currencies: action.payload.currencies,
        categories: action.payload.categories,
        deliveryZoneTypes: action.payload.deliveryZoneTypes,
      })
    case GET_GLOBALCONFIG_ERROR:
      return Object.assign({}, state, {
        loading: false,
        restaurants: [],
      })
    case CLEAR_GLOBALCONFIG:
      return initialState
    default:
      return state
  }
}

export default globalConfig
