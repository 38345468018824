import { editDirectMessage } from "../../config/config";
import {
    GET_DIRECT_MESSAGES_REQUEST,
    GET_DIRECT_MESSAGES_SUCCESS,
    GET_DIRECT_MESSAGES_ERROR,

    GET_DIRECT_MESSAGE_REQUEST,
    GET_DIRECT_MESSAGE_SUCCESS,
    GET_DIRECT_MESSAGE_ERROR,

    ADD_DIRECT_MESSAGE_STARTED,
    ADD_DIRECT_MESSAGE_ENDED,

    EDIT_DIRECT_MESSAGE_STARTED,
    EDIT_DIRECT_MESSAGE_ENDED,

    DELETE_DIRECT_MESSAGE_STARTED,
    DELETE_DIRECT_MESSAGE_ENDED,

    SET_EDIT_DIRECT_MESSAGE,

    CLEAR_LOADING_DM,
    CLEAR_ALL_DM
} from "../actions/DirectMessagesActions";

const initialState = {
    loading: false,
    addLoading: false,
    editLoading: false,
    deleteLoading: false,
    getSingleLoading: false,
    directMessages: [],
    directMessage: null,
    editDirectMessage: null
}

function directMessages(state = initialState, action) {
    switch (action.type) {
        case SET_EDIT_DIRECT_MESSAGE:
            return Object.assign({}, state, {
                editDirectMessage: action.payload
            })
        case CLEAR_ALL_DM:
            return initialState
        case CLEAR_LOADING_DM:
            return Object.assign({}, state, {
                loading: false,
                addLoading: false,
                editLoading: false,
                deleteLoading: false,
                getSingleLoading: false,
            })
        case DELETE_DIRECT_MESSAGE_ENDED:
            return Object.assign({}, state, {
                deleteLoading: false
            })
        case DELETE_DIRECT_MESSAGE_STARTED:
            return Object.assign({}, state, {
                deleteLoading: true
            })
        case EDIT_DIRECT_MESSAGE_ENDED:
            return Object.assign({}, state, {
                editLoading: false
            })
        case EDIT_DIRECT_MESSAGE_STARTED:
            return Object.assign({}, state, {
                editLoading: true
            })
        case ADD_DIRECT_MESSAGE_ENDED:
            return Object.assign({}, state, {
                addLoading: false
            })
        case ADD_DIRECT_MESSAGE_STARTED:
            return Object.assign({}, state, {
                addLoading: true
            })
        case GET_DIRECT_MESSAGE_ERROR:
            return Object.assign({}, state, {
                getSingleLoading: false,
                directMessage: null
            })
        case GET_DIRECT_MESSAGE_SUCCESS:
            return Object.assign({}, state, {
                getSingleLoading: false,
                directMessage: action.payload
            })
        case GET_DIRECT_MESSAGE_REQUEST:
            return Object.assign({}, state, {
                getSingleLoading: true
            })
        case GET_DIRECT_MESSAGES_ERROR:
            return Object.assign({}, state, {
                loading: false,
                directMessages: []
            })
        case GET_DIRECT_MESSAGES_SUCCESS:
            return Object.assign({}, state, {
                loading: false,
                directMessages: action.payload
            })
        case GET_DIRECT_MESSAGES_REQUEST:
            return Object.assign({}, state, {
                loading: true
            })
        default:
            return state
    }
}
export default directMessages