import { getIngredients, addIngredient, editIngredient, deleteIngredient, bulkUpdateIngredients } from '../../config/config'
import { toast } from 'react-toastify'
import { parseError } from '../../utils/parseError'
import { LOGOUT_USER, SET_PERMISSIONS } from './AuthActions'
import { handleError } from '../../utils/utils'

export const GET_INGREDIENT_REQUEST = 'GET_INGREDIENT_REQUEST'
export const GET_INGREDIENT_SUCCESS = 'GET_INGREDIENT_SUCCESS'
export const GET_INGREDIENT_ERROR = 'GET_INGREDIENT_ERROR'

export const ADD_INGREDIENT_STARTED = 'ADD_STARTED'
export const ADD_INGREDIENT_END = 'ADD_END'

export const EDIT_INGREDIENT_STARTED = 'EDIT_STARTED'
export const EDIT_INGREDIENT_END = 'EDIT_END'

export const DELETE_INGREDIENT_STARTED = 'DELETE_STARTED'
export const DELETE_INGREDIENT_END = 'DELETE_END'

export const SET_INGREDIENT_POPUP = 'SET_INGREDIENT_POPUP'
export const CLEAR_ALL_INGREDIENT = 'CLEAR_ALL'

export const BULK_UPDATE_INGREDIENTS_STARTED = 'BULK_UPDATE_INGREDIENTS_STARTED'
export const BULK_UPDATE_INGREDIENTS_ENDED = 'BULK_UPDATE_INGREDIENTS_ENDED'

export const CLEAR_INGREDIENT_LOADING = 'CLEAR_INGREDIENT_LOADING'

const actions = {
  bulkUpdateIngredients: (data, reset, setIsEditing) => async (dispatch) => {
    // dispatch({
    //   type: BULK_UPDATE_INGREDIENTS_STARTED
    // })
    // await bulkUpdateIngredients(data).then((response) => {
    //   if (response.status === 200) {
    //     toast.success(response.data.message || 'BULK UPDATE INGREDIENTS SUCCESS')
    //     dispatch({
    //       type: GET_INGREDIENT_SUCCESS,
    //       payload: { ingredients: response.data.allIngredientsData }
    //     })
    //     setIsEditing(false)
    //   } else {
    //     reset()
    //     handleError(dispatch)(response, null, 'BULK UPDATE INGREDIENTS ERROR')
    //   }
    // })
    // dispatch({
    //   type: BULK_UPDATE_INGREDIENTS_ENDED
    // })
    dispatch({
      type: BULK_UPDATE_INGREDIENTS_STARTED
    });
    let success = true
    let message = 'EDIT BULK INGREDIENTS SUCCESS'
    try {
      for (const item of data) {
        const response = await bulkUpdateIngredients({ ingredients: item });

        if (response.status === 200) {
          message = response?.data?.message || 'EDIT BULK INGREDIENTS SUCCESS'
          //  toast.success(response?.data?.message || 'EDIT BULK PRODUCTS SUCCESS');
          dispatch({
            type: GET_INGREDIENT_SUCCESS,
            payload: {
              ingredients: response?.data?.allIngredientsData
            }
          });
        } else {
          success = false
          handleError(dispatch)(response, null, 'EDIT BULK ERROR');
        }
      }
      if (success) {
        toast.success(message)
        setIsEditing(false);
      } else reset()
    } catch (error) {
      console.error(error);
      toast.error('An unexpected error occurred during the bulk edit.');
    } finally {
      dispatch({
        type: BULK_UPDATE_INGREDIENTS_ENDED
      });
    }
  },
  getIngredients: () => async (dispatch, getState) => {
    if (getState().ingredient.ingredients.length === 0)
      dispatch({
        type: GET_INGREDIENT_REQUEST,
      })
    await getIngredients().then((res) => {
      if (res.status === 200) {
        dispatch({
          type: GET_INGREDIENT_SUCCESS,
          payload: {
            ingredients: res?.data?.ingredients,
          },
        })
      } else {
        handleError(dispatch)(res, GET_INGREDIENT_ERROR, 'GET INGREDIENT ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Get All Ingredients error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
        // dispatch({
        //   type: GET_INGREDIENT_ERROR,
        //   payload: {
        //     message: parseError(res?.data?.error) || res?.data?.message || 'GET Ingredient error',
        //   },
        // })
      }
    })
  },

  addIngredient: (data) => async (dispatch) => {
    dispatch({
      type: ADD_INGREDIENT_STARTED,
    })
    await addIngredient(data).then((res) => {
      if (res.status === 200) {
        toast.success(res?.data?.message || 'Successfully Saved')
        dispatch({
          type: GET_INGREDIENT_SUCCESS,
          payload: {
            ingredients: res?.data?.ingredients,
          },
        })
        dispatch({
          type: SET_INGREDIENT_POPUP,
          payload: false,
        })
      } else {
        handleError(dispatch)(res, null, 'ADD INGREDIENT ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Add Ingredient error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
      }
    })
    dispatch({
      type: ADD_INGREDIENT_END,
    })
  },

  editIngredient: (uuid, data, setSelectedInfo) => async (dispatch) => {
    dispatch({
      type: EDIT_INGREDIENT_STARTED,
    })
    await editIngredient(uuid, data).then((res) => {
      if (res.status === 200) {
        toast.success(res?.data?.message || 'Successfully Saved')
        dispatch({
          type: GET_INGREDIENT_SUCCESS,
          payload: {
            ingredients: res?.data?.ingredients,
          },
        })
        dispatch({
          type: SET_INGREDIENT_POPUP,
          payload: false,
        })
        setSelectedInfo({})
      } else {
        handleError(dispatch)(res, null, 'EDIT INGREDIENT ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Edit Ingredient error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
      }
    })
    dispatch({
      type: EDIT_INGREDIENT_END,
    })
  },

  deleteIngredient: (uuid) => async (dispatch) => {
    dispatch({
      type: DELETE_INGREDIENT_STARTED,
    })
    await deleteIngredient(uuid).then((res) => {
      if (res.status === 200) {
        toast.success(res?.data?.message || 'Successfully Deleted')
        dispatch({
          type: GET_INGREDIENT_SUCCESS,
          payload: {
            ingredients: res?.data?.ingredients,
          },
        })
        dispatch({
          type: SET_INGREDIENT_POPUP,
          payload: false,
        })
      } else {
        handleError(dispatch)(res, null, 'DELETE INGREDIENT ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Delete Ingredient Error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
      }
    })
    dispatch({
      type: DELETE_INGREDIENT_END,
    })
  },

  setActionModal: (state) => async (dispatch) => {
    dispatch({
      type: SET_INGREDIENT_POPUP,
      payload: state,
    })
  },

  // CLEAR ALL Reducer
  clearAll: () => (dispatch) => {
    dispatch({
      type: CLEAR_ALL_INGREDIENT,
    })
  },
}

export default actions
