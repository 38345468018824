import {
    getRates,
    getRate,
    addRate,
    editRate,
    deleteRate,
    generateRates
} from "../../config/config";

import { toast } from "react-toastify";
import { handleError } from "../../utils/utils";

export const GET_RATES_REQUEST = 'GET_RATES_REQUEST'
export const GET_RATES_SUCCESS = 'GET_RATES_SUCCESS'
export const GET_RATES_ERROR = 'GET_RATES_ERROR'

export const GET_RATE_REQUEST = 'GET_RATE_REQUEST'
export const GET_RATE_SUCCESS = 'GET_RATE_SUCCESS'
export const GET_RATE_ERROR = 'GET_RATE_ERROR'

export const ADD_RATE_STARTED = 'ADD_RATE_STARTED'
export const ADD_RATE_ENDED = 'ADD_RATE_ENDED'

export const EDIT_RATE_STARTED = 'EDIT_RATE_STARTED'
export const EDIT_RATE_ENDED = 'EDIT_RATE_ENDED'

export const DELETE_RATE_STARTED = 'DELETE_RATE_STARTED'
export const DELETE_RATE_ENDED = 'DELETE_RATE_ENDED'

export const GENERATE_CURRENCY_STARTED = 'GENERATE_CURRENCY_STARTED'
export const GENERATE_CURRENCY_ENDED = 'GENERATE_CURRENCY_ENDED'

export const CLEAR_RATES = 'CLEAR_RATES'
export const CLEAR_RATES_LOADING = 'CLEAR_RATES_LOADING'

const actions = {
    generateCurrency: (ruuid) => async (dispatch) => {
        dispatch({
            type: GENERATE_CURRENCY_STARTED
        })
        await generateRates(ruuid).then((response) => {
            if (response.status !== 200)
                handleError(dispatch)(response, null, 'ERROR GENERATING CURRENCIES')
        })
        dispatch({
            type: GENERATE_CURRENCY_STARTED
        })
    },
    getRates: (ruuid) => async (dispatch) => {
        dispatch({
            type: GET_RATES_REQUEST
        })
        await getRates(ruuid).then((response) => {
            if (response.status === 200) {
                dispatch({
                    type: GET_RATES_SUCCESS,
                    payload: response.data.rates
                })
            } else handleError(dispatch)(response, GET_RATES_ERROR, 'GET RATES ERROR')
        })
    },
    getRate: (ruuid, uuid) => async (dispatch) => {
        dispatch({
            type: GET_RATE_REQUEST
        })
        await getRate(ruuid, uuid).then((response) => {
            if (response.status === 200) {
                dispatch({
                    type: GET_RATE_SUCCESS,
                    payload: response.data.rate
                })
            } else handleError(dispatch)(response, GET_RATE_ERROR, 'GET RATE ERROR')
        })
    },
    addRate: (ruuid, data, onClose) => async (dispatch) => {
        dispatch({
            type: ADD_RATE_STARTED
        })
        await addRate(ruuid, data).then((response) => {
            if (response.status === 200) {
                toast.success(response.data.message || 'ADD RATE SUCCESS')
                onClose()
                dispatch({
                    type: GET_RATES_SUCCESS,
                    payload: response.data.rates
                })
            } else handleError(dispatch)(response, null, 'ADD RATE ERROR')
        })
        dispatch({
            type: ADD_RATE_ENDED
        })
    },
    editRate: (ruuid, uuid, data, onClose) => async (dispatch) => {
        dispatch({
            type: EDIT_RATE_STARTED
        })
        await editRate(ruuid, uuid, data).then((response) => {
            if (response.status === 200) {
                toast.success(response.data.message || "EDIT RATE SUCCESS")
                onClose()
                dispatch({
                    type: GET_RATES_SUCCESS,
                    payload: response.data.rates
                })
            } else handleError(dispatch)(response, null, 'EDIT RATE ERROR')
        })
        dispatch({
            type: EDIT_RATE_ENDED
        })
    },
    deleteRate: (ruuid, uuid, close) => async (dispatch) => {
        dispatch({
            type: DELETE_RATE_STARTED
        })
        await deleteRate(ruuid, uuid).then((response) => {
            if (response.status === 200) {
                toast.success(response.data.message || "DELETE RATE SUCCESS")
                close()
                dispatch({
                    type: GET_RATES_SUCCESS,
                    payload: response.data.rates
                })
            } else handleError(dispatch)(response, null, "DELETE RATE ERROR")
        })
        dispatch({
            type: DELETE_RATE_ENDED
        })
    }
}
export default actions