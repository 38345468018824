import moment from 'moment'
import {
    getTopSpenders,
    getAvgOrderPrice,
    getLateOrders,
    getTotalOrders,
    getNewCustomers,
    getCanceledOrders,
    getSalesByZone,
    // getTotalCustomers,
    getSalesByAffiliate,
    getSignupsByAffiliate,
    getTotalDiscounts,
    getTotalPromoDiscounts,
    getTopXItemsQty,
    getTopXItemsAmt,
    getFeedbacksAndComplaints,
    getFeedbacksRating,
    getNotActiveCustomers,
    getTotalRevenuePerMonth,
    getNewProduct,
    getCustomerCountValue,
    getCustomerCountOrder,
    getQrCount,
    getNewProductsList,
    getFeedbacksSummary,
    getBirthdayCount,
    getDeliveryStatus,
    getNotSoldItems,
    getSalesByRestaurant,
    getSalesByBranch,
    getAffiliateClicks,
    getOrdersStatus,
    getTopItemsBranch,
    getAvgOrders,
    getSalesByCategory,
    getSalesByCustomer,
    getAvgPrepTime,
    getAvgDeliveryTime,
    getRestaurantsCurrency,
    setConsolidatedCurrency,
    getConsolidatedCurrency,
    getCardsPositioning,
    setCardsPositioning
} from '../../config/config'
import { handleError } from '../../utils/utils'
import { toast } from 'react-toastify'
export const SET_CARDS_POSITIONING_STARTED = 'SET_CARDS_POSITIONING_STARTED'
export const SET_CARDS_POSITIONING_ENDED = 'SET_CARDS_POSITIONING_ENDED'

export const GET_CARDS_POSITIONING_REQUEST = 'GET_CARDS_POSITIONING_REQUEST'
export const GET_CARDS_POSITIONING_SUCCESS = 'GET_CARDS_POSITIONING_SUCCESS'
export const GET_CARDS_POSITIONING_ERROR = 'GET_CARDS_POSITIONING_ERROR'

export const SET_PRODUCT_LAYOUT = 'SET_PRODUCT_LAYOUT'
export const SET_TODAY_LAYOUT = 'SET_TODAY_LAYOUT'
export const SET_TRANSACTIONS_LAYOUT = 'SET_TRANSACTIONS_LAYOUT'
export const SET_CUSTOMERS_LAYOUT = 'SET_CUSTOMERS_LAYOUT'

export const GET_DASHBOARD_REQUEST = 'GET_DASHBOARD_REQUEST'
export const GET_DASHBOARD_SUCCESS = 'GET_DASHBOARD_SUCCESS'
export const GET_DASHBOARD_ERROR = 'GET_DASHBOARD_ERROR'

export const GET_RESTAURANTS_CURRENCY_REQUEST = 'GET_RESTAURANTS_CURRENCY_REQUEST'
export const GET_RESTAURANTS_CURRENCY_SUCCESS = 'GET_RESTAURANTS_CURRENCY_SUCCESS'
export const GET_RESTAURANTS_CURRENCY_ERROR = 'GET_RESTAURANTS_CURRENCY_ERROR'

export const GET_CONSOLIDATED_CURRENCY_REQUEST = 'GET_CONSOLIDATED_CURRENCY_REQUEST'
export const GET_CONSOLIDATED_CURRENCY_SUCCESS = 'GET_CONSOLIDATED_CURRENCY_SUCCESS'
export const GET_CONSOLIDATED_CURRENCY_ERROR = 'GET_CONSOLIDATED_CURRENCY_ERROR'

export const SET_CONSOLIDATED_CURRENCY_STARTED = 'SET_CONSOLIDATED_CURRENCY_STARTED'
export const SET_CONSOLIDATED_CURRENCY_ENDED = 'SET_CONSOLIDATED_CURRENCY_ENDED'

export const GET_DASHBOARD_TRANSACTION_REQUEST = 'GET_DASHBOARD_TRANSACTION_REQUEST'
export const GET_DASHBOARD_TRANSACTION_SUCCESS = 'GET_DASHBOARD_TRANSACTION_SUCCESS'
export const GET_DASHBOARD_TRANSACTION_ERROR = 'GET_DASHBOARD_TRANSACTION_ERROR'

export const GET_DASHBOARD_PRODUCTS_REQUEST = 'GET_DASHBOARD_PRODUCTS_REQUEST'
export const GET_DASHBOARD_PRODUCTS_SUCCESS = 'GET_DASHBOARD_PRODUCTS_SUCCESS'
export const GET_DASHBOARD_PRODUCTS_ERROR = 'GET_DASHBOARD_PRODUCTS_ERROR'

export const GET_DASHBOARD_CUSTOMERS_REQUEST = 'GET_DASHBOARD_CUSTOMERS_REQUEST'
export const GET_DASHBOARD_CUSTOMERS_SUCCESS = 'GET_DASHBOARD_CUSTOMERS_SUCCESS'
export const GET_DASHBOARD_CUSTOMERS_ERROR = 'GET_DASHBOARD_CUSTOMERS_ERROR'

export const GET_DASHBOARD_TOTAL_REVENUE_REQUEST = 'GET_DASHBOARD_TOTAL_REVENUE_REQUEST'
export const GET_DASHBOARD_TOTAL_REVENUE_SUCCESS = 'GET_DASHBOARD_TOTAL_REVENUE_SUCCESS'
export const GET_DASHBOARD_TOTAL_REVENUE_ERROR = 'GET_DASHBOARD_TOTAL_REVENUE_ERROR'

export const GET_DASHBOARD_ORDERS_VALUE_REQUEST = 'GET_DASHBOARD_ORDERS_VALUE_REQUEST'
export const GET_DASHBOARD_ORDERS_VALUE_SUCCESS = 'GET_DASHBOARD_ORDERS_VALUE_SUCCESS'
export const GET_DASHBOARD_ORDERS_VALUE_ERROR = 'GET_DASHBOARD_ORDERS_VALUE_ERROR'

export const GET_DASHBOARD_ORDERS_COUNT_REQUEST = 'GET_DASHBOARD_ORDERS_COUNT_REQUEST'
export const GET_DASHBOARD_ORDERS_COUNT_SUCCESS = 'GET_DASHBOARD_ORDERS_COUNT_SUCCESS'
export const GET_DASHBOARD_ORDERS_COUNT_ERROR = 'GET_DASHBOARD_ORDERS_COUNT_ERROR'

export const GET_DASHBOARD_NEW_PRODUCTS_REQUEST = 'GET_DASHBOARD_NEW_PRODUCTS_REQUEST'
export const GET_DASHBOARD_NEW_PRODUCTS_SUCCESS = 'GET_DASHBOARD_NEW_PRODUCTS_SUCCESS'
export const GET_DASHBOARD_NEW_PRODUCTS_ERROR = 'GET_DASHBOARD_NEW_PRODUCTS_ERROR'

export const GET_DASHBOARD_TOTAL_REVENUE_TRANSACTIONS_REQUEST = 'GET_DASHBOARD_TOTAL_REVENUE_TRANSACTIONS_REQUEST'
export const GET_DASHBOARD_TOTAL_REVENUE_TRANSACTIONS_SUCCESS = 'GET_DASHBOARD_TOTAL_REVENUE_TRANSACTIONS_SUCCESS'
export const GET_DASHBOARD_TOTAL_REVENUE_TRANSACTIONS_ERROR = 'GET_DASHBOARD_TOTAL_REVENUE_TRANSACTIONS_ERROR'

export const SET_DASHBOARD_REST = 'SET_DASHBOARD_REST'

export const CLEAR_DASHBOARD_LOADING = 'CLEAR_DASHBOARD_LOADING'

const possibleKeys = [
    //'buuid',
    // 'fromDate',
    // 'toDate',
    // 'key',
    'limit',
    'order',
    'years',
    'amount',
    'count',
]

const keys = {
    notSoldItems: [
        'fromDate',
        'toDate',
        'key',
        'buuid'
    ],
    deliveryStatus: [
        'fromDate',
        'toDate',
        'key',
        'buuid'
    ],
    topSpenders: [
        'fromDate',
        'toDate',
        'buuid',
        'key'
    ],
    avgOrderPrice: [
        'fromDate',
        'toDate',
        'buuid',
        'key'
    ],
    lateOrders: [
        'fromDate',
        'toDate',
        'buuid',
        'key'
    ],
    totalOrders: [
        'fromDate',
        'toDate',
        'buuid',
        'key'
    ],
    newCustomers: [
        'fromDate',
        'toDate',
        'key'
    ],
    canceledOrders: [
        'fromDate',
        'toDate',
        'buuid',
        'key'
    ],
    salesByZone: [
        'fromDate',
        'toDate',
        'buuid',
        'key'
    ],
    salesByAffiliate: [],
    signupsByAffiliate: [],
    totalDiscounts: [
        'fromDate',
        'toDate',
        'buuid',
        'key'
    ],
    totalPromoDiscounts: [
        'fromDate',
        'toDate',
        'buuid'
    ],
    topXItems: [
        'fromDate',
        'toDate',
        'buuid',
        'limit',
        'key',
        'order'
    ],
    topXItemsAmt: [
        'fromDate',
        'toDate',
        'buuid',
        'limit',
        'key',
        'order'
    ],
    notActiveCustomers: [
        'fromDate',
        'toDate',
        'key',
        'buuid'
    ],
    feedbacksAndComplaints: [
        'fromDate',
        'toDate',
        'key',
        'limit',
        'buuid'
    ],
    feedbacksRating: [
        'fromDate',
        'toDate',
        'buuid',
        'key'
    ],
    totalRevenueMonth: [
        'key',
        'years',
        'buuid'
    ],
    newProducts: [
        'fromDate',
        'toDate'
    ],
    customerCountValue: [
        'fromDate',
        'toDate',
        'key',
        'amount',
        'buuid'
    ],
    customerCountOrder: [
        'fromDate',
        'toDate',
        'key',
        'count',
        'buuid'
    ],
    qrScans: [
        'fromDate',
        'toDate',
        'buuid',
        'key'
    ],
    newProductsList: [
        'fromDate',
        'toDate',
        'buuid'
    ],
    feedbacksSummary: [
        'fromDate',
        'toDate',
        'limit',
        'key',
        'buuid'
    ],
    birthdayCount: [
        'fromDate',
        'toDate'
    ],
    salesByRestaurant: [
        'fromDate',
        'toDate'
    ],
    salesByBranch: [
        'fromDate',
        'toDate'
    ],
    ordersStatus: [
        'fromDate',
        'toDate',
        'buuid'
    ],
    topItemsBranch: [
        'fromDate',
        'toDate',
        'limit',
        'buuid'
    ],
    avgOrders: [
        'fromDate',
        'toDate',
        'buuid'
    ],
    salesByCategory: [
        'fromDate',
        'toDate'
    ],
    salesByCustomer: [
        'fromDate',
        'toDate'
    ],
    avgPrepTime: [
        'fromDate',
        'toDate',
        'buuid',
        'key'
    ],
    avgDelvTime: [
        'fromDate',
        'toDate',
        'buuid',
        'key'
    ]
}

const getFilterString = (
    queryKeys,
    selectedBranches,
    filter,
    activeSection,
    topItemsLimit,
    topItemsAmtLimit,
    feedbacksAndComplaintsLimit,
    feedbacksSummaryLimit,
    years,
    amount,
    count,
    thisYear,
    forcedKey,
    order,
    topItemsBranchLimit
) => {
    let targetQueries = keys[queryKeys];
    if (!targetQueries) return '';

    let filterString = '?';
    let queryParts = [];

    targetQueries.forEach((key) => {
        switch (key) {
            case 'fromDate':
                queryParts.push(
                    !thisYear
                        ? `fromDate=${filter.period.start.format('YYYY-MM-DD')}`
                        : `fromDate=${moment().startOf('year').format('YYYY-MM-DD')}`
                );
                break;

            case 'toDate':
                queryParts.push(
                    !thisYear
                        ? `toDate=${filter.period.end.format('YYYY-MM-DD')}`
                        : `toDate=${moment().endOf('year').format('YYYY-MM-DD')}`
                );
                break;

            case 'buuid':
                if (selectedBranches.length > 0) {
                    selectedBranches.forEach((br) => {
                        queryParts.push(`buuid=${br}`);
                    });
                }
                break;

            case 'key':
                if (forcedKey)
                    queryParts.push(`key=${forcedKey}`);
                else
                    queryParts.push(
                        `key=${activeSection === 'today' ? 'not-closed' : 'closed'}`
                    );

                break;

            case 'limit':
                if (queryKeys === 'topXItems' && topItemsLimit) {
                    queryParts.push(`limit=${topItemsLimit}`);
                }
                if (queryKeys === 'topXItemsAmt' && topItemsAmtLimit) {
                    queryParts.push(`limit=${topItemsAmtLimit}`);
                }
                if (queryKeys === 'feedbacksAndComplaints' && feedbacksAndComplaintsLimit) {
                    queryParts.push(`limit=${feedbacksAndComplaintsLimit}`);
                }
                if (queryKeys === 'feedbacksSummary' && feedbacksSummaryLimit) {
                    queryParts.push(`limit=${feedbacksSummaryLimit}`);
                }
                if (queryKeys === 'topItemsBranch' && topItemsBranchLimit) {
                    queryParts.push(`limit=${topItemsBranchLimit}`);
                }
                break;

            case 'order':
                if (order) queryParts.push(`order=${order}`);
                break;

            case 'years':
                if (years?.length > 0) {
                    years.forEach((year) => {
                        queryParts.push(`years=${year}`);
                    });
                }
                break;

            case 'amount':
                queryParts.push(`amount=${amount}`);
                break;

            case 'count':
                queryParts.push(`count=${count}`);
                break;

            default:
                break;
        }
    });

    filterString += queryParts.join('&');
    return filterString;
};

function getMinMax(card, key, defaultObj) {
    let final = defaultObj[key]?.find((card2) => card2.i === card.i)
    return {
        minW: final.minW,
        minH: final.minH,
        maxW: final.maxW,
        maxH: final.maxH
    }
}

function checkArrays(obj, defaultObj) {
    const defaultCards = defaultObj.lg.map((card) => card.i)
    // Check if any of the arrays inside the object is empty
    const hasEmptyArray = Object.values(obj).some(arr => Array.isArray(arr) && arr.length === 0);

    // If any array is empty, return null
    if (hasEmptyArray) {
        return defaultObj;
    }
    let final = Object.fromEntries(
        Object.keys(obj).map((key) => [
            key,
            // obj[key].map((card) => {
            //     let tmp = getMinMax(card, key, defaultObj);
            //     return { ...card, ...tmp };
            // })
            defaultCards.map((dfCard) => {
                let target = obj[key].find((card) => card.i === dfCard)
                if (target) {
                    let tmp = getMinMax(target, key, defaultObj);
                    return { ...target, ...tmp };
                } else {
                    return defaultObj[key].find((card) => card.i === dfCard)
                }
            })
        ])
    );

    // Otherwise, return the object as is
    return final;
}

const actions = {
    getCardsPositioning: () => async (dispatch) => {
        dispatch({
            type: GET_CARDS_POSITIONING_REQUEST
        })
        await getCardsPositioning().then((response) => {
            if (response.status === 200) {
                dispatch({
                    type: SET_CUSTOMERS_LAYOUT,
                    payload: checkArrays(response.data.dashboardData.customersCards, response.data.default.customersCards)
                })
                dispatch({
                    type: SET_PRODUCT_LAYOUT,
                    payload: checkArrays(response.data.dashboardData.productCards, response.data.default.productCards)
                })
                dispatch({
                    type: SET_TODAY_LAYOUT,
                    payload: checkArrays(response.data.dashboardData.todayCards, response.data.default.todayCards)
                })
                dispatch({
                    type: SET_TRANSACTIONS_LAYOUT,
                    payload: checkArrays(response.data.dashboardData.transactionCards, response.data.default.transactionCards)
                })
                dispatch({
                    type: GET_CARDS_POSITIONING_SUCCESS
                })
            } else handleError(dispatch)(response, GET_CARDS_POSITIONING_ERROR, 'GET CARDS POSITIONING ERROR')
        })
    },
    setCardsPositioning: (data) => async (dispatch) => {
        try {
            dispatch({
                type: SET_CARDS_POSITIONING_STARTED
            });

            const response = await setCardsPositioning(data); // Wait for setCardsPositioning to complete

            if (response.status === 200) {
                toast.success(response.data.message || 'DASHBOARD SAVED');
                dispatch({
                    type: GET_CARDS_POSITIONING_REQUEST
                });

                const getResponse = await getCardsPositioning(); // Wait for getCardsPositioning to complete

                if (getResponse.status === 200) {
                    dispatch({
                        type: SET_CUSTOMERS_LAYOUT,
                        payload: checkArrays(getResponse.data.dashboardData.customersCards, getResponse.data.default.customersCards)
                    })
                    dispatch({
                        type: SET_PRODUCT_LAYOUT,
                        payload: checkArrays(getResponse.data.dashboardData.productCards, getResponse.data.default.productCards)
                    })
                    dispatch({
                        type: SET_TODAY_LAYOUT,
                        payload: checkArrays(getResponse.data.dashboardData.todayCards, getResponse.data.default.todayCards)
                    })
                    dispatch({
                        type: SET_TRANSACTIONS_LAYOUT,
                        payload: checkArrays(getResponse.data.dashboardData.transactionCards, getResponse.data.default.transactionCards)
                    })
                    dispatch({
                        type: GET_CARDS_POSITIONING_SUCCESS
                    })
                } else {
                    handleError(dispatch)(getResponse, GET_CARDS_POSITIONING_ERROR, 'GET CARDS POSITIONING ERROR');
                }
            } else {
                handleError(dispatch)(response, null, 'SET DASHBOARD ERROR');
            }
        } catch (error) {
            console.log(error)
            //handleError(dispatch)(error, SET_CARDS_POSITIONING_ENDED, 'An unexpected error occurred');
        } finally {
            dispatch({
                type: SET_CARDS_POSITIONING_ENDED
            });
        }
    },
    setConsolidatedCurrency: (data, onClose) => async (dispatch) => {
        dispatch({
            type: SET_CONSOLIDATED_CURRENCY_STARTED
        })
        await setConsolidatedCurrency(data).then((response) => {
            if (response.status === 200) {
                toast.success('UPDATE CONSOLIDATED CURRENCY SUCCESS')
                dispatch({
                    type: GET_CONSOLIDATED_CURRENCY_SUCCESS,
                    payload: response.data.message
                })
                onClose()
            } else handleError(dispatch)(response, null, 'UPDATE CONSOLIDATED CURRENCY ERROR')
        })
    },
    getConsolidatedCurrency: () => async (dispatch) => {
        dispatch({
            type: GET_CONSOLIDATED_CURRENCY_REQUEST
        })
        await getConsolidatedCurrency().then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_CONSOLIDATED_CURRENCY_SUCCESS,
                    payload: response.data.message
                })
            else handleError(dispatch)(response, GET_CONSOLIDATED_CURRENCY_ERROR, 'GET CONSOLIDATED CURRENCY ERROR')
        })
    },
    getRestaurantsCurrency: () => async (dispatch) => {
        dispatch({
            type: GET_RESTAURANTS_CURRENCY_REQUEST
        })
        await getRestaurantsCurrency().then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_RESTAURANTS_CURRENCY_SUCCESS,
                    payload: response.data.currencies
                })
            else handleError(dispatch)(response, GET_RESTAURANTS_CURRENCY_ERROR, 'GET RESTAURANTS CURRENCY ERROR')
        })
    },
    setDashboardRest: (ruuid) => (dispatch) => {
        dispatch({
            type: SET_DASHBOARD_REST,
            payload: ruuid
        })
    },
    getNewProductsList: (ruuid, filterString) => async (dispatch) => {
        dispatch({
            type: GET_DASHBOARD_NEW_PRODUCTS_REQUEST
        })
        await getNewProductsList(ruuid, filterString).then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_DASHBOARD_NEW_PRODUCTS_SUCCESS,
                    payload: response.data.newProducts
                })
            else handleError(dispatch)(response, GET_DASHBOARD_NEW_PRODUCTS_ERROR, 'GET DASHBOARD NEW PRODUCTS ERROR')
        })
    },
    getOrdersCountGreater: (ruuid, filterString, ordersBreakPoint) => async (dispatch) => {
        dispatch({
            type: GET_DASHBOARD_ORDERS_COUNT_REQUEST
        })
        await getCustomerCountOrder(ruuid, filterString += ordersBreakPoint).then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_DASHBOARD_ORDERS_COUNT_SUCCESS,
                    payload: response.data.customersCount
                })
            else handleError(dispatch)(response, GET_DASHBOARD_ORDERS_COUNT_ERROR, 'GET DASHBOARD ORDERS COUNT ERROR')
        })
    },
    getOrdersValue: (ruuid, filterString, valueBreakpoint) => async (dispatch) => {
        dispatch({
            type: GET_DASHBOARD_ORDERS_VALUE_REQUEST
        })
        await getCustomerCountValue(ruuid, filterString += valueBreakpoint).then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_DASHBOARD_ORDERS_VALUE_SUCCESS,
                    payload: response.data.customersCount
                })
            else handleError(dispatch)(response, GET_DASHBOARD_ORDERS_VALUE_ERROR, 'GET DASHBOARD ORDERS VALUE ERROR')
        })
    },
    getDashboardTotalRevenueTransactions: (ruuid, filterString) => async (dispatch) => {
        dispatch({
            type: GET_DASHBOARD_TOTAL_REVENUE_TRANSACTIONS_REQUEST
        })
        await getTotalRevenuePerMonth(ruuid, filterString).then((response) => {
            if (response.status === 200) {
                dispatch({
                    type: GET_DASHBOARD_TOTAL_REVENUE_TRANSACTIONS_SUCCESS,
                    payload: response.data.revenueByMonthYear
                })
            } else handleError(dispatch)(response, GET_DASHBOARD_TOTAL_REVENUE_TRANSACTIONS_ERROR, 'GET  TOTAL NRVEENUE ERROR')
        })
    },
    getDashboardTotalRevenue: (ruuid, filterString) => async (dispatch) => {
        dispatch({
            type: GET_DASHBOARD_TOTAL_REVENUE_REQUEST
        })
        await getTotalRevenuePerMonth(ruuid, filterString).then((response) => {
            if (response.status === 200) {
                dispatch({
                    type: GET_DASHBOARD_TOTAL_REVENUE_SUCCESS,
                    payload: response.data.revenueByMonthYear
                })
            } else handleError(dispatch)(response, GET_DASHBOARD_TOTAL_REVENUE_ERROR, 'GET TOTAL REVENUE ERROR')
        })
    },
    getDashboard: (
        ruuid,
        selectedBranches,
        filter,
        activeSection,
        topItemsLimit,
        topItemsAmtLimit,
        feedbacksAndComplaintsLimit,
        feedbacksSummaryLimit,
        years,
        amount,
        count,
        selectedCurrency
    ) => async (dispatch) => {
        dispatch({
            type: GET_DASHBOARD_REQUEST
        });

        try {
            const [
                topSpendersResponse,
                avgOrderPriceResponse,
                avgOrderPricePeriodResponse,
                lateOrdersResponse,
                totalOrdersResponse,
                newCustomersResponse,
                canceledOrdersResponse,
                salesByZoneResponse,
                salesByAffiliateResponse,
                signupsByAffiliateResponse,
                totalDiscountsResponse,
                totalPromoDiscountsResponse,
                topXItemsQtyResponse,
                leastXItemsQtyResponse,
                topXItemsAmountResponse,
                leastXItemsAmountResponse,
                feedbacksAndComplaintsResponse,
                feedbacksRatingResponse,
                notActiveCustomersResponse,
                totalRevenuePerMonthResponse,
                newProductResponse,
                customerCountValueResponse,
                customersCountResponse,
                totalOrdersByYearResponse,
                qrCountResponse,
                feedbacksSummaryPositiveResponse,
                feedbacksSummaryNegativeResponse,
                birthdayCountResponse,
                deliveriesStatusResponse,
                notSoldItemsResponse,
                ordersStatusResponse,
                avgDeliveryTimeResponse,
                avgPrepTimeResponse
            ] = await Promise.all([
                getTopSpenders(ruuid, getFilterString(
                    'topSpenders', selectedBranches, filter, activeSection
                ), selectedCurrency),
                getAvgOrderPrice(ruuid, getFilterString(
                    'avgOrderPrice', selectedBranches, filter, activeSection, null, null, null, null, null, null, null, true
                ), selectedCurrency),
                getAvgOrderPrice(ruuid, getFilterString(
                    'avgOrderPrice', selectedBranches, filter, activeSection
                ), selectedCurrency),
                getLateOrders(ruuid, getFilterString(
                    'lateOrders', selectedBranches, filter, activeSection
                ), selectedCurrency),
                getTotalOrders(ruuid, getFilterString(
                    'totalOrders', selectedBranches, filter, activeSection
                ), selectedCurrency),
                getNewCustomers(null, getFilterString(
                    'newCustomers', null, filter, activeSection
                ), selectedCurrency),
                getCanceledOrders(ruuid, getFilterString(
                    'canceledOrders', selectedBranches, filter, activeSection
                ), selectedCurrency),
                getSalesByZone(ruuid, getFilterString(
                    'salesByZone', selectedBranches, filter, activeSection
                ), selectedCurrency),
                getSalesByAffiliate(),
                getSignupsByAffiliate(),
                getTotalDiscounts(ruuid, getFilterString(
                    'totalDiscounts', selectedBranches, filter, activeSection
                ), selectedCurrency),
                getTotalPromoDiscounts(ruuid, getFilterString(
                    'totalPromoDiscounts', selectedBranches, filter
                ), selectedCurrency),
                getTopXItemsQty(ruuid, getFilterString(
                    'topXItems', selectedBranches, filter, activeSection, topItemsLimit, null, null, null, null, null, null, null, null, 'DESC'
                ), selectedCurrency),
                getTopXItemsQty(ruuid, getFilterString(
                    'topXItems', selectedBranches, filter, activeSection, topItemsLimit, null, null, null, null, null, null, null, null, 'ASC'
                ), selectedCurrency),
                getTopXItemsAmt(ruuid, getFilterString(
                    'topXItemsAmt', selectedBranches, filter, activeSection, null, topItemsAmtLimit, null, null, null, null, null, null, null, 'ASC'
                ), selectedCurrency),
                getTopXItemsAmt(ruuid, getFilterString(
                    'topXItemsAmt', selectedBranches, filter, activeSection, null, topItemsAmtLimit, null, null, null, null, null, null, null, 'DESC'
                ), selectedCurrency),
                getFeedbacksAndComplaints(ruuid, getFilterString(
                    'feedbacksAndComplaints', selectedBranches, filter, activeSection, null, null, feedbacksAndComplaintsLimit, null, null, null, null, null, 'complaint'
                ), selectedCurrency),
                getFeedbacksRating(ruuid, getFilterString(
                    'feedbacksRating', selectedBranches, filter, activeSection
                ), selectedCurrency),
                getNotActiveCustomers(ruuid, getFilterString(
                    'notActiveCustomers', selectedBranches, filter, activeSection
                ), selectedCurrency),
                getTotalRevenuePerMonth(ruuid, getFilterString(
                    'totalRevenueMonth', selectedBranches, null, activeSection, null, null, null, null, years
                ), selectedCurrency),
                getNewProduct(ruuid, getFilterString(
                    'newProducts', [], filter
                ), selectedCurrency),
                getCustomerCountValue(ruuid, getFilterString(
                    'customerCountValue', selectedBranches, filter, activeSection, null, null, null, null, null, amount
                ), selectedCurrency),
                getCustomerCountOrder(ruuid, getFilterString(
                    'customerCountOrder', selectedBranches, filter, activeSection, null, null, null, null, null, null, count
                ), selectedCurrency),
                getTotalOrders(ruuid, getFilterString(
                    'totalOrders', selectedBranches, filter, activeSection, null, null, null, null, null, null, null, true
                ), selectedCurrency),
                getQrCount(ruuid, getFilterString(
                    'qrScans', selectedBranches, filter, activeSection
                ), selectedCurrency),
                getFeedbacksSummary(ruuid, getFilterString(
                    'feedbacksSummary', selectedBranches, filter, activeSection, null, null, null, feedbacksSummaryLimit, null, null, null, null, 'feedback'
                ), selectedCurrency),
                getFeedbacksSummary(ruuid, getFilterString(
                    'feedbacksSummary', selectedBranches, filter, activeSection, null, null, null, feedbacksSummaryLimit, null, null, null, null, 'complaint'
                ), selectedCurrency),
                getBirthdayCount(ruuid, getFilterString(
                    'birthdayCount', [], filter
                ), selectedCurrency),
                getDeliveryStatus(ruuid, getFilterString(
                    'deliveryStatus', selectedBranches, filter, activeSection
                ), selectedCurrency),
                getNotSoldItems(ruuid, getFilterString(
                    'notSoldItems', selectedBranches, filter, activeSection
                ), selectedCurrency),
                getOrdersStatus(ruuid, getFilterString(
                    'ordersStatus', selectedBranches, filter
                ), selectedCurrency),
                getAvgDeliveryTime(ruuid, getFilterString(
                    'avgDelvTime', selectedBranches, filter, activeSection
                ), selectedCurrency),
                getAvgPrepTime(ruuid, getFilterString(
                    'avgPrepTime', selectedBranches, filter, activeSection
                ), selectedCurrency)
            ]);

            const [
                topSpenders,
                avgOrderPrice,
                avgOrderPricePeriod,
                lateOrders,
                totalOrders,
                newCustomers,
                canceledOrders,
                salesByZone,
                salesByAffiliate,
                signupsByAffiliate,
                totalDiscounts,
                totalPromoDiscounts,
                topXItemsQty,
                leastXItemsQty,
                topXItemsAmount,
                leastXItemsAmount,
                feedbacksAndComplaints,
                feedbacksRating,
                notActiveCustomers,
                totalRevenuePerMonth,
                newProduct,
                customerCountValue,
                customersCount,
                totalOrdersByYear,
                qrCount,
                feedbacksSummaryPositive,
                feedbacksSummaryNegative,
                birthdayCount,
                deliveriesStatus,
                notSoldItems,
                ordersStatus,
                avgDeliveryTime,
                avgPrepTime
            ] = await Promise.all([
                topSpendersResponse,
                avgOrderPriceResponse,
                avgOrderPricePeriodResponse,
                lateOrdersResponse,
                totalOrdersResponse,
                newCustomersResponse,
                canceledOrdersResponse,
                salesByZoneResponse,
                salesByAffiliateResponse,
                signupsByAffiliateResponse,
                totalDiscountsResponse,
                totalPromoDiscountsResponse,
                topXItemsQtyResponse,
                leastXItemsQtyResponse,
                topXItemsAmountResponse,
                leastXItemsAmountResponse,
                feedbacksAndComplaintsResponse,
                feedbacksRatingResponse,
                notActiveCustomersResponse,
                totalRevenuePerMonthResponse,
                newProductResponse,
                customerCountValueResponse,
                customersCountResponse,
                totalOrdersByYearResponse,
                qrCountResponse,
                feedbacksSummaryPositiveResponse,
                feedbacksSummaryNegativeResponse,
                birthdayCountResponse,
                deliveriesStatusResponse,
                notSoldItemsResponse,
                ordersStatusResponse,
                avgDeliveryTimeResponse,
                avgPrepTimeResponse
            ])

            const data = {
                topSpenders: topSpenders.data.topSpenders,
                avgOrderPrice: avgOrderPrice.data.avgBilledPrice,
                avgOrderPricePeriod: avgOrderPricePeriod.data.avgBilledPrice,
                lateOrders: lateOrders.data.lateOrders,
                totalOrders: totalOrders.data.orderCounts,
                newCustomers: newCustomers.data,
                canceledOrders: canceledOrders.data.canceledOrders,
                salesByZone: salesByZone.data,
                salesByAffiliate: salesByAffiliate.data.affiliateLinkSalesSummary,
                signupsByAffiliate: signupsByAffiliate.data.affiliateLinkSignUpsSummary,
                totalDiscounts: totalDiscounts.data.discounts,
                totalPromoDiscounts: totalPromoDiscounts.data.discounts,
                topXItemsQty: topXItemsQty.data.topItems,
                leastXItemsQty: leastXItemsQty.data.topItems,
                topXItemsAmount: topXItemsAmount.data.topItems,
                leastXItemsAmount: leastXItemsAmount.data.topItems,
                feedbacksAndComplaints: feedbacksAndComplaints.data.feedbacks,
                feedbacksRating: feedbacksRating.data,
                notActiveCustomers: notActiveCustomers.data.notActiveCustomersCount,
                totalRevenuePerMonth: totalRevenuePerMonth.data.revenueByMonthYear,
                totalRevenuePerMonthTransactions: totalRevenuePerMonth.data.revenueByMonthYear,
                newProduct: newProduct.data.newProductCount,
                customerCountValue: customerCountValue.data.customersCount,
                customersCount: customersCount.data.customersCount,
                totalOrdersByYear: totalOrdersByYear.data.orderCounts,
                qrCount: qrCount.data.qrCount,
                feedbacksSummaryPositive: feedbacksSummaryPositive.data.feedbacks,
                feedbacksSummaryNegative: feedbacksSummaryNegative.data.feedbacks,
                birthdayCount: birthdayCount.data.birthdayCount,
                deliveriesStatus: deliveriesStatus.data,
                notSoldItems: notSoldItems.data.notSoldItems,
                ordersStatus: ordersStatus.data.totalOrders,
                avgDeliveryTime: avgDeliveryTime.data.avg,
                avgPrepTime: avgPrepTime.data.avg
            };

            dispatch({
                type: GET_DASHBOARD_SUCCESS,
                payload: data
            });
        } catch (e) {
            dispatch({
                type: GET_DASHBOARD_ERROR
            });
            console.error(e);
        }
    },
    getDashboardTransactions: (
        ruuid,
        selectedBranches,
        filter,
        activeSection,
        topItemsLimit,
        years,
        selectedCurrency
    ) => async (dispatch) => {
        dispatch({
            type: GET_DASHBOARD_TRANSACTION_REQUEST
        })
        try {
            const [
                topSpendersResponse,
                avgOrderPriceResponse,
                avgOrderPricePeriodResponse,
                lateOrdersResponse,
                totalOrdersResponse,
                canceledOrdersResponse,
                salesByZoneResponse,
                salesByAffiliateResponse,
                signupsByAffiliateResponse,
                totalDiscountsResponse,
                totalPromoDiscountsResponse,
                topXItemsQtyResponse,
                totalRevenuePerMonthResponse,
                totalOrdersByYearResponse,
                qrCountResponse,
                deliveriesStatusResponse,
                salesByRestaurantResponse,
                salesByBranchResponse,
                affiliateClicksResponse,
                avgOrdersResponse,
                avgOrdersYearResponse,
                salesByCategoryResponse,
                salesByCustomerResponse,
                avgDeliveryTimeResponse,
                avgPrepTimeResponse
            ] = await Promise.all([
                getTopSpenders(ruuid, getFilterString(
                    'topSpenders',
                    selectedBranches,
                    filter,
                    activeSection,
                ), selectedCurrency),
                getAvgOrderPrice(ruuid, getFilterString(
                    'avgOrderPrice',
                    selectedBranches,
                    filter,
                    activeSection,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    true,
                ), selectedCurrency),
                getAvgOrderPrice(ruuid, getFilterString(
                    'avgOrderPrice',
                    selectedBranches,
                    filter,
                    activeSection,
                ), selectedCurrency),
                getLateOrders(ruuid, getFilterString(
                    'lateOrders',
                    selectedBranches,
                    filter,
                    activeSection,
                ), selectedCurrency),
                getTotalOrders(ruuid, getFilterString(
                    'totalOrders',
                    selectedBranches,
                    filter,
                    activeSection,
                ), selectedCurrency),
                getCanceledOrders(ruuid, getFilterString(
                    'canceledOrders',
                    selectedBranches,
                    filter,
                    activeSection,
                ), selectedCurrency),
                getSalesByZone(ruuid, getFilterString(
                    'salesByZone',
                    selectedBranches,
                    filter,
                    activeSection,
                ), selectedCurrency),
                // getTotalCustomers(),
                getSalesByAffiliate(),
                getSignupsByAffiliate(),
                getTotalDiscounts(ruuid, getFilterString(
                    'totalDiscounts',
                    selectedBranches,
                    filter,
                    activeSection
                ), selectedCurrency),
                getTotalPromoDiscounts(ruuid, getFilterString(
                    'totalPromoDiscounts',
                    selectedBranches,
                    filter,
                ), selectedCurrency),
                getTopXItemsQty(ruuid, getFilterString(
                    'topXItems',
                    selectedBranches,
                    filter,
                    activeSection,
                    topItemsLimit,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    'DESC'
                ), selectedCurrency),
                getTotalRevenuePerMonth(ruuid, getFilterString(
                    'totalRevenueMonth',
                    selectedBranches,
                    null,
                    activeSection,
                    null,
                    null,
                    null,
                    null,
                    years,
                ), selectedCurrency),
                getTotalOrders(ruuid, getFilterString(
                    'totalOrders',
                    selectedBranches,
                    filter,
                    activeSection,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    true,
                ), selectedCurrency),
                getQrCount(ruuid, getFilterString(
                    'qrScans',
                    selectedBranches,
                    filter,
                    activeSection
                ), selectedCurrency),
                getDeliveryStatus(ruuid, getFilterString(
                    'deliveryStatus',
                    selectedBranches,
                    filter,
                    activeSection,
                ), selectedCurrency),
                getSalesByRestaurant(getFilterString(
                    'salesByRestaurant',
                    null,
                    filter,
                ), selectedCurrency),
                getSalesByBranch(getFilterString(
                    'salesByBranch',
                    null,
                    filter,
                ), selectedCurrency),
                getAffiliateClicks(),
                getAvgOrders(ruuid, getFilterString(
                    'avgOrders',
                    selectedBranches,
                    filter
                ), selectedCurrency),
                getAvgOrders(ruuid, getFilterString(
                    'avgOrders',
                    selectedBranches,
                    filter,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    true
                ), selectedCurrency),
                getSalesByCategory(ruuid, getFilterString(
                    'salesByCategory',
                    null,
                    filter
                ), selectedCurrency),
                getSalesByCustomer(ruuid, getFilterString(
                    'salesByCategory',
                    null,
                    filter
                ), selectedCurrency),
                getAvgDeliveryTime(ruuid, getFilterString(
                    'avgDelvTime',
                    selectedBranches,
                    filter,
                    activeSection
                ), selectedCurrency),
                getAvgPrepTime(ruuid, getFilterString(
                    'avgPrepTime',
                    selectedBranches,
                    filter,
                    activeSection
                ), selectedCurrency)
            ])

            const [
                topSpenders,
                avgOrderPrice,
                avgOrderPricePeriod,
                lateOrders,
                totalOrders,
                canceledOrders,
                salesByZone,
                salesByAffiliate,
                signupsByAffiliate,
                totalDiscounts,
                totalPromoDiscounts,
                topXItemsQty,
                totalRevenuePerMonth,
                totalOrdersByYear,
                qrCount,
                deliveriesStatus,
                salesByRestaurant,
                salesByBranch,
                affiliateClicks,
                avgOrders,
                avgOrdersYear,
                salesByCategory,
                salesByCustomer,
                avgDeliveryTime,
                avgPrepTime
            ] = await Promise.all([
                topSpendersResponse,
                avgOrderPriceResponse,
                avgOrderPricePeriodResponse,
                lateOrdersResponse,
                totalOrdersResponse,
                canceledOrdersResponse,
                salesByZoneResponse,
                salesByAffiliateResponse,
                signupsByAffiliateResponse,
                totalDiscountsResponse,
                totalPromoDiscountsResponse,
                topXItemsQtyResponse,
                totalRevenuePerMonthResponse,
                totalOrdersByYearResponse,
                qrCountResponse,
                deliveriesStatusResponse,
                salesByRestaurantResponse,
                salesByBranchResponse,
                affiliateClicksResponse,
                avgOrdersResponse,
                avgOrdersYearResponse,
                salesByCategoryResponse,
                salesByCustomerResponse,
                avgDeliveryTimeResponse,
                avgPrepTimeResponse
            ])

            const data = {
                topSpenders: topSpenders.data.topSpenders,
                avgOrderPrice: avgOrderPrice.data.avgBilledPrice,
                avgOrderPricePeriod: avgOrderPricePeriod.data.avgBilledPrice,
                lateOrders: lateOrders.data.lateOrders,
                totalOrders: totalOrders.data.orderCounts,
                canceledOrders: canceledOrders.data.canceledOrders,
                // totalCustomers: totalCustomers.data.totalCustomers,
                salesByZone: salesByZone.data,
                salesByAffiliate: salesByAffiliate.data.affiliateLinkSalesSummary,
                signupsByAffiliate: signupsByAffiliate.data.affiliateLinkSignUpsSummary,
                //NEW
                totalDiscounts: totalDiscounts.data.discounts,
                totalPromoDiscounts: totalPromoDiscounts.data.discounts,
                topXItemsQty: topXItemsQty.data.topItems,
                totalRevenuePerMonth: totalRevenuePerMonth.data.revenueByMonthYear,
                totalRevenuePerMonthTransactions: totalRevenuePerMonth.data.revenueByMonthYear,
                totalOrdersByYear: totalOrdersByYear.data.orderCounts,
                qrCount: qrCount.data.qrCount,
                deliveriesStatus: deliveriesStatus.data,
                salesByRestaurant: salesByRestaurant.data.restaurant,
                salesByBranch: salesByBranch.data.branch,
                affiliateClicks: affiliateClicks.data.affiliateLinks,
                avgOrders: avgOrders.data.avg,
                avgOrdersYear: avgOrdersYear.data.avg,
                salesByCategory: salesByCategory.data.salesByCategory,
                salesByCustomer: salesByCustomer.data.customers,
                avgDeliveryTime: avgDeliveryTime.data.avg,
                avgPrepTime: avgPrepTime.data.avg
            }
            if (
                topSpenders.status !== 200 ||
                avgOrderPrice.status !== 200 ||
                avgOrderPricePeriod.status !== 200 ||
                lateOrders.status !== 200 ||
                totalOrders.status !== 200 ||
                canceledOrders.status !== 200 ||
                salesByZone.status !== 200 ||
                salesByAffiliate.status !== 200 ||
                signupsByAffiliate.status !== 200 ||
                totalDiscounts.status !== 200 ||
                totalPromoDiscounts.status !== 200 ||
                topXItemsQty.status !== 200 ||
                totalRevenuePerMonth.status !== 200 ||
                totalOrdersByYear.status !== 200 ||
                qrCount.status !== 200 ||
                deliveriesStatus.status !== 200 ||
                salesByRestaurant.status !== 200 ||
                salesByBranch.status !== 200 ||
                affiliateClicks.status !== 200 ||
                avgOrders.status !== 200 ||
                avgOrdersYear.status !== 200 ||
                salesByCategory.status !== 200 ||
                salesByCustomer.status !== 200 ||
                avgDeliveryTime.status !== 200 ||
                avgPrepTime.status !== 200
            ) {
                dispatch({
                    type: GET_DASHBOARD_TRANSACTION_ERROR
                })
            }
            else
                dispatch({
                    type: GET_DASHBOARD_TRANSACTION_SUCCESS,
                    payload: data
                })
        } catch (e) {
            dispatch({
                type: GET_DASHBOARD_ERROR
            })
        }
    },
    getDashboardProducts: (
        ruuid,
        selectedBranches,
        filter,
        activeSection,
        topItemsLimit,
        topItemsAmtLimit,
        years,
        selectedCurrency
    ) => async (dispatch) => {
        dispatch({
            type: GET_DASHBOARD_PRODUCTS_REQUEST
        })
        try {
            const [
                topXItemsQtyResponse,
                leastXItemsQtyResponse,
                topXItemsAmountResponse,
                leastXItemsAmountResponse,
                totalRevenuePerMonthResponse,
                newProductResponse,
                totalOrdersByYearResponse,
                notSoldItemsResponse,
                topItemsBranchResponse
            ] = await Promise.all([
                // getTotalCustomers(),
                getTopXItemsQty(ruuid, getFilterString(
                    'topXItems',
                    selectedBranches,
                    filter,
                    activeSection,
                    topItemsLimit,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    'DESC'
                ), selectedCurrency),
                getTopXItemsQty(ruuid, getFilterString(
                    'topXItems',
                    selectedBranches,
                    filter,
                    activeSection,
                    topItemsLimit,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    'ASC'
                ), selectedCurrency),
                getTopXItemsAmt(ruuid, getFilterString(
                    'topXItemsAmt',
                    selectedBranches,
                    filter,
                    activeSection,
                    null,
                    topItemsAmtLimit,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    'ASC'
                ), selectedCurrency),
                getTopXItemsAmt(ruuid, getFilterString(
                    'topXItemsAmt',
                    selectedBranches,
                    filter,
                    activeSection,
                    null,
                    topItemsAmtLimit,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    'DESC'
                ), selectedCurrency),
                getTotalRevenuePerMonth(ruuid, getFilterString(
                    'totalRevenueMonth',
                    selectedBranches,
                    null,
                    activeSection,
                    null,
                    null,
                    null,
                    null,
                    years,
                ), selectedCurrency),
                getNewProduct(ruuid, getFilterString(
                    'newProducts',
                    [],
                    filter,
                ), selectedCurrency),
                getTotalOrders(ruuid, getFilterString(
                    'totalOrders',
                    selectedBranches,
                    filter,
                    activeSection,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    true,
                ), selectedCurrency),
                getNotSoldItems(ruuid, getFilterString(
                    'notSoldItems',
                    selectedBranches,
                    filter,
                    activeSection,
                ), selectedCurrency),
                getTopItemsBranch(ruuid, getFilterString(
                    'topItemsBranch',
                    selectedBranches,
                    filter,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    5
                ), selectedCurrency)
            ])
            const [
                topXItemsQty,
                leastXItemsQty,
                topXItemsAmount,
                leastXItemsAmount,
                totalRevenuePerMonth,
                newProduct,
                totalOrdersByYear,
                notSoldItems,
                topItemsBranch
            ] = await Promise.all([
                topXItemsQtyResponse,
                leastXItemsQtyResponse,
                topXItemsAmountResponse,
                leastXItemsAmountResponse,
                totalRevenuePerMonthResponse,
                newProductResponse,
                totalOrdersByYearResponse,
                notSoldItemsResponse,
                topItemsBranchResponse
            ])
            const data = {
                //NEW
                topXItemsQty: topXItemsQty.data.topItems,
                topXItemsAmount: topXItemsAmount.data.topItems,
                totalRevenuePerMonth: totalRevenuePerMonth.data.revenueByMonthYear,
                totalRevenuePerMonthTransactions: totalRevenuePerMonth.data.revenueByMonthYear,
                newProduct: newProduct.data.newProductCount,
                leastXItemsQty: leastXItemsQty.data.topItems,
                leastXItemsAmount: leastXItemsAmount.data.topItems,
                totalOrdersByYear: totalOrdersByYear.data.orderCounts,
                notSoldItems: notSoldItems.data.notSoldItems,
                topItemsBranch: topItemsBranch.data.topItemsByBranch
            }
            if (
                topXItemsQty.status !== 200 ||
                leastXItemsQty.status !== 200 ||
                topXItemsAmount.status !== 200 ||
                leastXItemsAmount.status !== 200 ||
                totalRevenuePerMonth.status !== 200 ||
                newProduct.status !== 200 ||
                totalOrdersByYear.status !== 200 ||
                notSoldItems.status !== 200 ||
                topItemsBranch.status !== 200
            ) {
                dispatch({
                    type: GET_DASHBOARD_PRODUCTS_ERROR
                })
            }
            else
                dispatch({
                    type: GET_DASHBOARD_PRODUCTS_SUCCESS,
                    payload: data
                })
        } catch (e) {
            dispatch({
                type: GET_DASHBOARD_PRODUCTS_ERROR
            })
        }
    },
    getDashboardCustomers: (
        ruuid,
        selectedBranches,
        filter,
        activeSection,
        feedbacksAndComplaintsLimit,
        feedbacksSummaryLimit,
        amount,
        count,
        selectedCurrency
    ) => async (dispatch) => {
        dispatch({
            type: GET_DASHBOARD_CUSTOMERS_REQUEST
        })
        try {
            const [
                newCustomersResponse,
                feedbacksAndComplaintsResponse,
                feedbacksRatingResponse,
                notActiveCustomersResponse,
                customerCountValueResponse,
                customersCountResponse,
                feedbacksSummaryPositiveResponse,
                feedbacksSummaryNegativeResponse,
                birthdayCountResponse,
                avgOrdersResponse,
                salesByCustomerResponse
            ] = await Promise.all([
                getNewCustomers(null, getFilterString(
                    'newCustomers',
                    null,
                    filter,
                    activeSection
                ), selectedCurrency),
                // getTotalCustomers(),
                getFeedbacksAndComplaints(ruuid, getFilterString(
                    'feedbacksAndComplaints',
                    selectedBranches,
                    filter,
                    activeSection,
                    null,
                    null,
                    feedbacksAndComplaintsLimit,
                    null,
                    null,
                    null,
                    null,
                    null,
                    'complaint',
                ), selectedCurrency),
                getFeedbacksRating(ruuid, getFilterString(
                    'feedbacksRating',
                    selectedBranches,
                    filter,
                    activeSection
                ), selectedCurrency),
                getNotActiveCustomers(ruuid, getFilterString(
                    'notActiveCustomers',
                    selectedBranches,
                    filter,
                    activeSection,
                ), selectedCurrency),
                getCustomerCountValue(ruuid, getFilterString(
                    'customerCountValue',
                    selectedBranches,
                    filter,
                    activeSection,
                    null,
                    null,
                    null,
                    null,
                    null,
                    amount,
                ), selectedCurrency),
                getCustomerCountOrder(ruuid, getFilterString(
                    'customerCountOrder',
                    selectedBranches,
                    filter,
                    activeSection,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    count
                ), selectedCurrency),
                getFeedbacksSummary(ruuid, getFilterString(
                    'feedbacksSummary',
                    selectedBranches,
                    filter,
                    activeSection,
                    null,
                    null,
                    null,
                    feedbacksSummaryLimit,
                    null,
                    null,
                    null,
                    null,
                    'feedback',
                ), selectedCurrency),
                getFeedbacksSummary(ruuid, getFilterString(
                    'feedbacksSummary',
                    selectedBranches,
                    filter,
                    activeSection,
                    null,
                    null,
                    null,
                    feedbacksSummaryLimit,
                    null,
                    null,
                    null,
                    null,
                    'complaint',
                ), selectedCurrency),
                getBirthdayCount(ruuid, getFilterString(
                    'birthdayCount',
                    [],
                    filter,
                ), selectedCurrency),
                getAvgOrders(ruuid, getFilterString(
                    'avgOrders',
                    selectedBranches,
                    filter
                ), selectedCurrency),
                getSalesByCustomer(ruuid, getFilterString(
                    'salesByCategory',
                    null,
                    filter
                ), selectedCurrency)
            ])
            const [
                newCustomers,
                feedbacksAndComplaints,
                feedbacksRating,
                notActiveCustomers,
                customerCountValue,
                customersCount,
                feedbacksSummaryPositive,
                feedbacksSummaryNegative,
                birthdayCount,
                avgOrders,
                salesByCustomer
            ] = await Promise.all([
                newCustomersResponse,
                feedbacksAndComplaintsResponse,
                feedbacksRatingResponse,
                notActiveCustomersResponse,
                customerCountValueResponse,
                customersCountResponse,
                feedbacksSummaryPositiveResponse,
                feedbacksSummaryNegativeResponse,
                birthdayCountResponse,
                avgOrdersResponse,
                salesByCustomerResponse
            ])
            const data = {
                newCustomers: newCustomers.data,
                //NEW
                feedbacksAndComplaints: feedbacksAndComplaints.data.feedbacks,
                feedbacksRating: feedbacksRating.data,
                notActiveCustomers: notActiveCustomers.data.notActiveCustomersCount,
                customerCountValue: customerCountValue.data.customersCount,
                customersCount: customersCount.data.customersCount,
                feedbacksSummaryPositive: feedbacksSummaryPositive.data.feedbacks,
                feedbacksSummaryNegative: feedbacksSummaryNegative.data.feedbacks,
                birthdayCount: birthdayCount.data.birthdayCount,
                avgOrders: avgOrders.data.avg,
                salesByCustomer: salesByCustomer.data.customers
            }
            if (
                newCustomers.status !== 200 ||
                feedbacksAndComplaints.status !== 200 ||
                feedbacksRating.status !== 200 ||
                notActiveCustomers.status !== 200 ||
                avgOrders.status !== 200 ||
                salesByCustomer.status !== 200
            ) {
                dispatch({
                    type: GET_DASHBOARD_CUSTOMERS_ERROR
                })
            }
            else
                dispatch({
                    type: GET_DASHBOARD_CUSTOMERS_SUCCESS,
                    payload: data
                })
        } catch (e) {
            dispatch({
                type: GET_DASHBOARD_CUSTOMERS_ERROR
            })
        }
    },
}
export default actions