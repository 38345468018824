import { getTranslations } from '../../config/config'

import { toast } from 'react-toastify'
import { parseError } from '../../utils/parseError'
import { LOGOUT_USER } from './AuthActions'
import { handleError } from '../../utils/utils'

export const GET_TRANSLATION_REQUEST = 'GET_TRANSLATION_REQUEST'
export const GET_TRANSLATION_SUCCESS = 'GET_TRANSLATION_SUCCESS'
export const GET_TRANSLATION_ERROR = 'GET_TRANSLATION_ERROR'

export const SET_LANGUAGE = 'SET_LANGUAGE'

export const CLEAR_ALL_TRANSLATIONS = 'CLEAR_ALL_TRANSLATIONS'

export const CLEAR_TRANSLATIONS_LOADING = 'CLEAR_TRANSLATIONS_LOADING'

const actions = {
  // Get All translations with the selected one
  getTranslation: () => async (dispatch) => {
    dispatch({
      type: GET_TRANSLATION_REQUEST,
    })
    await getTranslations().then((res) => {
      if (res?.status === 200) {
        dispatch({
          type: GET_TRANSLATION_SUCCESS,
          payload: res?.data,
        })
      } else {
        handleError(dispatch)(res, GET_TRANSLATION_ERROR, 'GET TRANSLATION ERROR')
        // toast.error(res?.data?.message || 'Get All Translation error')
        // dispatch({
        //   type: GET_TRANSLATION_ERROR
        // })
        // if (res.status === 401)
        //   dispatch({
        //     type: LOGOUT_USER,
        //   })
      }
    })
  },
  setLanguage: (data) => (dispatch) => {
    dispatch({
      type: SET_LANGUAGE,
      payload: data
    })
  },
  clearAllTranslations: () => (dispatch) => {
    dispatch({
      type: CLEAR_ALL_TRANSLATIONS
    })
  }
}
export default actions
