import {
    getPlatforms,
    addPlatform,
    editPlatform,
    deletePlatform
} from '../../config/config'

import { toast } from 'react-toastify'
import { LOGOUT_USER, SET_PERMISSIONS } from './AuthActions'
import { handleError } from '../../utils/utils'

export const GET_PLATFORMS_REQUEST = 'GET_PLATFORMS_REQUEST'
export const GET_PLATFORMS_SUCCESS = 'GET_PLATFORMS_SUCCESS'
export const GET_PLATFORMS_ERROR = 'GET_PLATFORMS_ERROR'

export const ADD_PLATFORM_STARTED = 'ADD_PLATFORM_STARTED'
export const ADD_PLATFORM_ENDED = 'ADD_PLATFORM_ENDED'

export const EDIT_PLATFORM_STARTED = 'EDIT_PLATFORM_STARTED'
export const EDIT_PLATFORM_ENDED = 'EDIT_PLATFORM_ENDED'

export const DELETE_PLATFORM_STARTED = 'DELETE_PLATFORM_STARTED'
export const DELETE_PLATFORM_ENDED = 'DELETE_PLATFORM_ENDED'

export const CLEAR_ALL_PLATFORMS = 'CLEAR_ALL_PLATFORMS'

export const CLEAR_PLATFORM_LOADING = 'CLEAR_PLATFORM_LOADING'

const actions = {
    getPlatforms: (ruuid) => async (dispatch) => {
        dispatch({
            type: GET_PLATFORMS_REQUEST
        })
        await getPlatforms(ruuid).then((response) => {
            if (response.status === 200) {
                dispatch({
                    type: GET_PLATFORMS_SUCCESS,
                    payload: { offersPlatforms: response.data.offersPlatforms }
                })
            }
            else {
                handleError(dispatch)(response, GET_PLATFORMS_ERROR, 'GET PLATFORMS ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error(response.data.message || 'Get platforms error')
                // dispatch({
                //     type: GET_PLATFORMS_ERROR
                // })
                // if (response.status === 401)
                //     dispatch({
                //         type: LOGOUT_USER
                //     })
                // if (response.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: response?.data?.permissions
                //     })
                // }
            }
        })
    },
    addPlatform: (ruuid, data) => async (dispatch) => {
        dispatch({
            type: ADD_PLATFORM_STARTED
        })
        await addPlatform(ruuid, data).then((response) => {
            if (response.status === 200) {
                toast.success(response.data.message || 'Add Platform Success')
                dispatch({
                    type: GET_PLATFORMS_SUCCESS,
                    payload: { offersPlatforms: response.data.offersPlatforms }
                })
            }
            else {
                handleError(dispatch)(response, null, 'ADD PLATFORM ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error(response.data.message || 'Add Platform Error')
                // if (response.status === 401)
                //     dispatch({
                //         type: LOGOUT_USER
                //     })
                // if (response.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: response?.data?.permissions
                //     })
                // }
            }
        })
        dispatch({
            type: ADD_PLATFORM_ENDED
        })
    },
    editPlatform: (ruuid, uuid, data) => async (dispatch) => {
        delete data.uuid
        dispatch({
            type: EDIT_PLATFORM_STARTED
        })
        await editPlatform(ruuid, uuid, data).then((response) => {
            if (response.status === 200) {
                toast.success(response.data.message || 'Edit Platform Success')
                dispatch({
                    type: GET_PLATFORMS_SUCCESS,
                    payload: { offersPlatforms: response.data.offersPlatforms }
                })
            }
            else {
                handleError(dispatch)(response, null, 'EDIT PLATFORM ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error(response.data.message || 'Edit Platform Error')
                // if (response.status === 401)
                //     dispatch({
                //         type: LOGOUT_USER
                //     })
                // if (response.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: response?.data?.permissions
                //     })
                // }
            }
        })
        dispatch({
            type: EDIT_PLATFORM_ENDED
        })
    },
    deletePlatform: (ruuid, uuid) => async (dispatch) => {
        dispatch({
            type: DELETE_PLATFORM_STARTED
        })
        await deletePlatform(ruuid, uuid).then((response) => {
            if (response.status === 200) {
                toast.success(response.data.message || 'Delete Platform Success')
                dispatch({
                    type: GET_PLATFORMS_SUCCESS,
                    payload: { offersPlatforms: response.data.offersPlatforms }
                })
            }
            else {
                handleError(dispatch)(response, null, 'DELETE PLATFORM ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error(response.data.message || 'Delete Platform Error')
                // if (response.status === 401)
                //     dispatch({
                //         type: LOGOUT_USER
                //     })
                // if (response.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: response?.data?.permissions
                //     })
                // }
            }
        })
        dispatch({
            type: DELETE_PLATFORM_ENDED
        })
    },
    clearAllPlatforms: () => (dispatch) => {
        dispatch({
            type: CLEAR_ALL_PLATFORMS
        })
    }
}
export default actions
