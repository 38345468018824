import {
    getGallery,
    uploadGallery,
    updateLayout,
    deleteGallery,
    getGalleryCategories,
    getGalleryCategory,
    addGalleryCategory,
    editGalleryCategory,
    deleteGalleryCategory,
} from '../../config/config'
import { toast } from "react-toastify";
import { parseError } from "../../utils/parseError";
import { LOGOUT_USER, SET_PERMISSIONS } from "./AuthActions";
import { handleError } from '../../utils/utils';

export const GET_GALLERY_REQUEST = 'GET_GELLERY_REQUEST'
export const GET_GALLERY_SUCCESS = 'GET_GALLERY_SUCCESS'
export const GET_GALLERY_ERROR = 'GET_GALLERY_ERROR'

export const UPLOAD_GALLERY_STARTED = 'UPLOAD_GALLERY_STARTED'
export const UPLOAD_GALLERY_ENDED = 'UPLOAD_GALLERY_ENDED'

export const UPDATE_LAYOUT_STARTED = 'UPDATE_LAYOUT_STARTED'
export const UPDATE_LAYOUT_ENDED = 'UPDATE_LAYOUT_ENDED'

export const DELETE_GALLERY_STARTED = 'DELETE_GALLERY_STARTED'
export const DELETE_GALLERY_ENDED = 'DELETE_GALLERY_ENDED'

export const GET_GALLERY_CATEGORIES_REQUEST = 'GET_GALLERY_CATEGORIES_REQUEST'
export const GET_GALLERY_CATEGORIES_SUCCESS = 'GET_GALLERY_CATEGORIES_SUCCESS'
export const GET_GALLERY_CATEGORIES_ERROR = 'GET_GALLERY_CATEGORIES_ERROR'

export const GET_GALLERY_CATEGORY_REQUEST = 'GET_GALLERY_CATEGORY_REQUEST'
export const GET_GALLERY_CATEGORY_SUCCESS = 'GET_GALLERY_CATEGORY_SUCCESS'
export const GET_GALLERY_CATEGORY_ERROR = 'GET_GALLERY_CATEGORY_ERROR'

export const ADD_GALLERY_CATEGORY_STARTED = 'ADD_GALLERY_CATEGORY_STARTED'
export const ADD_GALLERY_CATEGORY_ENDED = 'ADD_GALLERY_CATEGORY_ENDED'

export const EDIT_GALLERY_CATEGORY_STARTED = 'EDIT_GALLERY_CATEGORY_STARTED'
export const EDIT_GALLERY_CATEGORY_ENDED = 'EDIT_GALLERY_CATEGORY_ENDED'

export const DELETE_GALLERY_CATEGORY_STARTED = 'DELETE_GALLERY_CATEGORY_STARTED'
export const DELETE_GALLERY_CATEGORY_ENDED = 'DELETE_GALLERY_CATEGORY_ENDED'

export const SET_SELECTED_CATEGORY = 'SET_SELECTED_CATEGORY'

export const CLEAR_ALL_GALLERY = 'CLEAR_ALL_GALLERY'

export const CLEAR_GALLERY_LOADING = 'CLEAR_GALLERY_LOADING'

const actions = {
    getGallery: (ruuid) => async (dispatch, getState) => {
        const currentRestaurant = getState().gallery.selectedRestaurantGallery
        if (ruuid !== currentRestaurant)
            dispatch({
                type: GET_GALLERY_REQUEST,
                payload: ruuid
            })
        await getGallery(ruuid).then((response) => {
            if (response.status === 200) {
                dispatch({
                    type: GET_GALLERY_SUCCESS,
                    payload: {
                        gallery: response?.data?.gallery
                    }
                })
            }
            else {
                handleError(dispatch)(response, GET_GALLERY_ERROR, 'GET GALLERY ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error(response?.data?.message || "Get gallery error")
                // if (response.status === 401)
                //     dispatch({ type: LOGOUT_USER })
                // if (response.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: response?.data?.permissions
                //     })
                // }
                // dispatch({
                //     type: GET_GALLERY_ERROR,
                //     payload: {
                //         message:
                //             parseError(response?.data?.error) ||
                //             response?.data?.message ||
                //             "Get gallery error",
                //     },
                // })
            }
        })
    },
    uploadGallery: (ruuid, catuuid, data) => async (dispatch) => {
        dispatch({
            type: UPLOAD_GALLERY_STARTED
        })
        await uploadGallery(ruuid, catuuid, data).then(async (response) => {
            if (response.status === 200) {
                await getGallery(ruuid).then((res) => {
                    if (response.status === 200) {
                        dispatch({
                            type: GET_GALLERY_SUCCESS,
                            payload: {
                                gallery: res?.data?.gallery
                            }
                        })
                    }
                    else {
                        handleError(dispatch)(response, null, 'UPLOAD GALLERY ERROR')
                        // if (response.status !== 401 && response.status !== 403)
                        //     toast.error(res?.data?.message || "Upload gallery error")
                        // if (response.status === 401)
                        //     dispatch({ type: LOGOUT_USER })
                        // if (response.status === 403)
                        //     dispatch({
                        //         type: SET_PERMISSIONS,
                        //         payload: response?.data?.permissions
                        //     })
                    }
                })
                toast.success(response?.data?.message)
            }
            else {
                handleError(dispatch)(response, null, 'UPLOAD IMAGE GALLERY ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error(response?.data?.message || "Upload image gallery error")
                // if (response.status === 401)
                //     dispatch({ type: LOGOUT_USER })
                // if (response.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: response?.data?.permissions
                //     })
                // }
            }
        })
        dispatch({
            type: UPLOAD_GALLERY_ENDED
        })
    },
    updateLayout: (ruuid, data) => async (dispatch) => {
        dispatch({
            type: UPDATE_LAYOUT_STARTED
        })
        await updateLayout(ruuid, data).then((response) => {
            if (response.status === 200) {
                dispatch({
                    type: GET_GALLERY_SUCCESS,
                    payload: {
                        gallery: response?.data?.gallery
                    }
                })
                toast.success('Layout updated')
            }
            else {
                handleError(dispatch)(response, null, 'UPDATE LAYOUT ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error(response?.data?.message || 'Update layout error')
                // if (response.status === 401)
                //     dispatch({
                //         type: LOGOUT_USER
                //     })
                // if (response.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: response?.data?.permissions
                //     })
                // }
            }
        })
        dispatch({
            type: UPDATE_LAYOUT_ENDED
        })
    },
    deleteGallery: (ruuid, data) => async (dispatch) => {
        dispatch({
            type: DELETE_GALLERY_STARTED
        })
        await deleteGallery(ruuid, data).then(async (response) => {
            if (response.status === 200) {
                await getGallery(ruuid).then((res) => {
                    if (response.status === 200) {
                        dispatch({
                            type: GET_GALLERY_SUCCESS,
                            payload: {
                                gallery: res?.data?.gallery
                            }
                        })
                    }
                    else {
                        handleError(dispatch)(response, null, 'UPLOAD GALLERY ERROR')
                        // if (response.status !== 401 && response.status !== 403)
                        //     toast.error(res?.data?.message || "Upload gallery error")
                        // if (response.status === 401)
                        //     dispatch({ type: LOGOUT_USER })
                        // if (response.status === 403)
                        //     dispatch({
                        //         type: SET_PERMISSIONS,
                        //         payload: response?.data?.permissions
                        //     })
                    }
                })
                toast.success(response?.data?.message)
            }
            else {
                handleError(dispatch)(response, null, 'DELETE GALLERY ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error(response?.data?.message || 'Delete gallery error')
                // if (response.status === 401)
                //     dispatch({
                //         type: LOGOUT_USER
                //     })
                // if (response.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: response?.data?.permissions
                //     })
                // }
            }
        })
        dispatch({
            type: DELETE_GALLERY_ENDED
        })
    },
    getGalleryCategories: (ruuid) => async (dispatch, getState) => {
        const currentRestaurant = getState().gallery.selectedRestaurantCategory
        if (ruuid !== currentRestaurant)
            dispatch({
                type: GET_GALLERY_CATEGORIES_REQUEST,
                payload: ruuid
            })
        await getGalleryCategories(ruuid).then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_GALLERY_CATEGORIES_SUCCESS,
                    payload: {
                        categories: response?.data?.categories
                    }
                })
            else {
                handleError(dispatch)(response, GET_GALLERY_CATEGORIES_ERROR, 'GET GALLERY CATEGORIES ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error(response?.data?.message || 'Get gallery categories error')
                // if (response.status === 401)
                //     dispatch({
                //         type: LOGOUT_USER
                //     })
                // if (response.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: response?.data?.permissions
                //     })
                // }
                // dispatch({
                //     type: GET_GALLERY_CATEGORIES_ERROR,
                //     payload: {
                //         message:
                //             parseError(response?.data?.error) ||
                //             response?.data?.message ||
                //             "Get gallery categories error",
                //     }
                // })
            }
        })

    },
    getGalleryCategory: (ruuid, catuuid) => async (dispatch) => {
        dispatch({
            type: GET_GALLERY_CATEGORY_REQUEST
        })
        await getGalleryCategory(ruuid, catuuid).then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_GALLERY_CATEGORY_SUCCESS,
                    payload: {
                        category: response?.data?.category
                    }
                })
            else {
                handleError(dispatch)(response, GET_GALLERY_CATEGORY_ERROR, 'GET GALLERY CATEGORY ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error(response?.data?.message || 'Get gallery category error')
                // if (response.status === 401)
                //     dispatch({
                //         type: LOGOUT_USER
                //     })
                // if (response.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: response?.data?.permissions
                //     })
                // }
                // dispatch({
                //     type: GET_GALLERY_CATEGORY_ERROR,
                //     payload: {
                //         message:
                //             parseError(response?.data?.error) ||
                //             response?.data?.message ||
                //             "Get gallery category error",
                //     }
                // })
            }
        })

    },
    addGalleryCategory: (ruuid, data) => async (dispatch) => {
        dispatch({
            type: ADD_GALLERY_CATEGORY_STARTED
        })
        await addGalleryCategory(ruuid, data).then((response) => {
            if (response.status === 200) {
                dispatch({
                    type: GET_GALLERY_CATEGORIES_SUCCESS,
                    payload: {
                        categories: response?.data?.categories
                    }
                })
                toast.success(response?.data?.message || 'Category added')
                dispatch({
                    type: SET_SELECTED_CATEGORY,
                    payload: response?.data?.categories[response.data.categories.length - 1]
                })
            }
            else {
                handleError(dispatch)(response, null, 'ADD GALLERY CATEGORY ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error(response?.data?.message || 'Add gallery category error')
                // if (response.status === 401)
                //     dispatch({
                //         type: LOGOUT_USER
                //     })
                // if (response.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: response?.data?.permissions
                //     })
                // }
            }
        })
        dispatch({
            type: ADD_GALLERY_CATEGORY_ENDED
        })
    },
    editGalleryCategory: (ruuid, catuuid, data) => async (dispatch) => {
        dispatch({
            type: EDIT_GALLERY_CATEGORY_STARTED
        })
        await editGalleryCategory(ruuid, catuuid, data).then((response) => {
            if (response.status === 200) {
                dispatch({
                    type: GET_GALLERY_CATEGORIES_SUCCESS,
                    payload: {
                        categories: response?.data?.categories
                    }
                })
                toast.success(response?.data?.message || 'Category saved')
            }
            else {
                handleError(dispatch)(response, null, 'EDIT GALLERY CATEGORY ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error(response?.data?.message || 'Edit gallery category error')
                // if (response.status === 401)
                //     dispatch({
                //         type: LOGOUT_USER
                //     })
                // if (response.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: response?.data?.permissions
                //     })
                // }
            }
        })
        dispatch({
            type: EDIT_GALLERY_CATEGORY_ENDED
        })
    },
    deleteGalleryCategory: (ruuid, catuuid) => async (dispatch) => {
        dispatch({
            type: DELETE_GALLERY_CATEGORY_STARTED
        })
        await deleteGalleryCategory(ruuid, catuuid).then((response) => {
            if (response.status === 200) {
                dispatch({
                    type: GET_GALLERY_CATEGORIES_SUCCESS,
                    payload: {
                        categories: response?.data?.categories
                    }
                })
                toast.success(response?.data?.message || 'Category deleted')
                let temp = []
                if (response?.data?.categories.length === 0)
                    dispatch({
                        type: SET_SELECTED_CATEGORY,
                        payload: {}
                    })
                response?.data?.categories.map((cat) => {
                    temp.push(cat.uuid)
                    if (!temp.includes(catuuid))
                        dispatch({
                            type: SET_SELECTED_CATEGORY,
                            payload: {}
                        })
                })
            }
            else {
                handleError(dispatch)(response, null, 'DELETE GALLERY CATEGORY ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error(response?.data?.message || 'Delete gallery category error')
            }
        })
    },
    setSelectedCategory: (data) => (dispatch) => {
        dispatch({
            type: SET_SELECTED_CATEGORY,
            payload: data
        })
    },
    clearAllGallery: () => (dispatch) => {
        dispatch({
            type: CLEAR_ALL_GALLERY
        })
    }
}
export default actions