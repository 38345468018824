import { getUoms, getUom, addUom, editUom, deleteUom } from '../../config/config'
import { toast } from 'react-toastify'
import { LOGOUT_USER, SET_PERMISSIONS } from './AuthActions'
import { GET_CONVERSIONS_SUCCESS } from './UomConversionsActions'
import { handleError } from '../../utils/utils'

export const GET_UOMS_REQUEST = 'GET_UOMS_REQUEST'
export const GET_UOMS_SUCCESS = 'GET_UOMS_SUCCESS'
export const GET_UOMS_ERROR = 'GET_UOMS_ERROR'

export const ADD_UOM_STARTED = 'ADD_UOM_STARTED'
export const ADD_UOM_END = 'ADD_UOM_END'

export const EDIT_UOM_STARTED = 'EDIT_UOM_STARTED'
export const EDIT_UOM_END = 'EDIT_UOM_END'

export const DELETE_UOM_STARTED = 'DELETE_UOM_STARTED'
export const DELETE_UOM_END = 'DELETE_UOM_END'

export const SET_UOM_POPUP = 'SET_UOM_POPUP'

export const CLEAR_UOM_LOADING = 'CLEAR_UOM_LOADING'
export const CLEAR_ALL_UOM = 'CLEAR_ALL_UOM'

const actions = {
  getUoms: () => async (dispatch) => {
      dispatch({
        type: GET_UOMS_REQUEST,
      })
    await getUoms().then((res) => {
      if (res?.status === 200) {
        dispatch({
          type: GET_UOMS_SUCCESS,
          payload: {
            uoms: res?.data?.uoms,
          },
        })
      }
    })
  },

  addUom: (data) => async (dispatch) => {
    dispatch({
      type: ADD_UOM_STARTED,
    })
    await addUom(data).then((res) => {
      if (res?.status === 200) {
        toast.success(res?.data?.message || 'Successfully Added')
        dispatch({
          type: GET_UOMS_SUCCESS,
          payload: {
            uoms: res?.data?.uoms,
          },
        })
        dispatch({
          type: SET_UOM_POPUP,
          payload: false,
        })
      } else {
        handleError(dispatch)(res, null, 'ADD UOM ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Add UOM error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
      }
    })
    dispatch({
      type: ADD_UOM_END,
    })
  },

  editUom: (uuid, data, setSelectedInfo) => async (dispatch) => {
    dispatch({
      type: EDIT_UOM_STARTED,
    })
    await editUom(uuid, data).then((res) => {
      if (res?.status === 200) {
        console.log(res?.data);
        toast.success(res?.data?.message || 'Successfully Saved')
        dispatch({
          type: GET_UOMS_SUCCESS,
          payload: {
            uoms: res?.data?.uoms,
          },
        })
        dispatch({
          type: SET_UOM_POPUP,
          payload: false,
        })
        setSelectedInfo({})
      } else {
        handleError(dispatch)(res, null, 'EDIT UOM ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Edit UOM error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
      }
    })
    dispatch({
      type: EDIT_UOM_END,
    })
  },

  deleteUom: (uuid) => async (dispatch) => {
    dispatch({
      type: DELETE_UOM_STARTED,
    })
    await deleteUom(uuid).then((res) => {
      if (res?.status === 200) {
        toast.success(res?.data?.message || 'Successfully Deleted')
        dispatch({
          type: GET_CONVERSIONS_SUCCESS,
          payload: {
            conversions: [],
          },
        })
        dispatch({
          type: GET_UOMS_SUCCESS,
          payload: {
            uoms: res?.data?.uoms,
          },
        })
        dispatch({
          type: SET_UOM_POPUP,
          payload: false,
        })
      } else {
        handleError(dispatch)(res, null, 'DELETE UOM ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Delete Uom Error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
      }
    })
    dispatch({
      type: DELETE_UOM_END,
    })
  },

  // SET ACTION MODAL STATE
  setActionModal: (state) => async (dispatch) => {
    dispatch({
      type: SET_UOM_POPUP,
      payload: state,
    })
  },

  // CLEAR ALL Reducer
  clearAll: () => (dispatch) => {
    dispatch({
      type: CLEAR_ALL_UOM,
    })
  },
}

export default actions
