import React, { lazy, useEffect } from 'react'
import LayoutActions from '../../redux/actions/LayoutActions'
import { Switch, withRouter, Route, Redirect } from 'react-router-dom'
import UserRoute from '../../routers/UserRoute'
import { connect } from 'react-redux'
import { Box } from '@mui/system'

const AdminRoles = lazy(() => import('../../pages/roles/AdminRoles'))
const components = {
    adminDashboard: lazy(() => import('../../pages/roles/AdminRoles'))
}

function RolesLayout({
    rolesActiveTab,
    setPathCheck,
    permission,
    reformat
}) {
    const access = reformat(permission.subPage)
    useEffect(() => {
        setPathCheck(permission.route)
    }, [window.location.pathname])
    return (
        <div>
            <Box>
                <Switch>
                    {access.map((permission2, i) =>
                        <UserRoute key={i} exact path={permission2.route} component={components[permission2.key]} permission={permission2} />
                    )}
                    <Redirect from="*" to="/not-found" />
                </Switch>
            </Box>
        </div>
    )
}
const mapStateToProps = (state) => ({
    rolesActiveTab: state.layout.rolesActiveTab
})
const mapDispatchToProps = (dispatch) => ({
    setPathCheck: (data) => dispatch(LayoutActions.setPathCheck(data))
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RolesLayout))