const LinkedProductsArray = [
    {
        id: '124-ps-corn-3',
        brandname: 'Plein Soleil',
        supplierName: "George luchi",
        gram: "0.1",
        gramAvg: "0.10",
        can: "0.1",
        canAvg: "0.20",
        deliveryDuration: "2",
        canWeight: 300,
        cansPerBox:20,
        lastPurchase: "27/11/2023",
        favorite: false,
        upriceonhand: 240,
        upricereq: 10,
        quantity: [
            { qtyType: 'Quantity on Hand', boxCount: 0, cansCount: 0, },
            { qtyType: 'Quantity Requested',  boxCount: 0, cansCount: 0,  }
        ],
        costonhand: 0,
        costReq: 0,
        discountAED: 0,
        discountPercentage: 0,
        totalPrice: 0,
        finalPrice: 0,
    },
    {
        id: '125-ps-corn-3',
        brandname: 'Maxims',
        supplierName: "Cyril Zeenny",
        gram: "0.50",
        gramAvg: "0.70",
        can: "0.5",
        canAvg: "0.70",
        deliveryDuration: "7",
        canWeight: 200,
        cansPerBox:24,
        lastPurchase: "27/11/2023",
        favorite: true,
        upriceonhand: 240,
        upricereq: 10,
        quantity: [
            { qtyType: 'Quantity on Hand',  boxCount: 0, cansCount: 0,  },
            { qtyType: 'Quantity Requested',  boxCount: 0, cansCount: 0,  }
        ],
        costonhand: 0,
        costReq: 0,
        discountAED: 0,
        discountPercentage: 0,
        totalPrice: 0,
        finalPrice: 0,
    },
    {
        id: '126-ps-corn-3',
        brandname: 'Heinz',
        supplierName: "Lucas Lucas",
        gram: "0.20",
        gramAvg: "0.20",
        can: "0.2",
        canAvg: "0.60",
        deliveryDuration: "3",
        canWeight: 400,
        cansPerBox:16,
        lastPurchase: "27/11/2023",
        favorite: false,
        upriceonhand: 240,
        upricereq: 10,
        quantity: [
            { qtyType: 'Quantity on Hand', boxCount: 0, cansCount: 0, },
            { qtyType: 'Quantity Requested', boxCount: 0, cansCount: 0,  }
        ],
        costonhand: 0,
        costReq: 0,
        discountAED: 0,
        discountPercentage: 0,
        totalPrice: 0,
        finalPrice: 0,
    },
    {
        id: '174-ps-humus-7',
        brandname: 'Cedars',
        supplierName: "George luchi",
        gram: "0.1",
        gramAvg: "0.50",
        can: "0.3",
        canAvg: "0.60",
        deliveryDuration: "3",
        canWeight: 300,
        cansPerBox:18,
        lastPurchase: "27/11/2023",
        favorite: false,
        upriceonhand: 240,
        upricereq: 10,
        quantity: [
            { qtyType: 'Quantity on Hand', boxCount: 0, cansCount: 0,  },
            { qtyType: 'Quantity Requested',  boxCount: 0, cansCount: 0,  }
        ],
        costonhand: 0,
        costReq: 0,
        discountAED: 0,
        discountPercentage: 0,
        totalPrice: 0,
        finalPrice: 0,
    },
    {
        id: '554-ps-humus-3',
        brandname: 'Cortas',
        supplierName: "George luchi",
        gram: "0.5",
        gramAvg: "0.70",
        can: "0.7",
        canAvg: "0.80",
        deliveryDuration: "7",
        canWeight: 300,
        cansPerBox:17,
        lastPurchase: "27/11/2023",
        favorite: false,
        upriceonhand: 240,
        upricereq: 10,
        quantity: [
            { qtyType: 'Quantity on Hand',  boxCount: 0, cansCount: 0,  },
            { qtyType: 'Quantity Requested', boxCount: 0, cansCount: 0,  }
        ],
        costonhand: 0,
        costReq: 0,
        discountAED: 0,
        discountPercentage: 0,
        totalPrice: 0,
        finalPrice: 0,
    },
]

export default  LinkedProductsArray;