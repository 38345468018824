import {
  GET_GROUP_REQUEST,
  GET_GROUP_SUCCESS,
  GET_GROUP_ERROR,
  ADD_GROUP_STARTED,
  ADD_GROUP_END,
  EDIT_GROUP_STARTED,
  EDIT_GROUP_END,
  DELETE_GROUP_STARTED,
  DELETE_GROUP_END,
  SET_GROUP_POPUP,
  CLEAR_ALL_GROUP,
  BULK_EDIT_GROUPS_STARTED,
  BULK_EDIT_GROUPS_ENDED,
  CLEAR_GROUP_LOADING
} from '../actions/GroupActions'

const initialState = {
  loading: false,
  groups: [],
  groupPopup: false,
  addLoading: false,
  editLoading: false,
  editBulkLoading: false,
  deleteLoading: false,
  selectedRestaurant: ''
}

function group(state = initialState, action) {
  switch (action.type) {
    case CLEAR_GROUP_LOADING:
      return Object.assign({}, state, {
        loading: false,
        addLoading: false,
        editLoading: false,
        editBulkLoading: false,
        deleteLoading: false,
      })
    case BULK_EDIT_GROUPS_STARTED:
      return Object.assign({}, state, {
        editBulkLoading: true
      })
    case BULK_EDIT_GROUPS_ENDED:
      return Object.assign({}, state, {
        editBulkLoading: false
      })
    case GET_GROUP_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        selectedRestaurant: action.payload
      })
    case GET_GROUP_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        groups: action.payload.groups,
      })
    case GET_GROUP_ERROR:
      return Object.assign({}, state, {
        loading: false,
        groups: [],
      })

    case ADD_GROUP_STARTED:
      return Object.assign({}, state, {
        addLoading: true,
      })

    case ADD_GROUP_END:
      return Object.assign({}, state, {
        addLoading: false,
      })

    case EDIT_GROUP_STARTED:
      return Object.assign({}, state, {
        editLoading: true,
      })

    case EDIT_GROUP_END:
      return Object.assign({}, state, {
        editLoading: false,
      })

    case DELETE_GROUP_STARTED:
      return Object.assign({}, state, {
        deleteLoading: true,
      })

    case DELETE_GROUP_END:
      return Object.assign({}, state, {
        deleteLoading: false,
      })

    case SET_GROUP_POPUP:
      return Object.assign({}, state, {
        groupPopup: action.payload,
      })

    case CLEAR_ALL_GROUP:
      return initialState

    default:
      return state
  }
}

export default group
