import React from 'react'
import { Tabs, Tab, useTheme } from '@mui/material'
import { getSVG } from '../../utils/getSvg'
import { connect } from 'react-redux'
import actions from '../../redux/actions/LayoutActions'
import { useHistory } from 'react-router-dom'
import { getTranslation, getFieldData } from '../../utils/utils'

const getValue = (data) => {
  if (data?.route)
    return data.route.split('/')[data.route.split('/').length - 1]
  else return data.key
}
const getValue2 = (data, isParent) => {
  return data.split('/')[isParent ? data.split('/').length - 2 : data.split('/').length - 1]
}

const TopNavigation = ({
  language,
  permission,
  reformat,
  isParent,
  extraComponent
}) => {
  const access = reformat(permission.subPage).filter((data) => data.type === 'hor' && data.route).sort((a, b) => a.order - b.order)
  const path = window.location.pathname
  const history = useHistory()
  const theme = useTheme()

  const handleChange = (event, newValue) => {
    history.push(`${permission.route}/${newValue}`)
  };
  return (
    <Tabs
      value={getValue2(path, isParent)}
      onChange={handleChange}
      variant="scrollable"
      sx={{ minHeight: 'unset', minWidth: 'unset', padding: '0 8px', maxWidth: '100vw' }} // Adjust the padding as needed
    >
      {access.map((acc, i) => (
        <Tab
          key={i}
          label={getFieldData(acc, "name", language)}
          value={getValue(acc)}
          href={`${permission.route}/${getValue(acc)}`}
          onClick={(event) => {
            event.preventDefault();
            handleChange(event, getValue(acc));
          }}
          sx={{
            minWidth: 'unset',
            minHeight: 'unset',
            padding: '14px', // Adjust the padding as needed
          }}
          icon={getSVG(
            acc.svgKey,
            getValue2(path) === getValue(acc) ? theme.palette.primary.main : null,
            { width: 20, height: 20 } // Adjust the icon size as needed
          )}
          iconPosition='start'
        />
      ))}
    </Tabs>

  )
}
const mapStateToProps = (state) => ({
  language: state.translation.language
})
const mapDispatchToProps = (dispatch) => ({
})
export default connect(mapStateToProps, mapDispatchToProps)(TopNavigation)