import {
    GET_MEDIA_INTERACTIONS_REQUEST,
    GET_MEDIA_INTERACTIONS_SUCCESS,
    GET_MEDIA_INTERACTIONS_ERROR,
    GET_MEDIA_REQUEST,
    GET_MEDIA_SUCCESS,
    GET_MEDIA_ERROR,
    ADD_MEDIA_STARTED,
    ADD_MEDIA_ENDED,
    EDIT_MEDIA_STARTED,
    EDIT_MEDIA_ENDED,
    DELETE_MEDIA_STARTED,
    DELETE_MEDIA_ENDED,
    HIDE_MEDIA_STARTED,
    HIDE_MEDIA_ENDED,
    SHOW_MEDIA_STARTED,
    SHOW_MEDIA_ENDED,
    SORT_MEDIA_STARTED,
    SORT_MEDIA_ENDED,
    GET_SORTING_REQUEST,
    GET_SORTING_SUCCESS,
    GET_SORTING_ERROR,
    CLEAR_ALL_MEDIA,
    CLEAR_MEDIA_LOADING
} from '../actions/MediaActions'

const initialState = {
    loading: false,
    getSortingLoading: false,
    media: [],
    mediaInteractions: [],
    totalRecords: 0,
    totalPages: 1,
    currentPage: 1,
    sorting: [],
    selectedRestaurant: '',
}

function media(state = initialState, action) {
    switch (action.type) {
        case CLEAR_MEDIA_LOADING:
            return Object.assign({}, state, {
                loading: false,
                getSortingLoading: false,
            })
        case GET_MEDIA_INTERACTIONS_REQUEST:
            return Object.assign({}, state, {
                loading: true,
                selectedRestaurant: action.payload
            })
        case GET_MEDIA_INTERACTIONS_SUCCESS:
            return Object.assign({}, state, {
                loading: false,
                mediaInteractions: action.payload.mediaInteractions
            })
        case GET_MEDIA_INTERACTIONS_ERROR:
            return Object.assign({}, state, {
                loading: false,
                mediaInteractions: []
            })
        case GET_MEDIA_REQUEST:
            return Object.assign({}, state, {
                loading: true,
                selectedRestaurant: action.payload
            });
        case GET_MEDIA_SUCCESS:
            return Object.assign({}, state, {
                loading: false,
                media: action.payload.media,
                totalRecords: action.payload.totalRecords,
                totalPages: action.payload.totalPages,
                currentPage: action.payload.currentPage
            });
        case GET_MEDIA_ERROR:
            return Object.assign({}, state, {
                loading: false,
                media: [],
            });

        case ADD_MEDIA_STARTED:
            return Object.assign({}, state, {
                loading: true,
            });

        case ADD_MEDIA_ENDED:
            return Object.assign({}, state, {
                loading: false,
            });

        case EDIT_MEDIA_STARTED:
            return Object.assign({}, state, {
                loading: true,
            });

        case EDIT_MEDIA_ENDED:
            return Object.assign({}, state, {
                loading: false,
            });

        case DELETE_MEDIA_STARTED:
            return Object.assign({}, state, {
                loading: true,
            });

        case DELETE_MEDIA_ENDED:
            return Object.assign({}, state, {
                loading: false,
            });

        case HIDE_MEDIA_STARTED:
            return Object.assign({}, state, {
                loading: true
            })
        case HIDE_MEDIA_ENDED:
            return Object.assign({}, state, {
                loading: false,
            })
        case SHOW_MEDIA_STARTED:
            return Object.assign({}, state, {
                loading: true,
            })
        case SHOW_MEDIA_ENDED:
            return Object.assign({}, state, {
                loading: false,
            })
        case SORT_MEDIA_STARTED:
            return Object.assign({}, state, {
                loading: true,
            })
        case SORT_MEDIA_ENDED:
            return Object.assign({}, state, {
                loading: false,
            })
        case CLEAR_ALL_MEDIA:
            return initialState
        case GET_SORTING_REQUEST:
            return Object.assign({}, state, {
                getSortingLoading: true
            })
        case GET_SORTING_SUCCESS:
            return Object.assign({}, state, {
                getSortingLoading: false,
                sorting: action.payload.media
            })
        case GET_SORTING_ERROR:
            return Object.assign({}, state, {
                getSortingLoading: false,
                sorting: []
            })
        default:
            return state;
    }
}

export default media