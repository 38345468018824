import {
  getFeedbackTemplate,
  editFeedbackTemplate,
  editFeedbackTemplateMessage,
  getFeedback,
  addFeedback,
  editFeedbackComplaint,
} from "../../config/config";
import { toast } from "react-toastify";
import { LOGOUT_USER, SET_PERMISSIONS } from "./AuthActions";
import { handleError } from "../../utils/utils";

export const GET_FEEDBACK_TEMPLATE_REQUEST = "GET_FEEDBACK_TEMPLATE_REQUEST";
export const GET_FEEDBACK_TEMPLATE_SUCCESS = "GET_FEEDBACK_TEMPLATE_SUCCESS";
export const GET_FEEDBACK_TEMPLATE_ERROR = "GET_FEEDBACK_TEMPLATE_ERROR";

export const EDIT_FEEDBACK_TEMPLATE_STARTED = "EDIT_FEEDBACK_TEMPLATE_STARTED";
export const EDIT_FEEDBACK_TEMPLATE_ENDED = "EDIT_FEEDBACK_TEMPLATE_ENDED";

export const EDIT_FEEDBACK_TEMPLATE_MESSAGE_STARTED =
  "EDIT_FEEDBACK_TEMPLATE_MESSAGE_STARTED";
export const EDIT_FEEDBACK_TEMPLATE_MESSAGE_ENDED =
  "EDIT_FEEDBACK_TEMPLATE_MESSAGE_ENDED";

export const GET_FEEDBACK_REQUEST = "GET_FEEDBACK_REQUEST";
export const GET_FEEDBACK_SUCCESS = "GET_FEEDBACK_SUCCESS";
export const GET_FEEDBACK_ERROR = "GET_FEEDBACK_ERROR";

export const ADD_FEEDBACK_STARTED = "ADD_FEEDBACK_STARTED";
export const ADD_FEEDBACK_ENDED = "ADD_FEEDBACK_ENDED";

export const EDIT_FEEDBACK_COMPLAINT_REQUEST =
  "EDIT_FEEDBACK_COMPLAINT_REQUEST";
export const EDIT_FEEDBACK_COMPLAINT_SUCCESS =
  "EDIT_FEEDBACK_COMPLAINT_SUCCESS";
export const EDIT_FEEDBACK_COMPLAINT_ERROR = "EDIT_FEEDBACK_COMPLAINT_ERROR";

export const SET_FEEDBACK_FILTER = 'SET_FEEDBACK_FILTER'

export const CLEAR_ALL_FEEDBACKS = 'CLEAR_ALL_FEEDBACKS'

export const CLEAR_FEEDBACK_LOADING = 'CLEAR_FEEDBACK_LOADING'

const actions = {
  getFeedbackTemplate: (ruuid) => async (dispatch, getState) => {
    const currentRestaurant = getState().feedback.selectedRestaurantTemplate
    if (ruuid !== currentRestaurant)
      dispatch({
        type: GET_FEEDBACK_TEMPLATE_REQUEST,
        payload: ruuid
      });
    await getFeedbackTemplate(ruuid).then((response) => {
      if (response?.status === 200) {
        dispatch({
          type: GET_FEEDBACK_TEMPLATE_SUCCESS,
          payload: {
            feedbacktemplate: response?.data?.feedbacktemplate[0],
          },
        });
      } else {
        handleError(dispatch)(response, GET_FEEDBACK_TEMPLATE_ERROR, 'GET FEEDBACK TEMPLATE ERROR')
        // dispatch({
        //   type: GET_FEEDBACK_TEMPLATE_ERROR,
        // });
        // if (response.status !== 401 && response.status !== 403)
        //   toast.error(response?.data?.message || "Get Feedback template error");
        // if (response.status === 401) dispatch({ type: LOGOUT_USER });
        // if (response.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: response?.data?.permissions
        //   })
        // }
      }
    });
  },
  editFeedbackTemplate: (ruuid, data) => async (dispatch) => {
    dispatch({
      type: EDIT_FEEDBACK_TEMPLATE_STARTED,
    });
    await editFeedbackTemplate(ruuid, data).then((response) => {
      if (response.status === 200) {
        toast.success(response?.data?.message || "Feedback template updated");
      } else {
        handleError(dispatch)(response, null, 'EDIT FEEDBACK TEMPLATE ERROR')
        // if (response.status !== 401 && response.status !== 403)
        //   toast.error(response?.data?.message || "Edit feedback template error");
        // if (response?.status === 401)
        //   dispatch({
        //     type: LOGOUT_USER,
        //   });
        // if (response.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: response?.data?.permissions
        //   })
        // }
      }
    });
    dispatch({
      type: EDIT_FEEDBACK_TEMPLATE_ENDED,
    });
  },
  editFeedbackTemplateMessage: (ruuid, data) => async (dispatch) => {
    dispatch({
      type: EDIT_FEEDBACK_TEMPLATE_MESSAGE_STARTED,
    });
    await editFeedbackTemplateMessage(ruuid, data).then(async (response) => {
      if (response.status === 200) {
        toast.success(
          response?.data?.message || "Feedback template message updated"
        )
        await getFeedbackTemplate(ruuid).then((response) => {
          if (response?.status === 200) {
            dispatch({
              type: GET_FEEDBACK_TEMPLATE_SUCCESS,
              payload: {
                feedbacktemplate: response?.data?.feedbacktemplate[0],
              },
            });
          } else {
            handleError(dispatch)(response, GET_FEEDBACK_TEMPLATE_ERROR, 'GET FEEDBACK TEMPLATE ERROR')
            // dispatch({
            //   type: GET_FEEDBACK_TEMPLATE_ERROR,
            // });
            // if (response.status !== 401 && response.status !== 403)
            //   toast.error(response?.data?.message || "Get Feedback template error");
            // if (response.status === 401) dispatch({ type: LOGOUT_USER });
            // if (response.status === 403)
            //   dispatch({
            //     type: SET_PERMISSIONS,
            //     payload: response?.data?.permissions
            //   })
          }
        })
      }
      else {
        handleError(dispatch)(response, null, 'EDIT FEEDBACK TEMPLATE MESSAGE ERROR')
        // if (response.status !== 401 && response.status !== 403)
        //   toast.error(
        //     response?.data?.message || "Edit feedback template message error"
        //   );
        // if (response.status === 401)
        //   dispatch({
        //     type: LOGOUT_USER,
        //   });
        // if (response.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: response?.data?.permissions
        //   })
        // }
      }
    });
    dispatch({
      type: EDIT_FEEDBACK_TEMPLATE_MESSAGE_ENDED,
    });
  },
  getFeedback: (ruuid) => async (dispatch, getState) => {
    const currentRestaurant = getState().feedback.selectedRestaurantFeedbacks
    if (ruuid !== currentRestaurant)
      dispatch({
        type: GET_FEEDBACK_REQUEST,
        payload: ruuid
      });
    await getFeedback(ruuid).then((response) => {
      if (response?.status === 200)
        dispatch({
          type: GET_FEEDBACK_SUCCESS,
          payload: response?.data,
        });
      else {
        handleError(dispatch)(response, GET_FEEDBACK_ERROR, 'GET FEEDBACK ERROR')
        // if (response.status !== 401 && response.status !== 403)
        //   toast.error(response?.data?.message || "Get Feedback error");
        // if (response?.status === 401)
        //   dispatch({
        //     type: LOGOUT_USER,
        //   });
        // if (response.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: response?.data?.permissions
        //   })
        // }
        // dispatch({
        //   type: GET_FEEDBACK_ERROR,
        // });
      }
    });
  },
  addFeedback: (ruuid, data) => async (dispatch) => {
    dispatch({
      type: ADD_FEEDBACK_STARTED,
    });
    await addFeedback(ruuid, data).then((response) => {
      if (response.status === 200)
        toast.success(response?.data?.message || "Feedback added");
      else {
        handleError(dispatch)(response, null, 'ADD FEEDBACK ERROR')
        // if (response.status !== 401 && response.status !== 403)
        //   toast.error(response?.data?.message || "Add feedback error");
        // if (response.status === 401)
        //   dispatch({
        //     type: LOGOUT_USER,
        //   });
        // if (response.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: response?.data?.permissions
        //   })
        // }
      }
    });
    dispatch({
      type: ADD_FEEDBACK_ENDED,
    });
  },
  editFeedbackComplaint: (ruuid, fuuid, data) => async (dispatch) => {
    dispatch({
      type: EDIT_FEEDBACK_COMPLAINT_REQUEST,
      payload: { fuuid: fuuid },
    });
    await editFeedbackComplaint(ruuid, fuuid, data).then((response) => {
      if (response.status === 200) {
        dispatch({
          type: EDIT_FEEDBACK_COMPLAINT_SUCCESS,
          payload: { fuuid: fuuid, data: data },
        });
        //update the data here
        //  toast.success(response?.data?.message || "Feedback added");
      } else {
        handleError(dispatch)(response, null, 'EDIT FEEDBACK TEMPLATE ERROR')
        // if (response.status !== 401 && response.status !== 403)
        //   toast.error(response?.data?.message || "Edit complaint error");
        // dispatch({
        //   type: EDIT_FEEDBACK_COMPLAINT_ERROR,
        //   payload: { fuuid: fuuid },
        // });
        // if (response.status === 401)
        //   dispatch({
        //     type: LOGOUT_USER,
        //   });
        // if (response.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: response?.data?.permissions
        //   })
        // }
      }
    });
  },
  clearFeedback: () => (dispatch) => {
    dispatch({
      type: CLEAR_ALL_FEEDBACKS
    })
  },
  setFeedbackFilter: (data) => (dispatch) => {
    dispatch({
      type: SET_FEEDBACK_FILTER,
      payload: data
    })
  }
};
export default actions;
