import { getGlobalConfig } from '../../config/config'
import { CLEAR_AUTH_LOADING, CLEAR_MESSAGES } from './AuthActions'
import { CLEAR_ALL_BRANCH, CLEAR_BRANCH_LOADING } from './BranchActions'
import { CLEAR_ALL_BRANCHES_FINANCE, CLEAR_BRANCHES_FINANCE_LOADING } from './BranchesFinanceActions'
import { CLEAR_ALL_CATEGORY, CLEAR_CATEGORY_LOADING } from './CategoryActions'
import { CLEAR_ALL_DELIVERYZONE, CLEAR_ZONES_LOADING } from './DeliveryZoneActions'
import { CLEAR_ALL_DISCOUNT, CLEAR_DISCOUNT_LOADING } from './DiscountActions'
import { CLEAR_ALL_EVENTS, CLEAR_EVENTS_LOADING } from './EventsActons'
import { CLEAR_ALL_FEEDBACKS, CLEAR_FEEDBACK_LOADING } from './FeedbackActions'
import { CLEAR_ALL_GALLERY, CLEAR_GALLERY_LOADING } from './GalleryActions'
import { CLEAR_ALL_GROUP, CLEAR_GROUP_LOADING } from './GroupActions'
import { CLEAR_ALL_INGREDIENT, CLEAR_INGREDIENT_LOADING } from './IngredientActions'
import { CLEAR_LAYOUT } from './LayoutActions'
import { CLEAR_ALL_LOYALTY, CLEAR_LOYALTY_LOADING } from './LoyaltyActions'
import { CLEAR_ALL_MEDIA, CLEAR_MEDIA_LOADING } from './MediaActions'
import { CLEAR_MENU, CLEAR_MENU_LOADING } from './MenuActions'
import { CLEAR_ALL_MODIFIER, CLEAR_MODIFIERS_LOADING } from './ModifiersActions'
import { CLEAR_ALL_MODIFIERGROUP, CLEAR_MODIFIERS_GROUPS_LOADING } from './ModifiersGroupsActions'
import { CLEAR_ALL_MODULES, CLEAR_MODULES_LOADING } from './ModulesActions'
import { CLEAR_ORDER_LOADING, RESET_ORDERS } from './OrderActions'
import { CLEAR_ALL_PLATFORMS, CLEAR_PLATFORM_LOADING } from './PlatformActions'
import { CLEAR_ALL_PRODUCT, CLEAR_PRODUCTS_LOADING } from './ProductActions'
import { CLEAR_ALL_PROFILE, CLEAR_PROFILE_LOADING } from './ProfileActions'
import { CLEAR_ALL_PROMOTIONS, CLEAR_PROMO_LOADING } from './PromoCodesActions'
import { CLEAR_ALL_REST, CLEAR_RESTAURANTS_LOADING } from './RestActions'
import { CLEAR_ALL_ROLE, CLEAR_ROLE_LOADING } from './RoleActions'
import { CLEAR_ALL_TAX, CLEAR_TAX_LOADING } from './TaxActions'
import { CLEAR_ALL_TYPES_AND_ALLERGIES, CLEAR_TYPES_LOADING } from './TypesAndAllergiesActions'
import { CLEAR_ALL_USER, CLEAR_USERS_LOADING } from './UsersActions'
import { CLEAR_ALL_ALLERGENS } from './AllergensActions'
import { RESET_APP_USER_ADDRESSES } from './AppUserAddressesActions'
import { CLEAR_APP_USERS_GROUPS } from './AppUsersGroupsActions'
import { CLEAR_DESIGN_LOADING, CLEAR_DESIGN_SETUP } from './DesignSetupActions'
import { CLEAR_ALL_QR, CLEAR_QR_LOADING } from './QRActions'
import { CLEAR_NOTIFICATIONS, CLEAR_NOTIFICATIONS_LOADING } from './NotificationsActions'
import { CLEAR_ALLERGENS_LOADING } from './AllergensActions'
import { CLEAR_APP_USERS_LOADING } from './AppUsersGroupsActions'

import { parseError } from '../../utils/parseError'
import { handleError } from '../../utils/utils'
import { CLEAR_URL_LINK_LOADING } from './AffiliateUrlLinkActions'
import { CLEAR_APP_USER_ADDRESSES_LOADING } from './AppUserAddressesActions'
import { CLEAR_AUTO_MSG_LOADING } from './AutomatedMessagesActions'
import { CLEAR_FLOOR_LOADING } from './FloorSetupActions'
import { CLEAR_LOGIN_DESIGN_LOADING } from './LoginDesignSetupActions'
import { CLEAR_MESSAGES_LOADING } from './MessagesActions'
import { CLEAR_PRODUCTS_AVAILABILITY_LOADING } from './ProductsAvailabilityActions'
import { CLEAR_SPLASH_SCREEN_LOADING } from './SplashScreenDesignSetupActions'
import { CLEAR_TRANSLATIONS_LOADING } from './TranslationsActions'
import { CLEAR_PAYMENTS } from './PaymentsActions'
import { CLEAR_ALL_DM, CLEAR_LOADING_DM } from './DirectMessagesActions'

export const GET_GLOBALCONFIG_REQUEST = 'GET_GLOBALCONFIG_REQUEST'
export const GET_GLOBALCONFIG_SUCCESS = 'GET_GLOBALCONFIG_SUCCESS'
export const GET_GLOBALCONFIG_ERROR = 'GET_GLOBALCONFIG_ERROR'
export const CLEAR_GLOBALCONFIG = 'CLEAR_GLOBALCONFIG'


const actions = {
  clearLoading: () => async (dispatch) => {
    dispatch({
      type: CLEAR_LOADING_DM
    })
    dispatch({
      type: CLEAR_URL_LINK_LOADING
    })
    dispatch({
      type: CLEAR_ALLERGENS_LOADING
    })
    dispatch({
      type: CLEAR_APP_USER_ADDRESSES_LOADING
    })
    dispatch({
      type: CLEAR_APP_USERS_LOADING
    })
    // dispatch({
    //   type: CLEAR_AUTH_LOADING
    // })
    dispatch({
      type: CLEAR_AUTO_MSG_LOADING
    })
    // dispatch({
    //   type: CLEAR_BRANCH_LOADING
    // })
    dispatch({
      type: CLEAR_BRANCHES_FINANCE_LOADING
    })
    dispatch({
      type: CLEAR_CATEGORY_LOADING
    })
    dispatch({
      type: CLEAR_ZONES_LOADING
    })
    dispatch({
      type: CLEAR_DESIGN_LOADING
    })
    dispatch({
      type: CLEAR_DISCOUNT_LOADING
    })
    dispatch({
      type: CLEAR_EVENTS_LOADING
    })
    dispatch({
      type: CLEAR_FEEDBACK_LOADING
    })
    dispatch({
      type: CLEAR_FLOOR_LOADING
    })
    dispatch({
      type: CLEAR_GALLERY_LOADING
    })
    dispatch({
      type: CLEAR_GROUP_LOADING
    })
    dispatch({
      type: CLEAR_INGREDIENT_LOADING
    })
    dispatch({
      type: CLEAR_LOGIN_DESIGN_LOADING
    })
    dispatch({
      type: CLEAR_LOYALTY_LOADING
    })
    dispatch({
      type: CLEAR_MEDIA_LOADING
    })
    dispatch({
      type: CLEAR_MENU_LOADING
    })
    dispatch({
      type: CLEAR_MESSAGES_LOADING
    })
    dispatch({
      type: CLEAR_MODIFIERS_LOADING
    })
    dispatch({
      type: CLEAR_MODIFIERS_GROUPS_LOADING
    })
    dispatch({
      type: CLEAR_MODULES_LOADING
    })
    dispatch({
      type: CLEAR_NOTIFICATIONS_LOADING
    })
    dispatch({
      type: CLEAR_ORDER_LOADING
    })
    dispatch({
      type: CLEAR_PLATFORM_LOADING
    })
    dispatch({
      type: CLEAR_PRODUCTS_LOADING
    })
    dispatch({
      type: CLEAR_PRODUCTS_AVAILABILITY_LOADING
    })
    dispatch({
      type: CLEAR_PROFILE_LOADING
    })
    dispatch({
      type: CLEAR_PROMO_LOADING
    })
    dispatch({
      type: CLEAR_QR_LOADING
    })
    // dispatch({
    //   type: CLEAR_RESTAURANTS_LOADING
    // })
    dispatch({
      type: CLEAR_ROLE_LOADING
    })
    dispatch({
      type: CLEAR_SPLASH_SCREEN_LOADING
    })
    dispatch({
      type: CLEAR_TAX_LOADING
    })
    dispatch({
      type: CLEAR_TRANSLATIONS_LOADING
    })
    dispatch({
      type: CLEAR_TYPES_LOADING
    })
    dispatch({
      type: CLEAR_USERS_LOADING
    })
  },
  // Get All global variables
  getGlobalConfig: () => async (dispatch) => {
    dispatch({
      type: GET_GLOBALCONFIG_REQUEST,
    })
    await getGlobalConfig().then((res) => {
      if (res?.status === 200) {
        let countries = []
        let currencies = []
        if (res.data.countries) {
          countries = [...res.data.countries]
          countries.sort((a, b) => a.countryName.localeCompare(b.countryName))
          res.data.countries = countries
        }
        if (res.data.currencies) {
          currencies = [...res.data.currencies]
          currencies.sort((a, b) => a.currencyName.localeCompare(b.currencyName))
          res.data.currencies = currencies
        }
        dispatch({
          type: GET_GLOBALCONFIG_SUCCESS,
          payload: res.data,
        })
      } else {
        handleError(dispatch)(res, GET_GLOBALCONFIG_ERROR, 'GET GLOBALS ERROR')
        // dispatch({
        //   type: GET_GLOBALCONFIG_ERROR,
        //   payload: {
        //     message: parseError(res?.data?.error) || res?.data?.message || 'get globals error',
        //   },
        // })
      }
    })
  },
  clearGlobalConfig: () => (dispatch) => {
    dispatch({
      type: CLEAR_GLOBALCONFIG
    })
  },


  clearAllData: () => (dispatch) => {
    dispatch({
      type: CLEAR_ALL_DM
    })
    // dispatch({
    //   type: CLEAR_DESIGN_SETUP
    // })
    // dispatch({
    //   type: CLEAR_APP_USERS_GROUPS
    // })
    dispatch({
      type: CLEAR_ALL_QR
    })
    dispatch({
      type: RESET_APP_USER_ADDRESSES
    })
    // dispatch({
    //   type: CLEAR_ALL_ALLERGENS
    // })
    dispatch({
      type: CLEAR_ALL_BRANCH
    })
    // dispatch({
    //   type: CLEAR_MESSAGES
    // })
    dispatch({
      type: CLEAR_ALL_BRANCHES_FINANCE
    })
    dispatch({
      type: CLEAR_ALL_DELIVERYZONE
    })
    dispatch({
      type: CLEAR_ALL_CATEGORY
    })
    dispatch({
      type: CLEAR_ALL_DISCOUNT
    })
    dispatch({
      type: CLEAR_ALL_EVENTS
    })
    dispatch({
      type: CLEAR_ALL_FEEDBACKS
    })
    dispatch({
      type: CLEAR_ALL_GALLERY
    })
    dispatch({
      type: CLEAR_ALL_GROUP
    })
    // dispatch({
    //   type: CLEAR_ALL_INGREDIENT
    // })
    // dispatch({
    //   type: CLEAR_LAYOUT
    // })
    dispatch({
      type: CLEAR_ALL_LOYALTY
    })
    dispatch({
      type: CLEAR_ALL_MEDIA
    })
    dispatch({
      type: CLEAR_MENU
    })
    dispatch({
      type: CLEAR_ALL_MODIFIER
    })
    dispatch({
      type: CLEAR_ALL_MODIFIERGROUP
    })
    // dispatch({
    //   type: CLEAR_ALL_MODULES
    // })
    dispatch({
      type: RESET_ORDERS
    })
    dispatch({
      type: CLEAR_ALL_PLATFORMS
    })
    dispatch({
      type: CLEAR_ALL_PRODUCT
    })
    // dispatch({
    //   type: CLEAR_ALL_PROFILE
    // })
    dispatch({
      type: CLEAR_ALL_PROMOTIONS
    })
    // dispatch({
    //   type: CLEAR_ALL_REST
    // })
    // dispatch({
    //   type: CLEAR_ALL_ROLE
    // })
    dispatch({
      type: CLEAR_ALL_TAX
    })
    dispatch({
      type: CLEAR_ALL_TYPES_AND_ALLERGIES
    })
    dispatch({
      type: CLEAR_NOTIFICATIONS
    })
    dispatch({
      type: CLEAR_PAYMENTS
    })
    // dispatch({
    //   type: CLEAR_ALL_USER
    // })
  }
}

export default actions
