import { Popover, Typography, CircularProgress, Box, Divider, Chip, Dialog } from '@mui/material'
import moment from 'moment'
import React from 'react'
import Scrollbars from 'react-custom-scrollbars-2'

import { connect } from 'react-redux'
import { getField } from '../../utils/utils'

import { GetScreenSize } from '../../utils/screenSize'
import { getSVG } from '../../utils/getSvg'


const Notifications = ({
    dictionary,
    language,
    id,
    open,
    anchorEl,
    handleClose,
    loading,
    notifications
}) => {
    const screenSize = GetScreenSize()

    if (screenSize.dynamicWidth > 677)
        return (
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box sx={{ minWidth: 400, display: 'flex', justifyContent: 'center', minHeight: 300, alignItems: 'center' }} padding={2}>
                    {loading ?
                        <CircularProgress />
                        :
                        <Scrollbars autoHeight autoHeightMin={300} sx={{ width: '100%' }}>
                            {
                                notifications.length > 0 ?
                                    notifications.map((notification) => {
                                        return (
                                            <Box>
                                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }} padding={2}>
                                                    <Box sx={{ width: '70%' }}>
                                                        <Box sx={{ fontWeight: 700 }}>
                                                            {notification?.title}
                                                        </Box>
                                                        <Box>
                                                            {notifications.description}
                                                        </Box>
                                                    </Box>
                                                    <Box>
                                                        {moment(notification.createdAt).local().fromNow()}
                                                    </Box>

                                                </Box>
                                                <Divider />
                                            </Box>
                                        )
                                    })
                                    :
                                    <Box padding={2} sx={{ height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'background.paper', borderRadius: '18px' }}>
                                        <Chip label={getField(dictionary, 'no-notifications', language)} sx={{ fontWeight: 'bold', fontSize: 14 }} />
                                    </Box>
                            }
                        </Scrollbars>
                    }
                </Box>
            </Popover>
        )
    else return (
        <Dialog open={Boolean(open)} fullScreen>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }} padding={2}>
                <Box onClick={handleClose}>
                    {getSVG('Close', null, { width: 25, height: 25 })}
                </Box>
            </Box>
            <Scrollbars autoHeight autoHeightMin={screenSize.dynamicHeight - 60} sx={{ width: '100%' }}>
                {
                    notifications.length > 0 ?
                        notifications.map((notification) => {
                            return (
                                <Box>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }} padding={2}>
                                        <Box sx={{ width: '70%' }}>
                                            <Box sx={{ fontWeight: 700 }}>
                                                {notification?.title}
                                            </Box>
                                            <Box>
                                                {notifications.description}
                                            </Box>
                                        </Box>
                                        <Box>
                                            {moment(notification.createdAt).local().fromNow()}
                                        </Box>

                                    </Box>
                                    <Divider />
                                </Box>
                            )
                        })
                        :
                        <Box padding={2} sx={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'background.paper', borderRadius: '18px' }}>
                            <Chip label={getField(dictionary, 'no-notifications', language)} sx={{ fontWeight: 'bold', fontSize: 14 }} />
                        </Box>
                }
            </Scrollbars>
        </Dialog>
    )
}
const mapStateToProps = (state) => ({
    loading: state.notifications.loading,
    notifications: state.notifications.notifications
})
export default connect(mapStateToProps)(Notifications)