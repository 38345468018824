import { getRestaurants, addRestaurant, editRestaurant, deleteRestaurant } from '../../config/config'
import globalActions from './GlobalActions'
import localforage from 'localforage'

import { toast } from 'react-toastify'
import { parseError } from '../../utils/parseError'
import { LOGOUT_USER, SET_PERMISSIONS } from './AuthActions'
import { handleError } from '../../utils/utils'

export const GET_REST_REQUEST = 'GET_REST_REQUEST'
export const GET_REST_SUCCESS = 'GET_REST_SUCCESS'
export const GET_REST_ERROR = 'GET_REST_ERROR'

export const ADD_REST_STARTED = 'ADD_REST_STARTED'
export const ADD_REST_END = 'ADD_REST_END'

export const EDIT_REST_STARTED = 'EDIT_REST_STARTED'
export const EDIT_REST_END = 'EDIT_REST_END'

export const DELETE_REST_STARTED = 'DELETE_REST_STARTED'
export const DELETE_REST_END = 'DELETE_REST_END'

export const SET_REST = 'SET_REST'
export const SET_REST_DATA = 'SET_REST_DATA'
export const SET_MODULES = 'SET_MODULES'
export const CLEAR_ALL_REST = 'CLEAR_ALL_REST'

export const ALL_RESTAURANTS_ROLES = 'ALL_RESTAURANTS_ROLES'

export const CLEAR_RESTAURANTS_LOADING = 'CLEAR_RESTAURANTS_LOADING'

const actions = {
  setSelectedRestData: (data) => (dispatch) => {
    dispatch({
      type: SET_REST_DATA,
      payload: data
    })
  },
  // Get All restaurants with the selected one
  getRest: (selected, getBranches, setBlockGetBranches) => async (dispatch, getState) => {
    setBlockGetBranches(true)
    dispatch({
      type: GET_REST_REQUEST,
    })
    await getRestaurants().then((res) => {
      if (res?.status === 200) {
        dispatch({
          type: GET_REST_SUCCESS,
          payload: {
            restaurants: res?.data?.restaurants,
          },
        })
        dispatch({
          type: SET_MODULES,
          payload: res?.data?.modules
        })
        if (res?.data?.restaurants?.length === 0) {
          dispatch({
            type: SET_REST,
            payload: { selected: '' },
          })
        }
        let temp = []
        if (res?.data?.restaurants?.length > 0) {
          temp = res.data.restaurants.map((rest) => { return rest.uuid })
          dispatch({
            type: SET_REST,
            payload: { selected: temp.includes(selected) ? selected : res?.data?.restaurantsPermissions.filter((perm) => perm.permissions.length > 0)[0]?.restaurantUuid },
          })
        }
        getBranches(temp.includes(selected) ? selected : res?.data?.restaurants[0]?.uuid)
        let selectedRestaurantPermission = res?.data?.restaurantsPermissions.find((rest) => rest.restaurantUuid === getState().rest.selectedRest)
        if (selectedRestaurantPermission.permissions.length > 0)
          dispatch({
            type: SET_PERMISSIONS,
            payload: selectedRestaurantPermission.permissions
          })
        else {
          localforage.dropInstance().then(function () {
          }).catch(function (err) {
            console.error('Error deleting database:', err);
          });
          window.location.reload()
        }
        dispatch({
          type: ALL_RESTAURANTS_ROLES,
          payload: res?.data?.restaurantsPermissions
        })
      } else {
        handleError(dispatch)(res, GET_REST_ERROR, 'GET RESTAURANT ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Get All Restaurant error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
        // dispatch({
        //   type: GET_REST_ERROR,
        //   payload: {
        //     message: parseError(res?.data?.error) || res?.data?.message || 'GET REST error',
        //   },
        // })
      }
    })
    setBlockGetBranches(false)
  },

  addRestaurant: (data) => async (dispatch) => {
    dispatch({
      type: ADD_REST_STARTED,
    })
    await addRestaurant(data).then((res) => {
      if (res?.status === 200) {
        toast.success('Successfully Saved!')
        dispatch({
          type: GET_REST_SUCCESS,
          payload: {
            restaurants: res?.data?.restaurants,
          },
        })
        if (res?.data?.restaurants?.length === 0) {
          dispatch({
            type: SET_REST,
            payload: { selected: '' },
          })
        }
        if (res?.data?.restaurants?.length === 1) {
          dispatch({
            type: SET_REST,
            payload: { selected: res?.data?.restaurants[0]?.uuid },
          })
        }
      } else {
        handleError(dispatch)(res, null, 'ADD RESTAURANT ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Add Restaurant error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
      }
    })
    dispatch({
      type: ADD_REST_END,
    })
  },

  editRestaurant: (data, uuid, history) => async (dispatch) => {
    dispatch({
      type: EDIT_REST_STARTED,
    })
    await editRestaurant(data, uuid).then((res) => {
      if (res?.status === 200) {
        toast.success('Successfully Saved!')
        dispatch({
          type: GET_REST_SUCCESS,
          payload: {
            restaurants: res?.data?.restaurants,
          },
        })
        if (res?.data?.restaurants?.length === 0) {
          dispatch({
            type: SET_REST,
            payload: { selected: '' },
          })
        }
        if (res?.data?.restaurants?.length === 1) {
          dispatch({
            type: SET_REST,
            payload: { selected: res?.data?.restaurants[0]?.uuid },
          })
        }
      } else {
        handleError(dispatch)(res, null, 'EDIT RESTAURANT ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Edit Restaurant error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
      }
    })
    dispatch({
      type: EDIT_REST_END,
    })
  },

  deleteRestaurant: (uuid, history) => async (dispatch) => {
    dispatch({
      type: DELETE_REST_STARTED,
    })
    await deleteRestaurant(uuid).then((res) => {
      if (res?.status === 200) {
        dispatch({
          type: GET_REST_SUCCESS,
          payload: {
            restaurants: res?.data?.restaurants,
          },
        })
        if (res?.data?.restaurants?.length === 0) {
          dispatch({
            type: SET_REST,
            payload: { selected: '' },
          })
        }
        if (res?.data?.restaurants?.length === 1) {
          dispatch({
            type: SET_REST,
            payload: { selected: res?.data?.restaurants[0]?.uuid },
          })
        }
        if (res?.data?.restaurants?.length > 1) {
          dispatch({
            type: SET_REST,
            payload: { selected: res?.data?.restaurants[0]?.uuid },
          })
        }
        history.push('/add-restaurant')
      } else {
        handleError(dispatch)(res, null, 'DELETE RESTAURANT ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Delete Restaurant error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
      }
    })
    dispatch({
      type: DELETE_REST_END,
    })
  },

  // SET Selected Restaurant
  setSelectedRest: (value) => (dispatch, getState) => {
    dispatch(globalActions.clearAllData())
    dispatch({
      type: SET_REST,
      payload: { selected: value },
    })
    let selectedRestaurantPermission = getState().rest.allRestaurantsRoles.find((rest) => rest.restaurantUuid === value)
    if (selectedRestaurantPermission.permissions.length > 0)
      dispatch({
        type: SET_PERMISSIONS,
        payload: selectedRestaurantPermission.permissions
      })
    else {
      localStorage.clear()
      window.location.reload()
    }
  },

  resetDeleteLoading: () => (dispatch) => {
    dispatch({
      type: DELETE_REST_END,
    })
  },

  // CLEAR ALL Reducer
  clearAll: () => (dispatch) => {
    dispatch({
      type: CLEAR_ALL_REST,
    })
  },
}

export default actions
