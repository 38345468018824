import {
  GET_INGREDIENT_REQUEST,
  GET_INGREDIENT_SUCCESS,
  GET_INGREDIENT_ERROR,
  CLEAR_ALL_INGREDIENT,
  ADD_INGREDIENT_STARTED,
  ADD_INGREDIENT_END,
  EDIT_INGREDIENT_STARTED,
  EDIT_INGREDIENT_END,
  DELETE_INGREDIENT_STARTED,
  DELETE_INGREDIENT_END,
  SET_INGREDIENT_POPUP,
  BULK_UPDATE_INGREDIENTS_STARTED,
  BULK_UPDATE_INGREDIENTS_ENDED,
  CLEAR_INGREDIENT_LOADING
} from '../actions/IngredientActions'


const initialState = {
  loading: false,
  ingredients: [],
  ingredientPopup: false,
  addLoading: false,
  editLoading: false,
  deleteLoading: false,
}

function ingredient(state = initialState, action) {
  switch (action.type) {
    case CLEAR_INGREDIENT_LOADING:
      return Object.assign({}, state, {
        loading: false,
        addLoading: false,
        editLoading: false,
        deleteLoading: false,
      })
    case BULK_UPDATE_INGREDIENTS_STARTED:
      return Object.assign({}, state, {
        editLoading: true
      })
    case BULK_UPDATE_INGREDIENTS_ENDED:
      return Object.assign({}, state, {
        editLoading: false
      })
    case GET_INGREDIENT_REQUEST:
      return Object.assign({}, state, {
        loading: true,
      })
    case GET_INGREDIENT_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        ingredients: action.payload.ingredients,
      })
    case GET_INGREDIENT_ERROR:
      return Object.assign({}, state, {
        loading: false,
        ingredients: [],
      })

    case ADD_INGREDIENT_STARTED:
      return Object.assign({}, state, {
        addLoading: true,
      })

    case ADD_INGREDIENT_END:
      return Object.assign({}, state, {
        addLoading: false,
      })

    case EDIT_INGREDIENT_STARTED:
      return Object.assign({}, state, {
        editLoading: true,
      })

    case EDIT_INGREDIENT_END:
      return Object.assign({}, state, {
        editLoading: false,
      })

    case DELETE_INGREDIENT_STARTED:
      return Object.assign({}, state, {
        deleteLoading: true,
      })

    case DELETE_INGREDIENT_END:
      return Object.assign({}, state, {
        deleteLoading: false,
      })

    case SET_INGREDIENT_POPUP:
      return Object.assign({}, state, {
        ingredientPopup: action.payload,
      })

    case CLEAR_ALL_INGREDIENT:
      return initialState

    default:
      return state
  }
}

export default ingredient
