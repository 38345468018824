import {
    getPayments,
    getPayment,
    editPayment,
    deletePayment,
    addPayment,
    addDefaultPayment
} from "../../config/config";

import { toast } from "react-toastify"
import { handleError } from '../../utils/utils'

export const GET_PAYMENTS_REQUEST = 'GET_PAYMENTS_REQUEST'
export const GET_PAYMENTS_SUCCESS = 'GET_PAYMENTS_SUCCESS'
export const GET_PAYMENTS_ERROR = 'GET_PAYMENTS_ERROR'

export const GET_PAYMENT_REQUEST = 'GET_PAYMENT_REQUEST'
export const GET_PAYMENT_SUCCESS = 'GET_PAYMENT_SUCCESS'
export const GET_PAYMENT_ERROR = 'GET_PAYMENT_ERROR'

export const EDIT_PAYMENT_STARTED = 'EDIT_PAYMENT_STARTED'
export const EDIT_PAYMENT_ENDED = 'EDIT_PAYMENT_ENDED'

export const DELETE_PAYMENT_STARTED = 'DELETE_PAYMENT_STARTED'
export const DELETE_PAYMENT_ENDED = 'DELETE_PAYMENT_ENDED'

export const ADD_PAYMENT_STARTED = 'ADD_PAYMENT_STARTED'
export const ADD_PAYMENT_ENDED = 'ADD_PAYMENT_ENDED'

export const ADD_DEFAULT_PAYMENT_STARTED = 'ADD_DEFAULT_PAYMENT_STARTED'
export const ADD_DEFAULT_PAYMENT_ENDED = 'ADD_DEFAULT_PAYMENT_ENDED'

export const CLEAR_PAYMENTS = 'CLEAR_PAYMENTS'
export const CLEAR_PAYMENTS_LOADING = 'CLEAR_PAYMENTS_LOADING'

const actions = {
    addDefaultPayment: (ruuid) => async (dispatch) => {
        dispatch({
            type: ADD_DEFAULT_PAYMENT_STARTED
        })
        await addDefaultPayment(ruuid).then((response) => {
            if (response.status === 200) {
                dispatch({
                    type: GET_PAYMENTS_SUCCESS,
                    payload: response.data.payments
                })
            }
        })
        dispatch({
            type: ADD_DEFAULT_PAYMENT_ENDED
        })
    },
    getPayments: (ruuid, successCb) => async (dispatch, getState) => {
        dispatch({
            type: GET_PAYMENTS_REQUEST
        })
        await getPayments(ruuid).then((response) => {
            if (response.status === 200) {
                dispatch({
                    type: GET_PAYMENTS_SUCCESS,
                    payload: response.data.payments
                })
                if (successCb) {
                    successCb(response?.data?.payments, getState().orderHistory.ordersHistoryFilter)
                }
            } else handleError(dispatch)(response, GET_PAYMENTS_ERROR, 'GET PAYMENTS ERROR')
        })
    },
    getPayment: (ruuid, uuid) => async (dispatch) => {
        dispatch({
            type: GET_PAYMENT_REQUEST
        })
        await getPayment(ruuid, uuid).then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_PAYMENT_SUCCESS,
                    payload: response.data
                })
            else handleError(dispatch)(response, GET_PAYMENT_ERROR, 'GET PAYMENT ERROR')
        })
    },
    addPayment: (ruuid, data, onClose) => async (dispatch) => {
        dispatch({
            type: ADD_PAYMENT_STARTED
        })
        await addPayment(ruuid, data).then((response) => {
            if (response.status === 200) {
                toast.success(response.data.message || 'ADD PAYMENT SUCCESS')
                onClose()
                dispatch({
                    type: GET_PAYMENTS_SUCCESS,
                    payload: response.data.payments
                })
            } else handleError(dispatch)(response, null, 'ADD PAYMENT ERROR')
        })
        dispatch({
            type: ADD_PAYMENT_ENDED
        })
    },
    editPayment: (ruuid, uuid, data, onClose) => async (dispatch) => {
        dispatch({
            type: EDIT_PAYMENT_STARTED
        })
        await editPayment(ruuid, uuid, data).then((response) => {
            if (response.status === 200) {
                toast.success(response.data.message || '')
                onClose()
                dispatch({
                    type: GET_PAYMENTS_SUCCESS,
                    payload: response.data.payments
                })
            } else handleError(dispatch)(response, null, 'EDIT PAYMENT ERROR')
        })
        dispatch({
            type: EDIT_PAYMENT_ENDED
        })
    },
    deletePayment: (ruuid, uuid, reset) => async (dispatch) => {
        dispatch({
            type: DELETE_PAYMENT_STARTED
        })
        await deletePayment(ruuid, uuid).then((response) => {
            if (response.status == 200) {
                toast.success(response.data.message || 'DELETE PAYMENT SUCCESS')
                reset()
                dispatch({
                    type: GET_PAYMENTS_SUCCESS,
                    payload: response.data.payments
                })
            }
        })
        dispatch({
            type: DELETE_PAYMENT_ENDED
        })
    }
}
export default actions