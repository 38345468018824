import React, { useState, useEffect } from "react";
import { NavLink as RouterLink, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { getSVG } from "../../utils/getSvg";
import { GetScreenSize } from "../../utils/screenSize";
import { Box, Button, Collapse, ListItem, useTheme, Link } from "@mui/material";
import layoutActions from "../../redux/actions/LayoutActions";
import { connect } from "react-redux";

const NavItem = ({
  active,
  children,
  depth,
  icon,
  info,
  open: openProp,
  path,
  title,
  sideNavChange,
  setIsOpen,
  priority,
  isOpen,
  ...other
}) => {
  const screenSize = GetScreenSize()
  const [open, setOpen] = useState();
  const history = useHistory();
  const theme = useTheme()
  useEffect(() => {
    if (priority)
      if (isOpen.includes(icon))
        setOpen(true)
      else
        setOpen(false)
  }, [isOpen])

  const handleToggle = (title) => {
    if (title === isOpen && priority)
      setIsOpen([])
    else if (priority)
      setIsOpen(title);
    else
      setOpen(!open)
  };

  let paddingLeft = 16;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  // Branch
  if (children) {
    return (
      <ListItem
        disableGutters
        sx={{
          display: "block",
          py: 0,
          marginTop: 1
        }}
      >
        <Button
          endIcon={
            getSVG('arrow-down-menu', active ? theme.palette.navItems.active.arrow : theme.palette.navItems.arrow, { width: 25, height: 25 }, open)
          }
          onClick={() => {
            handleToggle(icon);
          }}
          startIcon={getSVG(icon, active ? theme.palette.navItems.active.arrow : theme.palette.navItems.arrow, { width: 25, height: 25 })}
          sx={{
            color: priority ? "text.primary" : 'text.secondary',
            fontWeight: "700",
            justifyContent: "flex-start",
            pl: `${paddingLeft}px`,
            pr: "4px",
            py: "8px",
            textAlign: "left",
            textTransform: "none",
            width: "100%",
            cursor: 'pointer',
            ':hover': {
              backgroundColor: 'background.hover'
            },
            ...(active && {
              '&.MuiButtonBase-root': {
                backgroundColor: priority ? 'primary.main' : null,
              },
              color: priority ? 'navItems.active.text' : "navItems.active.subText",
              backgroundColor: priority ? 'primary.main' : null,
              fontWeight: "fontWeightBold",
            }),
          }}
          variant="text"
        >
          <Box sx={{ flexGrow: 1, display: 'flex' }}>
            {title}
          </Box>

        </Button>
        <Collapse sx={{ maxWidth: '100%' }} in={Boolean(open)}>{children}</Collapse>
      </ListItem>
    );
  }

  return (
    <ListItem
      disableGutters
      sx={{
        display: "flex",
        py: 0,
        marginTop: priority ? 1 : 0
      }}
    >
      <Button
        href={path}
        onClick={(event) => {
          event.preventDefault()
          history.push(path);
          if (priority) setIsOpen([]);
          sideNavChange(false);
        }}
        startIcon={getSVG(icon, active ? theme.palette.navItems.active.arrow : theme.palette.navItems.arrow, { width: 25, height: 25 })}
        sx={{
          color: priority ? "text.primary" : 'text.secondary',
          fontWeight: "700",
          justifyContent: "flex-start",
          textAlign: "left",
          pl: `${paddingLeft}px`,
          pr: "8px",
          py: "8px",
          textTransform: "none",
          width: "100%",
          cursor: 'pointer',
          ':hover': {
            backgroundColor: 'background.hover'
          },
          ...(active && {
            '&.MuiButtonBase-root': {
              backgroundColor: priority ? 'primary.main' : null,
            },
            color: priority ? 'navItems.active.text' : "navItems.active.subText",
            backgroundColor: priority ? 'primary.main' : null,
            fontWeight: "fontWeightBold",
          }),
        }}
        variant="text"
      >
        <Box sx={{ flexGrow: 1 }}>
          {title}
        </Box>
      </Button>
    </ListItem>
  );
};

NavItem.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  depth: PropTypes.number.isRequired,
  icon: PropTypes.node,
  info: PropTypes.node,
  open: PropTypes.bool,
  path: PropTypes.string,
  title: PropTypes.string.isRequired,
};

NavItem.defaultProps = {
  active: false,
  open: false,
};
const mapStateToProps = (state) => ({
  isOpen: state.layout.isOpen,
});
const mapDispatchToProps = (dispatch) => ({
  sideNavChange: (state) => dispatch(layoutActions.sideNavChange(state)),
  setIsOpen: (state) => dispatch(layoutActions.setIsOpen(state))
});

export default connect(mapStateToProps, mapDispatchToProps)(NavItem);
