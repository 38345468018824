import React, { lazy, useEffect } from 'react'
import LayoutActions from '../../redux/actions/LayoutActions'
import { Switch, withRouter, Route, Redirect } from 'react-router-dom'
import UserRoute from '../../routers/UserRoute'
import { connect } from 'react-redux'
import { Box } from '@mui/system'
import branchesActions from '../../redux/actions/BranchActions'
import Loading from '../../components/loaders/Loading'

const components = {
    urlMenu: lazy(() => import('../../pages/tableOrdering/UrlMenu')),
    tables: lazy(() => import('../../pages/tableOrdering/TablesActions')),
    actionButtons: lazy(() => import('../../pages/tableOrdering/ActionButtons')),
};

function TableOrderingLayout({
    tableOrderingActiveTab,
    setPathCheck,
    permission,
    reformat,
    selectedRestaurant,
    getBranchesTableOrdering,
    getBranchesQRMenu,
    loading
}) {
    const access = reformat(permission.subPage)
        .sort((a, b) => {
            // Check if either 'order' is null
            if (a.order === null && b.order === null) {
                return 0; // Keep the order unchanged if both are null
            } else if (a.order === null) {
                return 1; // Place 'a' (null) after 'b' (non-null)
            } else if (b.order === null) {
                return -1; // Place 'b' (null) after 'a' (non-null)
            }
            // Sort non-null 'order' values normally
            return a.order - b.order;
        });

    useEffect(() => {
        setPathCheck(permission.route)
    }, [window.location.pathname])
    useEffect(() => {
        if (access?.some((perm) => perm.key !== 'urlMenu'))
            getBranchesTableOrdering(selectedRestaurant)
        if (access?.some((perm) => perm.key === 'urlMenu'))
            getBranchesQRMenu(selectedRestaurant)
    }, [selectedRestaurant, access])
    return (
        <div>
            {loading && <Loading />}
            <Box>
                <Switch>
                    <Route exact path={permission.route} render={() => <Redirect to={access[0]?.route} />} />
                    {access.map((permission2, i) =>
                        <UserRoute key={i} exact path={permission2.route} component={components[permission2.key]} permission={permission2} />
                    )}
                    {/* <UserRoute exact path="/ordering/table-ordering-setup/url-menu" component={UrlMenu} />
                    <UserRoute exact path="/ordering/table-ordering-setup/tables" component={Tables} />
                    <UserRoute exact path="/ordering/table-ordering-setup/tables/actions" component={TablesActions} />
                    <UserRoute exact path="/ordering/table-ordering-setup/action-buttons" component={ActionButtons} /> */}
                    <Redirect from="*" to="/not-found" />
                </Switch>
            </Box>
        </div>
    )
}
const mapStateToProps = (state) => ({
    tableOrderingActiveTab: state.layout.tableOrderingActiveTab,
    selectedRestaurant: state.rest.selectedRest,
    loading: state.branch.loading || state.rest.loading,
})
const mapDispatchToProps = (dispatch) => ({
    setPathCheck: (data) => dispatch(LayoutActions.setPathCheck(data)),
    getBranchesTableOrdering: (ruuid) => dispatch(branchesActions.getBranchesTableOrdering(ruuid)),
    getBranchesQRMenu: (ruuid) => dispatch(branchesActions.getBranchesQRMenu(ruuid))
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TableOrderingLayout))