import React, { lazy, useEffect } from 'react'
import LayoutActions from '../../redux/actions/LayoutActions'
import { Switch, withRouter, Route, Redirect } from 'react-router-dom'
import UserRoute from '../../routers/UserRoute'
import { connect } from 'react-redux'
import { Box } from '@mui/system'
import LoyaltyRewardsLayout from './LoyaltyRewardsLayout'
import branchesActions from '../../redux/actions/BranchActions'

const components = {
    pointsCollection: lazy(() => import('../../pages/loyalty/PointsCollection')),
    loyaltyProgram: lazy(() => import('../../pages/loyalty/LoyaltyProgram')),
    customersActivity: lazy(() => import('../../pages/loyalty/CustomersActivity')),
};

function LoyaltyLayout({
    setPathCheck,
    permission,
    reformat,
    getBranchesLoyalty,
    selectedRestaurant
}) {
    const access = reformat(permission.subPage)
        .sort((a, b) => {
            // Check if either 'order' is null
            if (a.order === null && b.order === null) {
                return 0; // Keep the order unchanged if both are null
            } else if (a.order === null) {
                return 1; // Place 'a' (null) after 'b' (non-null)
            } else if (b.order === null) {
                return -1; // Place 'b' (null) after 'a' (non-null)
            }
            // Sort non-null 'order' values normally
            return a.order - b.order;
        });
    useEffect(() => {
        if (!window.location.pathname.includes(permission.subLayouts[0]?.route))
            setPathCheck(permission.route)
    }, [window.location.pathname])
    useEffect(() => {
        getBranchesLoyalty(selectedRestaurant)
    }, [selectedRestaurant])
    return (
        <div>
            <Box>
                <Switch>
                    {permission.subLayouts?.map((subLay, i) =>
                        <Route
                            key={i}
                            path={subLay.route}
                            render={(props) => <LoyaltyRewardsLayout {...props} permission={subLay} reformat={reformat} />}
                        />)}
                    <Route exact path={permission.route} render={() => <Redirect to={access[0]?.route} />} />
                    {access.map((permission2, i) =>
                        <UserRoute key={i} exact path={permission2.route} component={components[permission2.key]} permission={permission2} />
                    )}
                    {/* <UserRoute exact path="/crm/loyalty/points-collection" component={PointsCollection} />
                    <UserRoute exact path="/crm/loyalty/loyalty-program" component={LoyaltyProgram} />
                    <UserRoute exact path="/crm/loyalty/customers-activity" component={CustomersActivity} /> */}
                    <Redirect from="*" to="/not-found" />
                </Switch>
            </Box>
        </div>
    )
}
const mapStateToProps = (state) => ({
    selectedRestaurant: state.rest.selectedRest
})
const mapDispatchToProps = (dispatch) => ({
    setPathCheck: (data) => dispatch(LayoutActions.setPathCheck(data)),
    getBranchesLoyalty: (ruuid) => dispatch(branchesActions.getBranchesLoyalty(ruuid))
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoyaltyLayout))