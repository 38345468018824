import React from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import LoadingImage from '../../assets/images/loading-animation.gif'

const Loading = () => {
  const styles = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(1, 1, 1, 0.5)",
    zIndex: 9999,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  return (
    <Box sx={styles}>
      {/* <CircularProgress /> */}
      <img src={LoadingImage} width={400} />
      

    </Box>
  );
};

export default Loading;
