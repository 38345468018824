import {
    GET_PRODUCTS_AVAILABILITY_REQUEST,
    GET_PRODUCTS_AVAILABILITY_SUCCESS,
    GET_PRODUCTS_AVAILABILITY_ERROR,

    UPDATE_PRODUCTS_AVAILABILITY_STARTED,
    UPDATE_PRODUCTS_AVAILABILITY_ENDED,
    CLEAR_PRODUCTS_AVAILABILITY_LOADING
} from '../actions/ProductsAvailabilityActions'

const initialState = {
    loading: false,
    products: [],
    selectedRestaurant: '',
    selectedBranch: '',
    updateLoading: false
}

function productsAvailability(state = initialState, action) {
    switch (action.type) {
        case CLEAR_PRODUCTS_AVAILABILITY_LOADING:
            return Object.assign({}, state, {
                loading: false,
                updateLoading: false
            })
        case GET_PRODUCTS_AVAILABILITY_REQUEST:
            return Object.assign({}, state, {
                loading: true,
                selectedRestaurant: action.payload.ruuid,
                selectedBranch: action.payload.bruuid
            })
        case GET_PRODUCTS_AVAILABILITY_SUCCESS:
            return Object.assign({}, state, {
                loading: false,
                products: action.payload
            })
        case GET_PRODUCTS_AVAILABILITY_ERROR:
            return Object.assign({}, state, {
                products: [],
                loading: false
            })
        case UPDATE_PRODUCTS_AVAILABILITY_STARTED:
            return Object.assign({}, state, {
                updateLoading: true
            })
        case UPDATE_PRODUCTS_AVAILABILITY_ENDED:
            return Object.assign({}, state, {
                updateLoading: false
            })
        default:
            return state
    }
}
export default productsAvailability