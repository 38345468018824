import {
  SET_SIDENAV_SETTINGS,
  SET_IS_OPEN, UPDATE_HEADER_STATE,
  SET_PATH_CHECK,
  CLEAR_LAYOUT,
  SET_PRODUCTS_ATTRIBUTES_VIEW
} from '../actions/LayoutActions'

const initialState = {
  settings: {
    dir: 'ltr', // ltr, rtl
    layoutSettings: {
      sidebar: {
        open: false,
      },
    },
  },
  isOpen: [],
  headerHidden: false,
  pathCheck: '',
  productsAttributesView: false
}

const LayoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PRODUCTS_ATTRIBUTES_VIEW:
      return Object.assign({}, state, {
        productsAttributesView: action.payload
      })
    case SET_SIDENAV_SETTINGS:
      return Object.assign({}, state, {
        settings: {
          ...state.settings,
          layoutSettings: {
            ...state.settings.layoutSettings,
            sidebar: {
              open: action.payload
            }
          }
        },
      })
    case SET_IS_OPEN:
      return Object.assign({}, state, {
        isOpen: action.payload
      })
    case UPDATE_HEADER_STATE:
      return Object.assign({}, state, {
        headerHidden: action.payload
      })
    case SET_PATH_CHECK:
      return Object.assign({}, state, {
        pathCheck: action.payload
      })
    case CLEAR_LAYOUT:
      return initialState
    default:
      return state
  }
}

export default LayoutReducer
