import {
  SET_TOTAL_DRIVERS_CHECKED,
   SET_TOTAL_SALES_CHECKED,
   SET_DISCOUNTS_CHECKED,
   SET_PROMOTIONS_CHECKED,
   SET_TOTAL_STATUSES_CHECKED,
   SET_TAXES_CHECKED,
   SET_NET_SALES_CHECKED,
   SET_SALES_BY_ITEMS_CHECKED,
   SET_SALES_SUMMARY_BY_EMPLOYEE_CHECKED,
   SET_SALES_BY_WORKSTATION_CHECKED,
   SET_SALES_BY_CATEGORIES_CHECKED,
   SET_SALES_BY_GROUP_CHECKED,
   SET_SALES_BY_PAYMENTS_CHECKED,
   SET_FIRST_ORDER_CHECKED,
   SET_LAST_ORDER_CHECKED,
   SET_FILTER,

   GET_DAILY_REPORT_REQUEST,
   GET_DAILY_REPORT_SUCCESS,
   GET_DAILY_REPORT_ERROR,

   GET_DAILY_REPORT_DATES_REQUEST,
   GET_DAILY_REPORT_DATES_SUCCESS,
   GET_DAILY_REPORT_DATES_ERROR,

   CLEAR_DAILY_REPORT,
   CLEAR_DAILY_REPORT_LOADING
  } from '../actions/DailyReportActions'
  
  const initialState = {
    totalDriversChecked: true,
    totalSalesChecked: true,
    discountsChecked: true,
    promotionsChecked: true,
    totalStatusesChecked: true,
    taxesChecked: true,
    netSalesChecked: true,
    salesByItemsChecked: true,
    salesSummaryByEmployeeChecked: true,
    salesByWorkstationChecked: true,
    salesByCategoriesChecked: true,
    salesByGroupChecked: true,
    salesByPayments: true,
    firstOrderChecked: true,
    lastOrderChecked: true,
    filter : {
      eodRange: { 
      value: "last-7-days", 
      from: null,
      to: null 
    }
    },
    //DAILY REPORT DATA
    totalSales: null,
    discounts: null,
    promoDiscounts: null,
    totalStatuses: null,
    totalDrivers: null,
    salesByItems: null,
    salesByCategory: null,
    salesByPayments: null,
    firstLastOrder: null,
    getDailyReportLoading: false,

    //REPORT DATES
    getReportDatesLoading: false,
    reportDates: null
  }
  
  function dailyReport(state = initialState, action) {
    switch (action.type) {
      case SET_TOTAL_DRIVERS_CHECKED:
        return Object.assign({}, state, {
          totalDriversChecked: action.payload.value
        })
      case SET_TOTAL_SALES_CHECKED:
        return Object.assign({}, state, {
          totalSalesChecked: action.payload.value
        })
      case SET_DISCOUNTS_CHECKED:
        return Object.assign({}, state, {
          discountsChecked: action.payload.value
        })
      case SET_PROMOTIONS_CHECKED:
        return Object.assign({}, state, {
          promotionsChecked: action.payload.value
        })
      case SET_TOTAL_STATUSES_CHECKED:
        return Object.assign({}, state, {
          totalStatusesChecked: action.payload.value
        })
      case SET_TAXES_CHECKED:
        return Object.assign({}, state, {
          taxesChecked: action.payload.value
        })
      case SET_NET_SALES_CHECKED:
        return Object.assign({}, state, {
          netSalesChecked: action.payload.value
        })
      case SET_SALES_BY_ITEMS_CHECKED:
        return Object.assign({}, state, {
          salesByItemsChecked: action.payload.value
        })
      case SET_SALES_SUMMARY_BY_EMPLOYEE_CHECKED:
        return Object.assign({}, state, {
         salesSummaryByEmployeeChecked: action.payload.value
        })
      case SET_SALES_BY_WORKSTATION_CHECKED:
        return Object.assign({}, state, {
         salesByWorkstationChecked: action.payload.value
        })
      case SET_SALES_BY_CATEGORIES_CHECKED:
        return Object.assign({}, state, {
          salesByCategoriesChecked: action.payload.value
        })
      case SET_SALES_BY_GROUP_CHECKED:
        return Object.assign({}, state, {
          salesByGroupChecked: action.payload.value
        })
      case SET_SALES_BY_PAYMENTS_CHECKED:
        return Object.assign({}, state, {
          salesByPaymentsChecked: action.payload.value
        })
      case SET_FIRST_ORDER_CHECKED:
        return Object.assign({}, state, {
          firstOrderChecked: action.payload.value
        })
      case SET_LAST_ORDER_CHECKED:
        return Object.assign({}, state, {
          lastOrderChecked: action.payload.value
        })
      case SET_FILTER:
         return Object.assign({}, state, {
            filter: action.payload.value
        })
      case GET_DAILY_REPORT_REQUEST:
         return Object.assign({}, state, {
            getDailyReportLoading: true,
       });
      case GET_DAILY_REPORT_SUCCESS:
         return Object.assign({}, state, {
            getDailyReportLoading: false,
            //ADD REPORT DATA
            totalSales: action.payload?.totalSales || null,
            discounts: action.payload?.discounts || null,
            promoDiscounts: action.payload?.promoDiscounts || null,
            totalStatuses: action.payload?.stausSection || null,
            totalDrivers: action.payload?.totalDrivers || null,
            salesByItems: action.payload?.salesByItems || null,
            salesByCategory: action.payload?.salesByCategory || null,
            salesByPayments: action.payload.salesByPayments || null,
            firstLastOrder: action.payload?.firstLastOrder || null,
       });
      case GET_DAILY_REPORT_ERROR:
         return Object.assign({}, state, {
             getDailyReportLoading: false
       });
       case GET_DAILY_REPORT_DATES_REQUEST:
        return Object.assign({}, state, {
           getDailyReportDatesLoading: true,
      });
     case GET_DAILY_REPORT_DATES_SUCCESS:
        return Object.assign({}, state, {
           getDailyReportDatesLoading: false,
           reportDates: action?.payload
      });
     case GET_DAILY_REPORT_DATES_ERROR:
        return Object.assign({}, state, {
            getDailyReportDatesLoading: false,
            reportDates: null
      });
      case CLEAR_DAILY_REPORT:
          return Object.assign({}, state, {
            totalSales: null,
            discounts: null,
            promoDiscounts: null,
            totalStatuses: null,
            totalDrivers: null,
            salesByItems: null,
            salesByCategory: null,
            salesByPayments: null,
            firstLastOrder: null,
            getDailyReportLoading: false,
            getReportDatesLoading: false,
            reportDates: null
        });
      case CLEAR_DAILY_REPORT_LOADING:
          return Object.assign({}, state, {
            getDailyReportLoading: false
        });
      default:
        return state
    }
  }
  
  export default dailyReport
  