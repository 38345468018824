import merge from "lodash/merge";
import { createTheme, responsiveFontSizes } from "@mui/material";
import { THEMES } from "./constants";
import { lightShadows, darkShadows } from "./shadows";
import { light } from "@mui/material/styles/createPalette";

const baseOptions = {
  direction: "ltr",
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& input[type=color]': {
            cursor: 'pointer',
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        fallback: {
          height: "75%",
          width: "75%",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        "*": {
          boxSizing: "border-box",
        },
        html: {
          MozOsxFontSmoothing: "grayscale",
          WebkitFontSmoothing: "antialiased",
          height: "100%",
          width: "100%",
        },
        body: {
          height: "100%",
        },
        "#root": {
          height: "100%",
        },
        "#nprogress .bar": {
          zIndex: "2000 !important",
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: "h6",
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          overflow: "hidden",
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: "auto",
          marginRight: "16px",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: "none",
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          cursor: "pointer",
        },
      },
    },
  },
  typography: {
    button: {
      fontWeight: 600,
    },
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
    h1: {
      fontWeight: 600,
      fontSize: "3.5rem",
    },
    h2: {
      fontWeight: 600,
      fontSize: "3rem",
    },
    h3: {
      fontWeight: 600,
      fontSize: "2.25rem",
    },
    h4: {
      fontWeight: 600,
      fontSize: "2rem",
    },
    h5: {
      fontWeight: 600,
      fontSize: "1.5rem",
    },
    h6: {
      fontWeight: 600,
      fontSize: "1.125rem",
    },
    overline: {
      fontWeight: 600,
    },
  },
};

const themesOptions = {
  [THEMES.LIGHT]: {
    components: {
      MuiInputBase: {
        styleOverrides: {
          input: {
            "&::placeholder": {
              opacity: 0.86,
              color: "#42526e",
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            fontWeight: "bold",
          },
        },
      },
    },
    palette: {
      chartColors: [
        '#FF6E6E',
        '#938AE1',
        '#76C96D',
        '#6BA7F5',
        '#FE9E21',
        '#D567B4',
        '#0DAA94',
        '#CACE4C',
        '#B96161',
        '#D1AC77',
        '#7FCEE2',
        '#B986EC',
        '#B81955',
        '#B9D653',
        '#CF30AF'
      ],
      dashboardWarning: {
        high: {
          main: '#D22626',
          contrastText: "#ffffff",
          svgColor: "#ee404c"
        },
        medium: {
          main: '#e3750a',
          contrastText: "#ffffff",
          svgColor: "#ffa30b"
        },
        low: {
          main: '#e3c500',
          contrastText: "#ffffff",
          svgColor: "#ffee00"
        }
      },
      card: {
        background: '#f4f5f7'
      },
      chart: {
        even: "#7648a3",
        odd: "#b493be",
      },
      white: "#fff",
      darkGrey: "#5B5B5B",
      border: "#BDBDBD",
      disabled: "#d6d7d9",
      overlay: "rgba(0, 0, 0, 0.50)",
      boxShadow: "#6b778c",
      box: "#663e83",
      white: "#fff",
      secondaryTitle: "#6b778c",
      svg: "#5b5b5b",
      // delete: "#d95c5c",
      delete: "#BD3737",
      autoMessages: {
        promoBoxBackground: 'rgba(235, 66, 66, 0.2)',
        promoCode: '#EB4242 '
      },
      sendType: {
        sms: '#E3C500',
        email: '#1CB1DE',
        notification: '#E3750A',
        whatsapp: '#83BD37'
      },
      selected: {
        chip: "#86469a",
      },
      orderStatus: {
        isPaid: "#1FB719",
        notPaid: "#EB4242",
      },
      userMenu: {
        icons: "white",
      },
      pageTitle: "#5b5b5b",
      rateStar: "#97c24c",
      navItems: {
        active: {
          subText: "#723087",
          text: "#ffff",
          arrow: "white",
        },
        arrow: "black",
      },
      menuButton: {
        activeBackgroundColor: "#dadada",
        backgroundColor: "#ffffff",
      },
      titleBox: {
        contrastText: "#ffffff",
        backgroundColor: "#723087",
      },
      action: {
        active: "#6b778c",
      },
      background: {
        default: "#f4f5f7",
        paper: "#ffffff",
        hover: "#e5dee7",
        selected: "#eee3f6",
        chipSelected: "#c0a9c6",
        tableDropdown: "#c5c5c5",
        tableEven: "#eaebed",
        tableOdd: "#f4f5f7",
        shape: '#B493BE72',
      },
      activity: {
        isActive: "#7648a3",
        isInactive: "#D1D1D1",
        isAway: "#FFBD08",
      },
      error: {
        contrastText: "#ffffff",
        main: "#ff0000",
        light: '#ffcccc'
      },
      secondary: {
        contrastText: "#ffffff",
        main: "#5b5b5b",
        light: '#9D9D9D'
      },
      whiteButton: {
        contrastText: "#5b5b5b",
        main: "#f5f5f5",
      },
      primaryContrast: {
        contrastText: "#172b4d",
        main: "#ffffff",
      },
      mode: "light",
      primary: {
        contrastText: "#ffffff",
        main: "#723087",
        light: '#f4e1fe',
      },
      secondaryTable: {
        contrastText: "#ffffff",
        main: "#cecece",
        mainLight: '#b493be'
      },
      success: {
        contrastText: "#ffffff",
        main: "#4caf50",
      },
      text: {
        primary: "#172b4d",
        secondary: "#6b778c",
        third: '#000'
      },
      warning: {
        contrastText: "#ffffff",
        main: "#ff9800",
      },
    },
    shadows: lightShadows,
  },
  [THEMES.DARK]: {
    components: {
      MuiTableHead: {
        root: {
          borderRadius: 5,
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: "1px solid rgba(145, 158, 171, 0.24)",
          },
        },
      },
    },
    palette: {
      chartColors: [
        '#E81818',
        '#E28000',
        '#4ED040',
        '#40D0BC',
        '#6456E2',
        '#D040A5',
        '#0A74FF',
        '#9DA200',
        '#D28080',
        '#B87819',
        '#00B2DF',
        '#9933FF',
        '#EC699A',
        '#E3BE00',
        '#F29B17'
      ],
      background: {
        default: "#171c24",
        paper: "#222b36",
      },
      divider: "rgba(145, 158, 171, 0.24)",
      box: "#b975e0",
      titleBox: {
        contrastText: "#ffffff",
        backgroundColor: "#688eff",
      },
      error: {
        contrastText: "#ffffff",
        main: "#f44336",
        light: '#ffcccc',
      },
      mode: "dark",
      primary: {
        contrastText: "#ffffff",
        main: "#688eff",
        light: '#f4e1fe',
      },
      secondary: {
        contrastText: "#ffffff",
        main: "#5b5b5b",
        light: '#9D9D9D'
      },
      success: {
        contrastText: "#ffffff",
        main: "#4caf50",
      },
      text: {
        primary: "#ffffff",
        secondary: "#919eab",
        disabled: "#B4B4B4",

      },
      warning: {
        contrastText: "#ffffff",
        main: "#ff9800",
      },
    },
    shadows: darkShadows,
  },
  [THEMES.NATURE]: {
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: "1px solid rgba(145, 158, 171, 0.24)",
          },
        },
      },
    },
    palette: {
      background: {
        default: "#1c2531",
        paper: "#293142",
      },
      divider: "rgba(145, 158, 171, 0.24)",
      error: {
        contrastText: "#ffffff",
        main: "#f44336",
      },
      mode: "dark",
      primary: {
        contrastText: "#ffffff",
        main: "#01ab56",
        light: "#7648A3",
      },
      success: {
        contrastText: "#ffffff",
        main: "#4caf50",
      },
      text: {
        primary: "#ffffff",
        secondary: "#919eab",
      },
      warning: {
        contrastText: "#ffffff",
        main: "#ff9800",
      },
    },
    shadows: darkShadows,
  },
};

export const createCustomTheme = (config = {}) => {
  let themeOptions = themesOptions[config.theme];

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    themeOptions = themesOptions[THEMES.LIGHT];
  }

  let theme = createTheme(
    merge(
      {},
      baseOptions,
      themeOptions,
      // {
      //   ...(config.roundedCorners && {
      //     shape: {
      //       borderRadius: 16,
      //     },
      //   }),
      // },
      {
        direction: config.direction,
      }
    )
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
