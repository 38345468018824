import {
    GET_PLATFORMS_REQUEST,
    GET_PLATFORMS_SUCCESS,
    GET_PLATFORMS_ERROR,

    ADD_PLATFORM_STARTED,
    ADD_PLATFORM_ENDED,

    EDIT_PLATFORM_STARTED,
    EDIT_PLATFORM_ENDED,

    DELETE_PLATFORM_STARTED,
    DELETE_PLATFORM_ENDED,

    CLEAR_ALL_PLATFORMS,
    CLEAR_PLATFORM_LOADING
} from '../actions/PlatformActions'

const initialState = {
    offersPlatforms: [],
    loading: false
}

function platform(state = initialState, action) {
    switch (action.type) {
        case CLEAR_PLATFORM_LOADING:
            return Object.assign({}, state, {
                loading: false
            })
        case GET_PLATFORMS_REQUEST:
            return Object.assign({}, state, {
                loading: true
            })
        case GET_PLATFORMS_SUCCESS:
            return Object.assign({}, state, {
                loading: false,
                offersPlatforms: action.payload.offersPlatforms
            })
        case GET_PLATFORMS_ERROR:
            return Object.assign({}, state, {
                loading: false,
                offersPlatforms: []
            })
        case ADD_PLATFORM_STARTED:
            return Object.assign({}, state, {
                loading: true
            })
        case ADD_PLATFORM_ENDED:
            return Object.assign({}, state, {
                loading: false
            })
        case EDIT_PLATFORM_STARTED:
            return Object.assign({}, state, {
                loading: true
            })
        case EDIT_PLATFORM_ENDED:
            return Object.assign({}, state, {
                loading: false
            })
        case DELETE_PLATFORM_STARTED:
            return Object.assign({}, state, {
                loading: true
            })
        case DELETE_PLATFORM_ENDED:
            return Object.assign({}, state, {
                loading: false
            })
        case CLEAR_ALL_PLATFORMS:
            return initialState
        default:
            return state
    }
}
export default platform