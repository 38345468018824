
const unitMeasurement = [
    {
      id: '1',
      uom:'KG',
      linkedToUom:'Gr',
      conversion:'1000',
    },


    {
      id: '3',
      uom:'Liter',
      linkedToUom:'CL',
      conversion:'100',
    },
    {
        id: '4',
        uom:'Liter',
        linkedToUom:'ML',
        conversion:'1000',
      },
  
      {
        id: '5',
        uom:'KG',
        linkedToUom:'Gr',
        conversion:'1000',
      },
      {
        id: '6',
        uom:'',
        linkedToUom:'',
        conversion:'',
      },
      {
        id: '7',
        uom:'Liter',
        linkedToUom:'CL',
        conversion:'100',
      },
  
      {
        id: '8',
        uom:'Liter',
        linkedToUom:'ML',
        conversion:'1000',
      },
      {
        id: '9',
        uom:'KG',
        linkedToUom:'Gr',
        conversion:'1000',
      },
  
      {
        id: '10',
        uom:'',
        linkedToUom:'',
        conversion:'',
      },
      {
        id: '11',
        uom:'Liter',
        linkedToUom:'CL',
        conversion:'100',
      },
      {
          id: '12',
          uom:'Liter',
          linkedToUom:'ML',
          conversion:'1000',
        },
    
        {
          id: '13',
          uom:'KG',
          linkedToUom:'Gr',
          conversion:'1000',
        },
        {
          id: '14',
          uom:'',
          linkedToUom:'',
          conversion:'',
        },
        {
          id: '15',
          uom:'Liter',
          linkedToUom:'CL',
          conversion:'100',
        },
    
        {
          id: '16',
          uom:'Liter',
          linkedToUom:'ML',
          conversion:'1000',
        },

];

export default unitMeasurement;