import {
    getAllDiscount,
    getDiscount,
    addDiscount,
    editDiscount,
    deleteDiscount,
    sortDiscount,
    editDiscountSettings,
    getDiscountSettings
} from '../../config/config'

import { LOGOUT_USER, SET_PERMISSIONS } from './AuthActions'
import { toast } from 'react-toastify'
import { handleError } from '../../utils/utils'

export const GET_ALL_DISCOUNT_REQUEST = 'GET_ALL_DISCOUNT_REQUEST'
export const GET_ALL_DISCOUNT_SUCCESS = 'GET_ALL_DISCOUNT_SUCCESS'
export const GET_ALL_DISCOUNT_ERROR = 'GET_ALL_DISCOUNT_ERROR'

export const GET_DISCOUNT_REQUEST = 'GET_DISCOUNT_REQUEST'
export const GET_DISCOUNT_SUCCESS = 'GET_DISCOUNT_SUCCESS'
export const GET_DISCOUNT_ERROR = 'GET_DISCOUNT_ERROR'

export const GET_DISCOUNT_SETTINGS_REQUEST = 'GET_DISCOUNT_SETTINGS_REQUEST'
export const GET_DISCOUNT_SETTINGS_SUCCESS = 'GET_DISCOUNT_SETTINGS_SUCCESS'
export const GET_DISCOUNT_SETTINGS_ERROR = 'GET_DISCOUNT_SETTINGS_ERROR'

export const ADD_DISCOUNT_STARTED = 'ADD_DISCOUNT_STARTED'
export const ADD_DISCOUNT_ENDED = 'ADD_DISCOUNT_ENDED'

export const EDIT_DISCOUNT_STARTED = 'EDIT_DISCOUNT_STARTED'
export const EDIT_DISCOUNT_ENDED = 'EDIT_DISCOUNT_ENDED'

export const EDIT_DISCOUNT_SETTINGS_STARTED = 'EDIT_DISCOUNT_SETTINGS_STARTED'
export const EDIT_DISCOUNT_SETTINGS_ENDED = 'EDIT_DISCOUNT_SETTINGS_ENDED'

export const DELETE_DISCOUNT_STARTED = 'DELETE_DISCOUNT_STARTED'
export const DELETE_DISCOUNT_ENDED = 'DELETE_DISCOUNT_ENDED'

export const SORT_DISCOUNT_STARTED = 'SORT_DISCOUNT_STARTED'
export const SORT_DISCOUNT_ENDED = 'SORT_DISCOUNT_ENDED'

export const SET_EDIT_DISCOUNT = 'SET_EDIT_DISCOUNT'

export const SET_DISCOUNTS_FILTER = 'SET_DISCOUNTS_FILTER'

export const CLEAR_ALL_DISCOUNT = 'CLEAR_ALL_DISCOUNT'

export const CLEAR_DISCOUNT_LOADING = 'CLEAR_DISCOUNT_LOADING'

const actions = {
    setDiscountsFilter: (data) => (dispatch) => {
        dispatch({
            type: SET_DISCOUNTS_FILTER,
            payload: data
        })
    },
    getAllDiscount: (ruuid) => async (dispatch, getState) => {
        const currentRestaurant = getState().discounts.selectedRestaurant
        if (ruuid !== currentRestaurant)
            dispatch({
                type: GET_ALL_DISCOUNT_REQUEST,
                payload: ruuid
            })
        await getAllDiscount(ruuid).then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_ALL_DISCOUNT_SUCCESS,
                    payload: response.data.discounts
                })
            else {
                handleError(dispatch)(response, GET_ALL_DISCOUNT_ERROR, 'GET ALL DISCOUNT ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error(response.data.message || 'GET DISCOUNTS ERROR')
                // dispatch({
                //     type: GET_ALL_DISCOUNT_ERROR
                // })
                // if (response.status === 401)
                //     dispatch({
                //         type: LOGOUT_USER
                //     })
                // if (response.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: response?.data?.permissions
                //     })
                // }
            }
        })
    },
    getDiscount: (ruuid, uuid) => async (dispatch) => {
        dispatch({
            type: GET_DISCOUNT_REQUEST,
        })
        await getDiscount(ruuid, uuid).then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_DISCOUNT_SUCCESS,
                    payload: response.data.discount
                })
            else {
                handleError(dispatch)(response, GET_DISCOUNT_ERROR, 'GET DISCOUNT ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error(response.data.message || 'GET DISCOUNT ERROR')
                // dispatch({
                //     type: GET_DISCOUNT_ERROR
                // })
                // if (response.status === 401)
                //     dispatch({
                //         type: LOGOUT_USER
                //     })
                // if (response.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: response?.data?.permissions
                //     })
                // }
            }
        })
    },
    addDiscount: (ruuid, data, history) => async (dispatch) => {
        dispatch({
            type: ADD_DISCOUNT_STARTED
        })
        await addDiscount(ruuid, data).then((response) => {
            if (response.status === 200) {
                history.push('/crm/offers/discount')
                toast.success(response.data.message || 'Discount added')
                dispatch({
                    type: GET_ALL_DISCOUNT_SUCCESS,
                    payload: response.data.discounts
                })
            } else {
                handleError(dispatch)(response, null, 'ADD DISCOUNT ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error(response.data.message || 'Add discount error')
            }
        })
        dispatch({
            type: ADD_DISCOUNT_ENDED
        })
    },
    editDiscount: (ruuid, uuid, data, history, setEditDiscount) => async (dispatch) => {
        dispatch({
            type: EDIT_DISCOUNT_STARTED
        })
        await editDiscount(ruuid, uuid, data).then((response) => {
            if (response.status === 200) {
                history.push('/crm/offers/discount')
                setEditDiscount(response.data.discounts.find((disc) => disc.uuid === uuid))
                toast.success(response.data.message || 'Edit discount success')
                dispatch({
                    type: GET_ALL_DISCOUNT_SUCCESS,
                    payload: response.data.discounts
                })
            } else {
                handleError(dispatch)(response, null, 'EDIT DISCOUNT ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error(response.data.message || 'Edit discount error')
                // if (response.status === 401)
                //     dispatch({
                //         type: LOGOUT_USER
                //     })
                // if (response.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: response?.data?.permissions
                //     })
                // }
            }
        })
        dispatch({
            type: EDIT_DISCOUNT_ENDED
        })
    },
    getDiscountSettings: (ruuid) => async (dispatch, getState) => {
        const currentRestaurant = getState().discounts.selectedRestaurantSettings
        if (ruuid !== currentRestaurant)
            dispatch({
                type: GET_DISCOUNT_SETTINGS_REQUEST,
                payload: ruuid
            })
        await getDiscountSettings(ruuid).then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_DISCOUNT_SETTINGS_SUCCESS,
                    payload: response.data.settings
                })
            else {
                handleError(dispatch)(response, GET_DISCOUNT_SETTINGS_ERROR, 'GET DISCOUNT SETTINGS ERROR')
                // dispatch({
                //     type: GET_DISCOUNT_SETTINGS_ERROR
                // })
                // if (response.status === 401)
                //     dispatch({
                //         type: LOGOUT_USER
                //     })
                // if (response.status === 403)
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: response.data.permissions
                //     })
            }
        })
    },
    editDiscountSettings: (ruuid, data) => async (dispatch) => {
        dispatch({
            type: EDIT_DISCOUNT_SETTINGS_STARTED
        })
        await editDiscountSettings(ruuid, data).then((response) => {
            if (response.status === 200) {
                toast.success(response.data.message || 'EDIT DISCOUNT SETTINGS SUCCESS')
                dispatch({
                    type: GET_DISCOUNT_SETTINGS_SUCCESS,
                    payload: response.data.settings
                })
            } else {
                handleError(dispatch)(response, null, 'EDIT DISCOUNT SETTINGS ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error(response?.data?.message || 'EDIT DISCOUNT SETTINGS ERROR')
                // if (response.status === 401)
                //     dispatch({
                //         type: LOGOUT_USER
                //     })
                // if (response.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: response?.data?.permissions
                //     })
                // }
            }
        })
        dispatch({
            type: EDIT_DISCOUNT_SETTINGS_ENDED
        })
    },
    deleteDiscount: (ruuid, uuid, close) => async (dispatch) => {
        dispatch({
            type: DELETE_DISCOUNT_STARTED
        })
        await deleteDiscount(ruuid, uuid).then((response) => {
            if (response.status === 200) {
                toast.success(response.data.message || 'Delete discount success')
                dispatch({
                    type: GET_ALL_DISCOUNT_SUCCESS,
                    payload: response.data.discounts
                })
                if (close)
                    close()
            } else {
                handleError(dispatch)(response, null, 'DELETE DISCOUNT ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error(response.data.message || 'Delete discount error')
                // if (response.status === 401)
                //     dispatch({
                //         type: LOGOUT_USER
                //     })
                // if (response.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: response?.data?.permissions
                //     })
                // }
            }
        })
        dispatch({
            type: DELETE_DISCOUNT_ENDED
        })
    },
    setEditDiscount: (data) => (dispatch) => {
        dispatch({
            type: SET_EDIT_DISCOUNT,
            payload: data
        })
    },
    sortDiscount: (ruuid, data) => async (dispatch) => {
        dispatch({
            type: SORT_DISCOUNT_STARTED
        })
        await sortDiscount(ruuid, data).then((response) => {
            if (response.status === 200) {
                toast.success(response?.data?.message || 'SORT DISCOUNTS SUCCESS')
                dispatch({
                    type: GET_ALL_DISCOUNT_SUCCESS,
                    payload: response?.data?.discounts
                })
            } else {
                handleError(dispatch)(response, null, 'SORT DISCOUNTS ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error(response?.data?.message || 'SORT DISCOUNTS ERROR')
                // if (response.status === 401) {
                //     dispatch({
                //         LOGOUT_USER
                //     })
                // }
                // if (response.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: response?.data?.permissions
                //     })
                // }

            }
        })
        dispatch({
            type: SORT_DISCOUNT_ENDED
        })
    }
}
export default actions