import {
  GET_ROLE_REQUEST,
  GET_ROLE_SUCCESS,
  GET_ROLE_ERROR,
  CLEAR_ALL_ROLE,
  ADD_ROLE_STARTED,
  ADD_ROLE_END,
  EDIT_ROLE_STARTED,
  EDIT_ROLE_END,
  DELETE_ROLE_STARTED,
  DELETE_ROLE_END,
  SET_ROLE_POPUP,
  GET_ALL_PAGES_SUCCESS,
  CLEAR_ROLE_LOADING
} from '../actions/RoleActions'

const initialState = {
  loading: false,
  roles: [],
  rolePopup: false,
  addLoading: false,
  editLoading: false,
  deleteLoading: false,
  allPages: []
}

function role(state = initialState, action) {
  switch (action.type) {
    case CLEAR_ROLE_LOADING:
      return Object.assign({}, state, {
        loading: false,
        addLoading: false,
        editLoading: false,
        deleteLoading: false,
      })
    case GET_ROLE_REQUEST:
      return Object.assign({}, state, {
        loading: true,
      })
    case GET_ROLE_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        roles: action.payload.roles,
        allPages: action.payload.allPages
      })
    case GET_ALL_PAGES_SUCCESS:
      return Object.assign({}, state, {
        allPages: action.payload
      })
    case GET_ROLE_ERROR:
      return Object.assign({}, state, {
        loading: false,
        roles: [],
        allPages: []
      })

    case ADD_ROLE_STARTED:
      return Object.assign({}, state, {
        addLoading: true,
      })

    case ADD_ROLE_END:
      return Object.assign({}, state, {
        addLoading: false,
      })

    case EDIT_ROLE_STARTED:
      return Object.assign({}, state, {
        editLoading: true,
      })

    case EDIT_ROLE_END:
      return Object.assign({}, state, {
        editLoading: false,
      })

    case DELETE_ROLE_STARTED:
      return Object.assign({}, state, {
        deleteLoading: true,
      })

    case DELETE_ROLE_END:
      return Object.assign({}, state, {
        deleteLoading: false,
      })

    case SET_ROLE_POPUP:
      return Object.assign({}, state, {
        rolePopup: action.payload,
      })

    case CLEAR_ALL_ROLE:
      return initialState

    default:
      return state
  }
}

export default role
