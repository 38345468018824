export const SET_DM_SELECTED_PLATFORM = 'SET_DM_SELECTED_PLATFORM'

const actions = {
    setSelectedDmPlatform: (data) => (dispatch) => {
        dispatch({
            type: SET_DM_SELECTED_PLATFORM,
            payload: data
        })
    },
}
export default actions