import { addAffiliateLink, getAffiliateLink, editAffiliateLink, deleteAffiliateLink } from '../../config/config'
import { toast } from 'react-toastify'
import { handleError } from '../../utils/utils'

export const ADD_URL_LINK_STARTED = 'ADD_URL_LINK_STARTED'
export const ADD_URL_LINK_ENDED = 'ADD_URL_LINK_ENDED'

export const GET_URL_LINK_REQUEST = 'GET_URL_LINK_REQUEST'
export const GET_URL_LINK_SUCCESS = 'GET_URL_LINK_SUCCESS'
export const GET_URL_LINK_ERROR = 'GET_URL_LINKR_ERROR'

export const EDIT_URL_LINK_STARTED = 'EDIT_URL_LINK_STARTED'
export const EDIT_URL_LINK_ENDED = 'EDIT_URL_LINKR_ENDED'

export const DELETE_URL_LINK_STARTED = 'DELETE_URL_LINK_STARTED'
export const DELETE_URL_LINK_ENDED = 'DELETE_URL_LINKR_ENDED'

export const CLEAR_URL_LINK_LOADING = 'CLEAR_URL_LINK_LOADING'

const actions = {

    addAffiliateLink: (ruuid, data) => async (dispatch) => {
        dispatch({
            type: ADD_URL_LINK_STARTED
        })
        await addAffiliateLink(ruuid, data).then((response) => {
            if (response.status === 200) {
                toast.success(response.data.message || 'ADD URL LINK SUCCESS')
                dispatch({
                    type: GET_URL_LINK_SUCCESS,
                    payload: response.data.affiliateLinks,
                })
            } else {
                handleError(dispatch)(response, null, 'ADD URL LINK ERROR')

            }
        })

        // const getUrlLinkResponse = await getAffiliateLink(ruuid);
        // if (getUrlLinkResponse.status === 200 && addedUrl && addedUrl === getUrlLinkResponse.data) {
        //     dispatch({
        //         type: GET_URL_LINK_SUCCESS,
        //         payload: getUrlLinkResponse.data.affiliateLinks
        //     });
        // }
        dispatch({
            type: ADD_URL_LINK_ENDED
        })
    },

    getAffiliateLink: (ruuid) => async (dispatch, getState) => {
        const currentRestaurant = getState().affiliateUrl.selectedRestaurantAffiliateUrl
        // if (ruuid !== currentRestaurant)
        //     console.log('test1')
        dispatch({
            type: GET_URL_LINK_REQUEST,
            payload: {
                ruuid: ruuid,

            }

        },
        )

        await getAffiliateLink(ruuid).then((response) => {
            if (response.status === 200) {
                //   toast.success('Get URL Successful Successful')
                dispatch({
                    type: GET_URL_LINK_SUCCESS,
                    payload: response.data.affiliateLinks,

                },)

            } else {
                handleError(dispatch)(response, GET_URL_LINK_ERROR, 'GET URL LINKS ERROR')
            }
        })
    },

    editAffiliateLink: (ruuid, uuid, data) => async (dispatch) => {

        dispatch({
            type: EDIT_URL_LINK_STARTED
        })
        await editAffiliateLink(ruuid, uuid, data).then((response) => {
            if (response.status === 200) {
                toast.success(response.data.message || 'Edit Affiliate Link Success')
                dispatch({
                    type: GET_URL_LINK_SUCCESS,
                    payload: response.data.affiliateLinks
                })
            }
            else {
                handleError(dispatch)(response, null, 'EDIT URL LINK ERROR')

            }
        })
        dispatch({
            type: EDIT_URL_LINK_ENDED
        })
    },

    deleteAffiliateLink: (ruuid, uuid) => async (dispatch) => {
        dispatch({
            type: DELETE_URL_LINK_STARTED
        })
        await deleteAffiliateLink(ruuid, uuid).then((response) => {
            if (response.status === 200) {
                toast.success(response.data.message || 'Delete Affiliate Link Success')
                dispatch({
                    type: GET_URL_LINK_SUCCESS,
                    payload: response.data.affiliateLinks
                })
            } else {
                handleError(dispatch)(response, null, 'DELETE URL LINK ERROR')
            }
        })
        dispatch({
            type: DELETE_URL_LINK_ENDED
        })
    },

}
export default actions