import {
  SWITCH_FLOOR, UPDATE_FLOOR_TABLES, CHANGE_FLOOR_COLOR, CHANGE_FLOOR_BG, ADD_FLOOR, RENAME_FLOOR, DELETE_FLOOR,
  GET_FLOORS_REQUEST, GET_FLOORS_SUCCESS, GET_FLOORS_ERROR,
  GET_TABLES_REQUEST, GET_TABLES_SUCCESS, GET_TABLES_ERROR,
  ADD_TABLES_REQUEST, ADD_TABLES_SUCCESS, ADD_TABLES_ERROR,
  DELETE_FLOOR_REQUEST, DELETE_FLOOR_SUCCESS, DELETE_FLOOR_ERROR,
  DELETE_TABLE_REQUEST, DELETE_TABLE_SUCCESS, DELETE_TABLE_ERROR,
  CLEAR_FLOOR_LOADING

} from "../actions/FloorSetupActions";


import floorTablesData from "../../pages/reservation/reservationSetup/floorTablesData";

const INITIAL_STATE = {
  getFloorsLoading: false,
  currentFloor: {},
  floors: floorTablesData,
  selectedRestaurantFloor: '',
  selectedBranchFloor: '',
  floorsArray: [],
  tables: [],
  selectedFloorId: '',
  editFloorsTablesLoading: false,
  deleteFloorLoading: false,
  deleteTableLoading: false,
};

const floorSetupReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CLEAR_FLOOR_LOADING:
      return Object.assign({}, state, {
        getFloorsLoading: false,
        editFloorsTablesLoading: false,
        deleteFloorLoading: false,
        deleteTableLoading: false,
      })
    case SWITCH_FLOOR:
      const { payload: floorId } = action;
      const selectedFloor = state.floorsArray.find(floor => floor.uuid === floorId);
      return {
        ...state,
        currentFloor: selectedFloor,
        selectedFloorId: selectedFloor.uuid
      };

    // case UPDATE_FLOOR_TABLES:
    //   const { floorUid, updatedTables } = action.payload;
    //   return {
    //     ...state,
    //     floors: state.floors.map((floor) =>
    //       floor.uuid === floorUid ? { ...floor, tables: updatedTables } : floor
    //     ),
    //   };

    case CHANGE_FLOOR_COLOR:
      const { payload: { fId, newColor } } = action;
      const updatedFloors = state.floors.map(floor => {
        if (floor.uuid === fId) {
          return { ...floor, color: newColor };
        }
        return floor;
      });
      return {
        ...state,
        floors: updatedFloors,
      };

    case CHANGE_FLOOR_BG:
      const { payload: { flId, newImage } } = action;
      const updatedFlrs = state.floors.map(floor => {
        if (floor.uuid === flId) {
          return { ...floor, floorImage: newImage };
        }
        return floor;
      });
      return {
        ...state,
        floors: updatedFlrs,
      };

    case ADD_FLOOR:
      const { payload: newFloor } = action;
      return {
        ...state,
        floors: [...state.floors, newFloor],
      };

    case RENAME_FLOOR:
      const { fKey, newName } = action.payload;
      const renamedFloors = state.floors.map(floor => {
        if (floor.uuid === fKey) {
          return { ...floor, name: newName };
        }
        return floor;
      });
      return {
        ...state,
        floors: renamedFloors,
      };

    case DELETE_FLOOR:
      const { payload: dfId } = action;
      const filteredFloors = state.floors.filter(floor => floor.uuid !== dfId);
      return {
        ...state,
        floors: filteredFloors,
      };


    case GET_FLOORS_REQUEST:
      return Object.assign({}, state, {
        getFloorsLoading: true,
        selectedRestaurantFloor: action.payload.ruuid,
        selectedBranchFloor: action.payload.bruuid
      })
    case GET_FLOORS_SUCCESS:
      return Object.assign({}, state, {
        getFloorsLoading: false,
        floorsArray: action.payload,
        currentFloor: state.floorsArray[0],
        // selectedFloorId: action.selectedFloor
        selectedFloorId:state.selectedFloorId?state.selectedFloorId:action.selectedFloor
      })
    case GET_FLOORS_ERROR:
      return Object.assign({}, state, {
        getFloorsLoading: false,
        floorsArray: []
      })


    case GET_TABLES_REQUEST:
      return Object.assign({}, state, {
        getFloorsLoading: true,
        // selectedFloorId: action.payload.fuuid,

      })
    case GET_TABLES_SUCCESS:
      return Object.assign({}, state, {
        getFloorsLoading: false,
        tables: action.payload


      })

    case GET_TABLES_ERROR:
      return Object.assign({}, state, {
        getFloorsLoading: false,
        tables: []
      })


    case ADD_TABLES_REQUEST:
      return Object.assign({}, state, {
        addTablesLoading: true,
      });
    case ADD_TABLES_SUCCESS:
      return Object.assign({}, state, {
        addTablesLoading: false,
        // Handle state update accordingly, e.g., update the tables array
      });
    case ADD_TABLES_ERROR:
      return Object.assign({}, state, {
        addTablesLoading: false,
        // Handle error state accordingly
      });

    case DELETE_FLOOR_REQUEST:
      // Handle delete floor request
      return {
        ...state,
        deleteFloorLoading: true,
      };

    case DELETE_FLOOR_SUCCESS:
      // Handle delete floor success
      return {
        ...state,
        deleteFloorLoading: false,
        floorsArray: state.floorsArray.filter(floor => floor.fuuid !== action.payload.fuuid),
      };

    case DELETE_FLOOR_ERROR:
      return {
        ...state,
        deleteFloorLoading: false,
      };

    case DELETE_TABLE_REQUEST:
      // Handle delete floor request
      return {
        ...state,
        deleteTableLoading: true,
      };

    case DELETE_TABLE_SUCCESS:
      // Handle delete floor success
      return {
        ...state,
        deleteTableLoading: false,
        tables: state.tables.filter(table => table.tuuid !== action.payload.tuuid),
      };

    case DELETE_TABLE_ERROR:
      return {
        ...state,
        deleteTableLoading: false,
      };

    default:
      return state;
  }
};
export default floorSetupReducer;
