import {
  GET_ADMIN_REQUEST,
  GET_ADMIN_SUCCESS,
  GET_ADMIN_ERROR,
  ADD_ADMIN_REQUEST,
  ADD_ADMIN_SUCCESS,
  ADD_ADMIN_ERROR,
  EDIT_ADMIN_REQUEST,
  EDIT_ADMIN_SUCCESS,
  EDIT_ADMIN_ERROR,
  DELETE_ADMIN_REQUEST,
  DELETE_ADMIN_SUCCESS,
  DELETE_ADMIN_ERROR,
  CLEAR_ALL_PROFILE,
  CLEAR_PROFILE_LOADING
} from '../actions/ProfileActions'

const initialState = {
  loading: false,
  adminProfiles: [],
  selectedAdmin: '',
}

function Profiles(state = initialState, action) {
  switch (action.type) {
    case CLEAR_PROFILE_LOADING:
      return Object.assign({}, state, {
        loading: false
      })
    case GET_ADMIN_REQUEST:
      return Object.assign({}, state, {
        loading: true,
      })
    case GET_ADMIN_SUCCESS:
      return Object.assign({}, state, {
        adminProfiles: action.payload.userProfile,
        loading: false,
      })
    case GET_ADMIN_ERROR:
      return Object.assign({}, state, {
        loading: false,
        adminProfiles: [],
      })

    case ADD_ADMIN_REQUEST:
      return Object.assign({}, state, {
        loading: true,
      })
    case ADD_ADMIN_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
      })
    case ADD_ADMIN_ERROR:
      return Object.assign({}, state, {
        loading: false,
      })

    case EDIT_ADMIN_REQUEST:
      return Object.assign({}, state, {
        loading: true,
      })
    case EDIT_ADMIN_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
      })
    case EDIT_ADMIN_ERROR:
      return Object.assign({}, state, {
        loading: false,
      })

    case DELETE_ADMIN_REQUEST:
      return Object.assign({}, state, {
        loading: true,
      })
    case DELETE_ADMIN_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
      })
    case DELETE_ADMIN_ERROR:
      return Object.assign({}, state, {
        loading: false,
      })
    case CLEAR_ALL_PROFILE:
      return initialState
    default:
      return state
  }
}

export default Profiles
