import {
    GET_MODULES_REQUEST,
    GET_MODULES_SUCCESS,
    GET_MODULES_ERROR,
    CLEAR_ALL_MODULES,
    CLEAR_MODULES_LOADING
} from '../actions/ModulesActions'

const initialState = {
    loading: false,
    allModules: [],
    activatedModules: []
}

function modules(state = initialState, action) {
    switch (action.type) {
        case CLEAR_MODULES_LOADING:
            return Object.assign({}, state, {
                loading: false
            })
        case GET_MODULES_REQUEST:
            return Object.assign({}, state, {
                loading: true,
            })
        case GET_MODULES_SUCCESS:
            return Object.assign({}, state, {
                loading: false,
                allModules: action.payload.allModules,
                activatedModules: action.payload.activatedModules
            })
        case GET_MODULES_ERROR:
            return Object.assign({}, state, {
                loading: false,
                allModules: [],
                activatedModules: []
            })
        case CLEAR_ALL_MODULES:
            return initialState
        default:
            return state
    }
}
export default modules