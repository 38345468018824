import {
    getBranchesFinance,
    updateBranchesFinanceAvailability,
    updateBranchesFinancePrice,
    getBranchesFinanceProduct
} from '../../config/config'

import { handleError } from '../../utils/utils'

import { toast } from 'react-toastify'

export const GET_BRANCHES_FINANCE_REQUEST = 'GET_BRANCHES_FINANCE_REQUEST'
export const GET_BRANCHES_FINANCE_SUCCESS = 'GET_BRANCHES_FINANCE_SUCCESS'
export const GET_BRANCHES_FINANCE_ERROR = 'GET_BRANCHES_FINANCE_ERROR'

export const GET_BRANCHES_FINANCE_PRODUCT_REQUEST = 'GET_BRANCHES_FINANCE_PRODUCT_REQUEST'
export const GET_BRANCHES_FINANCE_PRODUCT_SUCCESS = 'GET_BRANCHES_FINANCE_PRODUCT_SUCCESS'
export const GET_BRANCHES_FINANCE_PRODUCT_ERROR = 'GET_BRANCHES_FINANCE_PRODUCT_ERROR'

export const UPDATE_FINANCE_AVAILABILITY_STARTED = 'UPDATE_FINANCE_AVAILABILITY_STARTED'
export const UPDATE_FINANCE_AVAILABILITY_ENDED = 'UPDATE_FINANCE_AVAILABILITY_ENDED'

export const UPDATE_FINANCE_PRICE_STARTED = 'UPDATE_FINANCE_PRICE_STARTED'
export const UPDATE_FINANCE_PRICE_ENDED = 'UPDATE_FINANCE_PRICE_ENDED'

export const CLEAR_ALL_BRANCHES_FINANCE = 'CLEAR_ALL_BRANCHES_FINANCE'

export const CLEAR_BRANCHES_FINANCE_LOADING = "CLEAR_BRANCHES_FINANCE_LOADING"

const actions = {
    getBranchesFinanceProduct: (ruuid, uuid) => async (dispatch) => {
        dispatch({
            type: GET_BRANCHES_FINANCE_PRODUCT_REQUEST
        })
        await getBranchesFinanceProduct(ruuid, uuid).then((response) => {
            if (response.status === 200) {
                dispatch({
                    type: GET_BRANCHES_FINANCE_PRODUCT_SUCCESS,
                    payload: response.data.product
                })
            } else handleError(dispatch)(response, GET_BRANCHES_FINANCE_PRODUCT_ERROR, 'GET BRANCHES FINANCE PRODUCT ERROR')
        })
    },
    getBranchesFinance: (ruuid) => async (dispatch) => {
        dispatch({
            type: GET_BRANCHES_FINANCE_REQUEST
        })
        await getBranchesFinance(ruuid).then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_BRANCHES_FINANCE_SUCCESS,
                    payload: response.data
                })
            else {
                handleError(dispatch)(response, GET_BRANCHES_FINANCE_ERROR, 'GET BRANCHES FINANCE ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error(response?.data?.message || 'Get branches finance error')
                // dispatch({
                //     type: GET_BRANCHES_FINANCE_ERROR
                // })
                // if (response.status === 401)
                //     dispatch({
                //         type: LOGOUT_USER
                //     })
                // if (response.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: response?.data?.permissions
                //     })
                // }
            }
        })
    },
    updateBranchesFinanceAvailability: (ruuid, data, onClose) => async (dispatch) => {
        dispatch({
            type: UPDATE_FINANCE_AVAILABILITY_STARTED
        })
        await updateBranchesFinanceAvailability(ruuid, data).then(async (response) => {
            if (response.status === 200) {
                toast.success(response?.data?.message || 'Finance availability updated')
                await getBranchesFinance(ruuid).then((response) => {
                    if (response.status === 200) {
                        dispatch({
                            type: GET_BRANCHES_FINANCE_SUCCESS,
                            payload: response.data
                        })
                        if (onClose)
                            onClose()
                    }
                    else {
                        handleError(dispatch)(response, GET_BRANCHES_FINANCE_ERROR, 'GET BRANCHES FINANCE ERROR')
                        // if (response.status !== 401 && response.status !== 403)
                        //     toast.error(response?.data?.message || 'Get branches finance error')
                        // dispatch({
                        //     type: GET_BRANCHES_FINANCE_ERROR
                        // })
                        // if (response.status === 401)
                        //     dispatch({
                        //         type: LOGOUT_USER
                        //     })
                        // if (response.status === 403)
                        //     dispatch({
                        //         type: SET_PERMISSIONS,
                        //         payload: response?.data?.permissions
                        //     })
                    }
                })
            } else handleError(dispatch)(response, null, 'EDIT BRANCHES FINANCE ERROR')
        })
        dispatch({
            type: UPDATE_FINANCE_AVAILABILITY_ENDED
        })
    },
    updateBranchesFinancePrice: (ruuid, data, onClose) => async (dispatch) => {
        dispatch({
            type: UPDATE_FINANCE_PRICE_STARTED
        })
        await updateBranchesFinancePrice(ruuid, data).then(async (response) => {
            if (response.status === 200) {
                toast.success(response?.data?.message || 'Finance prices updated')
                await getBranchesFinance(ruuid).then((response) => {
                    if (response.status === 200) {
                        dispatch({
                            type: GET_BRANCHES_FINANCE_SUCCESS,
                            payload: response.data
                        })
                        if (onClose)
                            onClose()
                    }
                    else {
                        handleError(dispatch)(response, GET_BRANCHES_FINANCE_ERROR, 'GET BRANCHES FINANCE ERROR')
                        // if (response.status !== 401 && response.status !== 403)
                        //     toast.error(response?.data?.message || 'Get branches finance error')
                        // dispatch({
                        //     type: GET_BRANCHES_FINANCE_ERROR
                        // })
                        // if (response.status === 401)
                        //     dispatch({
                        //         type: LOGOUT_USER
                        //     })
                        // if (response.status === 403)
                        //     dispatch({
                        //         type: SET_PERMISSIONS,
                        //         payload: response?.data?.permissions
                        //     })
                    }
                })
            } else handleError(dispatch)(response, null, 'EDIT BRANCHES FINANCE ERROR')
        })
        dispatch({
            type: UPDATE_FINANCE_PRICE_ENDED
        })
    },
    clearAll: () => (dispatch) => {
        dispatch({
            type: CLEAR_ALL_BRANCHES_FINANCE
        })
    }
}
export default actions