import {
    GET_EMAIL_SERVER_REQUEST,
    GET_EMAIL_SERVER_SUCCESS,
    GET_EMAIL_SERVER_ERROR,

    ADD_EMAIL_SERVER_STARTED,
    ADD_EMAIL_SERVER_ENDED,

    TEST_EMAIL_STARTED,
    TEST_EMAIL_ENDED,

    CLEAR_EMAIL,
    CLEAR_EMAIL_LOADING
} from "../actions/EmailActions";

const initialValues = {
    testLoading: false,
    loading: false,
    actionLoading: false,
    server: null
}

function email(state = initialValues, action) {
    switch (action.type) {
        case TEST_EMAIL_ENDED:
            return Object.assign({}, state, {
                testLoading: false
            })
        case TEST_EMAIL_STARTED:
            return Object.assign({}, state, {
                testLoading: true
            })
        case CLEAR_EMAIL:
            return initialValues
        case CLEAR_EMAIL_LOADING:
            return Object.assign({}, state, {
                loading: false,
                actionLoading: false
            })
        case ADD_EMAIL_SERVER_ENDED:
            return Object.assign({}, state, {
                actionLoading: false
            })
        case ADD_EMAIL_SERVER_STARTED:
            return Object.assign({}, state, {
                actionLoading: true
            })
        case GET_EMAIL_SERVER_ERROR:
            return Object.assign({
                loading: false,
                server: null
            })
        case GET_EMAIL_SERVER_SUCCESS:
            return Object.assign({}, state, {
                loading: false,
                server: action.payload
            })
        case GET_EMAIL_SERVER_REQUEST:
            return Object.assign({}, state, {
                loading: true
            })
        default:
            return state
    }
}
export default email