import React from 'react'
import { CircularProgress, Button } from '@mui/material'

function LoadingButton({ title, isSubmitting, float, disabled, ...props }) {
  return (
    <Button {...props}
      disabled={isSubmitting || disabled}
      style={{ display: 'flex', flexDirection: 'column', float: float }}>
      {isSubmitting ? (
        <CircularProgress
          size={20}
          sx={{
            color: 'white',
          }}
        />
      ) : (
        title
      )}
    </Button>
  )
}

export default LoadingButton
