import { getAdminProfiles, addAdminProfile, editAdminProfile, deleteAdminProfile } from '../../config/config'

import { toast } from 'react-toastify'
import { parseError } from '../../utils/parseError'
import { LOGOUT_USER, SET_PERMISSIONS } from './AuthActions'
import { handleError } from '../../utils/utils'

export const GET_ADMIN_REQUEST = 'GET_ADMIN_REQUEST'
export const GET_ADMIN_SUCCESS = 'GET_ADMIN_SUCCESS'
export const GET_ADMIN_ERROR = 'GET_ADMIN_ERROR'

export const ADD_ADMIN_REQUEST = 'ADD_ADMIN_REQUEST'
export const ADD_ADMIN_SUCCESS = 'ADD_ADMIN_SUCCESS'
export const ADD_ADMIN_ERROR = 'ADD_ADMIN_ERROR'

export const EDIT_ADMIN_REQUEST = 'EDIT_ADMIN_REQUEST'
export const EDIT_ADMIN_SUCCESS = 'EDIT_ADMIN_SUCCESS'
export const EDIT_ADMIN_ERROR = 'EDIT_ADMIN_ERROR'

export const DELETE_ADMIN_REQUEST = 'DELETE_ADMIN_REQUEST'
export const DELETE_ADMIN_SUCCESS = 'DELETE_ADMIN_SUCCESS'
export const DELETE_ADMIN_ERROR = 'DELETE_ADMIN_ERROR'

export const CLEAR_ALL_PROFILE = 'CLEAR_ALL_PROFILE'

export const CLEAR_PROFILE_LOADING = 'CLEAR_PROFILE_LOADING'

const actions = {
  getProfile: () => async (dispatch) => {
    dispatch({
      type: GET_ADMIN_REQUEST,
    })
    await getAdminProfiles().then((res) => {
      if (res?.status === 200) {
        dispatch({
          type: GET_ADMIN_SUCCESS,
          payload: {
            userProfile: res?.data?.data,
          },
        })
      } else {
        handleError(dispatch)(res, GET_ADMIN_ERROR, 'GET ADMIN ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Get All Admin Profile error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
        // dispatch({
        //   type: GET_ADMIN_ERROR,
        //   payload: {
        //     message: parseError(res?.data?.error) || res?.data?.message || 'GET_ADMIN error',
        //   },
        // })
      }
    })
  },

  getAdminProfiles: () => async (dispatch) => {
    dispatch({
      type: GET_ADMIN_REQUEST,
    })
    await getAdminProfiles().then((res) => {
      if (res?.status === 200) {
        dispatch({
          type: GET_ADMIN_SUCCESS,
          payload: {
            userProfile: res?.data?.data,
          },
        })
      } else {
        handleError(dispatch)(res, GET_ADMIN_ERROR, 'GET ADMIN ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Get All Admin Profile error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // dispatch({
        //   type: GET_ADMIN_ERROR,
        //   payload: {
        //     message: parseError(res?.data?.error) || res?.data?.message || 'GET_ADMIN error',
        //   },
        // })
      }
    })
  },

  addAdminProfile: (data) => async (dispatch) => {
    dispatch({
      type: ADD_ADMIN_REQUEST,
    })
    await addAdminProfile(data).then((res) => {
      if (res?.status === 200) {
        dispatch({
          type: ADD_ADMIN_SUCCESS,
          payload: {
            userProfile: res?.data?.data,
          },
        })
      } else {
        handleError(dispatch)(res, ADD_ADMIN_ERROR, 'ADD ADMIN ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Add admin Profile Error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // dispatch({
        //   type: ADD_ADMIN_ERROR,
        // })
      }
    })
  },

  editAdminProfile: (data, uuid) => async (dispatch) => {
    dispatch({
      type: EDIT_ADMIN_REQUEST,
    })
    await editAdminProfile(data, uuid).then((res) => {
      if (res?.status === 200) {
        dispatch({
          type: EDIT_ADMIN_SUCCESS,
          payload: {
            userProfile: res?.data?.data,
          },
        })
      } else {
        handleError(dispatch)(res, EDIT_ADMIN_ERROR, 'EDIT ADMIN ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Edit Admin Profile Error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // dispatch({
        //   type: EDIT_ADMIN_ERROR,
        // })
      }
    })
  },

  deleteAdminProfile: (uuid) => async (dispatch) => {
    dispatch({
      type: DELETE_ADMIN_REQUEST,
    })
    await deleteAdminProfile(uuid).then((res) => {
      if (res?.status === 200) {
        dispatch({
          type: DELETE_ADMIN_SUCCESS,
          payload: {
            userProfile: res?.data?.data,
          },
        })
      } else {
        handleError(dispatch)(res, DELETE_ADMIN_ERROR, 'DELETE ADMIN ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Edit Admin Error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // dispatch({
        //   type: DELETE_ADMIN_ERROR,
        // })
      }
    })
  },
  clearAllProfile: () => (dispatch) => {
    dispatch({
      type: CLEAR_ALL_PROFILE
    })
  }
}

export default actions
