import { toast } from "react-toastify"
import { registerDevice, getNotifications, viewNotification } from "../../config/config"
import { handleError } from "../../utils/utils"

export const SET_FCM_TOKEN = 'SET_FCM_TOKEN'

export const REGISTRATION_REQUEST = 'REGISTRATION_REQUEST'
export const REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS'
export const REGISTRATION_ERROR = 'REGISTRATION_ERROR'

export const GET_NOTIFICATIONS_REQUEST = 'GET_NOTIFICATIONS_REQUEST'
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS'
export const GET_NOTIFICATIONS_ERROR = 'GET_NOTIFICATIONS_ERROR'
export const VIEW_NOTIFICATION_STARTED = 'VIEW_NOTIFICATION_STARTED'
export const VIEW_NOTIFICATION_ENDED = 'VIEW_NOTIFICATION_ENDED'

export const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS'

export const CLEAR_NOTIFICATIONS_LOADING = 'CLEAR_NOTIFICATIONS_LOADING'

const actions = {
    getNotifications: () => async (dispatch) => {
        dispatch({
            type: GET_NOTIFICATIONS_REQUEST
        })
        await getNotifications().then((response) => {
            if (response.status === 200) {
                dispatch({
                    type: GET_NOTIFICATIONS_SUCCESS,
                    payload: response.data.notitifications
                })
                if (response.data.notitifications)
                    response.data.notitifications.map(async (not) => {
                        await viewNotification(not.uuid).then((response) => {
                            if (response.status !== 200) {
                                handleError(dispatch)(response, null, "VIEW NOTIFICATION ERROR")
                            }
                        })
                    })
            } else {
                handleError(dispatch)(response, GET_NOTIFICATIONS_ERROR, "GET NOTIFICATIONS ERROR")
            }
        })
    },
    viewNotification: (uuid) => async (dispatch) => {
        dispatch({
            type: VIEW_NOTIFICATION_STARTED
        })
        await viewNotification(uuid).then((response) => {
            if (response.status !== 200) {
                handleError(dispatch)(response, null, "VIEW NOTIFICATION ERROR")
            }
        })
        dispatch({
            type: VIEW_NOTIFICATION_ENDED
        })
    },
    setFcmToken: (token) => (dispatch) => {
        dispatch({
            type: SET_FCM_TOKEN,
            payload: token
        })
    },
    registerDevice: (token) => async (dispatch) => {
        dispatch({
            type: REGISTRATION_REQUEST
        })
        await registerDevice(token).then((response) => {
            if (response.status === 200) {
                dispatch({
                    type: REGISTRATION_SUCCESS,
                })
            } else handleError(response, REGISTRATION_ERROR, 'FCM REGISTRATION ERROR')
        })
    }
}
export default actions