import {
    GET_RATES_REQUEST,
    GET_RATES_SUCCESS,
    GET_RATES_ERROR,

    GET_RATE_REQUEST,
    GET_RATE_SUCCESS,
    GET_RATE_ERROR,

    ADD_RATE_STARTED,
    ADD_RATE_ENDED,

    EDIT_RATE_STARTED,
    EDIT_RATE_ENDED,

    DELETE_RATE_STARTED,
    DELETE_RATE_ENDED,

    CLEAR_RATES,
    CLEAR_RATES_LOADING
} from "../actions/CurrenciesActions";

const initialState = {
    rates: [],
    rate: null,
    loading: false,
    addLoading: false,
    editLoading: false,
    deleteLoading: false
}

function currencies(state = initialState, action) {
    switch (action.type) {
        case CLEAR_RATES_LOADING:
            return Object.assign({}, state, {
                loading: false,
                addLoading: false,
                editLoading: false,
                deleteLoading: false
            })
        case CLEAR_RATES:
            return initialState
        case DELETE_RATE_ENDED:
            return Object.assign({}, state, {
                deleteLoading: false
            })
        case DELETE_RATE_STARTED:
            return Object.assign({}, state, {
                deleteLoading: true
            })
        case EDIT_RATE_ENDED:
            return Object.assign({}, state, {
                editLoading: false
            })
        case EDIT_RATE_STARTED:
            return Object.assign({}, state, {
                editLoading: true
            })
        case ADD_RATE_ENDED:
            return Object.assign({}, state, {
                addLoading: false
            })
        case ADD_RATE_STARTED:
            return Object.assign({}, state, {
                addLoading: true
            })
        case GET_RATE_ERROR:
            return Object.assign({}, state, {
                loading: false,
                rate: null
            })
        case GET_RATE_SUCCESS:
            return Object.assign({}, state, {
                loading: false,
                rate: action.payload
            })
        case GET_RATE_REQUEST:
            return Object.assign({}, state, {
                loading: true,
            })
        case GET_RATES_ERROR:
            return Object.assign({}, state, {
                loading: false,
                rates: []
            })
        case GET_RATES_SUCCESS:
            return Object.assign({}, state, {
                loading: false,
                rates: action.payload
            })
        case GET_RATES_REQUEST:
            return Object.assign({}, state, {
                loading: true
            })
        default:
            return state
    }
}
export default currencies