import {
    GET_LOGIN_DESIGN_SETUP_REQUEST,
    GET_LOGIN_DESIGN_SETUP_SUCCESS,
    GET_LOGIN_DESIGN_SETUP_ERROR,
    EDIT_LOGIN_DESIGN_SETUP_REQUEST,
    EDIT_LOGIN_DESIGN_SETUP_SUCCESS,
    EDIT_LOGIN_DESIGN_SETUP_ERROR,
    CLEAR_LOGIN_DESIGN_LOADING
} from "../actions/LoginDesignSetupActions";

const initialState = {
    getloginDesignSetup: [],
    getLoginDesignSetupLoading: false,
    editLoginDesignSetupLoading: false,
    loginDesignSetup: null,
    mainDesignSetup: null,
    selectedRestaurant: '',
};

function loginDesignSetup(state = initialState, action) {
    // console.log(action?.payload)
    switch (action.type) {
        case CLEAR_LOGIN_DESIGN_LOADING:
            return Object.assign({}, state, {
                getLoginDesignSetupLoading: false,
                editLoginDesignSetupLoading: false,
            })
        case GET_LOGIN_DESIGN_SETUP_REQUEST:
            return Object.assign({}, state, {
                getLoginDesignSetupLoading: true,
                selectedRestaurant: action.payload
            })
        case GET_LOGIN_DESIGN_SETUP_SUCCESS:
            return Object.assign({}, state, {
                getLoginDesignSetupLoading: false,
                loginDesignSetup: action?.payload?.loginDesignSetup,
                mainDesignSetup: action?.payload?.mainDesignSetup
            })
        case GET_LOGIN_DESIGN_SETUP_ERROR:
            return Object.assign({}, state, {
                getLoginDesignSetupLoading: false,
                loginDesignSetup: null,
                mainDesignSetup: null
            })
        case EDIT_LOGIN_DESIGN_SETUP_REQUEST:
            return Object.assign({}, state, {
                editLoginDesignSetupLoading: true,
                selectedRestaurant: action?.payload
            })
        case EDIT_LOGIN_DESIGN_SETUP_SUCCESS:
            return Object.assign({}, state, {
                editLoginDesignSetupLoading: false,
            })
        case EDIT_LOGIN_DESIGN_SETUP_ERROR:
            return Object.assign({}, state, {
                editLoginDesignSetupLoading: false,
            })
        default:
            return state;
    }
}

export default loginDesignSetup;
