import {
  getAllergens,
  getAllergen,
  addAllergen,
  editAllergen,
  deleteAllergen
} from "../../config/config";
import { toast } from "react-toastify";
import { parseError } from "../../utils/parseError";
import { handleError } from "../../utils/utils";

export const GET_ALLERGENS_REQUEST = "GET_ALLERGENS_REQUEST";
export const GET_ALLERGENS_SUCCESS = "GET_ALLERGENS_SUCCESS";
export const GET_ALLERGENS_ERROR = "GET_ALLERGENS_ERROR";

export const GET_ALLERGEN_REQUEST = "GET_ALLERGEN_REQUEST";
export const GET_ALLERGEN_SUCCESS = "GET_ALLERGEN_SUCCESS";
export const GET_ALLERGEN_ERROR = "GET_ALLERGEN_ERROR";

export const ADD_ALLERGEN_STARTED = 'ADD_ALLERGENS_STARTED';
export const ADD_ALLERGEN_END = 'ADD_ALLERGNS_END';

export const EDIT_ALLERGEN_STARTED = 'EDIT_ALLERGENS_STARTED';
export const EDIT_ALLERGEN_END = 'EDIT_ALLERGNS_END';

export const DELETE_ALLERGEN_STARTED = 'DELETE_ALLERGENS_STARTED';
export const DELETE_ALLERGEN_END = 'DELETE_ALLERGNS_END';

export const SET_ALLERGENS_POPUP = 'SET_ALLERGENS_POPUP'

export const SET_ALLERGENS_POPUP_2 = 'SET_ALLERGENS_POPUP_2'

export const CLEAR_ALL_ALLERGENS = 'CLEAR_ALL_ALLERGENS'

export const CLEAR_ALLERGENS_LOADING = 'CLEAR_ALLERGENS_LOADING'

const actions = {
  setAllergensPopup: (bool) => (dispatch) => {
    dispatch({
      type: SET_ALLERGENS_POPUP_2,
      payload: bool
    })
  },
  getAllergens: () => async (dispatch, getState) => {
    if (getState().allergens.allergens.length === 0)
      dispatch({
        type: GET_ALLERGENS_REQUEST,
      });
    await getAllergens().then((res) => {
      if (res?.status === 200) {
        dispatch({
          type: GET_ALLERGENS_SUCCESS,
          payload: {
            allergens: res?.data?.allergens,
          },
        });
      } else {
        handleError(dispatch)(res, GET_ALLERGENS_ERROR, 'GET ALLERGENS ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || "Get Allergens error");
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER });
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
        // dispatch({
        //   type: GET_ALLERGENS_ERROR,
        //   payload: {
        //     message:
        //       parseError(res?.data?.error) ||
        //       res?.data?.message ||
        //       "Get Allergens error",
        //   },
        // });
      }
    });
  },
  getAllergen: (uuid) => async (dispatch) => {
    dispatch({
      type: GET_ALLERGEN_REQUEST
    })
    await getAllergen(uuid).then((response) => {
      if (response.status === 200)
        dispatch({
          type: GET_ALLERGEN_SUCCESS,
          payload: response.data.allergen
        })
      else {
        handleError(dispatch)(response, GET_ALLERGEN_ERROR, 'GET ALLERGEN ERROR')
        // if (response.status !== 401 && response.status !== 403) toast.error(response?.data?.message || 'GET ALLERGEN ERROR')
        // if (response.status === 401) {
        //   dispatch({ type: LOGOUT_USER });
        // }
        // if (response.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: response?.data?.permissions
        //   })
        // }
        // dispatch({
        //   type: GET_ALLERGEN_ERROR
        // })
      }
    })
  },
  addAllergen: (data, close) => async (dispatch) => {
    dispatch({
      type: ADD_ALLERGEN_STARTED,
    })
    await addAllergen(data).then((res) => {
      if (res.status === 200) {
        toast.success(res?.data?.message || 'Successfully Saved')
        dispatch({
          type: GET_ALLERGENS_SUCCESS,
          payload: {
            allergens: res?.data?.allergens,
          },
        })
        dispatch({
          type: SET_ALLERGENS_POPUP,
          payload: false,
        })
        close()
      } else {
        handleError(dispatch)(res, null, 'ADD ALLERGEN ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Add Allergen error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
      }
    })
    dispatch({
      type: ADD_ALLERGEN_END,
    })
  },
  editAllergen: (uuid, data, close) => async (dispatch) => {
    dispatch({
      type: EDIT_ALLERGEN_STARTED,
    })
    await editAllergen(uuid, data).then((res) => {
      if (res.status === 200) {
        toast.success(res?.data?.message || 'Successfully Saved')
        dispatch({
          type: GET_ALLERGENS_SUCCESS,
          payload: {
            allergens: res?.data?.allergens,
          },
        })
        close()
      } else {
        handleError(dispatch)(res, null, 'EDIT ALLERGEN ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Add Allergen error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
      }
    })
    dispatch({
      type: EDIT_ALLERGEN_END,
    })
  },
  deleteAllergen: (uuid, close) => async (dispatch) => {
    dispatch({
      type: DELETE_ALLERGEN_STARTED,
    })
    await deleteAllergen(uuid).then((res) => {
      if (res.status === 200) {
        toast.success(res?.data?.message || 'Successfully Saved')
        dispatch({
          type: GET_ALLERGENS_SUCCESS,
          payload: {
            allergens: res?.data?.allergens,
          },
        })
        close()
      } else {
        handleError(dispatch)(res, null, 'DELETE ALLERGEN ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Add Allergen error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
      }
    })
    dispatch({
      type: DELETE_ALLERGEN_END,
    })
  },
};

export default actions;
