import {
  GET_APP_USERS_GROUPS_REQUEST,
  GET_APP_USERS_GROUPS_SUCCESS,
  GET_APP_USERS_GROUPS_ERROR,
  ADD_APP_USERS_GROUPS_STARTED,
  ADD_APP_USERS_GROUPS_ENDED,
  EDIT_APP_USERS_GROUPS_STARTED,
  EDIT_APP_USERS_GROUPS_ENDED,
  DELETE_APP_USERS_GROUPS_STARTED,
  DELETE_APP_USERS_GROUPS_ENDED,
  CLEAR_APP_USERS_GROUPS,
  CLEAR_APP_USERS_LOADING
} from "../actions/AppUsersGroupsActions";

const initialState = {
  loading: false,
  addLoading: false,
  editLoading: false,
  deleteLoading: false,
  usersGroups: [],
};

function appUsersGroups(state = initialState, action) {
  switch (action.type) {
    case CLEAR_APP_USERS_LOADING:
      return Object.assign({}, state, {
        loading: false,
        addLoading: false,
        editLoading: false,
        deleteLoading: false,
      })
    case GET_APP_USERS_GROUPS_REQUEST:
      return Object.assign({}, state, {
        loading: true,
      });
    case GET_APP_USERS_GROUPS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        usersGroups: action.payload.usersGroups,
      });
    case GET_APP_USERS_GROUPS_ERROR:
      return Object.assign({}, state, {
        loading: false,
        usersGroups: [],
      });
    case ADD_APP_USERS_GROUPS_STARTED:
      return Object.assign({}, state, {
        addLoading: true
      })
    case ADD_APP_USERS_GROUPS_ENDED:
      return Object.assign({}, state, {
        addLoading: false
      })
    case EDIT_APP_USERS_GROUPS_STARTED:
      return Object.assign({}, state, {
        editLoading: true
      })
    case EDIT_APP_USERS_GROUPS_ENDED:
      return Object.assign({}, state, {
        editLoading: false
      })
    case DELETE_APP_USERS_GROUPS_STARTED:
      return Object.assign({}, state, {
        deleteLoading: true
      })
    case DELETE_APP_USERS_GROUPS_ENDED:
      return Object.assign({}, state, {
        deleteLoading: false
      })
    case CLEAR_APP_USERS_GROUPS:
      return initialState
    default:
      return state;
  }
}

export default appUsersGroups;
