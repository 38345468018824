import { Dispatch, Action } from 'redux';

export const ADD_PRODUCT = 'ADD_LINKED_PRODUCT';
export const EDIT_RECIPE = 'EDIT_RECIPE';
export const ADD_TO_MAIN_DINE_IN = 'ADD_TO_MAIN_DINE_IN';
export const ADD_TO_MAIN_PICK_UP = 'ADD_TO_MAIN_PICK_UP';
export const ADD_TO_MAIN_DELIVERY = 'ADD_TO_MAIN_DELIVERY';
export const ADD_TO_ADD_DINE_IN   = 'ADD_TO_ADD_DINE_IN';
export const ADD_TO_ADD_PICK_UP   = 'ADD_TO_ADD_PICK_UP';
export const ADD_TO_ADD_DELIVERY  = 'ADD_TO_ADD_DELIVERY';
export const ADD_TO_COMBO_DINE_IN  = 'ADD_TO_COMBO_DINE_IN';
export const ADD_TO_COMBO_PICK_UP  = 'ADD_TO_COMBO_PICK_UP';
export const ADD_TO_COMBO_DELIVERY = 'ADD_TO_COMBO_DELIVERY';

export const addLinkedProduct = (newLinkedProduct) => {
    return {
      type: ADD_PRODUCT,
      payload: newLinkedProduct,
    };
  };

  export const editRecipe = (id, productId, 
    nameValue,typeValue,prodTypeValue,
    yieldValue,imageValue,
    smallUnitValue,smallQtyValue,smallCostValue,
    largeUnitValue,largeQtyValue,largeCostValue,
    familyUnitValue,familyQtyValue,familyCostValue) => ({
    type: EDIT_RECIPE,
    payload: { id, productId, 
      nameValue,typeValue,prodTypeValue,
      yieldValue,imageValue,
      smallUnitValue,smallQtyValue,smallCostValue,
      largeUnitValue,largeQtyValue,largeCostValue,
      familyUnitValue,familyQtyValue,familyCostValue },
  });

  export const addToMainDineIn = (productId, newItem) => ({
    type: ADD_TO_MAIN_DINE_IN,
    payload: { productId, newItem },
  });
  
  export const addToMainPickUp = (productId, newItem) => ({
    type: ADD_TO_MAIN_PICK_UP,
    payload: { productId, newItem },
  });

  export const addToMainDelivery = (productId, newItem) => ({
    type: ADD_TO_MAIN_DELIVERY,
    payload: { productId, newItem },
  });
  

  export const addToAddDineIn = (productId, newItem) => ({
    type: ADD_TO_ADD_DINE_IN,
    payload: { productId, newItem },
  });
  
  export const addToAddPickUp = (productId, newItem) => ({
    type: ADD_TO_ADD_PICK_UP,
    payload: { productId, newItem },
  });

  export const addToAddDelivery = (productId, newItem) => ({
    type: ADD_TO_ADD_DELIVERY,
    payload: { productId, newItem },
  });
  
  export const addToComboDineIn = (productId, newItem) => ({
    type: ADD_TO_COMBO_DINE_IN,
    payload: { productId, newItem },
  });
  
  export const addToComboPickUp = (productId, newItem) => ({
    type: ADD_TO_COMBO_PICK_UP,
    payload: { productId, newItem },
  });

  export const addToComboDelivery = (productId, newItem) => ({
    type: ADD_TO_COMBO_DELIVERY,
    payload: { productId, newItem },
  });
