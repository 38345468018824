import React, { useEffect } from 'react'
import { Dialog, Box, FormControlLabel, Button } from '@mui/material'
import { connect } from 'react-redux'
import IOSwitch from '../../components/switch/switch'
import { Formik, Form } from 'formik'
import { getTranslation, getField } from '../../utils/utils'
import LoadingButton from '../../components/loadingButton/LoadingButton'
import actions from '../../redux/actions/LoyaltyActions'
import Loading from '../../components/loaders/Loading'

const LoyaltyConfig = ({
    isOpen,
    onClose,
    loading,
    translation,
    language,
    addLoyaltyConfig,
    selectedRestaurant,
    settings,
    getSettingsLoading,
    getLoyaltyConfig
}) => {
    useEffect(() => {
        getLoyaltyConfig(selectedRestaurant)
    }, [selectedRestaurant])

    const dictionary = getTranslation('loyalty-settings', translation)
    const initialState = {
        module: settings?.module || 'loyalty',
        values: settings?.values || { loyaltyIsDefault: true }
    }
    const handleSubmit = (finalValues) => {
        addLoyaltyConfig(selectedRestaurant, finalValues, onClose)
    }
    return (
        <Dialog open={isOpen} onClose={onClose}>
            {!getSettingsLoading ?
                <Box padding={2}>
                    <Box sx={{ fontWeight: 700, color: 'pageTitle' }}>
                        {getField(dictionary, 'title', language)}
                    </Box>
                    <Formik enableReinitialize initialValues={initialState} onSubmit={handleSubmit}>
                        {(formik) =>
                            <Box>
                                <FormControlLabel
                                    control={
                                        <IOSwitch
                                            checked={formik.values.values.loyaltyIsDefault}
                                            onChange={(e) =>
                                                formik.setFieldValue("values.loyaltyIsDefault", e.target.checked)
                                            }
                                        />
                                    }
                                    label={getField(dictionary, 'switch-desc', language)}
                                />
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button
                                        variant='contained'
                                        color='secondary'
                                        sx={{ margin: '0px 2px 0px 2px' }}
                                        onClick={onClose}
                                    >
                                        {getField(dictionary, 'cancel', language)}
                                    </Button>
                                    <LoadingButton
                                        variant='contained'
                                        sx={{ margin: '0px 2px 0px 2px' }}
                                        isSubmitting={loading}
                                        title={getField(dictionary, 'save', language)}
                                        onClick={formik.handleSubmit}

                                    />
                                </Box>
                            </Box>
                        }
                    </Formik>
                </Box>
                :
                <Loading />
            }
        </Dialog>
    )
}
const mapStateToProps = (state) => ({
    loading: state.loyalty.settingsLoading,
    translation: state.translation.translation,
    language: state.translation.language,
    selectedRestaurant: state.rest.selectedRest,
    settings: state.loyalty.settings,
    getSettingsLoading: state.loyalty.getSettingsLoading
})
const mapDispatchToProps = (dispatch) => ({
    addLoyaltyConfig: (ruuid, data, onClose) => dispatch(actions.addLoyaltyConfig(ruuid, data, onClose)),
    getLoyaltyConfig: (ruuid) => dispatch(actions.getLoyaltyConfig(ruuid))
})
export default connect(mapStateToProps, mapDispatchToProps)(LoyaltyConfig)