import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/lib/integration/react'
import { configurePersistor, configureStore } from './redux/store/store'
import Loading from './components/loaders/Loading'
import { ToastContainer } from 'react-toastify'
import packageJson from '../package.json';

// CSS
import './assets/styles/index.css'
import 'react-toastify/dist/ReactToastify.css'
import localforage from 'localforage';


// Redux setup
const store = configureStore()
const persistor = configurePersistor(store)
const root = ReactDOM.createRoot(document.getElementById('root'));

// const {
//     REACT_APP_FCM_API_KEY,
//     REACT_APP_FCM_AUTH_DOMAIN,
//     REACT_APP_FCM_PROJECT_ID,
//     REACT_APP_FCM_STORAGE_BUCKET,
//     REACT_APP_FCM_MESSAGING_SENDER_ID,
//     REACT_APP_FCM_APP_ID,
//     REACT_APP_FCM_MEASUREMENT_ID,
//   } = process.env

// Used to build and pass env vars to sw for messaging
// const swUrl = `${process.env.PUBLIC_URL}/firebase-messaging-sw.js?REACT_APP_FCM_API_KEY=${REACT_APP_FCM_API_KEY}&REACT_APP_FCM_AUTH_DOMAIN=${REACT_APP_FCM_AUTH_DOMAIN}&REACT_APP_FCM_PROJECT_ID=${REACT_APP_FCM_PROJECT_ID}&REACT_APP_FCM_STORAGE_BUCKET=${REACT_APP_FCM_STORAGE_BUCKET}&REACT_APP_FCM_MESSAGING_SENDER_ID=${REACT_APP_FCM_MESSAGING_SENDER_ID}&REACT_APP_FCM_APP_ID=${REACT_APP_FCM_APP_ID}&REACT_APP_FCM_MEASUREMENT_ID=${REACT_APP_FCM_MEASUREMENT_ID}`;
// navigator.serviceWorker.register(swUrl);

root.render(
  <React.Fragment>
    <Provider store={store}>
      <PersistGate loading={<Loading />} persistor={persistor}>
        <Router>
          <App />
          <ToastContainer />
        </Router>
      </PersistGate>
    </Provider>
  </React.Fragment>
);


function checkForNewVersionAndClearCache() {

  const currentVersion = localStorage.getItem('appVersion');
  const newVersion = packageJson.version;

  if (currentVersion !== newVersion) {

    localStorage.clear();
    localforage.dropInstance().then(function () {
    }).catch(function (err) {
      console.error('Error deleting database:', err);
    });
    setTimeout(() => {
      window.location.reload();
    }, 0);

    localStorage.setItem('appVersion', newVersion);
  }
}

checkForNewVersionAndClearCache();

window.onload = () => {
  checkForNewVersionAndClearCache();
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
