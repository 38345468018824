import { getBranches, addBranch, editBranch, deleteBranch, getBranchesTableOrdering, getBranchesLoyalty, getBranchesQRMenu, getBranchesFloorSetup, getBranchesOnlineDelivery, getBranchesOrders } from '../../config/config'
import { toast } from 'react-toastify'
import { parseError } from '../../utils/parseError'
import { handleError } from '../../utils/utils'

export const GET_BRANCH_REQUEST = 'GET_BRANCH_REQUEST'
export const GET_BRANCH_SUCCESS = 'GET_BRANCH_SUCCESS'
export const GET_BRANCH_ERROR = 'GET_BRANCH_ERROR'

export const GET_BRANCH_TABLE_ORDERING_REQUEST = 'GET_BRANCH_TABLE_ORDERING_REQUEST'
export const GET_BRANCH_TABLE_ORDERING_SUCCESS = 'GET_BRANCH_TABLE_ORDERING_SUCCESS'
export const GET_BRANCH_TABLE_ORDERING_ERROR = 'GET_BRANCH_TABLE_ORDERING_ERROR'

export const GET_BRANCH_LOYALTY_REQUEST = 'GET_BRANCH_LOYALTY_REQUEST'
export const GET_BRANCH_LOYALTY_SUCCESS = 'GET_BRANCH_LOYALTY_SUCCESS'
export const GET_BRANCH_LOYALTY_ERROR = 'GET_BRANCH_LOYALTY_ERROR'

export const GET_BRANCH_QR_MENU_REQUEST = 'GET_BRANCH_QR_MENU_REQUEST'
export const GET_BRANCH_QR_MENU_SUCCESS = 'GET_BRANCH_QR_MENU_SUCCESS'
export const GET_BRANCH_QR_MENU_ERROR = 'GET_BRANCH_QR_MENU_ERROR'

export const GET_BRANCH_ONLINE_DELIVERY_REQUEST = 'GET_BRANCH_ONLINE_DELIVERY_REQUEST'
export const GET_BRANCH_ONLINE_DELIVERY_SUCCESS = 'GET_BRANCH_ONLINE_DELIVERY_SUCCESS'
export const GET_BRANCH_ONLINE_DELIVERY_ERROR = 'GET_BRANCH_ONLINE_DELIVERY_ERROR'

export const ADD_BRANCH_STARTED = 'ADD_BRANCH_STARTED'
export const ADD_BRANCH_END = 'ADD_BRANCH_END'

export const EDIT_BRANCH_STARTED = 'EDIT_BRANCH_STARTED'
export const EDIT_BRANCH_END = 'EDIT_BRANCH_END'

export const DELETE_BRANCH_STARTED = 'DELETE_BRANCH_STARTED'
export const DELETE_BRANCH_END = 'DELETE_BRANCH_END'

export const CLEAR_ALL_BRANCH = 'CLEAR_ALL_BRANCH'
export const SET_SELECTED_BRANCH = 'SET_SELECTED_BRANCH'
export const SET_SELECTED_BRANCH_TABLE_ORDERING = 'SET_SELECTED_BRANCH_TABLE_ORDERING'
export const SET_SELECTED_BRANCH_LOYALTY = 'SET_SELECTED_BRANCH_LOYALTY'
export const SET_SELECTED_BRANCH_QR_MENU = 'SET_SELECTED_BRANCH_QR_MENU'

export const SET_SELECTED_BRANCH_ONLINE_DELIVERY = 'SET_SELECTED_BRANCH_ONLINE_DELIVERY'
export const SET_SELECTED_BRANCH_DATA = 'SET_SELECTED_BRANCH_DATA'


export const GET_BRANCH_FLOOR_SETUP_REQUEST = 'GET_BRANCH_FLOOR_SETUP_REQUEST'
export const GET_BRANCH_FLOOR_SETUP_SUCCESS = 'GET_BRANCH_FLOOR_SETUP_SUCCESS'
export const GET_BRANCH_FLOOR_SETUP_ERROR = 'GET_BRANCH_FLOOR_SETUP_ERROR'
export const SET_SELECTED_BRANCH_FLOOR_SETUP = 'SET_SELECTED_BRANCH_FLOOR_SETUP'

export const GET_BRANCHES_ORDERS_REQUEST = 'GET_BRANCHES_ORDERS_REQUEST'
export const GET_BRANCHES_ORDERS_SUCCESS = 'GET_BRANCHES_ORDERS_SUCCESS'
export const GET_BRANCHES_ORDERS_ERROR = 'GET_BRANCHES_ORDERS_ERROR'
export const SET_SELECTED_BRANCH_ORDERS = 'SET_SELECTED_BRANCH_ORDERS'

export const CLEAR_BRANCH_LOADING = "CLEAR_BRANCH_LOADING"

const actions = {
  getBranchesOrders: (ruuid) => async (dispatch, getState) => {
    const currentRestaurant = getState().branch.selectedRestaurantOrders
    if (ruuid !== currentRestaurant)
      dispatch({
        type: GET_BRANCHES_ORDERS_REQUEST,
        payload: ruuid
      })
    await getBranchesOrders(ruuid).then((res) => {
      if (res?.status === 200) {
        if (res?.data?.branches?.length === 0)
          toast.error("This restaurant doesn't have any branch")
        dispatch({
          type: GET_BRANCHES_ORDERS_SUCCESS,
          payload: {
            branches: res?.data?.branches,
          },
        })
        const currentSelectedBranch = getState().branch.selectedBranchOrders;
        const currentSelectedBranchStillAvailable = res?.data?.branches?.find((branch) => branch.uuid === currentSelectedBranch && branch.isActive);

        if (!(currentRestaurant === ruuid && currentSelectedBranchStillAvailable)) {
          let selectedBranch = res?.data?.branches.find((branch) => branch.isActive);
          dispatch({
            type: SET_SELECTED_BRANCH_ORDERS,
            data: selectedBranch?.uuid || '0'
          })
        }
      } else {
        handleError(dispatch)(res, GET_BRANCHES_ORDERS_ERROR, 'GET BRANCH ORDERS ERROR')
      }
    })
  },
  setSelectedBranchOrders: (data) => async (dispatch) => {
    dispatch({
      type: SET_SELECTED_BRANCH_ORDERS,
      data: data
    })
  },
  // Get All restaurants with the selected one
  getBranches: (ruuid, data) => async (dispatch, getState) => {
    const currentRestaurant = getState().branch.selectedRestaurant
    if (ruuid !== currentRestaurant)
      dispatch({
        type: GET_BRANCH_REQUEST,
        payload: ruuid
      })
    await getBranches(ruuid, data).then((res) => {
      if (res?.status === 200) {
        if (res?.data?.branches?.length === 0)
          toast.error("This restaurant doesn't have any branch")
        dispatch({
          type: GET_BRANCH_SUCCESS,
          payload: {
            branches: res?.data?.branches,
          },
        })
        let selectedBranch = res?.data?.branches.find((branch) => branch.isActive)
        dispatch({
          type: SET_SELECTED_BRANCH,
          data: selectedBranch?.uuid || '0'
        })
      } else {
        handleError(dispatch)(res, GET_BRANCH_ERROR, 'GET BRANCH ERROR')
      }
    })
  },
  getBranchesTableOrdering: (ruuid) => async (dispatch, getState) => {
    const currentRestaurant = getState().branch.selectedRestaurantTableOrdering
    if (ruuid !== currentRestaurant)
      dispatch({
        type: GET_BRANCH_TABLE_ORDERING_REQUEST,
        payload: ruuid
      })
    await getBranchesTableOrdering(ruuid).then((res) => {
      if (res?.status === 200) {
        if (res?.data?.branches?.length === 0)
          window.location = '/forbidden'
        dispatch({
          type: GET_BRANCH_TABLE_ORDERING_SUCCESS,
          payload: {
            branches: res?.data?.branches,
          },
        })
        let branchesUuids = res?.data?.branches.map((branch) => branch.uuid)
        if (getState().branch.selectedBranchtableOrdering === '0' || !branchesUuids.includes(getState().branch.selectedBranchtableOrdering)) {
          let selectedBranch = res?.data?.branches.find((branch) => branch.isActive)
          dispatch({
            type: SET_SELECTED_BRANCH_TABLE_ORDERING,
            data: selectedBranch?.uuid || '0'
          })
        }
      } else {
        handleError(dispatch)(res, GET_BRANCH_TABLE_ORDERING_ERROR, 'GET TABLE ORDERING BRANCHES ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Get All branches error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
        // dispatch({
        //   type: GET_BRANCH_TABLE_ORDERING_ERROR,
        //   payload: {
        //     message: parseError(res?.data?.error) || res?.data?.message || 'GET branch error',
        //   },
        // })
      }
    })
  },
  getBranchesLoyalty: (ruuid, data) => async (dispatch, getState) => {
    const currentRestaurant = getState().branch.selectedRestaurantLoyalty
    if (ruuid !== currentRestaurant)
      dispatch({
        type: GET_BRANCH_LOYALTY_REQUEST,
        payload: ruuid
      })
    await getBranchesLoyalty(ruuid, data).then((res) => {
      if (res?.status === 200) {
        if (res?.data?.branches?.length === 0)
          window.location = '/forbidden'
        dispatch({
          type: GET_BRANCH_LOYALTY_SUCCESS,
          payload: {
            branches: res?.data?.branches,
          },
        })
        let branchesUuids = res?.data?.branches.map((branch) => branch.uuid)
        if (getState().branch.selectedBranchLoyalty === "0" || !branchesUuids.includes(getState().branch.selectedBranchLoyalty)) {
          let selectedBranch = res?.data?.branches.find((branch) => branch.isActive)
          dispatch({
            type: SET_SELECTED_BRANCH_LOYALTY,
            data: selectedBranch?.uuid || "0",
          })
        }
      } else {
        handleError(dispatch)(res, GET_BRANCH_LOYALTY_ERROR, 'GET BRANCH LOYALTY ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Get All branches error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
        // dispatch({
        //   type: GET_BRANCH_LOYALTY_ERROR,
        //   payload: {
        //     message: parseError(res?.data?.error) || res?.data?.message || 'GET branch error',
        //   },
        // })
      }
    })
  },
  getBranchesQRMenu: (ruuid, data) => async (dispatch, getState) => {
    const currentRestaurant = getState().branch.selectedRestaurantQRMenu
    if (ruuid !== currentRestaurant)
      dispatch({
        type: GET_BRANCH_QR_MENU_REQUEST,
        payload: ruuid
      })
    await getBranchesQRMenu(ruuid, data).then((res) => {
      if (res?.status === 200) {
        if (res?.data?.branches?.length === 0)
          window.location = '/forbidden'
        dispatch({
          type: GET_BRANCH_QR_MENU_SUCCESS,
          payload: {
            branches: res?.data?.branches,
          },
        })
        let branchesUuids = res?.data?.branches.map((branch) => branch.uuid)
        if (getState().branch.setSelectedBranchQRMenu === "0" || !branchesUuids.includes(getState().branch.selectedBranchQRMenu)) {
          let selectedBranch = res?.data?.branches.find((branch) => branch.isActive)
          dispatch({
            type: SET_SELECTED_BRANCH_QR_MENU,
            data: selectedBranch?.uuid || "0",
          })
        }
      } else {
        handleError(dispatch)(res, GET_BRANCH_QR_MENU_ERROR, 'GET BRANCH QR MENU ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Get All branches error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
        // dispatch({
        //   type: GET_BRANCH_QR_MENU_ERROR,
        //   payload: {
        //     message: parseError(res?.data?.error) || res?.data?.message || 'GET branch error',
        //   },
        // })
      }
    })
  },

  getBranchesFloorSetup: (ruuid) => async (dispatch, getState) => {
    const currentRestaurant = getState().branch.selectedRestaurantFloorSetup
    if (ruuid !== currentRestaurant)
      dispatch({
        type: GET_BRANCH_FLOOR_SETUP_REQUEST,
        payload: ruuid
      })
    await getBranchesFloorSetup(ruuid).then((res) => {
      if (res?.status === 200) {
        if (res?.data?.branches?.length === 0)
          window.location = '/forbidden'
        dispatch({
          type: GET_BRANCH_FLOOR_SETUP_SUCCESS,
          payload: {
            branches: res?.data?.branches,
          },
        })
        let branchesUuids = res?.data?.branches.map((branch) => branch.uuid)
        if (getState().branch.setSelectedBranchFloorSetup === "0" || !branchesUuids.includes(getState().branch.selectedBranchFloorSetup)) {
          let selectedBranch = res?.data?.branches.find((branch) => branch.isActive)
          dispatch({
            type: SET_SELECTED_BRANCH_FLOOR_SETUP,
            data: selectedBranch?.uuid || "0",
          })
        }
      } else {
        handleError(dispatch)(res, GET_BRANCH_FLOOR_SETUP_ERROR, 'GET BRANCH FLOOR SETUP ERROR')

      }
    })
  },

  getBranchesOnlineDelivery: (ruuid, data) => async (dispatch, getState) => {
    const currentRestaurant = getState().branch.selectedRestaurantOnlineDelivery
    if (ruuid !== currentRestaurant)
      dispatch({
        type: GET_BRANCH_ONLINE_DELIVERY_REQUEST,
        payload: ruuid
      })
    await getBranchesOnlineDelivery(ruuid, data).then((res) => {
      if (res?.status === 200) {
        if (res?.data?.branches?.length === 0)
          window.location = '/forbidden'
        dispatch({
          type: GET_BRANCH_ONLINE_DELIVERY_SUCCESS,
          payload: {
            branches: res?.data?.branches,
          },
        })
        let branchesUuids = res?.data?.branches.map((branch) => branch.uuid)
        if (getState().branch.selectedBranchOnlineDelivery === "0" || !branchesUuids.includes(getState().branch.selectedBranchOnlineDelivery)) {
          let selectedBranch = res?.data?.branches.find((branch) => branch.isActive)
          dispatch({
            type: SET_SELECTED_BRANCH_ONLINE_DELIVERY,
            data: selectedBranch?.uuid || "0",
          })
        }
      } else {
        handleError(dispatch)(res, GET_BRANCH_ONLINE_DELIVERY_ERROR, 'GET BRANCH ONLINE DELIVERY ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Get All branches error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
        // dispatch({
        //   type: GET_BRANCH_ONLINE_DELIVERY_ERROR,
        //   payload: {
        //     message: parseError(res?.data?.error) || res?.data?.message || 'GET branch error',
        //   },
        // })
      }
    })
  },
  addBranch: (ruuid, data, history, updateBranchTaxes, taxes, taxesCache) => async (dispatch) => {
    dispatch({
      type: ADD_BRANCH_STARTED,
    })
    await addBranch(ruuid, data).then((res) => {
      if (res?.status === 200) {
        toast.success(res?.data?.message || 'Successfully Saved')
        dispatch({
          type: GET_BRANCH_SUCCESS,
          payload: {
            branches: res?.data?.branches,
          },
        })
        let taxesNeeded = taxesCache.map((tax) => tax.uuid)
        let excluded = {}
        taxes.map((tax) => {
          excluded[tax.key] = taxesNeeded.filter((neededTax) => !tax.taxes.some((txs) => txs.uuid === neededTax)) //tax.taxes.map((tx) => tx.uuid)
        })
        let finalTaxes = {
          details: [
            {
              buuid: res?.data?.addedBranch,
              exclusions: excluded,
              taxes: taxesNeeded
            }
          ],

        }

        updateBranchTaxes(ruuid, finalTaxes)
        let firstSelected = res?.data?.branches.filter((branch) => branch.isActive)[0]?.uuid || '0'
        dispatch({
          type: SET_SELECTED_BRANCH,
          data: firstSelected,
        })
        history.goBack()
      } else {
        handleError(dispatch)(res, null, 'ADD BRANCH ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Add Branch Error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
      }
    })
    dispatch({
      type: ADD_BRANCH_END,
    })
  },

  editBranch: (data, ruuid, uuid, history, taxes, taxesCache, updateBranchTaxes) => async (dispatch) => {
    dispatch({
      type: EDIT_BRANCH_STARTED,
    })
    await editBranch(ruuid, uuid, data).then((res) => {
      if (res?.status === 200) {
        toast.success(res?.data?.message || 'Successfully Saved')
        dispatch({
          type: GET_BRANCH_SUCCESS,
          payload: {
            branches: res?.data?.branches,
          },
        })
        let taxesNeeded = taxesCache.map((tax) => tax.uuid)
        let excluded = {}
        taxes.map((tax) => {
          excluded[tax.key] = taxesNeeded.filter((neededTax) => !tax.taxes.some((txs) => txs.uuid === neededTax)) //tax.taxes.map((tx) => tx.uuid)
        })
        let finalTaxes = {
          details: [
            {
              buuid: uuid,
              exclusions: excluded,
              taxes: taxesNeeded
            }
          ],

        }

        updateBranchTaxes(ruuid, finalTaxes)
        let firstSelected = res?.data?.branches.filter((branch) => branch.isActive)[0]?.uuid || '0'
        dispatch({
          type: SET_SELECTED_BRANCH,
          data: firstSelected,
        })
        history.goBack()
      } else {
        handleError(dispatch)(res, null, 'EDIT BRANCH ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Edit Branch Error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
      }
    })
    dispatch({
      type: EDIT_BRANCH_END,
    })
  },

  deleteBranch: (ruuid, uuid) => async (dispatch) => {
    dispatch({
      type: DELETE_BRANCH_STARTED,
    })
    await deleteBranch(ruuid, uuid).then((res) => {
      if (res?.status === 200) {
        toast.success(res?.data?.message || 'Successfully Deleted')
        dispatch({
          type: GET_BRANCH_SUCCESS,
          payload: {
            branches: res?.data?.branches,
          },
        })
      } else {
        handleError(dispatch)(res, null, 'DELETE BRANCH ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Delete Branch Error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
      }
    })
    dispatch({
      type: DELETE_BRANCH_END,
    })
  },

  setSelectedBranch: (data) => (dispatch) => {
    dispatch({
      type: SET_SELECTED_BRANCH,
      data: data,
    })
  },

  setSelectedBranchData: (data) => (dispatch) => {
    dispatch({
      type: SET_SELECTED_BRANCH_DATA,
      payload: data,
    })
  },

  setSelectedBranchTableOrdering: (data) => (dispatch) => {
    dispatch({
      type: SET_SELECTED_BRANCH_TABLE_ORDERING,
      data: data,
    })
  },

  setSelectedBranchLoyalty: (data) => (dispatch) => {
    dispatch({
      type: SET_SELECTED_BRANCH_LOYALTY,
      data: data,
    })
  },

  setSelectedBranchQRMenu: (data) => (dispatch) => {
    dispatch({
      type: SET_SELECTED_BRANCH_QR_MENU,
      data: data,
    })
  },

  setSelectedBranchFloorSetup: (data) => (dispatch) => {
    dispatch({
      type: SET_SELECTED_BRANCH_FLOOR_SETUP,
      data: data,
    })
  },

  setSelectedBranchOnlineDelivery: (data) => (dispatch) => {
    dispatch({
      type: SET_SELECTED_BRANCH_ONLINE_DELIVERY,
      data: data,
    })
  },

  // CLEAR ALL Reducer
  clearAllBranches: () => (dispatch) => {
    dispatch({
      type: CLEAR_ALL_BRANCH,
    })
  },
}

export default actions
