import React, { useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import actions from "../../redux/actions/RestActions";
import authActions from "../../redux/actions/AuthActions";
import layoutActions from "../../redux/actions/LayoutActions";
import { getSVG } from "../../utils/getSvg"
import { GetScreenSize } from "../../utils/screenSize";
import LoadingButton from "../loadingButton/LoadingButton";
import { useHistory } from "react-router-dom";
import {
  Box,
  Toolbar,
  Button,
  MenuItem,
  useTheme,
  styled,
  InputBase,
  NativeSelect,
  Tooltip,
  Select
} from "@mui/material";
import NavBarRoot from './NavBarRoot'
import StyledMenu from './StyledMenu'
import { getTranslation, getField, getFieldData, getPagePermissionByPath } from "../../utils/utils";
import ChooseLanguage from './ChooseLanguage'
import { getPagePermission } from "../../utils/utils";
import Notifications from "./Notifications";
import notificationsActions from '../../redux/actions/NotificationsActions'


const Header = ({
  sideNavState,
  sideNavChange,
  logout,
  onSave,
  onCancel,
  isSubmitting,
  leftComponents,
  updateHeaderState,
  translation,
  language,
  extraComponent,
  admin,
  disabled,
  disableBurgerMenu,
  belowHeader,
  setSelectedRest,
  restaurants,
  selectedRestaurant,
  permissions,
  getNotifications,
  allRestaurantsPermission,
  forceEnableRestaurantSelect,
  replaceLeft
}) => {
  const [finalRestaurants, setFinalRestaurants] = useState([])
  useEffect(() => {
    let final = []
    restaurants.map((rest, i) => {
      let obj = { ...rest }
      let tmp = allRestaurantsPermission.find((res) => res.restaurantUuid === rest.uuid)?.permissions
      let target
      if (tmp)
        target = getPagePermissionByPath(allRestaurantsPermission.find((res) => res.restaurantUuid === rest.uuid)?.permissions, window.location.pathname)
      final.push({ ...obj, isDisabled: !Boolean(target) })
    })
    setFinalRestaurants(final)
  }, [restaurants, window.location.pathname])
  const accessUsers = getPagePermission(permissions, 'users')
  const [isGlobal, setIsGlobal] = useState(false)

  useEffect(() => {
    let page = getPagePermissionByPath(permissions, window.location.pathname)
    setIsGlobal(page?.isGlobal)
  }, [window.location.pathname, permissions])

  //SELECT COMPONENT
  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    '& .MuiInputBase-input': {
      fontSize: 12, // Change the font size as needed
      fontWeight: 'bold',
      color: 'red', // Change the text color as needed
      textAlign: 'center', // Align the text to the center
      //borderBottom: 'none', // Remove the bottom line
      padding: '1px', // Add padding as needed
    },
    '& .MuiInputBase-input::-webkit-input-placeholder': {
      textAlign: 'center', // Align placeholder text to the center
    },
    '& .MuiInputBase-input:-moz-placeholder': {
      textAlign: 'center',
    },
    '& .MuiInputBase-input::-moz-placeholder': {
      textAlign: 'center',
    },
    '& .MuiInputBase-input:-ms-input-placeholder': {
      textAlign: 'center',
    },
  }));

  const [chooseLangModalState, setChooseLangModalState] = useState(false)
  const closeChooseLanguage = () => {
    setChooseLangModalState(false)
  }
  const dictionary = getTranslation('header', translation)
  const theme = useTheme()

  useEffect(() => {
    if (onSave || onCancel) {
      updateHeaderState(true)
    }
  }, [onSave, onCancel])


  const screenSize = GetScreenSize()
  const [userMenu, setUserMenu] = useState(false);
  const open = Boolean(userMenu);
  const history = useHistory()
  const handleClick = (event) => {
    setUserMenu(event.currentTarget);
  };


  const handleActions = (act) => {
    setUserMenu(false)
    if (act)
      switch (act) {
        case 'logout':
          logout()
          break
        case 'account-settings':
          history.push('/user-profile')
          break
        case 'choose-lang':
          setChooseLangModalState(true)
          break
        case 'users':
          history.push('/users')
          break
        default:
          return ('')
      }
  }

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClickNotification = (event) => {
    getNotifications()
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openNotification = Boolean(anchorEl);
  const id = open ? 'notification-popover' : undefined;

  const memoizedUserMenu = useMemo(() => {
    return (
      <StyledMenu
        anchorEl={userMenu}
        open={open}
        onClose={() => setUserMenu(false)}
      >
        <MenuItem onClick={() => handleActions("account-settings")}>
          {getField(dictionary, "account-settings", language)}
        </MenuItem>
        {accessUsers && (
          <MenuItem onClick={() => handleActions("users")}>
            {getField(dictionary, "users", language)}
          </MenuItem>
        )}
        <MenuItem onClick={() => handleActions("logout")}>
          {getField(dictionary, "logout", language)}
        </MenuItem>
        <MenuItem onClick={() => handleActions("choose-lang")}>
          {getField(dictionary, "language-settings", language)}
        </MenuItem>
      </StyledMenu>
    );
  }, [userMenu, open, dictionary, language, accessUsers]);    

  return (
    <NavBarRoot>
      <Toolbar variant="dense" sx={{ display: "flex", alignItems: "center", maxWidth: "100%" }}>
        {!disableBurgerMenu && (
          <Box
            sx={{
              backgroundColor: sideNavState
                ? theme.palette.menuButton.activeBackgroundColor
                : theme.palette.menuButton.backgroundColor,
              padding: "6px",
              display: "flex",
              alignItems: "center",
              borderRadius: "8px",
              cursor: "pointer",
              transform: `${sideNavState && screenSize.dynamicWidth > 666 ? `translateX(190px)` : "none"}`,
              transition: "transform 125ms cubic-bezier(0, 0, 0.2, 1) 0ms !Important",
              zIndex: 10,
            }}
            onClick={() => {
              sideNavChange(!sideNavState);
            }}
          >
            {getSVG("Menu", theme.palette.primary.main, { width: 20, height: 20 })}
          </Box>
        )}
        {!replaceLeft ? (
          restaurants.length > 1 &&
          (!isGlobal || forceEnableRestaurantSelect) &&
          screenSize.dynamicWidth > 700 && (
            <Select
              variant="standard"
              disableUnderline
              value={selectedRestaurant}
              onChange={(event) => setSelectedRest(event.target.value)}
              sx={{
                backgroundColor: "background.paper",
                borderRadius: "8px",
                height: 35,
                minWidth: screenSize.dynamicWidth > 700 ? "200px" : "90px",
                fontSize: screenSize.dynamicWidth > 700 ? 16 : 12,
                color: "primary.main",
                fontWeight: 700,
                padding: "6px",
                margin: "0px 5px 0px 5px",
                outline: "none",
                transform: `${
                  sideNavState && screenSize.dynamicWidth > 666 && !disableBurgerMenu ? `translateX(190px)` : "none"
                }`,
                transition: "transform 125ms cubic-bezier(0, 0, 0.2, 1) 0ms !important",
              }}
            >
              {finalRestaurants.map((restaurant, i) => (
                <MenuItem value={restaurant.uuid} disabled={restaurant.isDisabled} key={`resto-${restaurant.uuid}`}>
                  <Box>
                    {getFieldData(restaurant, "name", language)}
                    {restaurant.isDisabled && (
                      <Box sx={{ fontStyle: "italic", fontSize: 11 }}>
                        {getField(dictionary, "no-access", language)}
                      </Box>
                    )}
                  </Box>
                </MenuItem>
              ))}
            </Select>
          )
        ) : (
          replaceLeft
        )}
        <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%", ...(sideNavState && { marginLeft: "220px" }) }}>
          <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
            {leftComponents}
            {extraComponent}
  
            {onCancel && (
              <Button
                variant="contained"
                color="secondary"
                sx={{ marginRight: 0.5 }}
                onClick={onCancel}
                size="small"
              >
                {getField(dictionary, "cancel", language)}
              </Button>
            )}
  
            {onSave && (
              <LoadingButton
                disabled={isSubmitting || disabled}
                isSubmitting={isSubmitting}
                title={getField(dictionary, "save", language)}
                variant="contained"
                sx={{ marginLeft: 0.5 }}
                onClick={onSave}
                size="small"
              />
            )}
          </Box>
          <Button
            sx={{ marginLeft: 0.5, minWidth: 40, width: "auto" }}
            variant="contained"
            size="small"
            aria-describedby={id}
            onClick={handleClickNotification}
          >
            {getSVG("Bell", theme.palette.userMenu.icons, { width: 20, height: 20 })}
          </Button>
          <Button
            aria-expanded={open ? "true" : undefined}
            variant="contained"
            disableElevation
            onClick={handleClick}
            sx={{ marginLeft: 0.5, minWidth: screenSize.dynamicWidth > 667 ? 100 : 40, padding: "6px", width: "auto" }}
            endIcon={
              screenSize.dynamicWidth > 667
                ? getSVG("arrow-down", theme.palette.userMenu.icons, { width: 10, height: 10 }, userMenu)
                : null
            }
            size="small"
          >
            {getSVG("Person", theme.palette.userMenu.icons, { width: 20, height: 20 }, null, {
              marginRight: screenSize.dynamicWidth > 666 ? 7 : 0,
            })}
            {screenSize.dynamicWidth > 667
              ? admin
                ? admin.firstName
                : getField(dictionary, "user", language)
              : null}
          </Button>
  
          {memoizedUserMenu}
  
          <Notifications
            open={openNotification}
            id={id}
            anchorEl={anchorEl}
            handleClose={handleClose}
            dictionary={dictionary}
            language={language}
          />
        </Box>
      </Toolbar>
      {belowHeader && (
        <Toolbar sx={{ minHeight: "50px !important", display: "flex", alignItems: "center", maxWidth: "100%", paddingBottom: "5px" }}>
          {belowHeader}
        </Toolbar>
      )}
      <ChooseLanguage
        isOpen={chooseLangModalState}
        onClose={closeChooseLanguage}
        dictionary={dictionary}
        getField={getField}
      />
    </NavBarRoot>
  );  
};

const mapStateToProps = (state) => ({
  translation: state.translation.translation,
  language: state.translation.language,
  sideNavState: state.layout.settings?.layoutSettings?.sidebar?.open,
  admin: state.auth.admin,
  selectedRestaurant: state.rest.selectedRest,
  restaurants: state.rest.restaurants,
  permissions: state.auth.permissions,
  allRestaurantsPermission: state.rest.allRestaurantsRoles
});
const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(authActions.logout()),
  setSelectedRest: (value) => dispatch(actions.setSelectedRest(value)),
  sideNavChange: (state) => dispatch(layoutActions.sideNavChange(state)),
  updateHeaderState: (state) => dispatch(layoutActions.updateHeaderState(state)),
  getNotifications: () => dispatch(notificationsActions.getNotifications())
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);