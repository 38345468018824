import React from 'react'
import { TextField } from '@mui/material'

function InputField({ field, form, ...props }) {
  const destructure = (value, key) => {
    const keys = key.split('.')
    let obj = value
    keys?.map((zone) => {
      return (obj = obj[zone] ? obj[zone] : {})
    })
    return Object.keys(obj)?.length > 0 ? obj : obj === (true || false) ? obj : undefined
  }
  const touched =
    field?.name?.split('.')?.length > 1 ? destructure(form?.touched, field?.name, 1) : form?.touched[field.name]
  const hasError = field?.name.split('.')?.length > 1 ? destructure(form.errors, field?.name, 0) : form?.errors[field.name]
  const onInputChange = ({ target: { value } }) => form?.setFieldValue(field?.name, value)

  const onBlur = () => form?.setFieldTouched(field.name, true)
  return (
    <>
      <TextField
        size='small'
        {...field}
        {...props}
        onBlur={props.onBlur || onBlur}
        onChange={props.onChange || onInputChange}
        error={props.error || Boolean(touched && hasError)}
        helperText={props.helperText || (touched && hasError)}
        sx={{
          '& input[type="color"]': {
            cursor: 'pointer',
          },
          '& input[type="date"]': {
            cursor: 'pointer',
          },
          ...props.sx,
        }}
      />
    </>
  )
}

export default InputField
