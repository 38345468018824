import {
    GET_PAYMENTS_REQUEST,
    GET_PAYMENTS_SUCCESS,
    GET_PAYMENTS_ERROR,

    GET_PAYMENT_REQUEST,
    GET_PAYMENT_SUCCESS,
    GET_PAYMENT_ERROR,

    ADD_PAYMENT_STARTED,
    ADD_PAYMENT_ENDED,

    EDIT_PAYMENT_STARTED,
    EDIT_PAYMENT_ENDED,

    DELETE_PAYMENT_STARTED,
    DELETE_PAYMENT_ENDED,

    CLEAR_PAYMENTS,
    CLEAR_PAYMENTS_LOADING
} from "../actions/PaymentsActions";

const initialState = {
    payments: [],
    payment: null,
    loading: false,
    addLoading: false,
    editLoading: false,
    deleteLoading: false
}

function payments(state = initialState, action) {
    switch (action.type) {
        case CLEAR_PAYMENTS_LOADING:
            return Object.assign({}, state, {
                loading: false,
                addLoading: false,
                editLoading: false,
                deleteLoading: false
            })
        case CLEAR_PAYMENTS:
            return initialState
        case DELETE_PAYMENT_ENDED:
            return Object.assign({}, state, {
                deleteLoading: false
            })
        case DELETE_PAYMENT_STARTED:
            return Object.assign({}, state, {
                deleteLoading: true
            })
        case EDIT_PAYMENT_ENDED:
            return Object.assign({}, state, {
                editLoading: false
            })
        case EDIT_PAYMENT_STARTED:
            return Object.assign({}, state, {
                editLoading: true
            })
        case ADD_PAYMENT_ENDED:
            return Object.assign({}, state, {
                addLoading: false
            })
        case ADD_PAYMENT_STARTED:
            return Object.assign({}, state, {
                addLoading: true
            })
        case GET_PAYMENT_ERROR:
            return Object.assign({}, state, {
                looading: false,
                payment: null
            })
        case GET_PAYMENT_SUCCESS:
            return Object.assign({}, state, {
                loading: false,
                payment: action.payload
            })
        case GET_PAYMENT_REQUEST:
            return Object.assign({}, state, {
                loading: true
            })
        case GET_PAYMENTS_ERROR:
            return Object.assign({}, state, {
                loading: false,
                payments: []
            })
        case GET_PAYMENTS_SUCCESS:
            return Object.assign({}, state, {
                loading: false,
                payments: action.payload
            })
        case GET_PAYMENTS_REQUEST:
            return Object.assign({}, state, {
                loading: true
            })
        default:
            return state
    }
}
export default payments