import {
  GET_MENU_REQUEST,
  GET_MENU_SUCCESS,
  GET_MENU_ERROR,
  ADD_MENU_REQUEST,
  ADD_MENU_SUCCESS,
  ADD_MENU_ERROR,
  EDIT_MENU_REQUEST,
  EDIT_MENU_SUCCESS,
  EDIT_MENU_ERROR,
  GET_MENU_DISPLAY_DATA_SUCCESS,
  GET_MENU_DISPLAY_DATA_REQUEST,
  GET_MENU_DISPLAY_DATA_ERROR,
  GET_MENUS_REQUEST,
  GET_MENUS_SUCCESS,
  GET_MENUS_ERROR,
  DELETE_MENU_STARTED,
  DELETE_MENU_ENDED,
  SET_SELECTED_MENU,
  EDIT_MENU_NAME_STARTED,
  EDIT_MENU_NAME_ENDED,
  GET_MENU_DISPLAY_PRODUCTS_REQUEST,
  GET_MENU_DISPLAY_PRODUCTS_SUCCESS,
  GET_MENU_DISPLAY_PRODUCTS_ERROR,
  GET_MENUS_WITH_DATA_STARTED,
  GET_MENUS_WITH_DATA_ENDED,
  CLEAR_MENU,
  CLEAR_MENU_LOADING,
  GET_MULTIMENUS_REQUEST,
  GET_MULTIMENU_DATA_REQUEST
} from '../actions/MenuActions'

const initialState = {
  loading: false,
  addLoading: false,
  editLoading: false,
  deleteLoading: false,
  menu: {
    products: [],
    menu: {},
    groups: [],
    menuCache: ''
  },
  menuDisplayLoading: false,
  multiMenuLoading: false,
  menus: [],
  menusLoading: false,
  selectedMenu: '',
  blockGet: false
}

function menu(state = initialState, action) {
  switch (action.type) {
    case GET_MULTIMENU_DATA_REQUEST:
      return Object.assign({}, state, {
        multiMenuLoading: true
      })
    case GET_MULTIMENUS_REQUEST:
      return Object.assign({}, state, {
        multiMenuLoading: true
      })
    case CLEAR_MENU_LOADING:
      return Object.assign({}, state, {
        loading: false,
        addLoading: false,
        editLoading: false,
        deleteLoading: false,
        menuDisplayLoading: false,
        menusLoading: false,
        multiMenuLoading: false
      })
    case GET_MENU_REQUEST:
      return Object.assign({}, state, {
        menuDisplayLoading: true
      })
    case GET_MENU_SUCCESS:
      return Object.assign({}, state, {
        menuDisplayLoading: false,
        menu: { ...state.menu, menu: action.payload.menu },
        multiMenuLoading: false
      })
    case GET_MENU_DISPLAY_DATA_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        menu: action.payload,
        menuDisplayLoading: false,
        multiMenuLoading: false
      })

    case GET_MENU_ERROR:
      return Object.assign({}, state, {
        loading: false,
        menuDisplayLoading: false,
        multiMenuLoading: false
      })

    case ADD_MENU_REQUEST:
      return Object.assign({}, state, {
        addLoading: true,
      })
    case ADD_MENU_SUCCESS:
      return Object.assign({}, state, {
        addLoading: false,
        menu: action.payload.menu,
      })

    case ADD_MENU_ERROR:
      return Object.assign({}, state, {
        addLoading: false,
      })

    case EDIT_MENU_REQUEST:
      return Object.assign({}, state, {
        editLoading: true,
      })
    case EDIT_MENU_SUCCESS:
      return Object.assign({}, state, {
        editLoading: false,
        menu: action.payload,
      })

    case EDIT_MENU_ERROR:
      return Object.assign({}, state, {
        editLoading: false,
      })

    case GET_MENU_DISPLAY_DATA_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        menuDisplayLoading: true
      })
    case GET_MENU_DISPLAY_DATA_ERROR:
      return Object.assign({}, state, {
        loading: false,
        menuDisplayLoading: false,
        multiMenuLoading: false
      })
    case CLEAR_MENU:
      return initialState
    case GET_MENUS_REQUEST:
      return Object.assign({}, state, {
        menusLoading: true,
      })
    case GET_MENUS_SUCCESS:
      return Object.assign({}, state, {
        menusLoading: false,
        menus: action.payload,
      })
    case GET_MENUS_ERROR:
      return Object.assign({}, state, {
        menusLoading: false,
        menus: [],
      })
    case GET_MENUS_WITH_DATA_STARTED:
      return Object.assign({}, state, {
        blockGet: true,
        menuDisplayLoading: true
      })
    case GET_MENUS_WITH_DATA_ENDED:
      return Object.assign({}, state, {
        blockGet: false,
        menuDisplayLoading: false,
        multiMenuLoading: false
      })
    case DELETE_MENU_STARTED:
      return Object.assign({}, state, {
        deleteLoading: true,
      })
    case DELETE_MENU_ENDED:
      return Object.assign({}, state, {
        deleteLoading: false,
      })
    case SET_SELECTED_MENU:
      return Object.assign({}, state, {
        selectedMenu: action.payload
      })
    case EDIT_MENU_NAME_STARTED:
      return Object.assign({}, state, {
        editLoading: true
      })
    case EDIT_MENU_NAME_ENDED:
      return Object.assign({}, state, {
        editLoading: false
      })
    case GET_MENU_DISPLAY_PRODUCTS_REQUEST:
      return Object.assign({}, state, {
        menuDisplayLoading: true
      })
    case GET_MENU_DISPLAY_PRODUCTS_SUCCESS:
      return Object.assign({}, state, {
        menuDisplayLoading: false,
        menu: { ...state.menu, products: action.payload },
        multiMenuLoading: false
      })
    case GET_MENU_DISPLAY_PRODUCTS_ERROR:
      return Object.assign({}, state, {
        menuDisplayLoading: false,
        menu: { ...state.menu, products: [] },
        multiMenuLoading: false
      })
    default:
      return state
  }
}

export default menu
