import { initializeApp } from 'firebase/app';
import { onMessage, getMessaging, getToken } from 'firebase/messaging';

const {
  REACT_APP_FCM_API_KEY,
  REACT_APP_FCM_AUTH_DOMAIN,
  REACT_APP_FCM_PROJECT_ID,
  REACT_APP_FCM_STORAGE_BUCKET,
  REACT_APP_FCM_MESSAGING_SENDER_ID,
  REACT_APP_FCM_APP_ID,
  REACT_APP_FCM_MEASUREMENT_ID,
} = process.env

// Firebase configuration
const firebaseConfig = {
  apiKey: REACT_APP_FCM_API_KEY,
  authDomain: REACT_APP_FCM_AUTH_DOMAIN,
  projectId: REACT_APP_FCM_PROJECT_ID,
  storageBucket: REACT_APP_FCM_STORAGE_BUCKET,
  messagingSenderId: REACT_APP_FCM_MESSAGING_SENDER_ID,
  appId: REACT_APP_FCM_APP_ID,
  measurementId: REACT_APP_FCM_MEASUREMENT_ID
};



// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Retrieve Firebase Messaging object.
const messenger = getMessaging(app)
// Request permission for notifications
const requestNotificationPermission = async () => {
  try {
    const token = await getToken(messenger);
    return token;
  } catch (error) {
    console.error('Unable to get permission to notify.', error);
  }
}

const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messenger, (payload) => {
      resolve(payload);
    });
  });

export { messenger, requestNotificationPermission, onMessageListener };