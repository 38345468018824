import React, { useState } from 'react'
import { Dialog, Box, styled, FormControl, InputBase, NativeSelect, Button } from '@mui/material'
import { connect } from 'react-redux'
import actions from '../../redux/actions/TranslationsActions'
import userActions from '../../redux/actions/UsersActions'
import LoadingButton from '../loadingButton/LoadingButton'
import { getTranslation } from '../../utils/utils'

const ChooseLanguage = ({ isOpen, onClose, dictionary, language, getField, supportedLanguages, editUserSettings, loading, translations }) => {
    const generalDictionary = getTranslation('general', translations)
    const BootstrapInput = styled(InputBase)(({ theme }) => ({
        'label + &': {
            marginTop: theme.spacing(3),
        },
        '& .MuiInputBase-input': {
            fontSize: 16,
            fontWeight: 'bold',
            padding: '6px 6px 6px 6px',
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            color: theme.palette.primary.main,
        },
    }))
    const handleChangeLanguage = (value) => {
        let final = {
            settings: {
                language: value
            }
        }
        editUserSettings(final, onClose)
    }
    const [selectedLanguage, setSelectedLanguage] = useState(language)
    return (
        <Dialog open={isOpen} onClose={onClose} >
            <Box padding={2} sx={{ backgroundColor: 'background.default' }}>
                <h4>{getField(dictionary, 'choose-language', language)}</h4>
                <FormControl fullWidth>
                    <NativeSelect
                        variant="standard"
                        sx={{
                            backgroundColor: 'background.paper',
                            borderRadius: '8px',
                            width: '100%',
                            height: 36,
                        }}
                        input={<BootstrapInput />}
                        value={selectedLanguage}
                        onChange={(event) => setSelectedLanguage(event.target.value)}
                    >
                        {supportedLanguages.map((supportedLanguage, i) =>
                            <option key={i} value={supportedLanguage}>{getField(generalDictionary, supportedLanguage, language)}</option>
                        )}
                    </NativeSelect>
                </FormControl>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 1 }}>
                    <Button
                        variant='contained'
                        color='secondary'
                        sx={{ margin: '0px 2px 0px 2px' }}
                        onClick={onClose}
                    >
                        {getField(dictionary, 'cancel', language)}
                    </Button>
                    <LoadingButton
                        variant='contained'
                        isSubmitting={loading}
                        title={getField(dictionary, 'save', language)}
                        sx={{ margin: '0px 2px 0px 2px' }}
                        onClick={() => handleChangeLanguage(selectedLanguage)}
                    />
                </Box>
            </Box>
        </Dialog>
    )
}

const mapStateToProps = (state) => ({
    language: state.translation.language,
    supportedLanguages: state.translation.supportedLanguages,
    loading: state.user.editLoading,
    translations: state.translation.translation
});

const mapDispatchToProps = (dispatch) => ({
    editUserSettings: (data, onClose) => dispatch(userActions.editUserSettings(data, onClose))
})
export default connect(mapStateToProps, mapDispatchToProps)(ChooseLanguage)