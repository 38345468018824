import { getAppUsersGroups, addAppUsersGroup, editAppUsersGroup, deleteAppUsersGroup } from "../../config/config";
import { toast } from "react-toastify";
import { handleError } from "../../utils/utils";

export const GET_APP_USERS_GROUPS_REQUEST = "GET_APP_USERS_GROUPS_REQUEST";
export const GET_APP_USERS_GROUPS_SUCCESS = "GET_APP_USERS_GROUPS_SUCCESS";
export const GET_APP_USERS_GROUPS_ERROR = "GET_APP_USERS_GROUPS_ERROR";

export const ADD_APP_USERS_GROUPS_STARTED = "ADD_APP_USERS_GROUPS_STARTED"
export const ADD_APP_USERS_GROUPS_ENDED = "ADD_APP_USERS_GROUPS_ENDED"

export const EDIT_APP_USERS_GROUPS_STARTED = "EDIT_APP_USERS_GROUPS_STARTED"
export const EDIT_APP_USERS_GROUPS_ENDED = "EDIT_APP_USERS_GROUPS_ENDED"

export const DELETE_APP_USERS_GROUPS_STARTED = "DELETE_APP_USERS_GROUPS_STARTED"
export const DELETE_APP_USERS_GROUPS_ENDED = "DELETE_APP_USERS_GROUPS_ENDED"

export const CLEAR_APP_USERS_GROUPS = 'CLEAR_APP_USERS_GROUPS'

export const CLEAR_APP_USERS_LOADING = 'CLEAR_APP_USERS_LOADING'

const actions = {
  getAppUsersGroups: () => async (dispatch, getState) => {
    if (getState().appUsersGroups.usersGroups.length === 0)
      dispatch({
        type: GET_APP_USERS_GROUPS_REQUEST,
      });
    await getAppUsersGroups().then((res) => {
      if (res?.status === 200) {
        dispatch({
          type: GET_APP_USERS_GROUPS_SUCCESS,
          payload: {
            usersGroups: res?.data?.userGroups,
          },
        });
      } else {
        handleError(dispatch)(res, GET_APP_USERS_GROUPS_ERROR, 'GET APP USERS ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || "Get Groups error");
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER });
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
        // dispatch({
        //   type: GET_APP_USERS_GROUPS_ERROR,
        // });
      }
    });
  },
  addAppUsersGroup: (data, onClose) => async (dispatch) => {
    dispatch({
      type: ADD_APP_USERS_GROUPS_STARTED
    })
    await addAppUsersGroup(data).then((response) => {
      if (response.status === 200) {
        toast.success(response?.data?.message || 'ADD APP USERS GROUPS SUCCESS')
        dispatch({
          type: GET_APP_USERS_GROUPS_SUCCESS,
          payload: {
            usersGroups: response?.data?.userGroups,
          },
        })
        if (onClose)
          onClose()
      } else {
        handleError(dispatch)(response, null, 'ADD USERS GROUP ERROR')
        // if (response.status !== 401 && response.status !== 403)
        //   toast.error(response?.data?.message || "ADD USERS GROUP ERROR");
        // if (response.status === 401) {
        //   dispatch({ type: LOGOUT_USER });
        // }
      }
    })
    dispatch({
      type: ADD_APP_USERS_GROUPS_ENDED
    })
  },
  editAppUsersGroup: (uuid, data, onClose) => async (dispatch) => {
    dispatch({
      type: EDIT_APP_USERS_GROUPS_STARTED
    })
    await editAppUsersGroup(uuid, data).then((response) => {
      if (response.status === 200) {
        toast.success(response?.data?.message || 'EDIT APP USERS GROUPS SUCCESS')
        dispatch({
          type: GET_APP_USERS_GROUPS_SUCCESS,
          payload: {
            usersGroups: response?.data?.userGroups,
          },
        })
        if (onClose)
          onClose()
      } else {
        handleError(dispatch)(response, null, 'EDIT USERS GROUP ERROR')
        // if (response.status !== 401 && response.status !== 403)
        //   toast.error(response?.data?.message || "EDIT USERS GROUP ERROR");
        // if (response.status === 401) {
        //   dispatch({ type: LOGOUT_USER });
        // }
      }
    })
    dispatch({
      type: EDIT_APP_USERS_GROUPS_ENDED
    })
  },
  deleteAppUsersGroup: (uuid, close) => async (dispatch) => {
    dispatch({
      type: DELETE_APP_USERS_GROUPS_STARTED
    })
    await deleteAppUsersGroup(uuid).then((response) => {
      if (response.status === 200) {
        toast.success(response?.data?.message || 'DELETE APP USERS GROUPS SUCCESS')
        dispatch({
          type: GET_APP_USERS_GROUPS_SUCCESS,
          payload: {
            usersGroups: response?.data?.userGroups,
          },
        })
        if (close)
          close()
      } else {
        handleError(dispatch)(response, null, 'DELETE USERS GROUP ERROR')
        // if (response.status !== 401 && response.status !== 403)
        //   toast.error(response?.data?.message || "DELETE USERS GROUP ERROR");
        // if (response.status === 401) {
        //   dispatch({ type: LOGOUT_USER });
        // }
      }
    })
    dispatch({
      type: DELETE_APP_USERS_GROUPS_ENDED
    })
  }
};

export default actions;
