import {
    GET_CONVERSIONS_REQUEST,
    GET_CONVERSIONS_SUCCESS,
    GET_CONVERSIONS_ERROR,
    ADD_CONVERSION_STARTED,
    ADD_CONVERSION_END,
    EDIT_CONVERSION_STARTED,
    EDIT_CONVERSION_END,
    DELETE_CONVERSION_STARTED,
    DELETE_CONVERSION_END,
    SET_CONVERSION_POPUP,
    CLEAR_CONVERSION_LOADING,
    CLEAR_ALL_CONVERSION
  } from '../actions/UomConversionsActions'
  
  const initialState = {
    getConversionsLoading: false,
    addConversionLoading: false,
    editConversionLoading: false,
    deleteConversionLoading: false,
    conversions: [],
    conversionPopup: false
  }
  
  function uomConversions(state = initialState, action) {
    switch (action.type) {
      case GET_CONVERSIONS_REQUEST:
        return Object.assign({}, state, {
          getConversionsLoading: true,
        })
      case GET_CONVERSIONS_SUCCESS:
        return Object.assign({}, state, {
          getConversionsLoading: false,
          conversions: action.payload.conversions,
        })
      case GET_CONVERSIONS_ERROR:
        return Object.assign({}, state, {
          getConversionsLoading: false,
          conversions: [],
        })
      case ADD_CONVERSION_STARTED:
        return Object.assign({}, state, {
          addConversionLoading: true,
        })
  
      case ADD_CONVERSION_END:
        return Object.assign({}, state, {
          addConversionLoading: false,
        })
  
      case EDIT_CONVERSION_STARTED:
        return Object.assign({}, state, {
          editConversionLoading: true,
        })
  
      case EDIT_CONVERSION_END:
        return Object.assign({}, state, {
          editConversionLoading: false,
        })
  
      case DELETE_CONVERSION_STARTED:
        return Object.assign({}, state, {
          deleteConversionLoading: true,
        })
  
      case DELETE_CONVERSION_END:
        return Object.assign({}, state, {
          deleteConversionLoading: false,
        })

      case SET_CONVERSION_POPUP:
        return Object.assign({}, state, {
          conversionPopup: action.payload,        
        })

      case CLEAR_CONVERSION_LOADING:
        return Object.assign({}, state, {
          getConversionsLoading: false,
          addConversionLoading: false,
          editConversionLoading: false,
          deleteConversionLoading: false,
        })
      case CLEAR_ALL_CONVERSION:
        return Object.assign({}, state, {
          getConversionsLoading: false,
          addConversionLoading: false,
          editConversionLoading: false,
          deleteConversionLoading: false,
          conversions: []            
        })
      default:
        return state
    }
  }
  
  export default uomConversions
  