import {
    GET_SPLASH_SCREEN_DESIGN_SETUP_REQUEST,
    GET_SPLASH_SCREEN_DESIGN_SETUP_SUCCESS,
    GET_SPLASH_SCREEN_DESIGN_SETUP_ERROR,
    EDIT_SPLASH_SCREEN_DESIGN_SETUP_REQUEST,
    EDIT_SPLASH_SCREEN_DESIGN_SETUP_SUCCESS,
    EDIT_SPLASH_SCREEN_DESIGN_SETUP_ERROR,
    CLEAR_SPLASH_SCREEN_LOADING
} from "../actions/SplashScreenDesignSetupActions";

const initialState = {
    getSplashScreenDesignSetup: [],
    getSplashScreenDesignSetupLoading: false,
    editSplashScreenDesignSetupLoading: false,
    splashScreenDesignSetup: null,
    selectedRestaurant: '',
};

function splashScreenDesignSetup(state = initialState, action) {
    switch (action.type) {
        case CLEAR_SPLASH_SCREEN_LOADING:
            return Object.assign({}, state, {
                getSplashScreenDesignSetupLoading: false,
                editSplashScreenDesignSetupLoading: false,
            })
        case GET_SPLASH_SCREEN_DESIGN_SETUP_REQUEST:
            return Object.assign({}, state, {
                getSplashScreenDesignSetupLoading: true,
                selectedRestaurant: action.payload
            })
        case GET_SPLASH_SCREEN_DESIGN_SETUP_SUCCESS:
            return Object.assign({}, state, {
                getSplashScreenDesignSetupLoading: false,
                splashScreenDesignSetup: action?.payload?.splashScreenDesignSetup,
            })
        case GET_SPLASH_SCREEN_DESIGN_SETUP_ERROR:
            return Object.assign({}, state, {
                getSplashScreenDesignSetupLoading: false,
                splashScreenDesignSetup: null,
            })
        case EDIT_SPLASH_SCREEN_DESIGN_SETUP_REQUEST:
            return Object.assign({}, state, {
                editSplashScreenDesignSetupLoading: true,
                selectedRestaurant: action?.payload
            })
        case EDIT_SPLASH_SCREEN_DESIGN_SETUP_SUCCESS:
            return Object.assign({}, state, {
                editSplashScreenDesignSetupLoading: false,
            })
        case EDIT_SPLASH_SCREEN_DESIGN_SETUP_ERROR:
            return Object.assign({}, state, {
                editSplashScreenDesignSetupLoading: false,
            })
        default:
            return state;
    }
}

export default splashScreenDesignSetup;
