import { getModules } from '../../config/config'
import { toast } from 'react-toastify'
import { LOGOUT_USER, SET_PERMISSIONS } from './AuthActions'
import { handleError } from '../../utils/utils'

export const GET_MODULES_REQUEST = 'GET_MODULES_REQUEST'
export const GET_MODULES_SUCCESS = 'GET_MODULES_SUCCESS'
export const GET_MODULES_ERROR = 'GET_MODULES_ERROR'

export const CLEAR_ALL_MODULES = 'CLEAR_ALL_MODULES'

export const CLEAR_MODULES_LOADING = 'CLEAR_MODULES_LOADING'

const actions = {
    getModules: () => async (dispatch) => {
        dispatch({
            type: GET_MODULES_REQUEST
        })
        await getModules().then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_MODULES_SUCCESS,
                    payload: response.data
                })
            else {
                handleError(dispatch)(response, GET_MODULES_ERROR, 'GET MODULES ERROR')
                // if (response.status !== 401 && response.status !== 403)
                //     toast.error(response.data?.message || 'Get Modules Error')
                // dispatch({
                //     type: GET_MODULES_ERROR
                // })
                // if (response.status === 401)
                //     dispatch({
                //         type: LOGOUT_USER
                //     })
                // if (response.status === 403) {
                //     window.location = '/forbidden'
                //     dispatch({
                //         type: SET_PERMISSIONS,
                //         payload: response?.data?.permissions
                //     })
                // }
            }
        })
    },
    clearAllModules: () => (dispatch) => {
        dispatch({
            type: CLEAR_ALL_MODULES
        })
    }
}
export default actions