import {
    GET_LOYALTY_USER_ENTRIES_REQUEST,
    GET_LOYALTY_USER_ENTRIES_SUCCESS,
    GET_LOYALTY_USER_ENTRIES_ERROR,

    GET_LOYALTY_REQUEST,
    GET_LOYALTY_SUCCESS,
    GET_LOYALTY_ERROR,

    GET_LOYALTY_DESCRIPTION_REQUEST,
    GET_LOYALTY_DESCRIPTION_SUCCESS,
    GET_LOYALTY_DESCRIPTION_ERROR,

    GET_LOYALTY_USERS_REQUEST,
    GET_LOYALTY_USERS_SUCCESS,
    GET_LOYALTY_USERS_ERROR,

    GET_LOYALTY_CONFIG_REQUEST,
    GET_LOYALTY_CONFIG_SUCCESS,
    GET_LOYALTY_CONFIG_ERROR,

    GET_LOYALTY_CASHBACK_REQUEST,
    GET_LOYALTY_CASHBACK_ERROR,
    GET_LOYALTY_CASHBACK_SUCCESS,

    GET_LOYALTY_GIFTS_REQUEST,
    GET_LOYALTY_GIFTS_SUCCESS,
    GET_LOYALTY_GIFTS_ERROR,

    GET_LOYALTY_GIFT_REQUEST,
    GET_LOYALTY_GIFT_SUCCESS,
    GET_LOYALTY_GIFT_ERROR,

    GET_LOYALTY_MENU_REQUEST,
    GET_LOYALTY_MENU_SUCCESS,
    GET_LOYALTY_MENU_ERROR,

    GET_REWARDS_BASED_ON_LEVEL_REQUEST,
    GET_REWARDS_BASED_ON_LEVEL_SUCCESS,
    GET_REWARDS_BASED_ON_LEVEL_ERROR,

    GET_REWARD_BASED_ON_LEVEL_REQUEST,
    GET_REWARD_BASED_ON_LEVEL_SUCCESS,
    GET_REWARD_BASED_ON_LEVEL_ERROR,

    ADD_REWARD_BASED_ON_LEVEL_STARTED,
    ADD_REWARD_BASED_ON_LEVEL_ENDED,

    EDIT_REWARD_BASED_ON_LEVEL_STARTED,
    EDIT_REWARD_BASED_ON_LEVEL_ENDED,

    DELETE_REWARD_BASED_ON_LEVEL_STARTED,
    DELETE_REWARD_BASED_ON_LEVEL_ENDED,

    ADD_EDIT_LOYALTY_MENU_STARTED,
    ADD_EDIT_LOYALTY_MENU_ENDED,

    ADD_LOYALTY_GIFT_STARTED,
    ADD_LOYALTY_GIFT_ENDED,

    ADD_EDIT_LOYALTY_CASHBACK_STARTED,
    ADD_EDIT_LOYALTY_CASHBACK_ENDED,

    EDIT_LOYALTY_GIFT_STARTED,
    EDIT_LOYALTY_GIFT_ENDED,

    DELETE_LOYALTY_GIFT_STARTED,
    DELETE_LOYALTY_GIFT_ENDED,

    ADD_EDIT_LOYALTY_STARTED,
    ADD_EDIT_LOYALTY_ENDED,

    ADD_EDIT_LOYALTY_DESCRIPTION_STARTED,
    ADD_EDIT_LOYALTY_DESCRIPTION_ENDED,

    SET_LOYALTY_SETTINGS_MODAL_STATE,
    SET_EDIT_GIFT,
    SET_EDIT_REWARD_BASED_ON_LEVEL,

    ADD_LOYALTY_CONFIG_STARTED,
    ADD_LOYALTY_CONFIG_ENDED,

    EDIT_LOYALTY_POINTS_STARTED,
    EDIT_LOYALTY_POINTS_ENDED,

    SET_LOYALTY_LEVEL_FILTER,

    CLEAR_ALL_LOYALTY,
    CLEAR_LOYALTY_LOADING
} from '../actions/LoyaltyActions'

const initialState = {
    entriesLoading: false,
    entries: [],
    loading: false,
    addEditLoyaltyLoading: false,
    addEditDescriptionLoading: false,
    addEditMenuLoading: false,
    addGiftLoading: false,
    editGiftLoading: false,
    deleteGiftLoading: false,
    addEditCashbackLoading: false,
    addRewardLoading: false,
    editRewardLoading: false,
    deleteRewardBasedOnLevelLoading: false,
    editPointsLoading: false,
    loyalty: {},
    description: {},
    users: [],
    settingsModalState: false,
    settingsLoading: false,
    getSettingsLoading: false,
    settings: {},
    loyaltyMenu: [],
    loyaltyCashback: {},
    gifts: [],
    gift: {},
    editGift: null,
    rewardsBasedOnLevel: [],
    rewardBasedOnLevel: {},
    editRewardBasedOnLevel: null,
    selectedRestaurantDescription: '',
    selectedRestaurantLoyalty: '',
    selectedRestaurantsLoyaltyUsers: '',
    selectedRestaurantCashback: '',
    selectedRestaurantGifts: '',
    selectedRestaurantGift: '',
    selectedRestaurantMenu: '',
    selectedRestaurantRewards: '',
    selectedRestaurantReward: '',
    getGiftsLoading: false,
    loyaltyLevelFilter: {
        customerLevel: [],
        privileges: []
    }
}

function loyalty(state = initialState, action) {
    switch (action.type) {
        case CLEAR_LOYALTY_LOADING:
            return Object.assign({}, state, {
                entriesLoading: false,
                loading: false,
                addEditLoyaltyLoading: false,
                addEditDescriptionLoading: false,
                addEditMenuLoading: false,
                addGiftLoading: false,
                editGiftLoading: false,
                deleteGiftLoading: false,
                addEditCashbackLoading: false,
                addRewardLoading: false,
                editRewardLoading: false,
                deleteRewardBasedOnLevelLoading: false,
                editPointsLoading: false,
                settingsLoading: false,
                getSettingsLoading: false,
                getGiftsLoading: false,
            })
        case GET_LOYALTY_USER_ENTRIES_REQUEST:
            return Object.assign({}, state, {
                entriesLoading: true
            })
        case GET_LOYALTY_USER_ENTRIES_SUCCESS:
            return Object.assign({}, state, {
                entriesLoading: false,
                entries: action.payload
            })
        case GET_LOYALTY_USER_ENTRIES_ERROR:
            return Object.assign({}, state, {
                entriesLoading: false
            })
        case SET_LOYALTY_LEVEL_FILTER:
            return Object.assign({}, state, {
                loyaltyLevelFilter: action.payload
            })
        case GET_LOYALTY_REQUEST:
            return Object.assign({}, state, {
                loading: true,
                selectedRestaurantLoyalty: action.payload
            })
        case GET_LOYALTY_SUCCESS:
            return Object.assign({}, state, {
                loading: false,
                loyalty: action.payload.loyalty
            })
        case GET_LOYALTY_ERROR:
            return Object.assign({}, state, {
                loading: false,
                loyalty: {}
            })
        case GET_LOYALTY_DESCRIPTION_REQUEST:
            return Object.assign({}, state, {
                loading: true,
                selectedRestaurantDescription: action.payload
            })
        case GET_LOYALTY_DESCRIPTION_SUCCESS:
            return Object.assign({}, state, {
                loading: false,
                description: action.payload.description
            })
        case GET_LOYALTY_DESCRIPTION_ERROR:
            return Object.assign({}, state, {
                loading: false,
                description: {}
            })
        case GET_LOYALTY_USERS_REQUEST:
            return Object.assign({}, state, {
                loading: true,
                selectedRestaurantsLoyaltyUsers: action.payload
            })
        case GET_LOYALTY_USERS_SUCCESS:
            return Object.assign({}, state, {
                loading: false,
                users: action.payload.users
            })
        case GET_LOYALTY_USERS_ERROR:
            return Object.assign({}, state, {
                loading: false,
                users: []
            })
        case ADD_EDIT_LOYALTY_STARTED:
            return Object.assign({}, state, {
                addEditLoyaltyLoading: true
            })
        case ADD_EDIT_LOYALTY_ENDED:
            return Object.assign({}, state, {
                addEditLoyaltyLoading: false
            })
        case ADD_EDIT_LOYALTY_DESCRIPTION_STARTED:
            return Object.assign({}, state, {
                addEditDescriptionLoading: true
            })
        case ADD_EDIT_LOYALTY_DESCRIPTION_ENDED:
            return Object.assign({}, state, {
                addEditDescriptionLoading: false
            })
        case CLEAR_ALL_LOYALTY:
            return initialState
        case SET_LOYALTY_SETTINGS_MODAL_STATE:
            return Object.assign({}, state, {
                settingsModalState: action.payload
            })
        case ADD_LOYALTY_CONFIG_STARTED:
            return Object.assign({}, state, {
                settingsLoading: true
            })
        case ADD_LOYALTY_CONFIG_ENDED:
            return Object.assign({}, state, {
                settingsLoading: false
            })
        case GET_LOYALTY_CONFIG_REQUEST:
            return Object.assign({}, state, {
                getSettingsLoading: true
            })
        case GET_LOYALTY_CONFIG_SUCCESS:
            return Object.assign({}, state, {
                getSettingsLoading: false,
                settings: action.payload
            })
        case GET_LOYALTY_CONFIG_ERROR:
            return Object.assign({}, state, {
                getSettingsLoading: false,
                settings: {}
            })
        case GET_LOYALTY_CASHBACK_REQUEST:
            return Object.assign({}, state, {
                loading: true,
                selectedRestaurantCashback: action.payload
            })
        case GET_LOYALTY_CASHBACK_SUCCESS:
            return Object.assign({}, state, {
                loading: false,
                loyaltyCashback: action.payload
            })
        case GET_LOYALTY_CASHBACK_ERROR:
            return Object.assign({}, state, {
                loading: false,
                loyaltyCashback: {}
            })
        case GET_LOYALTY_GIFTS_REQUEST:
            return Object.assign({}, state, {
                getGiftsLoading: true,
                selectedRestaurantGifts: action.payload
            })
        case GET_LOYALTY_GIFTS_SUCCESS:
            return Object.assign({}, state, {
                getGiftsLoading: false,
                gifts: action.payload
            })
        case GET_LOYALTY_GIFTS_ERROR:
            return Object.assign({}, state, {
                getGiftsLoading: false,
                gifts: []
            })
        case GET_LOYALTY_GIFT_REQUEST:
            return Object.assign({}, state, {
                loading: true,
                selectedRestaurantGift: action.payload
            })
        case GET_LOYALTY_GIFT_SUCCESS:
            return Object.assign({}, state, {
                loading: false,
                gift: action.payload
            })
        case GET_LOYALTY_GIFT_ERROR:
            return Object.assign({}, state, {
                loading: false,
                gift: {}
            })
        case GET_LOYALTY_MENU_REQUEST:
            return Object.assign({}, state, {
                loading: true,
                selectedRestaurantMenu: action.payload
            })
        case GET_LOYALTY_MENU_SUCCESS:
            return Object.assign({}, state, {
                loading: false,
                loyaltyMenu: action.payload
            })
        case GET_LOYALTY_MENU_ERROR:
            return Object.assign({}, state, {
                loading: false,
                loyaltyMenu: {}
            })
        case ADD_EDIT_LOYALTY_MENU_STARTED:
            return Object.assign({}, state, {
                addEditMenuLoading: true
            })
        case ADD_EDIT_LOYALTY_MENU_ENDED:
            return Object.assign({}, state, {
                addEditMenuLoading: false
            })
        case ADD_LOYALTY_GIFT_STARTED:
            return Object.assign({}, state, {
                addGiftLoading: true
            })
        case ADD_LOYALTY_GIFT_ENDED:
            return Object.assign({}, state, {
                addGiftLoading: false
            })
        case ADD_EDIT_LOYALTY_CASHBACK_STARTED:
            return Object.assign({}, state, {
                addEditCashbackLoading: true
            })
        case ADD_EDIT_LOYALTY_CASHBACK_ENDED:
            return Object.assign({}, state, {
                addEditCashbackLoading: false
            })
        case EDIT_LOYALTY_GIFT_STARTED:
            return Object.assign({}, state, {
                editGiftLoading: true
            })
        case EDIT_LOYALTY_GIFT_ENDED:
            return Object.assign({}, state, {
                editGiftLoading: false
            })
        case DELETE_LOYALTY_GIFT_STARTED:
            return Object.assign({}, state, {
                deleteGiftLoading: true
            })
        case DELETE_LOYALTY_GIFT_ENDED:
            return Object.assign({}, state, {
                deleteGiftLoading: false
            })
        case SET_EDIT_GIFT:
            return Object.assign({}, state, {
                editGift: action.payload
            })
        case GET_REWARDS_BASED_ON_LEVEL_REQUEST:
            return Object.assign({}, state, {
                loading: true,
                selectedRestaurantRewards: action.payload
            })
        case GET_REWARDS_BASED_ON_LEVEL_SUCCESS:
            return Object.assign({}, state, {
                loading: false,
                rewardsBasedOnLevel: action.payload
            })
        case GET_REWARDS_BASED_ON_LEVEL_ERROR:
            return Object.assign({}, state, {
                loading: false,
                rewardsBasedOnLevel: []
            })
        case GET_REWARD_BASED_ON_LEVEL_REQUEST:
            return Object.assign({}, state, {
                loading: true,
                selectedRestaurantReward: action.payload
            })
        case GET_REWARD_BASED_ON_LEVEL_SUCCESS:
            return Object.assign({}, state, {
                loading: false,
                rewardBasedOnLevel: action.payload
            })
        case GET_REWARD_BASED_ON_LEVEL_ERROR:
            return Object.assign({}, state, {
                loading: false,
                rewardBasedOnLevel: []
            })
        case ADD_REWARD_BASED_ON_LEVEL_STARTED:
            return Object.assign({}, state, {
                addRewardLoading: true
            })
        case ADD_REWARD_BASED_ON_LEVEL_ENDED:
            return Object.assign({}, state, {
                addRewardLoading: false
            })
        case EDIT_REWARD_BASED_ON_LEVEL_STARTED:
            return Object.assign({}, state, {
                editRewardLoading: true
            })
        case EDIT_REWARD_BASED_ON_LEVEL_ENDED:
            return Object.assign({}, state, {
                editRewardLoading: false
            })
        case DELETE_REWARD_BASED_ON_LEVEL_STARTED:
            return Object.assign({}, state, {
                deleteRewardBasedOnLevelLoading: true
            })
        case DELETE_REWARD_BASED_ON_LEVEL_ENDED:
            return Object.assign({}, state, {
                deleteRewardBasedOnLevelLoading: false
            })
        case SET_EDIT_REWARD_BASED_ON_LEVEL:
            return Object.assign({}, state, {
                editRewardBasedOnLevel: action.payload
            })
        case EDIT_LOYALTY_POINTS_STARTED:
            return Object.assign({}, state, {
                editPointsLoading: true
            })
        case EDIT_LOYALTY_POINTS_ENDED:
            return Object.assign({}, state, {
                editPointsLoading: false
            })
        default:
            return state
    }
}
export default loyalty