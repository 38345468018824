import {
    GET_GALLERY_REQUEST,
    GET_GALLERY_SUCCESS,
    GET_GALLERY_ERROR,
    UPLOAD_GALLERY_STARTED,
    UPLOAD_GALLERY_ENDED,
    UPDATE_LAYOUT_STARTED,
    UPDATE_LAYOUT_ENDED,
    DELETE_GALLERY_STARTED,
    DELETE_GALLERY_ENDED,
    GET_GALLERY_CATEGORIES_REQUEST,
    GET_GALLERY_CATEGORIES_SUCCESS,
    GET_GALLERY_CATEGORIES_ERROR,
    GET_GALLERY_CATEGORY_REQUEST,
    GET_GALLERY_CATEGORY_SUCCESS,
    GET_GALLERY_CATEGORY_ERROR,
    ADD_GALLERY_CATEGORY_STARTED,
    ADD_GALLERY_CATEGORY_ENDED,
    EDIT_GALLERY_CATEGORY_STARTED,
    EDIT_GALLERY_CATEGORY_ENDED,
    DELETE_GALLERY_CATEGORY_STARTED,
    DELETE_GALLERY_CATEGORY_ENDED,
    SET_SELECTED_CATEGORY,
    CLEAR_ALL_GALLERY,
    CLEAR_GALLERY_LOADING
} from '../actions/GalleryActions'

const initialState = {
    loading: false,
    gallery: {},
    categories: [],
    category: {},
    selectedCategory: {},
    selectedRestaurantGallery: '',
    selectedRestaurantCategory: ''
}

function gallery(state = initialState, action) {
    switch (action.type) {
        case CLEAR_GALLERY_LOADING:
            return Object.assign({}, state, {
                loading: false
            })
        case GET_GALLERY_REQUEST:
            return Object.assign({}, state, {
                loading: true,
                selectedRestaurantGallery: action.payload
            })
        case GET_GALLERY_SUCCESS:
            return Object.assign({}, state, {
                loading: false,
                gallery: action.payload.gallery
            })
        case GET_GALLERY_ERROR:
            return Object.assign({}, state, {
                loading: false,
                gallery: {}
            })
        case UPLOAD_GALLERY_STARTED:
            return Object.assign({}, state, {
                loading: true,
            })
        case UPLOAD_GALLERY_ENDED:
            return Object.assign({}, state, {
                loading: false,
            })
        case UPDATE_LAYOUT_STARTED:
            return Object.assign({}, state, {
                loading: true,
            })
        case UPDATE_LAYOUT_ENDED:
            return Object.assign({}, state, {
                loading: false
            })
        case DELETE_GALLERY_STARTED:
            return Object.assign({}, state, {
                loading: true
            })
        case DELETE_GALLERY_ENDED:
            return Object.assign({}, state, {
                loading: false
            })
        case GET_GALLERY_CATEGORIES_REQUEST:
            return Object.assign({}, state, {
                loading: true,
                selectedRestaurantCategory: action.payload
            })
        case GET_GALLERY_CATEGORIES_SUCCESS:
            return Object.assign({}, state, {
                loading: false,
                categories: action.payload.categories
            })
        case GET_GALLERY_CATEGORIES_ERROR:
            return Object.assign({}, state, {
                loading: false,
                categories: []
            })
        case GET_GALLERY_CATEGORY_REQUEST:
            return Object.assign({}, state, {
                loading: true,
            })
        case GET_GALLERY_CATEGORY_SUCCESS:
            return Object.assign({}, state, {
                loading: false,
                category: action.payload.category
            })
        case GET_GALLERY_CATEGORY_ERROR:
            return Object.assign({}, state, {
                loading: false,
                category: {}
            })
        case ADD_GALLERY_CATEGORY_STARTED:
            return Object.assign({}, state, {
                loading: true,
            })
        case ADD_GALLERY_CATEGORY_ENDED:
            return Object.assign({}, state, {
                loading: false
            })
        case EDIT_GALLERY_CATEGORY_STARTED:
            return Object.assign({}, state, {
                loading: true
            })
        case EDIT_GALLERY_CATEGORY_ENDED:
            return Object.assign({}, state, {
                loading: false
            })
        case DELETE_GALLERY_CATEGORY_STARTED:
            return Object.assign({}, state, {
                loading: true
            })
        case DELETE_GALLERY_CATEGORY_ENDED:
            return Object.assign({}, state, {
                loading: false
            })
        case SET_SELECTED_CATEGORY:
            return Object.assign({}, state, {
                selectedCategory: action.payload
            })
        case CLEAR_ALL_GALLERY:
            return initialState
        default:
            return state;
    }
} export default gallery