import {
    GET_DESIGN_SETUP_GLOBAL_SETTINGS_REQUEST,
    GET_DESIGN_SETUP_GLOBAL_SETTINGS_SUCCESS,
    GET_DESIGN_SETUP_GLOBAL_SETTINGS_ERROR,
    EDIT_DESIGN_SETUP_GLOBAL_SETTINGS_REQUEST,
    EDIT_DESIGN_SETUP_GLOBAL_SETTINGS_SUCCESS,
    EDIT_DESIGN_SETUP_GLOBAL_SETTINGS_ERROR,
    GET_DESIGN_SETUP_REQUEST,
    GET_DESIGN_SETUP_SUCCESS,
    GET_DESIGN_SETUP_ERROR,
    EDIT_DESIGN_SETUP_REQUEST,
    EDIT_DESIGN_SETUP_SUCCESS,
    EDIT_DESIGN_SETUP_ERROR,
    CLEAR_DESIGN_SETUP,
    CLEAR_DESIGN_LOADING
} from "../actions/DesignSetupActions";

const initialState = {
    designSetupGlobalSettings: [],
    getDesignSetupGlobalSettingsLoading: false,
    editDesignSetupGlobalSettingsLoading: false,
    designSetup: [],
    getDesignSetupLoading: false,
    editDesignSetupLoading: false,
    selectedRestaurant: '',
};

function designSetup(state = initialState, action) {

    switch (action.type) {
        case CLEAR_DESIGN_LOADING:
            return Object.assign({}, state, {
                getDesignSetupGlobalSettingsLoading: false,
                editDesignSetupGlobalSettingsLoading: false,
                getDesignSetupLoading: false,
                editDesignSetupLoading: false,
            })
        case CLEAR_DESIGN_SETUP:
            return initialState
        case GET_DESIGN_SETUP_GLOBAL_SETTINGS_REQUEST:
            return Object.assign({}, state, {
                getDesignSetupGlobalSettingsLoading: true,
            });
        case GET_DESIGN_SETUP_GLOBAL_SETTINGS_SUCCESS:
            return Object.assign({}, state, {
                getDesignSetupGlobalSettingsLoading: false,
                designSetupGlobalSettings: action?.payload?.values,
            });
        case GET_DESIGN_SETUP_GLOBAL_SETTINGS_ERROR:
            return Object.assign({}, state, {
                getDesignSetupGlobalSettingsLoading: false,
                designSetupGlobalSettings: null,
            });
        case EDIT_DESIGN_SETUP_GLOBAL_SETTINGS_REQUEST:
            return Object.assign({}, state, {
                editDesignSetupGlobalSettingsLoading: true,
            })
        case EDIT_DESIGN_SETUP_GLOBAL_SETTINGS_SUCCESS:
            return Object.assign({}, state, {
                editDesignSetupGlobalSettingsLoading: false,
                //   designSetupGlobalSettings: action?.payload?.values
            })
        case EDIT_DESIGN_SETUP_GLOBAL_SETTINGS_ERROR:
            return Object.assign({}, state, {
                editDesignSetupGlobalSettingsLoading: false
            })
        case GET_DESIGN_SETUP_REQUEST:
            return Object.assign({}, state, {
                getDesignSetupLoading: true,
                selectedRestaurant: action.payload
            })
        case GET_DESIGN_SETUP_SUCCESS:
            return Object.assign({}, state, {
                getDesignSetupLoading: false,
                designSetup: action?.payload?.designSetup
            })
        case GET_DESIGN_SETUP_ERROR:
            return Object.assign({}, state, {
                getDesignSetupLoading: false,
                // designSetup: null
            })
        case EDIT_DESIGN_SETUP_REQUEST:
            return Object.assign({}, state, {
                editDesignSetupLoading: true,
            })
        case EDIT_DESIGN_SETUP_SUCCESS:
            return Object.assign({}, state, {
                editDesignSetupLoading: false,
            })
        case EDIT_DESIGN_SETUP_ERROR:
            return Object.assign({}, state, {
                editDesignSetupLoading: false,
            })
        default:
            return state;
    }
}

export default designSetup;
