import {
    GET_ORDERS_HISTORY_REQUEST,
    GET_ORDERS_HISTORY_SUCCESS,
    GET_ORDERS_HISTORY_ERROR,
    SET_ORDERS_HISTORY,
    RESET_ORDERS_HISTORY,
  
    SET_ORDERS_HISTORY_VIEW,
    SET_ORDERS_HISTORY_GROUP,
    SET_ACTIVE_ORDERS_HISTORY_FILTER,
    SET_IS_ORDERS_HISTORY_FILTER,
    SET_ORDERS_HISTORY_FILTER,
    SET_ORDERS_HISTORY_GRID_SORT_KEY,
    SET_ORDERS_HISTORY_GRID_SORTING_ORDER,
    CLEAR_ORDER_HISTORY_LOADING,
  } from "../actions/OrderHistoryActions";
  
  const initialState = {
    ordersHistory: [],
    ordersHistoryBranch: "",
    ordersHistoryRest: "",
    // order: null,
    getOrdersHistoryLoading: false,
    // getOrderLoading: false,
    page: 0,
    totalPages: 0,
    //filters and selected data
    ordersHistoryView: "grid",
    ordersHistoryGroup: "Active",
    activeOrdersHistoryFilter: "all-active-orders",
    isOrdersHistoryFilter: false,
    ordersHistoryFilter: {
      orderDate: { from: null, to: null },
      orderTime: { from: null, to: null },
      dueDate: { from: null, to: null },
      dueTime: { from: null, to: null },
      eodRange: { value: "last-7-days", from: null, to: null },
      orderType: [],
      paymentMethod: [],
      allergen: [],
      status: [],
    },
    ordersHistoryGridSortKey: "createdAt",
    ordersHistoryGridSortingOrder: "asc"
  };
  
  function orderHistory(state = initialState, action) {
    switch (action.type) {
      case CLEAR_ORDER_HISTORY_LOADING:
        return Object.assign({}, state, {
          getOrdersHistoryLoading: false,
          // getOrderLoading: false
        })
      case GET_ORDERS_HISTORY_REQUEST:
        return Object.assign({}, state, {
          getOrdersHistoryLoading: true,
        });
      case GET_ORDERS_HISTORY_SUCCESS:
        return Object.assign({}, state, {
          getOrdersHistoryLoading: false,
          ordersHistory: action?.payload?.orders,
          totalPages: action?.payload?.totalPages,
          page: action?.payload?.page,
          ordersHistoryBranch: action?.payload?.buuid,
          ordersHistoryRest: action?.payload?.ruuid
        });
      case GET_ORDERS_HISTORY_ERROR:
        return Object.assign({}, state, {
          getOrdersHistoryLoading: false,
          ordersHistory: [],
          ordersHistoryBranch: action?.payload?.buuid,
          ordersHistoryRest: action?.payload?.ruuid
        });
      case SET_ORDERS_HISTORY:
        return Object.assign({}, state, {
          ordersHistory: action?.payload?.data
        });
      // case GET_ORDER_REQUEST:
      //   return Object.assign({}, state, {
      //     getOrderLoading: true,
      //   });
      // case GET_ORDER_SUCCESS:
      //   return Object.assign({}, state, {
      //     getOrderLoading: false,
      //     order: action?.payload?.order,
      //   });
      // case GET_ORDER_ERROR:
      //   return Object.assign({}, state, {
      //     getOrderLoading: false,
      //     order: null,
      //   });
      case RESET_ORDERS_HISTORY:
        return Object.assign({}, state, {
          ordersHistory: [],
          // order: null,
          getOrdersHistoryLoading: false,
          ordersHistoryBranch: "",
          ordersHistoryRest: ""
          // getOrderLoading: false,
        });
      case SET_ORDERS_HISTORY_VIEW:
        return Object.assign({}, state, {
          ordersHistoryView: action?.payload?.value
        });
      case SET_IS_ORDERS_HISTORY_FILTER:
        return Object.assign({}, state, {
          isOrdersHistoryFilter: action?.payload?.value
        });
      case SET_ACTIVE_ORDERS_HISTORY_FILTER:
        return Object.assign({}, state, {
          activeOrdersHistoryFilter: action?.payload?.value
        });
      case SET_ORDERS_HISTORY_FILTER:
        return Object.assign({}, state, {
          ordersHistoryFilter: action?.payload?.value
        });
      case SET_ORDERS_HISTORY_GRID_SORT_KEY:
        return Object.assign({}, state, {
          ordersHistoryGridSortKey: action?.payload?.value
        });
      case SET_ORDERS_HISTORY_GRID_SORTING_ORDER:
        return Object.assign({}, state, {
          ordersHistoryGridSortingOrder: action?.payload?.value
        })
      default:
        return state;
    }
  }
  export default orderHistory;
  