import * as React from 'react'
import { InputLabel, MenuItem, FormControl, Select, FormHelperText } from '@mui/material'

export default function MainSelect({ value, handleChange, title, options, error, helperText, dropdownStyle, variant, ...props }) {
  const [open, setOpen] = React.useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }
  return (
    <FormControl fullWidth error={error}>
      <InputLabel id={`selectable-label-${title}`}>{title}</InputLabel>
      <Select
        labelId={`selectable-label-${title}`}
        id={`selectable-${title}`}
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        value={value}
        label={title}
        onChange={handleChange}
        style={dropdownStyle}
        variant={variant}
        {...props}
      >
        {options?.map((option, index) => (
          <MenuItem value={option.value} key={`opt-${index}`}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {error && helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}
