import {
  GET_EVENTS_REQUEST,
  GET_EVENTS_SUCCESS,
  GET_EVENTS_ERROR,
  CLEAR_ALL_EVENTS,
  ADD_EVENT_STARTED,
  ADD_EVENT_END,
  EDIT_EVENT_STARTED,
  EDIT_EVENT_END,
  DELETE_EVENT_STARTED,
  DELETE_EVENT_END,
  CLEAR_EVENTS_LOADING
} from "../actions/EventsActons";

const initialState = {
  loading: false,
  events: [],
  addLoading: false,
  editLoading: false,
  deleteLoading: false,
  selectedRestaurant: '',
  selectedBranch: ''
};

function event(state = initialState, action) {
  switch (action.type) {
    case CLEAR_EVENTS_LOADING:
      return Object.assign({}, state, {
        loading: false,
        addLoading: false,
        editLoading: false,
        deleteLoading: false,
      })
    case GET_EVENTS_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        selectedRestaurant: action.payload.ruuid,
        selectedBranch: action.payload.bruuid
      });
    case GET_EVENTS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        events: action.payload.events,
      });
    case GET_EVENTS_ERROR:
      return Object.assign({}, state, {
        loading: false,
        events: [],
      });

    case ADD_EVENT_STARTED:
      return Object.assign({}, state, {
        addLoading: true,
      });

    case ADD_EVENT_END:
      return Object.assign({}, state, {
        addLoading: false,
      });

    case EDIT_EVENT_STARTED:
      return Object.assign({}, state, {
        editLoading: true,
      });

    case EDIT_EVENT_END:
      return Object.assign({}, state, {
        editLoading: false,
      });

    case DELETE_EVENT_STARTED:
      return Object.assign({}, state, {
        deleteLoading: true,
      });

    case DELETE_EVENT_END:
      return Object.assign({}, state, {
        deleteLoading: false,
      });


    case CLEAR_ALL_EVENTS:
      return initialState

    default:
      return state;
  }
}

export default event;
