import React from 'react'

//REDUX
import { connect } from 'react-redux'

import { useHistory } from 'react-router-dom'

//COMPONENTS
import {
  Typography,
  Button,
  Box
} from '@mui/material'
import { getField, getTranslation } from '../../utils/utils'
import { getSVG } from '../../utils/getSvg'

const OfflinePage = ({
  language,
  translation
}) => {
  const history = useHistory()
  const dictionary = getTranslation('offline', translation)
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', position: 'relative' }}>
      <Box sx={{ backgroundColor: 'background.paper', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', padding: 8, borderRadius: '18px', zIndex: 999 }}>
        <Box>
          {getSVG('offline', null, { width: 300, height: 300 })}
        </Box>
        <Typography variant='h2'>
          {getField(dictionary, 'error', language)}
        </Typography>
        <Typography variant='h5'>
          {getField(dictionary, 'error-desc', language)}
        </Typography>
        <Button
          variant='contained'
          sx={{ borderRadius: '18px', marginTop: 7 }}
          onClick={() => window.location.reload()}
        >
          {getField(dictionary, 'refresh', language)}
        </Button>
      </Box>
      <Box sx={{ height: '250px', backgroundColor: '#b493be', width: '100%', position: 'absolute', top: '45%' }} />
    </div>
  )
}
const mapStateToProps = (state) => ({
  language: state.translation.language,
  translation: state.translation.translation
})
const mapDispatchToProps = (dispatch) => ({

})
export default connect(mapStateToProps, mapDispatchToProps)(OfflinePage)