import {
  GET_TAX_REQUEST,
  GET_TAX_SUCCESS,
  GET_TAX_ERROR,
  GET_BRANCH_TAXES_REQUEST,
  GET_BRANCH_TAXES_SUCCESS,
  GET_BRANCH_TAXES_ERROR,
  GET_PRODUCTS_TAXES_REQUEST,
  GET_PRODUCTS_TAXES_SUCCESS,
  GET_PRODUCTS_TAXES_ERROR,
  UPDATE_ALL_PRODUCTS_TAXES_STARTED,
  UPDATE_ALL_PRODUCTS_TAXES_END,
  UPDATE_PRODUCTS_TAXES_STARTED,
  UPDATE_PRODUCTS_TAXES_END,
  CLEAR_ALL_TAX,
  ADD_TAX_STARTED,
  ADD_TAX_END,
  EDIT_TAX_STARTED,
  EDIT_TAX_END,
  DELETE_TAX_STARTED,
  DELETE_TAX_END,
  UPDATE_BRANCH_TAXES_STARTED,
  UPDATE_BRANCH_TAXES_END,
  SET_TAX_POPUP,
  GET_MODIFIERS_TAXES_REQUEST,
  GET_MODIFIERS_TAXES_SUCCESS,
  GET_MODIFIERS_TAXES_ERROR,
  UPDATE_ALL_MODIFIERS_TAXES_STARTED,
  UPDATE_ALL_MODIFIERS_TAXES_END,
  CLEAR_TAX_LOADING
} from '../actions/TaxActions'

const initialState = {
  loading: false,
  taxes: [],
  taxPopup: false,
  addLoading: false,
  editLoading: false,
  deleteLoading: false,
  productsTaxesLoading: false,
  updateBranchTaxesLoading: false,
  modifiersTaxesLoading: false,
  branchTaxes: [],
  productTaxes: [],
  modifiersTaxes: [],
  selectedRestaurantBranch: '',
  selectedRestaurantProduct: '',
  selectedRestaurantModifiers: ''
}

function tax(state = initialState, action) {
  switch (action.type) {
    case CLEAR_TAX_LOADING:
      return Object.assign({}, state, {
        loading: false,
        addLoading: false,
        editLoading: false,
        deleteLoading: false,
        productsTaxesLoading: false,
        updateBranchTaxesLoading: false,
        modifiersTaxesLoading: false,
      })
    case GET_TAX_REQUEST:
      return Object.assign({}, state, {
        loading: true,
      })
    case GET_TAX_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        taxes: action.payload.taxes,
      })
    case GET_TAX_ERROR:
      return Object.assign({}, state, {
        loading: false,
        taxes: [],
      })
    case GET_BRANCH_TAXES_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        selectedRestaurantBranch: action.payload
      })
    case GET_BRANCH_TAXES_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        branchTaxes: action.payload,
      })
    case GET_BRANCH_TAXES_ERROR:
      return Object.assign({}, state, {
        loading: false,
        branchTaxes: [],
      })
    case UPDATE_BRANCH_TAXES_STARTED:
      return Object.assign({}, state, {
        updateBranchTaxesLoading: true,
      })

    case UPDATE_BRANCH_TAXES_END:
      return Object.assign({}, state, {
        updateBranchTaxesLoading: false,
      })
    case ADD_TAX_STARTED:
      return Object.assign({}, state, {
        addLoading: true,
      })

    case ADD_TAX_END:
      return Object.assign({}, state, {
        addLoading: false,
      })

    case EDIT_TAX_STARTED:
      return Object.assign({}, state, {
        editLoading: true,
      })

    case EDIT_TAX_END:
      return Object.assign({}, state, {
        editLoading: false,
      })

    case DELETE_TAX_STARTED:
      return Object.assign({}, state, {
        deleteLoading: true,
      })

    case DELETE_TAX_END:
      return Object.assign({}, state, {
        deleteLoading: false,
      })

    case SET_TAX_POPUP:
      return Object.assign({}, state, {
        taxPopup: action.payload,
      })
    case GET_PRODUCTS_TAXES_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        selectedRestaurantProduct: action.payload
      })
    case GET_PRODUCTS_TAXES_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        productTaxes: action.payload
      })
    case GET_PRODUCTS_TAXES_ERROR:
      return Object.assign({}, state, {
        loading: false,
        prpductTaxes: []
      })
    case UPDATE_ALL_PRODUCTS_TAXES_STARTED:
      return Object.assign({}, state, {
        productsTaxesLoading: true
      })
    case UPDATE_ALL_PRODUCTS_TAXES_END:
      return Object.assign({}, state, {
        productsTaxesLoading: false
      })
    case UPDATE_PRODUCTS_TAXES_STARTED:
      return Object.assign({}, state, {
        productsTaxesLoading: true
      })
    case UPDATE_PRODUCTS_TAXES_END:
      return Object.assign({}, state, {
        productsTaxesLoading: false
      })
    case UPDATE_ALL_MODIFIERS_TAXES_STARTED:
      return Object.assign({}, state, {
        modifiersTaxesLoading: true
      })
    case UPDATE_ALL_MODIFIERS_TAXES_END:
      return Object.assign({}, state, {
        modifiersTaxesLoading: false
      })
    case CLEAR_ALL_TAX:
      return Object.assign({}, state, {
        loading: false,
        taxPopup: false,
        addLoading: false,
        editLoading: false,
        deleteLoading: false,
        productsTaxesLoading: false,
        updateBranchTaxesLoading: false,
        modifiersTaxesLoading: false,
        branchTaxes: [],
        productTaxes: [],
        modifiersTaxes: [],
        selectedRestaurantBranch: '',
        selectedRestaurantProduct: '',
        selectedRestaurantModifiers: ''
      })
    case GET_MODIFIERS_TAXES_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        selectedRestaurantModifiers: action.payload
      })
    case GET_MODIFIERS_TAXES_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        modifiersTaxes: action.payload
      })
    case GET_MODIFIERS_TAXES_ERROR:
      return Object.assign({}, state, {
        loading: false,
        modifiersTaxes: []
      })
    default:
      return state
  }
}

export default tax
