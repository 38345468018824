import { getFloors, getTables, editOrAddFloorsAndTables, deleteTheFloor, deleteTable } from '../../config/config'
import { handleError } from '../../utils/utils'
import { toast } from 'react-toastify'

export const SWITCH_FLOOR = 'SWITCH_FLOOR';
export const UPDATE_FLOOR_TABLES = 'UPDATE_FLOOR_TABLES';
export const CHANGE_FLOOR_COLOR = 'CHANGE_FLOOR_COLOR';
export const CHANGE_FLOOR_BG = 'CHANGE_FLOOR_BG';
export const ADD_FLOOR = 'ADD_FLOOR';
export const RENAME_FLOOR = 'RENAME_FLOOR';
export const DELETE_FLOOR = 'DELETE_FLOOR';

export const GET_FLOORS_REQUEST = 'GET_FLOORS_REQUEST'
export const GET_FLOORS_SUCCESS = 'GET_FLOORS_SUCCESS'
export const GET_FLOORS_ERROR = 'GET_FLOORS_ERROR'

export const GET_TABLES_REQUEST = 'GET_TABLES_REQUEST'
export const GET_TABLES_SUCCESS = 'GET_TABLES_SUCCESS'
export const GET_TABLES_ERROR = 'GET_TABLES_ERROR'

export const ADD_TABLES_REQUEST = 'ADD_TABLES_REQUEST'
export const ADD_TABLES_SUCCESS = 'ADD_TABLES_SUCCESS'
export const ADD_TABLES_ERROR = 'ADD_TABLES_ERROR'

export const DELETE_FLOOR_REQUEST = 'DELETE_FLOOR_REQUEST';
export const DELETE_FLOOR_SUCCESS = 'DELETE_FLOOR_SUCCESS';
export const DELETE_FLOOR_ERROR = 'DELETE_FLOOR_ERROR';

export const DELETE_TABLE_REQUEST = 'DELETE_TABLE_REQUEST';
export const DELETE_TABLE_SUCCESS = 'DELETE_TABLE_SUCCESS';
export const DELETE_TABLE_ERROR = 'DELETE_TABLE_ERROR';

export const CLEAR_FLOOR_LOADING = "CLEAR_FLOOR_LOADING"


export const switchFloor = (floorId) => ({
  type: SWITCH_FLOOR,
  payload: floorId,
});

export const addFloor = (newFloor) => ({
  type: ADD_FLOOR,
  payload: newFloor,
});

export const updateFloorTables = (floorUid, updatedTables) => ({
  type: UPDATE_FLOOR_TABLES,
  payload: { floorUid, updatedTables },
});

export const changeFloorColor = (fId, newColor) => ({
  type: CHANGE_FLOOR_COLOR,
  payload: { fId, newColor },
});

export const changeFloorBackground = (flId, newImage) => ({
  type: CHANGE_FLOOR_BG,
  payload: { flId, newImage },
});

export const renameFloor = (fKey, newName) => ({
  type: RENAME_FLOOR,
  payload: { fKey, newName },
});

export const deleteFloor = (dfId) => ({
  type: DELETE_FLOOR,
  payload: dfId,
});

const actions = {
  getFloors: (ruuid, bruuid) => async (dispatch, getState) => {
    const currentRestaurant = getState().floorSetupReducer.selectedRestaurantFloor
    const currentBranch = getState().floorSetupReducer.selectedBranchFloor
    if (ruuid !== currentRestaurant || bruuid !== currentBranch)
      dispatch({
        type: GET_FLOORS_REQUEST,
        payload: {
          ruuid: ruuid,
          bruuid: bruuid
        }
      })
    await getFloors(ruuid, bruuid).then((response) => {
      if (response.status === 200) {
        // toast.success('Get Floors Successful')
        dispatch({
          type: GET_FLOORS_SUCCESS,
          payload: response.data.floors,
          selectedFloor: response?.data?.floors[0]?.uuid
        })

      } else {
        handleError(dispatch)(response, GET_FLOORS_ERROR, 'GET FLOORS ERROR')
      }
    })
  },

  getTables: (fuuid) => async (dispatch, getState) => {
    const currentFloor = getState().floorSetupReducer.selectedFloorId
    if (fuuid !== currentFloor)
      dispatch({
        type: GET_TABLES_REQUEST,
        payload: {
          fuuid: fuuid,

        }
      })
    await getTables(fuuid).then((response) => {
      if (response.status === 200) {
        // toast.success('Get Tables Successful')
        dispatch({
          type: GET_TABLES_SUCCESS,
          payload: response.data.tables
        })
      } else {
        handleError(dispatch)(response, GET_TABLES_ERROR, 'GET TABLES ERROR')
      }
    })
  },


  addTables: (ruuid, bruuid, data) => async (dispatch) => {
    dispatch({
      type: ADD_TABLES_REQUEST,
      // payload: {
      //   fuuid: fuuid,
      // },
    });

    await editOrAddFloorsAndTables(ruuid, bruuid, data).then((response) => {
      if (response.status === 200) {
        toast.success('Floor Saved Successfully')
        dispatch({
          type: ADD_TABLES_SUCCESS,
          payload: response.data,
        });

      } else {
        handleError(dispatch)(response, ADD_TABLES_ERROR, 'ADD TABLES ERROR');
      }
    });
  },


  deleteTheFloor: (ruuid, bruuid, fuuid) => async (dispatch) => {
    dispatch({
      type: DELETE_FLOOR_REQUEST,
    });

    try {
      const response = await deleteTheFloor(ruuid, bruuid, fuuid);
      if (response.status === 200) {
        toast.success('Floor Deleted')
        dispatch({
          type: DELETE_FLOOR_SUCCESS,
          payload: { fuuid },
        });
      } else {
        handleError(dispatch)(response, DELETE_FLOOR_ERROR, 'DELETE FLOOR ERROR');
      }
    } catch (error) {
      handleError(dispatch)(error, DELETE_FLOOR_ERROR, 'DELETE FLOOR ERROR');
    }
  },

  deleteTable: (fuuid, tuuid) => async (dispatch) => {
    dispatch({
      type: DELETE_TABLE_REQUEST,
    });

    try {
      const response = await deleteTable(fuuid, tuuid);
      if (response.status === 200) {
        toast.success('Table Deleted')
        dispatch({
          type: DELETE_TABLE_SUCCESS,
          payload: { tuuid },
        });
      } else {
        handleError(dispatch)(response, DELETE_TABLE_ERROR, 'DELETE TABLE ERROR');
      }
    } catch (error) {
      handleError(dispatch)(error, DELETE_TABLE_ERROR, 'DELETE TABLE ERROR');
    }
  }


}

export default actions